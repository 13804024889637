import React from 'react';

export default function ManualsSidebar({ setView }) {
  return (
    <div className="manuals-modal-sidebar">
      <p>Sidebar Content</p>
      <button onClick={() => setView('mainCategories')}>Main Categories</button>
      <button onClick={() => setView('manageManuals')}>Manage Manuals</button>
      <button onClick={() => setView('createManuals')}>Create Manuals</button>
    </div>
  );
}
