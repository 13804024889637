import { useEffect } from 'react';

const MasterUploadConfirm = ({ isOpen, onClose, onConfirm, onCancel }) => {
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (event) => {
      if (event.code === 'Space') { // Lytt etter 'Space' knappen
        event.preventDefault();
        onCancel(); // Utfør decline handlingen
      }
    };

    // Legg til event listener når modalen er åpen
    window.addEventListener('keydown', handleKeyDown);


    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onCancel]);

  if (!isOpen) return null;

  return (
    <div className="modal-background">
      <div className="edit-modal-content">
        <h2 style={{ color: 'white' }}>Master File Upload</h2>
        <p style={{ color: 'white' }}>
          Do you want to upload a master file, or other file as an internal file?
        </p>
        <div className="modal-actions">
          <button onClick={onConfirm} className="confirm-button">
            Upload Master
          </button>
          <button onClick={onCancel} className="decline-button">
            Continue Without
          </button>
        </div>
      </div>
    </div>
  );
};

export default MasterUploadConfirm;
