import React, { useState, useCallback } from 'react';

const ManualsSearchModal = ({ isOpen, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedManual, setSelectedManual] = useState(null);

    const handleSearch = useCallback(async () => {
        if (!searchTerm.trim()) {
            setSearchResults([]);
            return;
        }
    
        try {
            const response = await fetch(
                `${process.env.REACT_APP_SITE_URL}/ocsearch/manuals?query=${encodeURIComponent(searchTerm)}`
            );
            if (!response.ok) {
                throw new Error('Failed to fetch search results');
            }
    
            const data = await response.json();
            console.log('Search Results:', data); // Log fetched search results
            setSearchResults(data); // Update results to only include manuals
        } catch (error) {
            console.error('Error fetching search results:', error);
            setSearchResults([]); // Reset results on error
        }
    }, [searchTerm]);
    

    const handleManualClick = async (manualId) => {
        console.log('Clicked Manual ID:', manualId);

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manual/${manualId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch manual content');
            }

            const manual = await response.json();
            console.log('Fetched Manual:', manual);

            if (!manual || !manual.content) {
                throw new Error('Manual content not found');
            }

            setSelectedManual(manual);
        } catch (error) {
            console.error('Error fetching manual content:', error.message);
            setSelectedManual(null);
        }
    };

    const renderContent = () => {
        if (selectedManual) {
            return (
                <div className='manuals-center'>
                <div className="manual-details">
                    <h2>{selectedManual.title || 'Untitled Manual'}</h2>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: selectedManual.content || '<p>No content available.</p>',
                        }}
                    />
                    <button onClick={() => setSelectedManual(null)}>Back to Search Results</button>
                </div>
                </div>
            );
        }

        return (
            <ul className="search-results-list">
                {searchResults.map((manual) => (
                    <li key={manual.id} onClick={() => handleManualClick(manual.id)}>
                        {manual.name || `Manual ID: ${manual.id}`}
                    </li>
                ))}
            </ul>
        );
    };

    if (!isOpen) return null;

    return (
        <div className="manuals-modal-overlay manuals-modal-buttons">
            <div className="manuals-search-modal ">
            <button className="manuals-modal-close-button" onClick={onClose}>
          ✖
        </button>
                <h2>Search Manuals</h2>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search manuals..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                   <span> <button onClick={handleSearch}>Search</button></span>
                </div>
                <div className="search-modal-content2">{renderContent()}</div>
            </div>
        </div>
    );
};

export default ManualsSearchModal;
