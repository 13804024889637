import React, { useState, useEffect, useCallback, useRef } from 'react';
import './../../App.css';
import ManualsModal from './Manualsmodal';
import ManualsSearchModal from './ManualsSearchModal';
import { unstable_batchedUpdates } from 'react-dom';

export default function Manuals() {
    const [categories, setCategories] = useState([]); // Categories list
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // Search term
    const [selectedCategory, setSelectedCategory] = useState(null); // Selected category
    const [selectedSubcategory, setSelectedSubcategory] = useState(null); // Selected subcategory
    const [manualContent, setManualContent] = useState(null); // Content of the selected manual
    const [isAdmin, setIsAdmin] = useState(true); // Admin mode
    const [showAdminPanel, setShowAdminPanel] = useState(false); // State for showing/hiding admin panel
    const [subcategories, setSubcategories] = useState([]); // Subcategories list
    const [manualTitles, setManualTitles] = useState([]); // Manual titles list
    const [selectedManual, setSelectedManual] = useState(null); // Selected manual for detail view
    const [showManualsModal, setShowManualsModal] = useState(false);
    const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null); // Track selected sub-subcategory
    const [subSubcategories, setSubSubcategories] = useState([]); // Store sub-subcategories
    const isFetching = useRef(false); // Track ongoing fetches


    useEffect(() => {
        fetchCategories();
    }, []);

    const handleSearch = useCallback(async () => {
        if (!searchTerm.trim()) {
            setSearchResults([]);
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ocsearch?query=${encodeURIComponent(searchTerm)}`);
            if (!response.ok) {
                throw new Error('Failed to fetch search results');
            }
    
            const data = await response.json();
            setSearchResults(data);
        } catch (error) {
            console.error('Error fetching search results:', error);
            setSearchResults([]);
        }
    }, [searchTerm]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            handleSearch();
        }, 300); // Debounce to prevent frequent API calls
    
        return () => clearTimeout(timeoutId);
    }, [searchTerm, handleSearch]);

    // Fetch categories from API
    const fetchCategories = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/categories`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            // console.log('Fetched categories:', data);
            setCategories(data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    // Fetch subcategories based on selected category
    const fetchSubcategories = async (categoryId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}/subcategories`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            // console.log('Subcategories fetched:', data);
            setSubcategories(data);
            setSelectedSubSubcategory(null); // Clear sub-subcategory when switching subcategories
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    // Fetch sub-subcategories based on selected subcategory
    const fetchSubSubcategories = async (subcategoryId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}/sub-subcategories`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            // console.log('Sub-subcategories fetched:', data);
            setSubSubcategories(data);
        } catch (error) {
            console.error("Error fetching sub-subcategories:", error);
        }
    };
    
    // Fetch manual titles for the selected sub-subcategory
    
     const fetchManualTitles = useCallback(async (id, level) => {
        if (!id || isFetching.current) return;

        isFetching.current = true; // Mark fetch in progress
        try {
            const apiUrl =
                level === 'category'
                    ? `${process.env.REACT_APP_SITE_URL}/oc/manuals/category/${id}`
                    : level === 'subcategory'
                    ? `${process.env.REACT_APP_SITE_URL}/oc/manuals/subcategory/${id}`
                    : `${process.env.REACT_APP_SITE_URL}/oc/manuals/${id}`;

            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch manuals: ${response.status}`);
            }

            const data = await response.json();
            // console.log(`Fetched manuals for ${level} ID ${id}:`, data);

            const sortedManuals = Array.isArray(data)
            ? data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            : [];

            setManualTitles(sortedManuals);
            setSelectedManual(null);
            setManualContent('');
        } catch (error) {
            console.error(`Error fetching manual titles for ${level} ID ${id}:`, error.message);
        } finally {
            isFetching.current = false; // Reset fetch state
        }
    }, []);

    useEffect(() => {
        let timeout;
        if (selectedCategory && !selectedSubcategory) {
            console.log("Fetching manuals for category");
            timeout = setTimeout(() => fetchManualTitles(selectedCategory.id, 'category'), 100);
        } else if (selectedSubcategory && !selectedSubSubcategory) {
            console.log("Fetching manuals for subcategory");
            timeout = setTimeout(() => fetchManualTitles(selectedSubcategory.id, 'subcategory'), 100);
        } else if (selectedSubSubcategory) {
            console.log("Fetching manuals for sub-subcategory");
            timeout = setTimeout(() => fetchManualTitles(selectedSubSubcategory.id, 'subsubcategory'), 100);
        }

        return () => {
            console.log("Cleaning up effect");
            clearTimeout(timeout);
        };
    }, [selectedCategory?.id, selectedSubcategory?.id, selectedSubSubcategory?.id, fetchManualTitles]);

    // Fetch manual content based on manual ID
    const fetchManualContent = async (manualId) => {
        try {
            let apiUrl;
    
            // Determine the appropriate URL based on the selected level
            if (selectedSubSubcategory) {
                apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/${selectedSubSubcategory.id}`;
            } else if (selectedSubcategory) {
                apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/subcategory/${selectedSubcategory.id}`;
            } else if (selectedCategory) {
                apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/category/${selectedCategory.id}`;
            } else {
                throw new Error('No valid category, subcategory, or sub-subcategory selected');
            }
    
            const response = await fetch(apiUrl);
            const data = await response.json();
    
            // console.log('Fetched data:', data);
    
            if (!Array.isArray(data) || data.length === 0) {
                throw new Error('No manuals found for this category');
            }
    
            const manual = data.find(item => item.id === manualId);
            // console.log('Selected manual:', manual);
    
            if (!manual || !manual.content) {
                throw new Error('Manual content not found');
            }
    
            setManualContent(manual.content || ''); // Set the content
            setSelectedManual(manual); // Set the selected manual for breadcrumb
        } catch (error) {
            console.error('Error fetching manual content:', error.message);
        }
    };
    

    // Handle category click
    const handleCategoryClick = async (category) => {
        if (selectedCategory?.id === category.id) return; // Avoid redundant calls

        unstable_batchedUpdates(() => {
            setSelectedCategory(category);
            setSelectedSubcategory(null);
            setSelectedSubSubcategory(null);
            setManualTitles([]);
            setManualContent('');
            setSelectedManual(null);
        });

        await fetchManualTitles(category.id, 'category');
        await fetchSubcategories(category.id);
    };

    const handleSubcategoryClick = async (subcategory) => {
        if (selectedSubcategory?.id === subcategory.id) return;
        unstable_batchedUpdates(() => {
            setSelectedSubcategory(subcategory);
            setSelectedSubSubcategory(null);
            setManualTitles([]);
            setManualContent('');
            setSelectedManual(null);
        });

        await fetchManualTitles(subcategory.id, 'subcategory');
        await fetchSubSubcategories(subcategory.id);
    };

    const handleSubSubcategoryClick = async (subSubcategory) => {
        if (selectedSubSubcategory?.id === subSubcategory.id) return;
        unstable_batchedUpdates(() => {
            setSelectedSubSubcategory(subSubcategory);
            setManualTitles([]);
            setManualContent('');
            setSelectedManual(null);
        });

        await fetchManualTitles(subSubcategory.id, 'subsubcategory');
    };

    // Handle manual click (fetch content)
    const handleManualClick = async (manual) => {
        await fetchManualContent(manual.id);
    };

   
    // Clear manual content (when clicking the breadcrumb to go back)
    const clearManualContent = () => {
        unstable_batchedUpdates(() => {
            setManualContent('');
            setSelectedManual(null);
        });
    };

    const toggleAdminPanel = () => {
        setShowManualsModal(!showManualsModal);
    };

    // Render manual content
    const renderManualContent = () => (
        <div className="manual-content">
            {manualContent ? (
                <div>
                    <h3 className='manual-content-title'>{selectedManual?.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: manualContent }} />
                </div>
            ) : (
                <p>No manuals available for this subcategory.</p>
            )}
        </div>
    );

    const handleOpenSearchModal = () => {
        setIsSearchModalOpen(true);
    };

    const handleCloseSearchModal = () => {
        setIsSearchModalOpen(false);
    };

    const handleManualSelect = (manual) => {
        console.log('Manual selected:', manual);
        setIsSearchModalOpen(false); // Close the modal after selection
    };

    return (
        <div>
            <h2>Manuals</h2>
    
            {/* Main Layout */}
            <div className="manuals-center-layout">
                {/* Sidebar Menu */}
                <div className="manuals-center-sidebar">

                <ManualsSearchModal
                isOpen={isSearchModalOpen}
                onClose={handleCloseSearchModal}
                onSelectManual={handleManualSelect}
            />

                    <div>
                    
                    <button className="manuals-modal-buttons" onClick={handleOpenSearchModal}>
                        Search Manuals
                        </button>
    
                    </div>
                    <h3>Categories</h3>
                    {categories.length > 0 ? (
                        categories
                            .filter(cat => cat.title.toLowerCase().includes(searchTerm.toLowerCase()))
                            .map(category => (
                                <div key={category.id} className="manuals-center-category">
                                    <div
                                        className="manuals-center-category-title"
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        {category.title}
                                    </div>
                                </div>
                            ))
                    ) : (
                        <p>No categories available</p>
                    )}
    
                    {isAdmin && (
                        <div className="manuals-modal-buttons">
                            <button onClick={toggleAdminPanel}>
                                {showManualsModal ? 'Close Admin Panel' : 'Open Admin Panel'}
                            </button>
    
                            {showManualsModal && <ManualsModal onClose={toggleAdminPanel} />}
                        </div>
                    )}
                </div>
    
                {/* Content Area */}
                <div className="manuals-center-content">
                   {/* Breadcrumb Navigation */}
                        <div className="manuals-center-breadcrumb">
                            <span onClick={() => { setSelectedCategory(null); clearManualContent(); }} className="breadcrumb-link">
                                Manuals
                            </span>
                            {selectedCategory && (
                                <>
                                    {' > '}
                                    <span
                                        onClick={() => {
                                            setSelectedSubcategory(null);
                                            clearManualContent();
                                        }}
                                        className="breadcrumb-link"
                                    >
                                        {selectedCategory.title}
                                    </span>
                                </>
                            )}
                            {selectedSubcategory && (
                                <>
                                    {' > '}
                                    <span
                                        onClick={() => {
                                            setSelectedSubSubcategory(null);
                                            clearManualContent();
                                        }}
                                        className="breadcrumb-link"
                                    >
                                        {selectedSubcategory.title}
                                    </span>
                                </>
                            )}
                            {selectedSubSubcategory && (
                                <>
                                    {' > '}
                                    <span
                                        onClick={() => {
                                            unstable_batchedUpdates(() => {
                                                setSelectedManual(null); // Clear manual-specific states
                                                setManualContent('');   // Clear manual content
                                            });
                                        }}
                                        className="breadcrumb-link"
                                    >
                                        {selectedSubSubcategory.title}
                                    </span>
                                </>
                            )}
                            {selectedManual && (
                                <>
                                    {' > '}
                                    <span className="breadcrumb-link">{selectedManual.title}</span>
                                </>
                            )}
                        </div>

    
                   {/* Subcategories */}

                   
                        {selectedCategory && !selectedSubcategory && subcategories.length > 0 && (
                        <div className="manuals-center-box-layout">
                            {subcategories.map(subcategory => (
                            <div
                                key={subcategory.id}
                                className="manuals-center-box"
                                onClick={() => handleSubcategoryClick(subcategory)}
                            >
                                <h4>{subcategory.title}</h4>
                                <p>{subcategory.description}</p>
                            </div>
                            ))}
                        </div>
                        )}

                        {/* Sub-Subcategories */}
                        {selectedSubcategory && !selectedSubSubcategory && subSubcategories.length > 0 && (
                        <div className="manuals-center-box-layout">
                            {subSubcategories.map(subSubcategory => (
                            <div
                                key={subSubcategory.id}
                                className="manuals-center-box"
                                onClick={() => handleSubSubcategoryClick(subSubcategory)}
                            >
                                <h4>{subSubcategory.title}</h4>
                                <p>{subSubcategory.description}</p>
                            </div>
                            ))}
                        </div>
                        )}

                    {/* Manual Titles List */}
                    {manualTitles.length > 0 && !selectedManual && (
                        <div className="manuals-title-list">
                            <h3>Manuals</h3>
                            {manualTitles.map(manual => (
                                <div
                                    key={manual.id}
                                    className="manual-title"
                                    onClick={() => handleManualClick(manual)}
                                >
                                    {manual.title}
                                </div>
                            ))}
                        </div>
                    )}
    
                    {/* Fallback for No Manuals */}
                    {selectedCategory && manualTitles.length === 0 && !selectedManual && (
                        <div className="manuals-title-list">
                            <p></p>
                        </div>
                    )}
    
                    {/* Manual Content Section */}
                    {selectedManual && (
                        <div className="manual-details2">
                            {renderManualContent()}
                        </div>
                    )}
                     
                </div>
                
            </div>
            
        </div>
        
    );
    
}
