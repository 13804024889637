import React, { useState, useEffect } from 'react';

export default function Ooh({ onClose, id }) {
    const [overrideDates, setOverrideDates] = useState([]);
    const [newDate, setNewDate] = useState('');

    useEffect(() => {
    }, [id]);



    const fetchOverrideDates = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/override-dates`);
            const dates = await response.json();
            setOverrideDates(dates.map(date => date.split('T')[0])); // Format date to YYYY-MM-DD
        } catch (error) {
            console.error('Error fetching override dates:', error);
        }
    };

    const handleDateChange = (e) => {
        setNewDate(e.target.value);
    };

    const handleDateSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/override-dates`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ date: newDate })
            });
            if (response.ok) {
                fetchOverrideDates();
                setNewDate('');
            } else {
                console.error('Error adding override date');
            }
        } catch (error) {
            console.error('Error adding override date:', error);
        }
    };
    const handleDateDelete = async (date) => {
        try {
            const encodedDate = encodeURIComponent(date);
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/override-dates/${encodedDate}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                fetchOverrideDates();
            } else {
                const errorText = await response.text();
                console.error('Error deleting override date:', errorText);
            }
        } catch (error) {
            console.error('Error deleting override date:', error);
        }
    };




    return (
        <div className="ooh-background" onClick={onClose}>
            <div className="ooh-content" onClick={e => e.stopPropagation()}>
                <div className="override-dates-section">
                    <h3>Closed Dates</h3>
                    <ul>
                        {overrideDates.map((date, index) => (
                            <li key={index}>
                                {date}
                                <button onClick={() => handleDateDelete(date)}>Delete</button>
                            </li>
                        ))}
                    </ul>
                    <form onSubmit={handleDateSubmit}>
                        <label htmlFor="newDate">Add Closed Date:</label>
                        <input
                            type="date"
                            id="newDate"
                            value={newDate}
                            onChange={handleDateChange}
                            required
                        />
                        <button type="submit">Add Date</button>
                    </form>
                </div>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}
