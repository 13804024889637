import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../../auth/AuthProvider';
import { UserContext } from '../../context/UserContext';
import { useLocation } from 'react-router-dom';
import Newscenter from './Newscenter';
import Support from './Support';
import Dropbox from './Dropbox';
import Tickets from './Tickets';
import Optischool from './Optischool';
import Manuals from './Manuals';
import './../../App.css';

export default function Opticenter() {
    const { authToken } = useAuth();
    const { userProfile, setUserProfile } = useContext(UserContext);
    const [selectedCategory, setSelectedCategory] = useState('support');
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSupport, setIsSupport] = useState(true); 
    const [isTuner, setIsTuner] = useState(true);  
    const location = useLocation();

    // Menu access configuration
    const menuAccess = {
        newscenter: true, // Alle har tilgang til News
        support: true, // Alle
        tickets: true, // Alle
        dropbox: userProfile.approved === 1, // Kun brukere med approved = 1
        optischool: isAdmin, // Kun admins
        manuals: true, // Alle
    };

    // Category names for breadcrumb display
    const categoryNames = {
        newscenter: 'News', // Forhandlerbaserte nyheter, typ lik Optifiles forhandlere på facebook. (Optituing bransjerelaterte nyheter)
        support: 'Supportforum',
        tickets: 'Supporttickets',
        dropbox: 'Dropbox',
        optischool: 'E-learning',
        manuals: 'Knowledge database',
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
    
                if (response.ok) {
                    const data = await response.json();
                    setUserProfile(data);
                    setIsAdmin(data.admin === 1);
                    setIsTuner(data.tuner === 1);
                    setIsSupport(data.support === 1);
                } else {
                    throw new Error(`Failed to fetch user profile. Status: ${response.status}`);
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
    
        if (authToken) {
            fetchUserProfile();
        }
    }, [authToken, setUserProfile]);
    
    

    useEffect(() => {
        if (!document.getElementById('dropboxjs')) {
            const script = document.createElement('script');
            script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
            script.id = 'dropboxjs';
            script.setAttribute('data-app-key', 'oarwzxmdki584d4'); // Replace with your app key
            document.body.appendChild(script);
        }
    }, []);

    // Sync selectedCategory with the current URL
    useEffect(() => {
        if (location.pathname.startsWith('/manual')) {
            setSelectedCategory('manuals');
        }
    }, [location.pathname]);

    const renderCategory = () => {
        switch (selectedCategory) {
            case 'newscenter':
                return <Newscenter />;
            case 'support':
                return <Support isAdmin={isAdmin} isTuner={isTuner} isSupport={isSupport} />;
                case 'tickets':
                    return <Tickets userProfile={userProfile} isAdmin={isAdmin} />; // Send userProfile som prop
            case 'dropbox':
                return <Dropbox />;
            case 'optischool':
                return <Optischool userProfile={userProfile} isAdmin={isAdmin} />;
            case 'manuals':
                return <Manuals />;
            default:
                return <Newscenter />;
        }
    };

    const renderMenu = () => {
        return Object.keys(menuAccess).map((category) => {
            if (menuAccess[category]) {
                return (
                    <li
                        key={category}
                        className="opticenter-menu-title"
                        onClick={() => setSelectedCategory(category)}
                    >
                        {categoryNames[category]}
                    </li>
                );
            }
            return null;
        });
    };

    return (
        <div className="opticenter-container">
            <div className="opticenter-sidebar">
                <div className="opticenter-profile-section">
                </div>
                <div className="opticenter-category-section">
                    <ul>{renderMenu()}</ul>
                </div>
            </div>
            <div className="opticenter-content-section">
                {/* Breadcrumb section */}
                {/* <div className="opticenter-breadcrumb">
                    <p>
                        <span
                            className="breadcrumb-link"
                            onClick={() => setSelectedCategory('newscenter')}
                        >
                            Opticenter
                        </span>
                        {' > '}
                        <span className="breadcrumb-link" onClick={() => setSelectedCategory(selectedCategory)}>
                            {categoryNames[selectedCategory]}
                        </span>
                    </p>
                </div> */}
                {menuAccess[selectedCategory] ? renderCategory() : <p>Access Denied: Section Restricted</p>}
            </div>
        </div>
    );
}
