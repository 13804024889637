import React, { useState, useEffect } from 'react';
import ManualsSidebar from './ManualsSidebar';
import ManualsCategoryManager from './ManualsCategoryManager';
import ManualsManualManager from './ManualsManualManager';
import ManualsManager from './ManualsManager';

export default function ManualsModal({ onClose }) {
  const [view, setView] = useState('mainCategories');
  const [categories, setCategories] = useState([]); // State to store categories
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null);

  // Define the fetchCategories function
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories`);
      const data = await response.json();
      setCategories(data); // Update state with fetched categories
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Fetch categories on component mount
  useEffect(() => {
    fetchCategories();
  }, []);



  return (
    <div className="manuals-modal-overlay">
      
      <div className="manuals-modal-content">
        {/* Close Button */}
        <button className="manuals-modal-close-button" onClick={onClose}>
          ✖
        </button>

        {/* Sidebar */}
        <div className="manuals-modal-sidebar">
          <ManualsSidebar setView={setView} />
        </div>

        {/* Main Content */}
        <div className="manuals-modal-main-content">
          {view === 'mainCategories' && (
            <ManualsCategoryManager
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedSubcategory={selectedSubcategory}
              setSelectedSubcategory={setSelectedSubcategory}
              fetchCategories={fetchCategories}
              categories={categories}
              setCategories={setCategories}
            />
          )}
          {view === 'createManuals' && (
                <ManualsManualManager
                categories={categories}
                fetchCategories={fetchCategories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedSubcategory={selectedSubcategory}
                setSelectedSubcategory={setSelectedSubcategory}
              />
                )}

          {view === 'manageManuals' && (
                          <ManualsManager
                          categories={categories}
                          fetchCategories={fetchCategories}
                          selectedCategory={selectedCategory}
                          setSelectedCategory={setSelectedCategory}
                          selectedSubcategory={selectedSubcategory}
                          setSelectedSubcategory={setSelectedSubcategory}
                        />
                          )}

        </div>
      </div>
    </div>
  );
}
