import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';
import logo from '../assets/logo.png'; // Juster stien til den faktiske plasseringen


export default function TopBar({ openDatabaseModal, openUploadModal }) {

    const [pendingFilesCount, setPendingFilesCount] = useState(0); // State for antall filer i kø
    const nuh = new Date().toLocaleTimeString('no-NO', { timeZone: 'Europe/Oslo' });
    const [timeNow, setTimeNow] = useState(() => new Date());

    const fetchPendingFilesCount = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/pending-count`);
            const data = await response.json();

            if (response.ok) {
                setPendingFilesCount(data.pendingCount);
            } else {
                console.error('Failed to fetch pending files count:', data.message);
            }
        } catch (error) {
            console.error('Error fetching pending files count:', error);
        }
    };

    useEffect(() => {
            fetchPendingFilesCount(); // Hent filer i kø
    }, []);
    
    useEffect(() => {
        const interval = setInterval(() => {
            fetchPendingFilesCount(); 
        }, 20000); 

        return () => clearInterval(interval); // Rydd opp når komponenten demonteres
    }, []); 

    useEffect(() => {
        const timer = setInterval(() => {
          setTimeNow(new Date());
        }, 1000);
    
        return () => clearInterval(timer); // Cleanup
      }, []);

    const getQueueClass = () => {
        if (pendingFilesCount === 0) {
            return 'queue-none';
        } else if (pendingFilesCount >= 1 && pendingFilesCount <= 3) {
            return 'queue-low';
        } else if (pendingFilesCount >= 4 && pendingFilesCount <= 5) {
            return 'queue-medium';
        } else if (pendingFilesCount >= 6) {
            return 'queue-high';
        }
    };
    const navigate = useNavigate();
    return (
        <nav>
            <img 
                src={logo} 
                alt="Logo" 
                className="logo" 
                onClick={() => navigate('/')} // Redirects to the FilePortal
                style={{ cursor: 'pointer' }} // Gjør det tydelig at logoen er klikkbar
            />
            <ul className="topbarlinks">
                <li><Link to="/" onClick={openUploadModal}>UPLOAD</Link></li>
                <li><Link to="/">FILEPORTAL</Link></li>
                <li><Link to="/" onClick={openDatabaseModal}>DATABASE</Link></li>
                <li><Link to="/opticenter">SUPPORT</Link></li>
            </ul>
            <div className="queuecontainer">            
                <div className={`queuetext ${getQueueClass()}`}>
                {pendingFilesCount} FILES IN QUEUE{' '}
                {timeNow.toLocaleTimeString('no-NO', { timeZone: 'Europe/Oslo' })}
                </div>
            </div>
        </nav>
    );
}
