import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AutoresponderStats = () => {
    const [tunerStats, setTunerStats] = useState([]);
    const [tunerStatsToolType1, setTunerStatsToolType1] = useState([]); // NEW
    const [tunerUploads, setTunerUploads] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [chartDataToolType1, setChartDataToolType1] = useState(null); // NEW
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch the default stats
                const statsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/tuner-stats`);
                const statsData = await statsResponse.json();

                // Fetch stats only for tooltype=1
                const statsResponseToolType1 = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/tuner-stats-tooltype1`);
                const statsDataToolType1 = await statsResponseToolType1.json();

                // Fetch the uploads
                const uploadsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/tuner-135-uploads`);
                const uploadsData = await uploadsResponse.json();
    
                // Filter out data before November 2024 for the "stats" arrays
                const filteredStats = statsData.filter(({ year, month }) => year > 2024 || (year === 2024 && month >= 11));
                const filteredStatsToolType1 = statsDataToolType1.filter(({ year, month }) => year > 2024 || (year === 2024 && month >= 11));
                
                // Filter the uploads from 2024-11-01 (or 2024-12-01 if needed, matching your logic)
                const filteredUploads = uploadsData.filter(({ created }) => new Date(created) >= new Date('2024-11-01'));
    
                setTunerStats(filteredStats);
                setTunerStatsToolType1(filteredStatsToolType1); // NEW
                setTunerUploads(filteredUploads);

                // Process the chart data
                processChartData(filteredStats);
                processChartDataToolType1(filteredStatsToolType1); // NEW

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, []);
    
    const calculatePercentages = () => {
        const total = tunerUploads.length;
        if (total === 0) {
            return {
                egr: 0,
                dpf: 0,
                adblue: 0,
                nsl: 0,
                o2: 0,
            };
        }
        const egrCount = tunerUploads.filter(upload => upload.egr === 1).length;
        const dpfCount = tunerUploads.filter(upload => upload.dpf === 1).length;
        const adblueCount = tunerUploads.filter(upload => upload.adblue === 1).length;
        const nslCount = tunerUploads.filter(upload => upload.nsl === 1).length;
        const o2Count = tunerUploads.filter(upload => upload.o2 === 1).length;

        return {
            egr: ((egrCount / total) * 100).toFixed(2),
            dpf: ((dpfCount / total) * 100).toFixed(2),
            adblue: ((adblueCount / total) * 100).toFixed(2),
            nsl: ((nslCount / total) * 100).toFixed(2),
            o2: ((o2Count / total) * 100).toFixed(2),
        };
    };

    const percentages = calculatePercentages();

    // Process data for the default chart
    const processChartData = (statsData) => {
        // Group data by month-year
        const aggregatedData = statsData.reduce((acc, { year, month, total_count, tuner_135_count }) => {
            const label = `${year}-${month.toString().padStart(2, '0')}`;
            if (!acc[label]) {
                acc[label] = { total_count: 0, tuner_135_count: 0 };
            }
            acc[label].total_count += total_count;
            acc[label].tuner_135_count += tuner_135_count;
            return acc;
        }, {});

        // Extract labels and values
        const labels = Object.keys(aggregatedData);
        const totalCounts = labels.map((label) => aggregatedData[label].total_count);
        const tunerCounts = labels.map((label) => aggregatedData[label].tuner_135_count);

        // Percentage dataset
        const percentageData = labels.map((label) => {
            const { total_count, tuner_135_count } = aggregatedData[label];
            if (total_count === 0) return 0;
            return ((tuner_135_count / total_count) * 100).toFixed(2);
        });

        // Set the chart data
        setChartData({
            labels,
            datasets: [
                {
                    label: 'Total Files (All Tools)',
                    data: totalCounts,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Files by AuRes',
                    data: tunerCounts,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Percentage by AuRes (%)',
                    data: percentageData,
                    backgroundColor: 'rgba(255, 206, 86, 0.6)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    type: 'line',
                    borderWidth: 2,
                    yAxisID: 'y2',
                },
            ],
        });
    };

    // Process data for the "tooltype=1" chart
    const processChartDataToolType1 = (statsData) => {
        // Group data by month-year
        const aggregatedData = statsData.reduce((acc, { year, month, total_count, tuner_135_count }) => {
            const label = `${year}-${month.toString().padStart(2, '0')}`;
            if (!acc[label]) {
                acc[label] = { total_count: 0, tuner_135_count: 0 };
            }
            acc[label].total_count += total_count;
            acc[label].tuner_135_count += tuner_135_count;
            return acc;
        }, {});

        // Extract labels and values
        const labels = Object.keys(aggregatedData);
        const totalCounts = labels.map((label) => aggregatedData[label].total_count);
        const tunerCounts = labels.map((label) => aggregatedData[label].tuner_135_count);

        // Percentage dataset
        const percentageData = labels.map((label) => {
            const { total_count, tuner_135_count } = aggregatedData[label];
            if (total_count === 0) return 0;
            return ((tuner_135_count / total_count) * 100).toFixed(2);
        });

        // Set the second chart data
        setChartDataToolType1({
            labels,
            datasets: [
                {
                    label: 'Total Files (Autotuner)',
                    data: totalCounts,
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Files by AuRes',
                    data: tunerCounts,
                    backgroundColor: 'rgba(153, 102, 255, 0.6)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Percentage by AuRes (%) Only Autotuner files',
                    data: percentageData,
                    backgroundColor: 'rgba(255, 206, 86, 0.6)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    type: 'line',
                    borderWidth: 2,
                    yAxisID: 'y2',
                },
            ],
        });
    };

    return (
        <div>
            <div style={{ width: '80%', margin: '0 auto', marginBottom: '20px' }}>
                {isLoading ? (
                    <p>Loading chart data...</p>
                ) : (
                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Stats for AuRes (All Tool Types)',
                                },
                            },
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    title: {
                                        display: true,
                                        text: 'Count',
                                    },
                                },
                                y2: {
                                    position: 'right',
                                    beginAtZero: true,
                                    min: 0,       // Sett minsteverdi
                                    max: 100,     // Sett maksverdi
                                    title: {
                                      display: true,
                                      text: 'Percentage (%)',
                                    },
                                    grid: {
                                      drawOnChartArea: false,
                                    },
                                  },
                                  
                                x: {
                                    title: {
                                        display: true,
                                        text: 'Months',
                                    },
                                },
                            },
                        }}
                    />
                )}
            </div>

            {/* SECOND CHART FOR TOOLTYPE=1 */}
            <div style={{ width: '80%', margin: '0 auto', marginBottom: '20px' }}>
                {isLoading ? (
                    <p>Loading chart data (tooltype=1)...</p>
                ) : (
                    <Bar
                        data={chartDataToolType1}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Stats for AuRes (Only Autotuner)',
                                },
                            },
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    title: {
                                        display: true,
                                        text: 'Count',
                                    },
                                },
                                y2: {
                                    position: 'right',
                                    beginAtZero: true,
                                    min: 0,       // Sett minsteverdi
                                    max: 100,     // Sett maksverdi
                                    title: {
                                      display: true,
                                      text: 'Percentage (%)',
                                    },
                                    grid: {
                                      drawOnChartArea: false,
                                    },
                                  },
                                  
                                x: {
                                    title: {
                                        display: true,
                                        text: 'Months',
                                    },
                                },
                            },
                        }}
                    />
                )}
            </div>

            <div style={{ marginTop: '20px' }}>
                <p>EGR: {percentages.egr}%</p>
                <p>DPF: {percentages.dpf}%</p>
                <p>AdBlue: {percentages.adblue}%</p>
                <p>NSL: {percentages.nsl}%</p>
                <p>O2: {percentages.o2}%</p>
            </div>

            {isLoading ? (
                <p>Loading vehicle data...</p>
            ) : tunerUploads.length > 0 ? (
                <>
                    <table
                        style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            margin: '20px 0',
                        }}
                    >
                        <thead>
                            <tr
                                style={{
                                    backgroundColor: '#333',
                                    color: '#fff',
                                    textAlign: 'left',
                                }}
                            >
                                <th style={{ padding: '8px' }}>ID</th>
                                <th style={{ padding: '8px' }}>Type</th>
                                <th style={{ padding: '8px' }}>Dealer</th>
                                <th style={{ padding: '8px' }}>Tuning Type</th>
                                <th style={{ padding: '8px' }}>Created</th>
                                <th style={{ padding: '8px' }}>Charged</th>
                                <th style={{ padding: '8px' }}>EGR</th>
                                <th style={{ padding: '8px' }}>DPF</th>
                                <th style={{ padding: '8px' }}>AdBlue</th>
                                <th style={{ padding: '8px' }}>NSL</th>
                                <th style={{ padding: '8px' }}>O2</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tunerUploads.map((upload, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        borderBottom: '1px solid #ddd',
                                        textAlign: 'left',
                                    }}
                                >
                                    <td style={{ padding: '8px' }}>{upload.id}</td>
                                    <td style={{ padding: '8px' }}>
                                        {upload.vehicle_name || 'Unknown'}
                                    </td>
                                    <td style={{ padding: '8px' }}>
                                        {upload.member_name || 'Unknown'}
                                    </td>
                                    <td style={{ padding: '8px' }}>
                                        {upload.tuning_type_value || 'N/A'}
                                    </td>
                                    <td style={{ padding: '8px' }}>
                                        {new Date(upload.created).toLocaleDateString()}
                                    </td>
                                    <td style={{ padding: '8px' }}>
                                        {upload.charged}
                                    </td>
                                    <td
                                        style={{
                                            padding: '8px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {upload.egr === 1 ? '✅' : '❌'}
                                    </td>
                                    <td
                                        style={{
                                            padding: '8px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {upload.dpf === 1 ? '✅' : '❌'}
                                    </td>
                                    <td
                                        style={{
                                            padding: '8px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {upload.adblue === 1 ? '✅' : '❌'}
                                    </td>
                                    <td
                                        style={{
                                            padding: '8px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {upload.nsl === 1 ? '✅' : '❌'}
                                    </td>
                                    <td
                                        style={{
                                            padding: '8px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {upload.o2 === 1 ? '✅' : '❌'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <p>No data available for tuner 135.</p>
            )}
        </div>
    );
};

export default AutoresponderStats;
