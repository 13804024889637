import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { UserContext } from '../context/UserContext';
//import useFCM from './useFCM';
import FileDetails from './FileDetails';
import Profilesite from './Profilesite';
import Adminsite from './Adminsite';
import Oohsite from './Oohsite';
import Customers2 from './Customers2';
import Tossite from './Tossite';
import Chat from './Chat';
import VehicleRow from './VehicleRow';
import WarningSection from './WarningSection';
import StatsSection from './StatsSection';
import LatestAlerts from './LatestAlerts';
import ServiceStatus from './ServiceStatus';
import ScrollFeed from './ScrollFeed';
import ProfileSection from './ProfileSection';
import SearchAndFilterSection from './SearchAndFilterSection';


const FilePortal = () => {
  const [unreadForumCount, setUnreadForumCount] = useState(0);
  const [unreadSupportTicketCount, setUnreadSupportTicketCount] = useState(0);
  const [showChat, setShowChat] = useState(false);
  const [vehicleData, setVehicleData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0); // State for uleste meldinger
  const [showModal, setShowModal] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showCustomersModal, setShowCustomersModal] = useState(false);
  const [showTosModal, setShowTosModal] = useState(false);
  const { authToken, logout } = useAuth();
  const { userProfile, setUserProfile } = useContext(UserContext);
  const [overrideDates, setOverrideDates] = useState([]);
  const [isAutoRefreshOn, setIsAutoRefreshOn] = useState(false);
  const [dealers, setDealers] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState('');
  const [wipOnly, setWipOnly] = useState(false);
  const [nsaOnly, setNsaOnly] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [taskCountToday, setTaskCountToday] = useState(0);
  const [userStats, setUserStats] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const [initialWarnings, setInitialWarnings] = useState([]);
  const [latestUploads, setLatestUploads] = useState([]);
  const [topMembers, setTopMembers] = useState([]);
  const [activeAdminComponent, setActiveAdminComponent] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState(new Set());
  const [ws, setWs] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(5); // Start med 5 sekunder

  const isAdmin = userProfile.admin === 1;
  const isTuner = userProfile.tuner === 1;
  const isSupport = userProfile.support === 1;

  const fetchPendingCount = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ooh/pending-count`);
        const data = await response.json();
        setPendingCount(data.pendingCount || 0);
    } catch (error) {
        console.error('Error fetching pending requests count:', error);
    }
};

useEffect(() => {
  if (!userProfile?.id) return;

  const fetchAllData = async () => {
    try {
      if (isAdmin || isTuner) {
        await fetchPendingCount();
      }

      await Promise.all([
        fetchUnreadMessagesCount(),
        fetchTicketCount(),
        fetchUnreadForumCount()
      ]);
    } catch (error) {
      console.error('Feil ved henting av data:', error);
    }
  };

  fetchAllData();
}, [userProfile.id, isAdmin, isTuner]);



useEffect(() => {
  if (!userProfile.id) return;

  const websocket = new WebSocket(process.env.REACT_APP_WS_URL);

  websocket.onopen = () => {
    websocket.send(JSON.stringify({
      type: 'identify',
      userId: userProfile.id
    }));

    const heartbeatInterval = setInterval(() => {
      if (websocket.readyState === WebSocket.OPEN) {
        websocket.send(JSON.stringify({ type: 'heartbeat' }));
      }
    }, 30000);

    websocket.heartbeatInterval = heartbeatInterval;
  };

  websocket.onmessage = (event) => {
    const data = JSON.parse(event.data);

    switch (data.type) {
      case 'status':
        setOnlineUsers(prev => {
          const newSet = new Set(prev);
          if (data.data.status === 'online') {
            newSet.add(data.data.userId);
          } else {
            newSet.delete(data.data.userId);
          }
          return newSet;
        });
        break;

      case 'notification':
        // The notification handling is now done in LatestAlerts component
        break;

      default:
        console.log('Unknown message type:', data.type);
    }
  };

  websocket.onclose = () => {
    if (websocket.heartbeatInterval) {
      clearInterval(websocket.heartbeatInterval);
    }
  };

  setWs(websocket);

  return () => {
    if (websocket.heartbeatInterval) {
      clearInterval(websocket.heartbeatInterval);
    }
    websocket.close();
  };
}, [userProfile.id]);

const fetchVehicleData = useCallback(async () => {
  let url = `${process.env.REACT_APP_SITE_URL}/uploads?page=${page}&limit=100&searchText=${encodeURIComponent(searchText)}&wipOnly=${wipOnly}&nsaOnly=${nsaOnly}`;
  if (!(isAdmin || isTuner)) {
    if (!selectedDealer || selectedDealer === 'default') {
      // console.log('Fetching default files for DID:', userProfile.did); // Log DID
      url += `&did=${userProfile.did}`; // Standardvisning bruker `did`
    } else if (selectedDealer === 'all') {
      // console.log('Fetching all files for PIDs'); // Log "Show All"
      url += `&showAll=true`;
    } else {
      // console.log('Fetching files for selected dealer (PID):', selectedDealer); // Log valgt PID
      url += `&pid=${selectedDealer}`;
    }
  }

  if (isSupport) {
    // console.log('Support role detected'); // Log support-rolle
    url += `&support=true`;
  }

  if (selectedDealer && selectedDealer !== 'all') {
    // console.log('Filtering by dealer ID:', selectedDealer); // Log dealer-id
    url += `&dealerId=${selectedDealer}`;
  }

  // console.log('Fetching vehicle data with URL:', url); // Log hele URL-en

  try {
    const response = await fetch(url, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    const newData = await response.json();
    if (response.ok) {
      // console.log('Fetched vehicle data:', newData); // Log fetched data
      setVehicleData(prev => (page === 1 ? newData : [...prev, ...newData]));
    } else {
      console.error("Failed to fetch vehicle data:", newData.message);
    }
  } catch (error) {
    console.error("Error fetching vehicle data:", error);
  }
}, [page, searchText, wipOnly, nsaOnly, authToken, isAdmin, isTuner, isSupport, userProfile.did, selectedDealer]);



  useEffect(() => {
    fetchVehicleData();
  }, [fetchVehicleData]);

  useEffect(() => {
    const fetchTaskCountToday = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SITE_URL + '/tasks/count-today', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });

        const data = await response.json();
        if (response.ok) {
          setTaskCountToday(data.count);
        } else {
          console.error('Failed to fetch task count. Server responded with:', data.message);
        }
      } catch (error) {
        console.error('Error fetching task count:', error);
      }
    };
    fetchTaskCountToday();
  }, [authToken]);



    const fetchInitialData = useCallback(async () => {
    const fetchDealers = fetch(`${process.env.REACT_APP_SITE_URL}/dealers`).then(res => res.json());

    const fetchOverrideDates = fetch(`${process.env.REACT_APP_SITE_URL}/override-dates`, {
      headers: { Authorization: `Bearer ${authToken}` },
    }).then(res => res.json());


    const fetchLatestUploads = fetch(`${process.env.REACT_APP_SITE_URL}/uploads/latest`, {
      headers: { Authorization: `Bearer ${authToken}` },
    }).then(res => res.json());

    const { vehicles, members } = await fetchLatestUploads;


    const [dealersData, datesData, uploadsData] = await Promise.all([
      fetchDealers, fetchOverrideDates, fetchLatestUploads
    ]);

    setDealers(dealersData);
    setOverrideDates(datesData.map(date => new Date(date).toISOString().split('T')[0]));
    setLatestUploads(uploadsData);
    setLatestUploads(vehicles); // Setter kjøretøy
    setTopMembers(members); // Setter medlemmer
  }, [authToken]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const fetchUserProfile = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.ok) {
        const data = await response.json();
        setUserProfile(data);
      } else {
        throw new Error('Failed to fetch user profile');
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  }, [authToken, setUserProfile]);

  useEffect(() => {
    if (authToken) {
      fetchUserProfile();
    }
  }, [authToken, fetchUserProfile]);

  const fetchStats = useCallback(async () => {
    const url = isAdmin || isTuner
      ? `${process.env.REACT_APP_SITE_URL}/uploads/stats`
      : `${process.env.REACT_APP_SITE_URL}/uploads/stats/${userProfile.did}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        setUserStats(data);
      } else {
        console.error('Failed to fetch stats:', data.message);
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  }, [isAdmin, isTuner, userProfile.did]);

  useEffect(() => {
    if (userProfile.id) {
      fetchStats();
    }
  }, [userProfile.id, fetchStats]);




  const handleUpdateStatus = useCallback(async (id, newStatus, isAdmin, tuner) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id, status: newStatus, isAdmin }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      if (newStatus === 2) {
        logDoneAction(id, userProfile.name);  // Pass userProfile.name
      }

      fetchVehicleData();
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  }, [authToken, fetchVehicleData]);

  const logDoneAction = async (id, username) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/log-done-action`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`, // Use authToken here for consistency
        },
        body: JSON.stringify({
          fileid: id,      // Corresponds to the vehicle id
          user: username   // Send userProfile.name as the user
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to log the done action');
      }

      // console.log('Action logged successfully!');
    } catch (error) {
      console.error('Error logging the done action:', error);
    }
  };

  const handleDelete = useCallback(async (fileId) => {
    if (!isAdmin) {
      console.error("Unauthorized: Only admins can delete files.");
      return;
    }

    // Confirmation dialog
    const confirmDelete = window.confirm(`Er du sikker på at du vil slette filen med ID: ${fileId}?`);
    if (!confirmDelete) {
      // console.log("Sletting av fil avbrutt.");
      return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/deleteFile`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({ id: fileId }),
        });

        if (response.ok) {
            // console.log('File deleted successfully!');
            logDeleteAction(fileId, userProfile.name); // Log deletion action with userProfile.name
            fetchVehicleData(); // Refresh the vehicle data after deletion
        } else {
            console.error('Failed to delete file');
        }
    } catch (error) {
        console.error('Error deleting file:', error);
    }
}, [authToken, fetchVehicleData, isAdmin, userProfile.name]);





  const logDeleteAction = async (fileId, username) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/log-delete-action`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
                fileId: fileId,      // Corresponds to the vehicle id
                user: username       // Send userProfile.name as the user
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to log the delete action');
        }

        // console.log('Delete action logged successfully!');
    } catch (error) {
        console.error('Error logging the delete action:', error);
    }
};



  const toggleDropdown = useCallback((index) => {
    setShowDropdown(prevState => {
      if (prevState[index]) {
        return {};
      }
      return { [index]: true };
    });
  }, []);

  const handleAction = useCallback(async (index, action) => {
    let newStatus;
    switch (action) {
      case 'Sent':
        newStatus = 0;
        break;
      case 'In progress':
        newStatus = 1;
        break;
      case 'Ready':
        newStatus = 2;
        break;
      case 'Hold':
        newStatus = 3;
        break;
      case 'Support':
        newStatus = 4;
        break;
      case 'Delete':
        await handleDelete(vehicleData[index].id); // Call handleDelete for 'Delete' action
        return;
      case 'Credback':
        // Implement creditback logic here
        return;
      default:
        return;
    }

    await handleUpdateStatus(vehicleData[index].id, newStatus, isAdmin);
    setShowDropdown({});
  }, [vehicleData, handleUpdateStatus, isAdmin, handleDelete]);

  const sortedVehicleData = useMemo(() => {
    return [...vehicleData].sort((a, b) => {
      const alertA = ((isAdmin || isTuner) && a.tunercheck === 1) || (!isAdmin && !isTuner && a.dealercheck === 1);
      const alertB = ((isAdmin || isTuner) && b.tunercheck === 1) || (!isAdmin && !isTuner && b.dealercheck === 1);
      if (a.status !== 2 && b.status === 2) return -1;
      if (a.status === 2 && b.status !== 2) return 1;
      if (alertA && !alertB) return -1;
      if (!alertA && alertB) return 1;
      return b.id - a.id;
    });
  }, [vehicleData, isAdmin, isTuner]);

// // UNDER LIGGER TEST FOR Å SJEKKE AT TING SORTERES RIKTIG PÅ ID UAVHENGIG AV STATUS
// const priorityList = sortedVehicleData.filter(vehicle => vehicle.status !== 2 ||
//   (isAdmin && vehicle.tunercheck === 1) || (!isAdmin && vehicle.dealercheck === 1));
// const otherList = sortedVehicleData.filter(vehicle => !priorityList.includes(vehicle));

const priorityList = useMemo(() => {
  return sortedVehicleData
    .filter(vehicle =>
      vehicle.status !== 2 ||
      ((isAdmin || isTuner) && vehicle.tunercheck === 1) ||
      (!isAdmin && !isTuner && vehicle.dealercheck === 1)
    )
    .sort((a, b) => {
      if (a.priority !== b.priority) {
        return b.priority - a.priority; // Sort by priority first (ascending)
      }
      return b.id - a.id; // If priorities are equal, sort by ID (descending)
    });
}, [sortedVehicleData, isAdmin, isTuner]);


const otherList = useMemo(() => {
  return sortedVehicleData
    .filter(vehicle =>
      !(
        vehicle.status !== 2 ||
        ((isAdmin || isTuner) && vehicle.tunercheck === 1) ||
        (!isAdmin && !isTuner && vehicle.dealercheck === 1)
      )
    )
    .sort((a, b) => b.id - a.id); // Sort by ID in descending order
}, [sortedVehicleData, isAdmin, isTuner]);


const [currentView, setCurrentView] = useState(0); // 0 = View 1, 1 = View 2, 2 = View 3

const handleToggleContent = () => {
  setCurrentView((prevView) => (prevView + 1) % 3); // Sykl gjennom 3 visninger
};


  const handleRowClick = useCallback((fileId) => {
    setSelectedFileId(fileId);
    setShowModal(true);
  }, []);

  useEffect(() => {
    const fetchInitialWarnings = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/warnings`);
        const data = await response.json();
        setInitialWarnings(data);
      } catch (error) {
        console.error('Error fetching initial warnings:', error);
      }
    };
    fetchInitialWarnings();
  }, []);

    const handlePopupLogic = (messages) => {
        const now = new Date();
        const threeDaysAgo = new Date(now);
        threeDaysAgo.setDate(now.getDate() - 3);
    
        const sevenDaysAgo = new Date(now);
        sevenDaysAgo.setDate(now.getDate() - 7);
    
        const oldMessages = messages.filter((message) => {
            const createdDate = new Date(message.created);
            return createdDate <= threeDaysAgo;
        });
    
        if (oldMessages.length > 0) {
            const isOlderThanSevenDays = oldMessages.some((message) => {
                const createdDate = new Date(message.created);
                return createdDate <= sevenDaysAgo;
            });
    
            setPopupMessage(
                <>
                    You have {oldMessages.length} imporant unread messages older than {isOlderThanSevenDays ? '7' : '3'} days.
                    <br />
                    Please read messages to avoid getting your work interrupted.
                </>
            );
            
    
            if (isOlderThanSevenDays) {
                setCloseButtonDisabled(true); 
                setCountdown(2); 
                const countdownInterval = setInterval(() => {
                    setCountdown((prev) => {
                        if (prev <= 1) {
                            clearInterval(countdownInterval); // Stopp nedtellingen når den når 0
                            setCloseButtonDisabled(false); // Aktiver close-knappen
                            return 0;
                        }
                        return prev - 1; // Reduser nedtellingen
                    });
                }, 2000); // Oppdater hvert sekund
            }
            setShowPopup(true); // Vis popup
        } else {
            setShowPopup(false); // Ingen popup hvis ingen meldinger er eldre enn 3 dager
        }
    };

  const fetchUnreadMessagesCount = async () => {
    if (!userProfile.id) {
        console.warn('fetchUnreadMessagesCount called without a valid userId');
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/messages/unread-count?userId=${userProfile.id}`);
        const data = await response.json();

        if (response.ok) {

            setUnreadMessagesCount(data.unreadCount);
            handlePopupLogic(data.unreadMessages); // Kall popup-logikk med meldingsdetaljer
        } else {
            console.error('Failed to fetch unread messages count:', data.message);
        }
    } catch (error) {
        console.error('Error fetching unread messages count and details:', error);
    }
};

const fetchUnreadForumCount = async () => {
  try {
    // 1. Hent alle kategorier
    const catResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/categories`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    if (!catResponse.ok) {
      console.error('Feil ved henting av kategorier.');
      return;
    }
    const categoriesData = await catResponse.json();

    let totalUnread = 0;

    // 2. For hver kategori: hent alle topics
    for (const cat of categoriesData) {
      // Eksempel: steng ute kategori 6 hvis brukeren ikke har tilgang
      if (cat.categoryId === 6 && !(isAdmin || isTuner || isSupport)) {
        continue;
      }

      const topicsResp = await fetch(`${process.env.REACT_APP_SITE_URL}/category-topics/${cat.categoryId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      if (!topicsResp.ok) {
        console.error('Feil ved henting av topics for kategori', cat.categoryId);
        continue;
      }

      const topicsData = await topicsResp.json();

      // 3. Filtrer ut de trådene hvor brukeren IKKE er i `reads`‐listen
      const unreadTopics = topicsData.filter(topic => {
        const readsArr = topic.reads
          ? topic.reads.split(',').map(Number)
          : [];
        return !readsArr.includes(userProfile.id);
      });

      totalUnread += unreadTopics.length;
    }

    setUnreadForumCount(totalUnread);
  } catch (error) {
    console.error('Feil ved henting av uleste forumtråder:', error);
  }
};


const fetchTicketCount = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tickets`);
    const allTickets = await response.json();

    if (!response.ok) {
      console.error('Failed to fetch tickets:', allTickets.message);
      return;
    }

    let relevantTickets = [];
    if (isAdmin || isTuner) {
      // Admin eller tuner: ignorér mid, men kun de som IKKE er "closed" og har message = 1
      relevantTickets = allTickets.filter(
        (ticket) => ticket.closed === 0 && ticket.message === 1
      );
    } else {
      // Vanlig bruker (ikke admin/tuner): sjekk tickets med mid = userProfile.did, closed = 0, message = 2
      relevantTickets = allTickets.filter(
        (ticket) =>
          ticket.closed === 0 &&
          ticket.mid === userProfile.did &&
          ticket.message === 2
      );
    }

    setUnreadSupportTicketCount(relevantTickets.length);
  } catch (err) {
    console.error('Error fetching tickets:', err);
  }
};



  return (
    <div className="container">
      <div className="samlecontainer">
      <div className="news-column" style={{ position: "relative" }}>

        <>
          {/* Standard innhold for andre brukere */}
          <StatsSection userStats={userStats} />


          {(isAdmin || isTuner) && (
        <>
          <LatestAlerts userStats={userStats} websocket={ws} />
        </>
        
      )}
          <WarningSection
            initialWarnings={initialWarnings}
            isAdmin={isAdmin}
            isTuner={isTuner}
            userDid={userProfile.did}
          />








        <div className="warningcontainer">
          
          {unreadMessagesCount > 0 && (
            <div className="warningNumberTextBase warningRed">
              📩 {unreadMessagesCount} Unread messages
            </div>
          )}

          {unreadForumCount > 0 && (
          <div className="warningNumberTextBase warningYellow">
            📩 {unreadForumCount} Unread Forumthreads
          </div>
          )}

          {unreadSupportTicketCount > 0 && (
          <div className="warningNumberTextBase warningBlue">
          📩 {unreadSupportTicketCount} Unread Supporttickets
          </div>
          )}

        </div>

        </>
    </div>
    {showPopup && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                        <button
                            onClick={() => setShowPopup(false)}
                            disabled={closeButtonDisabled}
                            style={{ opacity: closeButtonDisabled ? 0.5 : 1 }}
                        >
                            {closeButtonDisabled ? `Close (${countdown}s)` : 'Close'}
                        </button>
                    </div>
                </div>
            )}



        <ProfileSection
          userProfile={userProfile}
          isAdmin={isAdmin}
          isTuner={isTuner}
          userDid={userProfile.did}
          userId={userProfile.id}
          showWarning={showWarning}
          taskCountToday={taskCountToday}
          pendingCount={pendingCount}
          isAutoRefreshOn={isAutoRefreshOn}
          toggleAutoRefresh={() => setIsAutoRefreshOn(!isAutoRefreshOn)}
          openProfileModal={() => setShowProfileModal(true)}
          openAdminModal={() => setShowAdminModal(true)}
          openCustomersModal={() => setShowCustomersModal(true)}
          openTosModal={() => setShowTosModal(true)}
          handleLogout={logout}
          fetchVehicleData={fetchVehicleData}
          setActiveAdminComponent={setActiveAdminComponent}
        />
        <ServiceStatus overrideDates={overrideDates} />
      </div>

      {showProfileModal && (
        <Profilesite
          onClose={() => setShowProfileModal(false)}
          username={userProfile.name}
          usernameid={userProfile.id}
          did={userProfile.did}
          country={userProfile.country}
        />
      )}
      {showAdminModal && (
            // console.log('Rendering Adminsite with userProfile:', userProfile),
        <Adminsite
          onClose={() => setShowAdminModal(false)}
          id={userProfile.id}
          isAdmin={isAdmin}
          isTuner={isTuner}
          username={userProfile.name} // Send username
          userProfile={userProfile}  // Send hele userProfile
        />
      )}
      {showCustomersModal && (
        <Customers2
          onClose={() => setShowCustomersModal(false)}
          did={userProfile.did}
          country={userProfile.country}
          userProfile={userProfile} // Send hele userProfile som prop
        />
      )}

      {showTosModal && (
        <Tossite onClose={() => setShowTosModal(false)} country={userProfile.country} />
      )}
      {showModal && (
        <FileDetails
          fileId={selectedFileId}
          onClose={() => setShowModal(false)}
          isAdmin={isAdmin}
          isTuner={isTuner}
          userProfile={userProfile}
        />
      )}

      <button onClick={() => setShowChat(!showChat)} className="chat-button">+</button>
      {showChat && (
        <Chat
          onClose={() => setShowChat(false)}
          userId={userProfile.id}
          isAdmin={userProfile.isAdmin}
          isTuner={userProfile.isTuner}
          did={userProfile.did}
        />
      )}

      <div className="files-section">
        <div className="search-and-scroll-container">
          <SearchAndFilterSection
            setSearchText={setSearchText}
            selectedDealer={selectedDealer}
            setSelectedDealer={setSelectedDealer}
            dealers={dealers}
            isAdmin={isAdmin}
            isTuner={isTuner}
            wipOnly={wipOnly}
            nsaOnly={nsaOnly}
            setWipOnly={setWipOnly}
            setNsaOnly={setNsaOnly}
            userId={userProfile.id}

          // Ny prop som barn kan kalle
            onOpenFileDetails={(fileId) => {
              setSelectedFileId(fileId);
              setShowModal(true);
            }}

          />
<ScrollFeed latestUploads={latestUploads} topMembers={topMembers} />

        </div>


        {priorityList.length > 0 && (
  <>
    <table className="priority-list">
      <thead>
        <tr className="priority-header">
          <th>ID</th>
          {(isAdmin || isTuner) && <th></th>}

          <th>Vehicle</th>
          <th>Registration</th>
          <th>Dealer</th>
          <th></th>
          <th></th>
          <th>Type</th>
          {(isAdmin || isTuner) && <th>Tuner</th>}
          {!(isAdmin || isTuner) && (
        <th>Paid</th>
      )}
          <th>Date</th>
          <th>Charged</th>
          {(isAdmin || isTuner) && <th>Tool</th>}
          <th>Actions</th></tr>

      </thead>
      <tbody>
        {priorityList.map((vehicle, index) => (
          <VehicleRow
            key={vehicle.id}
            vehicle={vehicle}
            isAdmin={isAdmin}
            isTuner={isTuner}
            handleRowClick={handleRowClick}
            handleUpdateStatus={handleUpdateStatus}
            showDropdown={showDropdown[index]}
            toggleDropdown={() => toggleDropdown(index)}
            handleDelete={() => handleDelete(vehicle.id)}
          />
        ))}
      </tbody>
    </table>

    <div style={{ height: "35px" }} />
  </>
)}


        <table className="other-list">
  <thead>
    <tr className="other-header">
      <th>ID</th>
      <th>Vehicle</th>
      <th>Registration</th>
      <th>Dealer</th>
      <th></th>
      <th></th>
      <th>Type</th>
      {(isAdmin || isTuner) && <th>Tuner</th>}
      {!(isAdmin || isTuner) && (
        <th>Paid</th>
      )}

      <th>Date</th>

      <th>Charged</th>
      {(isAdmin || isTuner) && <th>Tool</th>}
      <th>Actions</th></tr>

  </thead>
  <tbody>
    {otherList.map((vehicle, index) => (
      <VehicleRow
        key={vehicle.id}
        vehicle={vehicle}
        isAdmin={isAdmin}
        isTuner={isTuner}
        handleRowClick={handleRowClick}
        handleUpdateStatus={handleUpdateStatus}
        showDropdown={showDropdown[index + priorityList.length]}
        toggleDropdown={() => toggleDropdown(index + priorityList.length)}
        handleDelete={() => handleDelete(vehicle.id)}  // Sender handleDelete som prop
      />
    ))}
  </tbody>
</table>
      </div>
    </div>
  );


};






export default FilePortal;