import React, { useState, useEffect } from 'react';

export default function ManualsManager() {
  const [manuals, setManuals] = useState([]); // List of all manuals
  const [categories, setCategories] = useState([]); // List of categories
  const [subcategories, setSubcategories] = useState([]); // List of subcategories for the selected category
  const [subSubcategories, setSubSubcategories] = useState([]); // List of sub-subcategories for the selected subcategory
  const [manualLocation, setManualLocation] = useState(''); // 'category', 'subcategory', 'subsubcategory'

  const [selectedManual, setSelectedManual] = useState(null); // Manual being moved
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null);

  const [expandedManualId, setExpandedManualId] = useState(null);


  // Fetch all manuals on component mount
  useEffect(() => {
    const fetchManuals = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals`);
        const data = await response.json();
        setManuals(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching manuals:', error);
        setManuals([]); // Reset to empty array on error
      }
    };

    fetchManuals();
    fetchCategories(); // Fetch categories on mount
  }, []);

  // Fetch all categories
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories`);
      if (!response.ok) {
        throw new Error(`Failed to fetch categories: ${response.status}`);
      }
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };

  // Fetch subcategories dynamically based on the selected category
  const fetchSubcategories = async (categoryId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}/subcategories`);
      const data = await response.json();
      setSubcategories(data);
      setSubSubcategories([]); // Reset sub-subcategories
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      setSubcategories([]);
    }
  };

  // Fetch sub-subcategories dynamically based on the selected subcategory
  const fetchSubSubcategories = async (subcategoryId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}/sub-subcategories`);
      const data = await response.json();
      setSubSubcategories(data);
    } catch (error) {
      console.error('Error fetching sub-subcategories:', error);
      setSubSubcategories([]);
    }
  };

  // Move manual to a different category, subcategory, or sub-subcategory
  const handleMoveManual = async () => {
    if (!selectedManual || (!selectedSubcategory && !selectedSubSubcategory)) {
      alert('Please select a manual and a new category or sub-category.');
      return;
    }
  
    const targetId = selectedSubSubcategory?.id || selectedSubcategory?.id;
    const key = selectedSubSubcategory ? 'subSubcategoryId' : 'subcategoryId';
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals/${selectedManual.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          title: selectedManual.title,
          content: selectedManual.content,
          [key]: targetId,
        }),
      });
  
      if (response.ok) {
        alert('Manual moved successfully!');
        setManuals((prevManuals) =>
          prevManuals.map((manual) =>
            manual.id === selectedManual.id
              ? {
                  ...manual,
                  ...(selectedSubSubcategory
                    ? { subSubcategoryId: selectedSubSubcategory.id, subcategoryId: null }
                    : { subcategoryId: selectedSubcategory.id, subSubcategoryId: null }),
                }
              : manual
          )
        );
        setSelectedManual(null);
        setSelectedCategory(null);
        setSelectedSubcategory(null);
        setSelectedSubSubcategory(null);
      } else {
        alert('Failed to move manual.');
      }
    } catch (error) {
      console.error('Error moving manual:', error);
    }
  };
  

  const resetSelections = () => {
    setSelectedManual(null);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setSelectedSubSubcategory(null);
    setSubcategories([]);
    setSubSubcategories([]);
  };

  return (
    <div>
  <h3>All Manuals</h3>
  <ul className="manuals-modal-categories-list">
    {manuals.map((manual) => (
      <div key={manual.id} className="manuals-modal-categories-list-item-container">
        <div className="manuals-modal-categories-list-item">
          <span>{manual.title}</span> - Sub-Subcategory ID: {manual.id || 'None'}
          <button
            onClick={() =>
              setExpandedManualId((prevId) => (prevId === manual.id ? null : manual.id))
            }
          >
            Move
          </button>
        </div>

        {expandedManualId === manual.id && (
          <div className="manual-move-section">
            <h3>Move Manual: {manual.title}</h3>

            {/* Select Category */}
            <label htmlFor={`category-${manual.id}`}>Select Category</label>
            <select
              id={`category-${manual.id}`}
              value={selectedCategory?.id || ''}
              onChange={(e) => {
                const category = categories.find((cat) => cat.id === Number(e.target.value));
                setSelectedCategory(category);
                setSelectedSubcategory(null); // Reset subcategory
                setSelectedSubSubcategory(null); // Reset sub-subcategory
                if (category) fetchSubcategories(category.id);
              }}
            >
              <option value="">-- Select Category --</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>

            {/* Select Subcategory */}
            {subcategories.length > 0 && (
              <>
                <label htmlFor={`subcategory-${manual.id}`}>Select Subcategory</label>
                <select
                  id={`subcategory-${manual.id}`}
                  value={selectedSubcategory?.id || ''}
                  onChange={(e) => {
                    const subcategory = subcategories.find((sub) => sub.id === Number(e.target.value));
                    setSelectedSubcategory(subcategory);
                    setSelectedSubSubcategory(null); // Reset sub-subcategory
                    if (subcategory) fetchSubSubcategories(subcategory.id);
                  }}
                >
                  <option value="">-- Select Subcategory --</option>
                  {subcategories.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.title}
                    </option>
                  ))}
                </select>
              </>
            )}

            {/* Select Sub-Subcategory */}
            {subSubcategories.length > 0 && (
              <>
                <label htmlFor={`subsubcategory-${manual.id}`}>Select Sub-Subcategory</label>
                <select
                  id={`subsubcategory-${manual.id}`}
                  value={selectedSubSubcategory?.id || ''}
                  onChange={(e) =>
                    setSelectedSubSubcategory(
                      subSubcategories.find((sub) => sub.id === Number(e.target.value))
                    )
                  }
                >
                  <option value="">-- Select Sub-Subcategory --</option>
                  {subSubcategories.map((subSubcategory) => (
                    <option key={subSubcategory.id} value={subSubcategory.id}>
                      {subSubcategory.title}
                    </option>
                  ))}
                </select>
              </>
            )}

            <button onClick={handleMoveManual}>Move Manual</button>
          </div>
        )}
      </div>
    ))}
  </ul>
</div>

  );
}
