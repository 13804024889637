import React, { useMemo, useRef, useEffect, useState } from 'react';

const ScrollFeed = React.memo(({ latestUploads, topMembers }) => {
  const scrollContainerRef = useRef(null);
  const [animationDuration, setAnimationDuration] = useState(60); // Standard varighet i sekunder

  const scrollText = useMemo(() => {
    // Funksjon for å legge til farget skille
    const addSeparator = (items, separatorClass) => {
      return items
        .map(item => `<span>${item}</span>`)
        .join(`<span class="${separatorClass}">/</span>`); // Legg til farget skille
    };

    // Tekst for kjøretøyene
    const vehiclesText = addSeparator(
      (latestUploads || []).slice(0, 5).map(vehicle =>
        `${vehicle.dealerName || 'Unknown Dealer'} - ${vehicle.vehicleDescription || 'Unknown Vehicle'}`
      ),
      'separator'
    );

    // Tekst for medlemmene
    const membersText = addSeparator(
      (topMembers || []).slice(0, 5).map(member =>
        `${member.memberName || 'Unknown Member'} - (Rank ${member.rank})`
      ),
      'separator'
    );

    // Legg til overskrifter foran
    return `
      <span class="highlight-vehicles">Latest Vehicles:</span> ${vehiclesText} 
      <span class="separator"> | </span> 
      <span class="highlight-members">Top Dealers:</span> ${membersText}
    `;
  }, [latestUploads, topMembers]);

  useEffect(() => {
    const containerWidth = scrollContainerRef.current?.offsetWidth || 0;
    const textWidth = scrollContainerRef.current?.scrollWidth || 0;

    // console.log('Container width:', containerWidth);
    // console.log('Text width:', textWidth);

    if (textWidth > containerWidth) {
      const speed = 80; // 80px per sekund for en jevn hastighet
      const duration = textWidth / speed; // Beregn varighet
      setAnimationDuration(duration);
    } else {
      // Hvis teksten er kortere enn containeren, sett en standard varighet
      setAnimationDuration(10); // Kort varighet for mindre tekst
    }
  }, [scrollText]);

  return (
    <div className="scroll-feed-container" ref={scrollContainerRef}>
      <div
        className="scroll-feed"
        style={{
          animation: `scroll-left ${animationDuration}s linear infinite`,
        }}
        dangerouslySetInnerHTML={{ __html: scrollText }} // Bruker HTML for å inkludere <span>
      />
    </div>
  );
});

export default ScrollFeed;
