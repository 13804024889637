import React, { useState, useEffect } from 'react';

function DeleteItemsModal({ onClose }) {
  const [manufacturers, setManufacturers] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SITE_URL}/all-manufacturers`)
      .then((response) => response.json())
      .then((data) => setManufacturers(data))
      .catch((err) => console.error('Error fetching manufacturers:', err));

    fetch(`${process.env.REACT_APP_SITE_URL}/all-ranges`)
      .then((response) => response.json())
      .then((data) => setRanges(data))
      .catch((err) => console.error('Error fetching ranges:', err));

    fetch(`${process.env.REACT_APP_SITE_URL}/all-vehicles`)
      .then((response) => response.json())
      .then((data) => setVehicles(data))
      .catch((err) => console.error('Error fetching vehicles:', err));
  }, []);

  const handleDeleteManufacturer = (id) => {
    if (!window.confirm('Er du sikker på at du vil slette denne produsenten?')) {
      return;
    }
    fetch(`${process.env.REACT_APP_SITE_URL}/deleteManufacturer/${id}`, {
      method: 'DELETE',
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to delete manufacturer');
        }
        setManufacturers((prev) => prev.filter((m) => m.id !== id));
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteRange = (id) => {
    if (!window.confirm('Er du sikker på at du vil slette denne "range"?')) {
      return;
    }
    fetch(`${process.env.REACT_APP_SITE_URL}/deleteRange/${id}`, {
      method: 'DELETE',
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to delete range');
        }
        setRanges((prev) => prev.filter((r) => r.id !== id));
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteVehicle = (id) => {
    if (!window.confirm('Er du sikker på at du vil slette denne modellen?')) {
      return;
    }
    fetch(`${process.env.REACT_APP_SITE_URL}/deleteVehicleModel/${id}`, {
      method: 'DELETE',
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to delete vehicle');
        }
        setVehicles((prev) => prev.filter((v) => v.id !== id));
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="delete-items-modal-background" onClick={onClose}>
      <div className="delete-items-modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>Delete Items</h2>
        <div>
          <h3>Manufacturers</h3>
          {manufacturers.length === 0 && <p>Ingen manufacturers funnet.</p>}
          <ul>
          {manufacturers.map((m) => (
            <li key={m.id}>
                <strong>({m.id})</strong> {m.manufacturerName}{' '}
                {m.typeName && (
                <em>({m.typeName})</em>
                )}
                {' – '}
                {m.range_count} ranges, {m.model_count} models
                {' '}
                <button onClick={() => handleDeleteManufacturer(m.id)}>Slett</button>
            </li>
            ))}

          </ul>
        </div>
        <div>
          <h3>Ranges</h3>
          {ranges.length === 0 && <p>Ingen ranges funnet.</p>}
          <ul>
            {ranges.map((r) => (
              <li key={r.id}>
                <strong>({r.id})</strong> {r.value}{' '}
                <span style={{ fontSize: '0.85rem', color: '#666' }}>
                  &nbsp; – {r.model_count} models
                </span>
                {' '}
                <button onClick={() => handleDeleteRange(r.id)}>Slett</button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3>Vehicle Models</h3>
          {vehicles.length === 0 && <p>Ingen vehicle models funnet.</p>}
          <ul>
            {vehicles.map((v) => (
              <li key={v.id}>
                <strong>({v.id})</strong> {v.value}{' '}
                <button onClick={() => handleDeleteVehicle(v.id)}>Slett</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DeleteItemsModal;
