import React, { useState } from 'react';

const FileDetailsInfo = ({
  fileDetails,
  dealerCredit,
  tuningToolOptions,
  isAdmin,
  isTuner,
  updatePaymentStatus,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCustomerEditModal, setShowCustomerEditModal] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    customername: fileDetails.customername,
    customeraddress: fileDetails.customeraddress,
    customerphone: fileDetails.customerphone,
    customeremail: fileDetails.customeremail,
    customernotes: fileDetails.customernotes,
  });

  const handleCustomerInfoChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleSaveCustomerInfo = () => {
    updateCustomerInfo(fileDetails.id, customerInfo);
    setShowCustomerEditModal(false);
  };

  const updateCustomerInfo = async (fileId, customerInfo) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-customer-info`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
                  },
        body: JSON.stringify({ fileId, ...customerInfo }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update customer info');
      }
  
      // console.log('Customer info updated successfully');
      setCustomerInfo((prevInfo) => ({ ...prevInfo, ...customerInfo }));
    } catch (error) {
      console.error('Failed to update customer info', error);
    }
  };

  const getTuningToolClass = (toolName) => {
    switch (toolName) {
      case 'Autotuner':
        return 'autotuner';
      case 'MMFlex':
        return 'mmflex';
      case 'NewGenius':
        return 'newgenius';
      case 'Unlisted - Other tool':
        return 'unlisted';
      case 'Trasdata':
        return 'trasdata';
      case 'Kess V3':
        return 'kessv3';
      case 'K-Tag':
        return 'ktag';
      default:
        return 'default-tool';
    }
  };

  const calculateDaysSinceUpload = (uploadDate) => {
    const uploadTime = new Date(uploadDate).getTime();
    const currentTime = new Date().getTime();
    const differenceInMilliseconds = currentTime - uploadTime;
    return Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)); // Konverter til dager
  };

  
  return (
    <>
      <div className="info-box">
        <p className="detailtextmedium">
          <b>{fileDetails.dealerName}<br /></b> {dealerCredit} credits left<br />
        </p>
        <p className={`detailtexttool ${getTuningToolClass(tuningToolOptions[fileDetails.tooltype])}`}>
        <b>Tuningtool:</b> {tuningToolOptions[fileDetails.tooltype]}
      </p>
      <p className="detailtext">
        <b>Filename: </b> {fileDetails.file}
      </p>


      <p className="detailtext">
        <b>Uploaded:</b> {new Date(fileDetails.created).toLocaleDateString('no-NO', { timeZone: 'UTC' }) }  {new Date(fileDetails.created).toLocaleTimeString('no-NO', { timeZone: 'UTC' })}  ({calculateDaysSinceUpload(fileDetails.created)} dsu)
      </p>
        <p className="detailtext"><b>Modified:</b> {new Date(fileDetails.updated).toLocaleDateString('no-NO', { timeZone: 'UTC' })} {new Date(fileDetails.updated).toLocaleTimeString('no-NO', { timeZone: 'UTC' })}</p>
        <p className="detailtext"><b>Charged:</b> {fileDetails.charged}</p>
      </div>

      <div className="info-box">
        {fileDetails.country}
        {fileDetails.credit}
        <p className="detailtext"><b>Customer info:</b>
          <button className="edit-status-button" onClick={() => setShowCustomerEditModal(true)}>Edit</button>
        </p>
        <p className="detailtext">{fileDetails.customername}</p>
        <p className="detailtext">{fileDetails.customeraddress}</p>
        <p className="detailtext">{fileDetails.customerphone}</p>
        <p className="detailtext">{fileDetails.customeremail}</p>
        <p className="detailtext">{fileDetails.customernotes}</p>
        <p className="detailtext">
          <b>Paid:</b>
          <span className={`status-box ${fileDetails.paid === 1 ? 'paid' : 'not-paid'}`}>
            {fileDetails.paid === 1 ? '✔' : '✖'}
          </span>
          {!(isAdmin || isTuner) && (
            <button className="edit-status-button" onClick={() => setShowEditModal(true)}>Edit</button>
          )}
        </p>

        {showEditModal && (
          <div className="modal-background" onClick={() => setShowEditModal(false)}>
            <div className="uploadconfirmation" onClick={e => e.stopPropagation()}>
              <div className="modal-header">
                <button className="close-btn" onClick={() => setShowEditModal(false)}>CLOSE</button>
              </div>
              <h3>Edit Payment Status</h3>
              <button
                className="confirm-button"
                onClick={() => updatePaymentStatus(fileDetails.id, 1)}
              >
                Paid
              </button>
              <button
                className="decline-button"
                onClick={() => updatePaymentStatus(fileDetails.id, 0)}
              >
                Not Paid
              </button>
            </div>
          </div>
        )}

        {showCustomerEditModal && (
          <div className="modal-background" onClick={() => setShowCustomerEditModal(false)}>
            <div className="uploadconfirmation" onClick={e => e.stopPropagation()}>
              <div className="modal-header">
                <button className="close-btn" onClick={() => setShowCustomerEditModal(false)}>CLOSE</button>
              </div>
              <h3>Edit Customer Info</h3>
              Name:<br></br> <input
                type="text"
                name="customername"
                value={customerInfo.customername}
                onChange={handleCustomerInfoChange}
                placeholder="Customer Name"
              /><br></br><br></br> 
              Address:<br></br> <input
                type="text"
                name="customeraddress"
                value={customerInfo.customeraddress}
                onChange={handleCustomerInfoChange}
                placeholder="Customer Address"
              /><br></br><br></br>
              Phone:<br></br> <input
                type="text"
                name="customerphone"
                value={customerInfo.customerphone}
                onChange={handleCustomerInfoChange}
                placeholder="Customer Phone"
              /><br></br><br></br>
             Email:<br></br> <input
                type="email"
                name="customeremail"
                value={customerInfo.customeremail}
                onChange={handleCustomerInfoChange}
                placeholder="Customer Email"
              /><br></br><br></br>
              Notes:<br></br><textarea
                name="customernotes"  rows="5" cols="50"
                value={customerInfo.customernotes}
                onChange={handleCustomerInfoChange}
                placeholder="Customer Notes"
              /><br></br>
              <button className="confirm-button" onClick={handleSaveCustomerInfo}>Save</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FileDetailsInfo;

