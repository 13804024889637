import React, { useEffect, useState } from 'react';

function TransferId({ fileId, fileDetails, userProfile, onClose }) {
  // Holds the list of dealers
  const [dealers, setDealers] = useState([]);
  // The selected dealer's ID (mid)
  const [selectedDealerMid, setSelectedDealerMid] = useState('');
  // Loading/error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    console.log('[TransferId] Received props:', {
      fileId,
      fileDetails,
      userProfile,
    });

    // Fetch dealers when component mounts
    fetchDealers();
    // eslint-disable-next-line
  }, []);

  /**
   * Fetches the list of dealers from /dealers
   * The endpoint is expected to return an array of objects like:
   * [ { id: 123, name: 'Some Dealer' }, ... ]
   */
  const fetchDealers = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/dealers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // If you need auth, e.g., `Authorization: 'Bearer ' + userProfile.token`
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch dealers');
      }
      const data = await response.json();
      const priorityNames = ['OptiTuning avd Øst', 'OptiTuning avd Østfold'];
      data.sort((a, b) => {
        const aIsPriority = priorityNames.includes(a.name);
        const bIsPriority = priorityNames.includes(b.name);
        if (aIsPriority && !bIsPriority) return -1; 
        if (!aIsPriority && bIsPriority) return 1;
        return a.name.localeCompare(b.name);
      });
      setDealers(data);
    } catch (err) {
      console.error('Error fetching dealers:', err);
      setError('Could not load dealers');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles selection of a dealer in the dropdown
   */
  const handleDealerChange = (event) => {
    setSelectedDealerMid(event.target.value);
  };

  /**
   * Submits the transfer request (updates 'mid' in uploads table)
   */
  const handleTransfer = async () => {
    if (!selectedDealerMid) {
      alert('Please select a dealer first.');
      return;
    }

    try {
      setLoading(true);
      setError('');

      const payload = {
        fileId,
        newMid: selectedDealerMid, // The mid of the dealer
      };

      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/transfer-id`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // If protected route: Authorization: `Bearer ${userProfile.token}`
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to transfer ID');
      }

      const result = await response.json();
      console.log('Transfer result:', result);

      alert('ID was successfully transferred.');
      onClose(); // Close the modal
    } catch (err) {
      console.error('Error transferring ID:', err);
      setError(err.message || 'Transfer failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="special-modal-background">
      <div className="special-modal-content">
        <div className="modal-header">
          <button className="close-btn" onClick={onClose}>Close</button>
        </div>

        <div className="modal-body">
          {/* Show loading or error states */}
          {loading && <p>Loading...</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}

          <div className="transfer-line">
  <span>{fileId}</span>
  <span>{fileDetails?.vehicleDescription || 'N/A'}</span>

  <div className="dealer-selector">
    <select
      id="dealerSelect"
      value={selectedDealerMid}
      onChange={handleDealerChange}
      disabled={loading}
    >
      <option value="">-- Choose Dealer --</option>
      {dealers.map((dealer) => (
        <option key={dealer.id} value={dealer.id}>
          {dealer.name} (ID: {dealer.id})
        </option>
      ))}
    </select>
  </div>

  <button
  className="transfer-btn"
    onClick={handleTransfer}
    disabled={loading}
  >
    Transfer
  </button>




</div>

        </div>
      </div>
    </div>
  );
}

export default TransferId;
