import React, { useEffect, useState } from 'react';
import FileDetails from './FileDetails';

const FileDetailsMatch = ({
  fileDetails,
  isAdmin,
  isTuner,
  handleBoxClick,
  showModal,
  selectedFileId,
  closeModal,
  userProfile,
  dialogs
}) => {
  const [modfileMatchesByDialog, setModfileMatchesByDialog] = useState({});
  const [regnumMatches, setRegnumMatches] = useState([]);

  useEffect(() => {
    if (fileDetails && (isAdmin || isTuner)) {
      const fetchRegnumData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/check-regnum/${fileDetails.regnum}`);
          const data = await response.json();

          if (data.found) {
            setRegnumMatches(data.ids);
          } else {
            setRegnumMatches([]);
          }
        } catch (error) {
          console.error('Error fetching regnum data:', error);
        }
      };
      fetchRegnumData();
    }
  }, [fileDetails, isAdmin, isTuner]);

  const filteredMatches = regnumMatches.filter(id => id !== fileDetails.id);

  useEffect(() => {
    const fetchModfileMatches = async (dialogId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/modfile-matches-dialog/${dialogId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        setModfileMatchesByDialog((prevState) => ({
          ...prevState,
          [dialogId]: data,
        }));
      } catch (error) {
        console.error(`Error fetching modfile matches for dialog ${dialogId}:`, error);
        setModfileMatchesByDialog((prevState) => ({
          ...prevState,
          [dialogId]: [],
        }));
      }
    };

    if (dialogs && dialogs.length > 0) {
      dialogs.forEach((dialog) => {
        fetchModfileMatches(dialog.id);
      });
    }
  }, [dialogs]);

  // Aggregere alle dialog-matches
  const masterMatches = Object.values(modfileMatchesByDialog).flat().filter(id => id !== fileDetails.id);

  return (
    <>
      {fileDetails && (isAdmin || isTuner) && (
        <div className="info-box">
          {filteredMatches.length > 0 && (
            <>
              <p className="detailtext"><b>{fileDetails.regnum} matches id:</b></p>
              <div className="id-boxes-container">
                {filteredMatches.map(id => (
                  <div key={id} className="id-box" onClick={() => handleBoxClick(id)}>
                    ID: {id}
                  </div>
                ))}
              </div>
              <br />
            </>
          )}

          {masterMatches.length > 0 && (
            <>
              <p className="detailtext"><b>{fileDetails.regnum} has matching master file name:</b></p>
              <div className="id-boxes-container">
                {masterMatches.map(id => (
                  <div key={id} className="id-box" onClick={() => handleBoxClick(id)}>
                    ID: {id} (Master match)
                  </div>
                ))}
              </div>
              <br />
            </>
          )}
        </div>
      )}

      {showModal && (
        <FileDetails
          fileId={selectedFileId}
          onClose={closeModal}
          isAdmin={isAdmin}
          isTuner={isTuner}
          userProfile={userProfile}
        />
      )}
    </>
  );
};

export default FileDetailsMatch;
