// import React, { useState, useEffect } from 'react';
// import defaultModelImage from '../assets/modelimage.png';
// import defaultDynoImage from '../assets/dynoimage.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';

// const DatabaseClassic = ({ country }) => {
//   const [vehicleModels, setVehicleModels] = useState([]);
//   const [selectedType, setSelectedType] = useState(null);
//   const [selectedManufacturer, setSelectedManufacturer] = useState(null);
//   const [selectedRange, setSelectedRange] = useState(null);
//   const [selectedModel, setSelectedModel] = useState(null);
//   const [copiedOpti, setCopiedOpti] = useState(false);
//   const [copiedPower, setCopiedPower] = useState(false);
//     const [viewImage, setViewImage] = useState(null);

//   useEffect(() => {
//     fetch(`${process.env.REACT_APP_SITE_URL}/vehicle-models`)
//       .then((response) => response.json())
//       .then((data) => setVehicleModels(data))
//       .catch((error) => console.error('Error fetching vehicle models:', error));
//   }, []);

//   const types = [...new Set(vehicleModels.map((model) => model.typeName))];
//   const manufacturers = selectedType
//     ? [...new Set(vehicleModels.filter((model) => model.typeName === selectedType).map((model) => model.manufacturerName))]
//     : [];
    
//     const ranges = selectedManufacturer
//     ? [
//         ...new Map(
//           vehicleModels
//             .filter((model) => model.manufacturerName === selectedManufacturer)
//             .map((model) => [model.rangeId, { id: model.rangeId, name: model.rangeName }]) 
//         ).values(),
//       ]
//     : [];
  

//   const models = selectedRange
//     ? vehicleModels.filter((model) => model.rangeId === selectedRange)
//     : [];

//   const handleTypeSelect = (type) => {
//     setSelectedType(type);
//     setSelectedManufacturer(null);
//     setSelectedRange(null);
//     setSelectedModel(null);
//   };

//   const openImage = (imageUrl) => {
//     setViewImage(imageUrl);
//   };

//   const copyToClipboard = (text, type) => {
//     navigator.clipboard.writeText(text).then(() => {
//       if (type === 'opti') {
//         setCopiedOpti(true);
//         setTimeout(() => setCopiedOpti(false), 2500);
//       } else if (type === 'power') {
//         setCopiedPower(true);
//         setTimeout(() => setCopiedPower(false), 2500);
//       }
//     }, (err) => {
//       console.error('Failed to copy text to clipboard', err);
//     });
//   };


//   const handleManufacturerSelect = (manufacturer) => {
//     setSelectedManufacturer(manufacturer);
//     setSelectedRange(null);
//     setSelectedModel(null);
//   };

//   const handleRangeSelect = (range) => {
//     setSelectedRange(range);
//     setSelectedModel(null);
//   };

//   const handleModelSelect = (model) => {
//     setSelectedModel(model);
//   };

//   return (
//     <div className="database-classic-container">
//       <div className="breadcrumbs">
//         {selectedType && (
//           <span onClick={() => handleTypeSelect(null)}>Type</span>
//         )}
//         {selectedManufacturer && (
//           <span onClick={() => handleManufacturerSelect(null)}> > Manufacturer</span>
//         )}
//         {selectedRange && (
//           <span onClick={() => handleRangeSelect(null)}> > Range</span>
//         )}
//         {selectedModel && (
//           <span onClick={() => handleModelSelect(null)}> > Model</span>
//         )}
//       </div>

//       {!selectedType && (
//         <div className="type-selection">
//           {types.map((type) => (
//             <div key={type} onClick={() => handleTypeSelect(type)} className="clickable-item">
//               <img 
//                 src={`/images/types/${type.toLowerCase()}.svg`} 
//                 alt={type} 
//                 className="type-icon"
//               />
//               <span>{type}</span>
//             </div>
//           ))}
//         </div>
//       )}

//       {selectedType && !selectedManufacturer && (
//         <div className="manufacturer-selection">
//           {manufacturers.map((manufacturer) => (
//             <div
//               key={manufacturer}
//               onClick={() => handleManufacturerSelect(manufacturer)}
//               className="clickable-item"
//             >
//               <img 
//                 src={`/images/manufacturers/${manufacturer.toLowerCase()}.webp`} 
//                 alt={manufacturer} 
//                 className="manufacturer-image"
//               />
//               {/* {manufacturer} */}
//             </div>
//           ))}
//         </div>
//       )}

//       {selectedManufacturer && !selectedRange && (
//         <div className="range-selection">
//           <ul className="range-list">
//             {ranges.map((range) => (
//               <li key={range.id} onClick={() => handleRangeSelect(range.id)} className="range-item">
//                 <img
//                   src={`/images/manufacturers/${selectedManufacturer.toLowerCase()}.webp`}
//                   alt={selectedManufacturer}
//                   className="list-manufacturer-image"
//                 />
//                 {range.name}
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}

//       {selectedRange && !selectedModel && (
//         <div className="model-selection">
//           <ul className="model-list">
//           {models.map((model) => (
//             <li key={model.id} onClick={() => handleModelSelect(model)} className="model-item">
//               <img
//                 src={`/images/manufacturers/${selectedManufacturer.toLowerCase()}.webp`}
//                 alt={selectedManufacturer}
//                 className="list-manufacturer-image"
//               />
//               {model.modelName} 
//               {/* - {model.fueltype} */}
//               <span className="model-statsclassic">
//                 {model.fueltype !== 3 && (
//                   <span className="model-opti">
//                     Opti: <b>{model.power_opti} Hp / {model.torque_opti} Nm</b>
//                   </span>
//                 )}
//                 <span className="model-power">
//                   Power: <b>{model.power} Hp / {model.torque} Nm</b>
//                 </span>
//               </span>
//             </li>
//           ))}

//           </ul>
//         </div>
//       )}

//       {selectedModel && (



//                   <div>
//                     <div className="model-name-display">
//                       <h2>{selectedModel.modelName}</h2>
//                     </div>
//                     <div className="model-expanded-details">
//                     <span className="databasemodal-orivalue">
//                       <div className="db3image-container">
//                             <img
//                               src={selectedModel.modelimage || defaultModelImage}
//                               alt="Model Image"
//                               className="detail-image"
//                               onClick={() => openImage(selectedModel.modelimage || defaultModelImage)}
//                             />
//                        </div>
//                       </span>
//                       <div className="text-details">
//                         <div class="databasemodal-details">
//                           <div class="databasemodal-fullrow">
//                             <div class="databasemodal-label">
//                               Original: 
//                               <span class="databasemodal-orivalue">
//                                 {selectedModel.power_original} Hp / {selectedModel.torque_original} Nm
//                               </span>
//                             </div>
//                           </div>

//                           {selectedModel.fueltype !== 3 && (
//                           <div class="databasemodal-row">
//                             <div class="databasemodal-left">
//                                 <span 
//                                   className={`copy-iconopti ${copiedOpti ? 'copied' : ''}`} 
//                                   onClick={() => copyToClipboard(`${selectedModel.power_opti} Hp / ${selectedModel.torque_opti} Nm`, 'opti')}
//                                 >
//                                   <FontAwesomeIcon icon={copiedOpti ? faCheck : faCopy} />
//                                 </span>
//                             </div>
//                             <div class="databasemodal-right">
//                                   <div class="databasemodal-optivalue">
//                                     Opti: 
//                                     <span class="databasemodal-orivalue">
//                                       {selectedModel.power_opti} Hp / {selectedModel.torque_opti} Nm
//                                       <br />
//                                       <span className="databasemodal-difference">
//                                         {`+${selectedModel.power_opti - selectedModel.power_original} Hp / +${selectedModel.torque_opti - selectedModel.torque_original} Nm`}
//                                       </span>
//                                     </span>
//                                   </div>      
//                             </div>
//                           </div>
//                         )}

//                           <div class="databasemodal-row">
//                             <div class="databasemodal-left">
//                               <span 
//                                 className={`copy-iconpower ${copiedPower ? 'copied' : ''}`} 
//                                 onClick={() => copyToClipboard(`${selectedModel.power} Hp / ${selectedModel.torque} Nm`, 'power')}
//                               >
//                                 <FontAwesomeIcon icon={copiedPower ? faCheck : faCopy} />
//                               </span>
//                             </div>
//                             <div class="databasemodal-right">
//                               <div class="databasemodal-powervalue">
//                                 Power: 
//                                 <span class="databasemodal-orivalue">
//                                   {selectedModel.power} Hp / {selectedModel.torque} Nm
//                                   <br />
//                                   <span className="databasemodal-difference">
//                                     {`+${selectedModel.power - selectedModel.power_original} Hp / +${selectedModel.torque - selectedModel.torque_original} Nm`}
//                                   </span>
//                                 </span>
//                               </div>
//                             </div>
//                           </div>
//                     </div>
//                       <div class="databasemodal-label">
//                       Notes:
//                         <span class="databasemodal-orivalue">
//                         {selectedModel.notes}
//                         </span>
//                       </div>
//                       </div>
//                       <span className="databasemodal-orivalue">
//                       <div className="db3image-container">
//                       {selectedModel.dynoimage ? (
//                       <img
//                         src={selectedModel.dynoimage}
//                         alt="Dyno Image"
//                         className="detail-image"
//                         onClick={() => openImage(selectedModel.dynoimage)}
//                       />
//                     ) : (
//                       <div className="dyno-chart-container">

//                             <img
//                               src={selectedModel.dynoimage || defaultDynoImage}
//                               alt="Dyno Image"
//                               className="detail-image"
//                               onClick={() => openImage(selectedModel.dynoimage || defaultDynoImage)}
//                                                     />

//                               {/* <DynoChart
//                                 powerOriginal={model.power_original}
//                                 torqueOriginal={model.torque_original}
//                                 powerOpti={model.power_opti}
//                                 torqueOpti={model.torque_opti}
//                               /> */}
//                             </div>
//                           )}

//                        </div>
//                       </span>
//                     </div>
//                     <div className="db3split">
//                     <div className="dbcolumn">
//                       <div className="databasemodal-label">
//                         OBD Location:
//                         <span className="databasemodal-orivalue">
//                           {selectedModel.obd_location}
//                           <br />
//                           <div className="db3image-container">

//                               <img
//                                 src={selectedModel.obdimage || defaultModelImage}
//                                 alt="OBD Picture"
//                                 className="littledetail-image"
//                                 onClick={() => openImage(selectedModel.obdimage || defaultModelImage)}
//                               />

//                           </div>
//                         </span>
//                       </div>
//                     </div>
//                     <div className="dbcolumn">
//                       <div className="databasemodal-label">
//                         ECU Location:
//                         <span className="databasemodal-orivalue">
//                           {selectedModel.ecu_location}
//                           <br />
//                           <div className="db3image-container">

//                               <img
//                                 src={selectedModel.ecuimage || defaultModelImage}
//                                 alt="ECU Picture"
//                                 className="littledetail-image"
//                                 onClick={() => openImage(selectedModel.ecuimage || defaultModelImage)}
//                               />

//                           </div>
//                         </span>
//                       </div>
//                     </div>                    <div className="dbcolumn">
//                       <div className="databasemodal-label">
//                         ACM Location:
//                         <span className="databasemodal-orivalue">
//                           {selectedModel.acm_location}
//                           <br />
//                           <div className="db3image-container">

//                               <img
//                                 src={selectedModel.acmimage || defaultModelImage}
//                                 alt="ACM Picture"
//                                 className="littledetail-image"
//                                 onClick={() => openImage(selectedModel.acmimage || defaultModelImage)}
//                               />
//                           </div>
//                         </span>
//                       </div>
//                     </div>
//                     </div>
//                   </div>
//       )}
//     </div>
//   );
// };

// export default DatabaseClassic;


import React, { useState, useEffect } from 'react';
import defaultModelImage from '../assets/modelimage.png';
import defaultDynoImage from '../assets/dynoimage.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';

const DatabaseClassic = ({ country }) => {
  const [vehicleModels, setVehicleModels] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [copiedOpti, setCopiedOpti] = useState(false);
  const [copiedPower, setCopiedPower] = useState(false);
  const [viewImage, setViewImage] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SITE_URL}/vehicle-models`)
      .then((response) => response.json())
      .then((data) => setVehicleModels(data))
      .catch((error) => console.error('Error fetching vehicle models:', error));
  }, []);

  // Filter out undefined/null typeNames
  const types = [
    ...new Set(
      vehicleModels
        .map((model) => model.typeName)
        .filter(Boolean) // removes null, undefined, ""
    ),
  ];

  // Only produce manufacturers for the selected type
  const manufacturers = selectedType
    ? [
        ...new Set(
          vehicleModels
            .filter((model) => model.typeName === selectedType)
            .map((model) => model.manufacturerName)
            .filter(Boolean)
        ),
      ]
    : [];

  // Only produce ranges for the selected manufacturer
  const ranges = selectedManufacturer
    ? [
        ...new Map(
          vehicleModels
            .filter((model) => model.manufacturerName === selectedManufacturer)
            .map((model) => [
              model.rangeId,
              { id: model.rangeId, name: model.rangeName },
            ])
        ).values(),
      ]
    : [];

  // Models for the selected range
  const models = selectedRange
    ? vehicleModels.filter((model) => model.rangeId === selectedRange)
    : [];

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setSelectedManufacturer(null);
    setSelectedRange(null);
    setSelectedModel(null);
  };

  const openImage = (imageUrl) => {
    setViewImage(imageUrl);
  };

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(
      () => {
        if (type === 'opti') {
          setCopiedOpti(true);
          setTimeout(() => setCopiedOpti(false), 2500);
        } else if (type === 'power') {
          setCopiedPower(true);
          setTimeout(() => setCopiedPower(false), 2500);
        }
      },
      (err) => {
        console.error('Failed to copy text to clipboard', err);
      }
    );
  };

  const handleManufacturerSelect = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
    setSelectedRange(null);
    setSelectedModel(null);
  };

  const handleRangeSelect = (range) => {
    setSelectedRange(range);
    setSelectedModel(null);
  };

  const handleModelSelect = (model) => {
    setSelectedModel(model);
  };

  return (
    <div className="database-classic-container">
      <div className="breadcrumbs">
        {selectedType && (
          <span onClick={() => handleTypeSelect(null)}>Database</span>
        )}
        {selectedManufacturer && (
          <span onClick={() => handleManufacturerSelect(null)}> &gt;  {selectedType}</span>
        )}
        {selectedRange && (
          <span onClick={() => handleRangeSelect(null)}> &gt;  {selectedManufacturer}</span>
        )}
        {selectedModel && (
          <span onClick={() => handleModelSelect(null)}></span>
        )}
      </div>


      {!selectedType && (
  <div className="type-selection">
    {types.map((type) => {
      // Definer ekstra tekst basert på type
      let extraText = [];
      if (type === "Cars") extraText = ["Vans", "Pickups", "Motorhomes"];
      if (type === "Construction") extraText = ["Stationary Engines", "Offroad Equipment"];
      if (type === "MC") extraText = ["ATV", "Snowmobile"];
      if (type === "Truck") extraText = ["Bus"];
      if (type === "Tractor") extraText = ["Combines", "Agricultural"];
      if (type === "Marine") extraText = ["Watercraft", "Outboard Engines", "Inboard Engines"];

      return (
        <div
          key={type}
          onClick={() => handleTypeSelect(type)}
          className="clickable-item"
        >
          <img
            src={`/images/types/${type.toLowerCase()}.svg`}
            alt={type}
            className="type-icon"
          />
          <span>{type}</span>
          {extraText.length > 0 && (
            <div className="extra-text2">
              <span>
              {extraText.map((item, index) => (
                <div key={index}>{item}</div>
              ))}</span>
            </div>
          )}
        </div>
      );
    })}
  </div>
)}


      {/* STEP 2: Manufacturer selection */}
      {selectedType && !selectedManufacturer && (
        <div className="manufacturer-selection">
          {manufacturers.map((manufacturer) => (
            <div
              key={manufacturer}
              onClick={() => handleManufacturerSelect(manufacturer)}
              className="clickable-item"
            >
              <img
                // Safely handle toLowerCase()
                src={`/images/manufacturers/${manufacturer.toLowerCase()}.webp`}
                alt={manufacturer}
                className="manufacturer-image"
              />
            </div>
          ))}
        </div>
      )}

      {/* STEP 3: Range selection */}
      {selectedManufacturer && !selectedRange && (
        <div className="range-selection">
          <ul className="range-list">
          {selectedManufacturer}
            {ranges.map((range) => (
              <li
                key={range.id}
                onClick={() => handleRangeSelect(range.id)}
                className="range-item"
              >
                <img
                  src={`/images/manufacturers/${selectedManufacturer.toLowerCase()}.webp`}
                  alt={selectedManufacturer}
                  className="list-manufacturer-image"
                />
                {range.name}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* STEP 4: Model selection */}
      {selectedRange && !selectedModel && (
        <div className="model-selection">
          <ul className="model-list">

            {models.map((model) => (
              <li
                key={model.id}
                onClick={() => handleModelSelect(model)}
                className="model-item"
              >
                <img
                  src={`/images/manufacturers/${selectedManufacturer.toLowerCase()}.webp`}
                  alt={selectedManufacturer}
                  className="list-manufacturer-image"
                />
                {model.modelName}
                <span className="model-statsclassic">
                  {model.fueltype !== 3 && (
                    <span className="model-opti">
                      Opti: <b>{model.power_opti} Hp / {model.torque_opti} Nm</b>
                    </span>
                  )}
                  <span className="model-power">
                    Power: <b>{model.power} Hp / {model.torque} Nm</b>
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* STEP 5: Show selected model details */}
      {selectedModel && (
        <div>
          <div className="model-name-display">
            <h2>{selectedModel.modelName}</h2>
          </div>
          <div className="model-expanded-details">
            <span className="databasemodal-orivalue">
              <div className="db3image-container">
                <img
                  src={selectedModel.modelimage || defaultModelImage}
                  alt="Model Image"
                  className="detail-image"
                  onClick={() =>
                    openImage(selectedModel.modelimage || defaultModelImage)
                  }
                />
              </div>
            </span>

            <div className="text-details">
              <div className="databasemodal-details">
                <div className="databasemodal-fullrow">
                  <div className="databasemodal-label">
                    Original:
                    <span className="databasemodal-orivalue">
                      {selectedModel.power_original} Hp /{' '}
                      {selectedModel.torque_original} Nm
                    </span>
                  </div>
                </div>

                {/* If not EV */}
                {selectedModel.fueltype !== 3 && (
                  <div className="databasemodal-row">
                    <div className="databasemodal-left">
                      <span
                        className={`copy-iconopti ${
                          copiedOpti ? 'copied' : ''
                        }`}
                        onClick={() =>
                          copyToClipboard(
                            `${selectedModel.power_opti} Hp / ${selectedModel.torque_opti} Nm`,
                            'opti'
                          )
                        }
                      >
                        <FontAwesomeIcon icon={copiedOpti ? faCheck : faCopy} />
                      </span>
                    </div>
                    <div className="databasemodal-right">
                      <div className="databasemodal-optivalue">
                        Opti:
                        <span className="databasemodal-orivalue">
                          {selectedModel.power_opti} Hp /{' '}
                          {selectedModel.torque_opti} Nm
                          <br />
                          <span className="databasemodal-difference">
                            +
                            {selectedModel.power_opti -
                              selectedModel.power_original}{' '}
                            Hp / +
                            {selectedModel.torque_opti -
                              selectedModel.torque_original}{' '}
                            Nm
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="databasemodal-row">
                  <div className="databasemodal-left">
                    <span
                      className={`copy-iconpower ${
                        copiedPower ? 'copied' : ''
                      }`}
                      onClick={() =>
                        copyToClipboard(
                          `${selectedModel.power} Hp / ${selectedModel.torque} Nm`,
                          'power'
                        )
                      }
                    >
                      <FontAwesomeIcon icon={copiedPower ? faCheck : faCopy} />
                    </span>
                  </div>
                  <div className="databasemodal-right">
                    <div className="databasemodal-powervalue">
                      Power:
                      <span className="databasemodal-orivalue">
                        {selectedModel.power} Hp / {selectedModel.torque} Nm
                        <br />
                        <span className="databasemodal-difference">
                          +{selectedModel.power - selectedModel.power_original} Hp / +
                          {selectedModel.torque - selectedModel.torque_original} Nm
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="databasemodal-label">
                Notes:
                <span className="databasemodal-orivalue">
                  {selectedModel.notes}
                </span>
              </div>
            </div>

            <span className="databasemodal-orivalue">
              <div className="db3image-container">
                {selectedModel.dynoimage ? (
                  <img
                    src={selectedModel.dynoimage}
                    alt="Dyno Image"
                    className="detail-image"
                    onClick={() => openImage(selectedModel.dynoimage)}
                  />
                ) : (
                  <div className="dyno-chart-container">
                    <img
                      src={defaultDynoImage}
                      alt="Default Dyno Image"
                      className="detail-image"
                      onClick={() => openImage(defaultDynoImage)}
                    />
                  </div>
                )}
              </div>
            </span>
          </div>

          <div className="db3split">
            <div className="dbcolumn">
              <div className="databasemodal-label">
                OBD Location:
                <span className="databasemodal-orivalue">
                  {selectedModel.obd_location}
                  <br />
                  <div className="db3image-container">
                    <img
                      src={selectedModel.obdimage || defaultModelImage}
                      alt="OBD Picture"
                      className="littledetail-image"
                      onClick={() =>
                        openImage(selectedModel.obdimage || defaultModelImage)
                      }
                    />
                  </div>
                </span>
              </div>
            </div>
            <div className="dbcolumn">
              <div className="databasemodal-label">
                ECU Location:
                <span className="databasemodal-orivalue">
                  {selectedModel.ecu_location}
                  <br />
                  <div className="db3image-container">
                    <img
                      src={selectedModel.ecuimage || defaultModelImage}
                      alt="ECU Picture"
                      className="littledetail-image"
                      onClick={() =>
                        openImage(selectedModel.ecuimage || defaultModelImage)
                      }
                    />
                  </div>
                </span>
              </div>
            </div>
            <div className="dbcolumn">
              <div className="databasemodal-label">
                ACM Location:
                <span className="databasemodal-orivalue">
                  {selectedModel.acm_location}
                  <br />
                  <div className="db3image-container">
                    <img
                      src={selectedModel.acmimage || defaultModelImage}
                      alt="ACM Picture"
                      className="littledetail-image"
                      onClick={() =>
                        openImage(selectedModel.acmimage || defaultModelImage)
                      }
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatabaseClassic;
