import React, { useState, useEffect } from 'react';
import CreditShop from './CreditShop';
import './Profilesite.css';
import LatestForumTopics from './LatestForumTopics';
import LatestDatabaseEntries from './LatestDatabaseEntries';
import RssFeed from './RssFeed';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useReadingMessage } from './ReadingMessageContext';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function Profilesite({ onClose, username, usernameid, did, country }) {
    const [activeSection, setActiveSection] = useState('Home');
    const [todaysBookings, setTodaysBookings] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [filter, setFilter] = useState('All');
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [newTask, setNewTask] = useState({ vehicle: '', info: '', customer: '', datetime: '' });
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null); // Tilstand for valgt melding
    const [showAllMessagesModal, setShowAllMessagesModal] = useState(false);
    const [allMessages, setAllMessages] = useState([]); // For alle meldinger
    const { isReadingMessage, setIsReadingMessage } = useReadingMessage();
    const [topUsers, setTopUsers] = useState([]);
    const openAllMessages = () => {
        fetchAllMessages(); // Hent alle meldinger fra API-et
        setShowAllMessagesModal(true); // Åpne modalen
    };
    const closeAllMessagesModal = () => {
        setShowAllMessagesModal(false);
        setIsReadingMessage(false);
        console.log("setIsReadingMessage FALSE");
    };
    const [uploadsData, setUploadsData] = useState([]);
    const [shopFilter, setShopFilter] = useState('All');
    const [orders, setOrders] = useState([]);
    const [messages, setMessages] = useState([]);
    const [olevelStatus, setOlevelStatus] = useState({
        forced: false,
        olevel: 0,
        olevelexp: null,
      });
      const [opticoins, setOpticoins] = useState(0);
      const [opticoinsLog, setOpticoinsLog] = useState([]);


      const fetchTodaysBookings = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks?did=${did}`);
            const data = await response.json();
            const today = new Date();
            const todaysBookings = data.filter((task) => {
                const taskDate = new Date(task.datetime);
                return (
                    taskDate.toDateString() === today.toDateString() &&
                    !task.done // Bare ikke-fullførte oppgaver
                );
            });

            setTodaysBookings(todaysBookings);
        } catch (error) {
            console.error("Error fetching today's bookings:", error);
        }
    };

    const fetchAllMessages = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/messages/latest?limit=1000`);
            const data = await response.json();

            setAllMessages(data);
            data.forEach(message => {
                const readByIds = JSON.parse(message.readby || '[]').map(id => parseInt(id, 10)); // Konverter til tall
                const confirmedByIds = JSON.parse(message.confirmedby || '[]').map(id => parseInt(id, 10)); // Konverter til tall

            });
        } catch (error) {
            console.error('Error fetching all messages:', error);
        }
    };



        const fetchMessages = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/messages/latest?limit=3`);
                const data = await response.json();
                setMessages(data);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        const handleOpenMessage = (message) => {
            setShowAllMessagesModal(false); // Lukk "all messages"-modalen
            setSelectedMessage(message); // Sett valgt melding
            setIsReadingMessage(true);
            console.log("setIsReadingMessage TRUE");
            fetch(`${process.env.REACT_APP_SITE_URL}/messages/read`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ messageId: message.id, userId: usernameid }),
            })
                .then(() => {
                    // Oppdater "readby" i begge tilstandene
                    setMessages((prev) =>
                        prev.map((msg) =>
                            msg.id === message.id ? { ...msg, readby: [...(msg.readby || []), usernameid] } : msg
                        )
                    );
                    setAllMessages((prev) =>
                        prev.map((msg) =>
                            msg.id === message.id ? { ...msg, readby: [...(msg.readby || []), usernameid] } : msg
                        )
                    );
                })
                .catch((error) => console.error('Error marking message as read:', error));
        };



        // const confirmRead = (messageId) => {
        //     fetch(`${process.env.REACT_APP_SITE_URL}/messages/read`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ messageId, userId: usernameid }),
        //     }).then(() => {
        //         // Oppdater lokalt at meldingen er lest
        //         setMessages((prevMessages) =>
        //             prevMessages.map((msg) =>
        //                 msg.id === messageId ? { ...msg, readby: [...(msg.readby || []), usernameid] } : msg
        //             )
        //         );
        //     }).catch((error) => console.error('Error confirming read:', error));
        // };

        const confirmMessage = async (messageId) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/messages/confirm`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ messageId, userId: usernameid }),
                });

                if (response.ok) {
                    // Oppdater meldingslisten
                    setMessages((prevMessages) =>
                        prevMessages.map((message) =>
                            message.id === messageId
                                ? {
                                      ...message,
                                      confirmedby: JSON.stringify([
                                          ...JSON.parse(message.confirmedby || '[]'),
                                          parseInt(usernameid, 10),
                                      ]),
                                  }
                                : message
                        )
                    );

                    // Oppdater `selectedMessage` for popup
                    setSelectedMessage((prevMessage) =>
                        prevMessage
                            ? {
                                  ...prevMessage,
                                  confirmedby: JSON.stringify([
                                      ...JSON.parse(prevMessage.confirmedby || '[]'),
                                      parseInt(usernameid, 10),
                                  ]),
                              }
                            : null
                    );
                } else {
                    console.error('Failed to confirm message.');
                }
            } catch (error) {
                console.error('Error confirming message:', error);
            }
        };






    useEffect(() => {
        const loadOlevelStatus = async () => {
          const status = await fetchOlevelStatus(did);
          setOlevelStatus(status);
        };

        loadOlevelStatus();
      }, [did]);

    const fetchOrders = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/orders/${did}`);
            const data = await response.json();
            setOrders(data);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
        if (shopFilter === 'Orders') {
            fetchOrders();
        }
    }, [shopFilter]);



    const renderOrders = () => {
        const handleViewPdf = async (orderId) => {
            try {
              const apiUrl = `${process.env.REACT_APP_SITE_URL}/get-invoice/${orderId}`;
              const response = await fetch(apiUrl);

              if (!response.ok) {
                console.error('Response status:', response.status); // Log the status of the response
                throw new Error('Failed to retrieve invoice');
              }

              const data = await response.json();
              if (data.invoiceUrl) {
                window.open(data.invoiceUrl, '_blank');
              } else {
                console.error('Invoice URL not found'); // Log when invoice URL is missing
                throw new Error('Invoice URL not found');
              }
            } catch (error) {
              console.error('Error viewing invoice:', error);
            }
          };




        return (
            <div className="orders-section">
                <table className="orders-table">
                    <thead>
                        <tr>
                            <th>Order Date</th>
                            <th>Credits</th>
                            <th>VAT</th>
                            <th>Amount</th>
                            <th>PDF</th></tr>
                    </thead>
                    <tbody>
                        {orders.map(order => (
                            <tr key={order.id}>
                                <td>{new Date(order.created).toLocaleDateString('no-NO', { timeZone: 'UTC' })}</td>
                                <td>{order.credits}</td>
                                <td>{order.vat}</td>
                                <td>{order.total}</td>
                                <td>
                                <button onClick={() => handleViewPdf(order.id)}>View Invoice</button>
                </td></tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };



    const renderShop = () => {
        return (
            <div className="shop-section">
                <div className="shop-sidebar">
                    <h3>Shop Categories</h3>
                    <ul>
                        <li onClick={() => setShopFilter('Shop')}><b>Shop</b></li>
                        <li onClick={() => setShopFilter('Orders')}>Orders</li>
                        <li onClick={() => setShopFilter('Policies')}>Policies</li>
                    </ul>
                </div>
                <div className="shop-content">
                    {renderShopContent()}
                </div>
            </div>
        );
    };

    const renderShopContent = () => {
        switch (shopFilter) {
            case 'Shop':
                return <CreditShop userId={usernameid} onPurchaseComplete={fetchOrders} />;
            case 'Orders':
                return renderOrders();
            case 'Policies':
                return <p>Displaying Policies items...</p>;
            default:
                return <p>Displaying Shop items...</p>;
        }
    };
    const [userStats, setUserStats] = useState({
        rank: 0,
        filesNeededToRankup: 0,
        filesAwayFromRankdown: 0,
        filesNeededToLevelup: 0,
        filesAwayFromLeveldown: 0,
        filelast90days: 0,
        totalFiles: 0,
        todayFiles: 0,
        weeklyFiles: 0,
        monthlyFiles: 0,
        yearlyFiles: 0
    });
    const [companyLevel, setCompanyLevel] = useState({
        level: '',
        baseCredits: ''
    });
    const [profileData, setProfileData] = useState({
        name: '',
        legal: '',
        phone: '',
        vat: '',
        address: '',
        employees: []
    });
    const [avatar, setAvatar] = useState('');
    const [hotManufacturer, setHotManufacturer] = useState('');
    const [hotOption, setHotOption] = useState('');
    const [hotTool, setHotTool] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editProfileData, setEditProfileData] = useState({
        name: '',
        phone: '',
        email: '',
        avatar: ''
    });

    const [profilePictureFile, setProfilePictureFile] = useState(null);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        if (activeSection === 'Statistics') {
            fetchUploadsData(did);
            //fetchUploadsData();
            fetchUserStats();
            fetchProfileData();
            fetchAvatar();
            fetchHotItems();
            fetchCompanyLevel();
        } else if (activeSection === 'Log') {
            fetchLogs();
        } else if (activeSection === 'optipoints') {
            console.log(usernameid);
            fetchUserOpticoins(usernameid);
            fetchUserOpticoinsLog(usernameid);
            fetchTopUsers();
        } else if (activeSection === 'Home') {
            fetchUploadsData(did);
            //fetchUploadsData();
            fetchUserStats();
            fetchProfileData();
            fetchAvatar();
            fetchHotItems();
            fetchCompanyLevel();
            fetchTodaysBookings();
            fetchMessages();

        }
    }, [activeSection, did]);


    useEffect(() => {
        fetchUploadsData(did);
    }, [did]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    const fetchUserStats = async () => {

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/rankandlevel/${did}`);
            const data = await response.json();
            setUserStats(data);
        } catch (error) {
            console.error(`Error fetching user stats for user ${did}:`, error); // Log error
        }
    };


    const fetchCompanyLevel = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/calculate-credits/${did}`);
            const data = await response.json();
            setCompanyLevel(data);
        } catch (error) {
            console.error('Error fetching user stats:', error);
        }
    };

    const fetchProfileData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/members/profile/${did}`);
            const data = await response.json();
            setProfileData(data);
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    const fetchEditProfileData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/editable-profile/${usernameid}`);
            const data = await response.json();
            setEditProfileData(data);
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    const openEditModal = () => {
        fetchEditProfileData();
        setShowEditModal(true);
    };

    const fetchAvatar = async () => {
        try {
            const fetchUrl = `${process.env.REACT_APP_SITE_URL}/members/avatar/${usernameid}`;

            const response = await fetch(fetchUrl);
            const data = await response.json();
            let baseUrl = process.env.REACT_APP_SITE_URL;
            if (baseUrl.includes('/api')) {
                baseUrl = baseUrl.split('/api')[0];
            }

            const avatarUrl = `${baseUrl}${data.avatar}`;
            setAvatar(avatarUrl);
        } catch (error) {
            console.error('Error fetching avatar:', error);
        }
    };


    const fetchHotItems = async () => {
        try {
            const hotManufacturerResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/hot-manufacturer/${did}`);
            if (!hotManufacturerResponse.ok) {
                console.error("Failed to fetch Hot Manufacturer:", hotManufacturerResponse.status, hotManufacturerResponse.statusText);
            }
            const hotManufacturerData = await hotManufacturerResponse.json();
            setHotManufacturer(hotManufacturerData.value?.toLowerCase()); // Normalize to lowercase
            const hotOptionResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/hot-option/${did}`);
            if (!hotOptionResponse.ok) {
                console.error("Failed to fetch Hot Option:", hotOptionResponse.status, hotOptionResponse.statusText);
            }
            const hotOptionData = await hotOptionResponse.json();
            setHotOption(hotOptionData.value?.toLowerCase()); // Normalize to lowercase
            const hotToolResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/hot-tool/${did}`);
            if (!hotToolResponse.ok) {
                console.error("Failed to fetch Hot Tool:", hotToolResponse.status, hotToolResponse.statusText);
            }
            const hotToolData = await hotToolResponse.json();
            setHotTool(hotToolData.value?.toLowerCase()); // Normalize to lowercase

        } catch (error) {
            console.error('Error fetching hot items:', error); // Log any error during the process
        }
    };



    const fetchUploadsData = async (did) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/charged-data-per-member/${did}`);
            const data = await response.json();
            setUploadsData(data);
        } catch (error) {
            console.error('Error fetching uploads data:', error);
        }
    };

    const fetchLogs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/logsite/logs/dealer/${did}`);
            const data = await response.json();
            setLogs(data);
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditProfileData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleProfilePictureChange = (e) => {
        setProfilePictureFile(e.target.files[0]);
    };

    const handleEditProfile = async () => {
        if (profilePictureFile) {
            const formData = new FormData();
            formData.append('profilePicture', profilePictureFile);
            formData.append('userId', usernameid); // Legg til userId i formdata

            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/upload-profile-picture`, {
                    method: 'POST',
                    body: formData,
                });
                const data = await response.json();
                setEditProfileData(prevState => ({ ...prevState, avatar: data.profilePictureUrl }));
            } catch (error) {
                console.error('Error uploading profile picture:', error);
            }
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/editable-profile/${usernameid}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(editProfileData),
            });
            if (response.ok) {
                fetchProfileData();
                setShowEditModal(false);
            } else {
                console.error('Error updating profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const renderLogs = () => {
        return (
            <div className="logs-section">
                <div className="logs-container">
                    <table className="log-table">
                        <thead>
                            <tr>
                                <th>Log Text</th>
                                <th>Date</th></tr>

                        </thead>
                        <tbody>
                            {logs.map(log => (
                                <tr key={log.id}>
                                    <td>{log.op_text}</td>
                                    <td>{new Date(log.created).toLocaleString('no-NO', { timeZone: 'UTC' })}</td></tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    const fetchUserOpticoins = async (userId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-opticoins/${userId}`);
          if (!response.ok) throw new Error('Failed to fetch user opticoins');
          const data = await response.json();
          setOpticoins(data.opticoins);
        } catch (error) {
          console.error('Error fetching user opticoins:', error);
        }
      };

      const fetchUserOpticoinsLog = async (userId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/opticoins-log/${userId}`);
          if (!response.ok) throw new Error('Failed to fetch opticoins log');
          const data = await response.json();
          setOpticoinsLog(data);
        } catch (error) {
          console.error('Error fetching opticoins log:', error);
        }
      };

      const fetchTopUsers = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_SITE_URL + '/top-opticoins');
          if (!response.ok) throw new Error('Failed to fetch top opticoins');
          const data = await response.json();  // e.g. [ { id, name, opticoins }, ... ]
          setTopUsers(data);
        } catch (error) {
          console.error('Error fetching top opticoins:', error);
        }
      };

    const renderoptipoints = () => {
        return (
            <div className="logs-section">
              <div className="logs-container">
                <div className="logs-left-col">
                  <h3 className="logs-title">Your Optipoints</h3>
                  <div className="opticoins-amount">{opticoins}</div>
                  <div className="top-users-section">
            <h4 className="top-users-title">Top 10 Leaderboard</h4>
            {topUsers.length === 0 ? (
              <p>No data</p>
            ) : (
              <ul className="top-users-list">
                {topUsers.map((user, index) => (
                  <li key={user.id} className="top-users-item">
{user.opticoins} pts - {user.name}

                  </li>
                ))}
              </ul>
            )}
          </div>
                </div>
                <div className="logs-right-col">
                  <h3 className="logs-title">Optipoints Log</h3>

                Stuff to do with Optipoints will be added.... Stay tuned, and tune alot!


                  {opticoinsLog.length === 0 ? (
                    <p>No log entries found.</p>
                  ) : (
                    <ul className="logs-list">
                      {opticoinsLog.map((entry) => (
                        <li key={entry.id}>
                          <span className="logs-log-date">
                            {new Date(entry.date).toLocaleString()}
                          </span>
                          {' '}– {entry.message}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          );
    };


    const processChartData = (data) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const groupedData = {};
        data.forEach(({ year_value, month, count }) => {
            if (!groupedData[year_value]) {
                groupedData[year_value] = Array(12).fill(null);
            }
            groupedData[year_value][month - 1] = count;
        });

        const yearColors = {
            2024: 'rgba(255, 99, 132, 1)',
            2023: 'rgba(54, 162, 235, 1)',
            2022: 'rgba(75, 192, 192, 1)',
            2021: 'rgba(153, 102, 255, 1)',
            2020: 'rgba(255, 159, 64, 1)',
        };

        const datasets = Object.keys(groupedData).map(year => ({
            label: year,
            data: groupedData[year],
            borderColor: yearColors[year] || getRandomColor(),
            backgroundColor: (yearColors[year] || getRandomColor()).replace('1)', '0.5)'),
            spanGaps: true,
        }));

        datasets.sort((a, b) => parseInt(b.label) - parseInt(a.label));

        return {
            labels: months,
            datasets
        };
    };

    const getRandomColor = (opacity = 1) => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };

    const getOptions = (title) => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        return tooltipItems[0].label;
                    },
                    label: (tooltipItem) => {
                        const monthIndex = tooltipItem.dataIndex;
                        const chart = tooltipItem.chart;
                        const datasets = chart.data.datasets;
                        const uniqueTooltipData = new Map();
                        datasets.forEach(dataset => {
                            const year = dataset.label;
                            const value = dataset.data[monthIndex];
                            if (!uniqueTooltipData.has(year)) {
                                uniqueTooltipData.set(year, {
                                    year: year,
                                    value: value !== null ? value : 'null',
                                    color: dataset.borderColor
                                });
                            }
                        });
                        return Array.from(uniqueTooltipData.values())
                            .sort((a, b) => b.year - a.year)
                            .map(data => `<span style="color:${data.color}">${data.year}</span>: ${data.value}`);
                    },
                    labelColor: (tooltipItem) => {
                        return {
                            borderColor: tooltipItem.dataset.borderColor,
                            backgroundColor: tooltipItem.dataset.borderColor
                        };
                    }
                },
                usePointStyle: false,
                enabled: true,
                mode: 'index',
                intersect: false,
                itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
                callbacks: {
                    labelTextColor: function(tooltipItem) {
                        return tooltipItem.dataset.borderColor;
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    stepSize: 100
                }
            }
        }
    });

    const chartContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        height: '400px',
        margin: '0 auto',
        marginTop: '30px',
        backgroundColor: '#282828',
        padding: '10px',

    };



    const fetchOlevelStatus = async (did) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/forced/olevel-status/${did}`);
          if (!response.ok) {
            throw new Error('Failed to fetch olevel status');
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error fetching olevel status:', error);
          return { forced: false, olevel: 0, olevelexp: null }; // Standard fallback
        }
      };

      const formatDate = (dateString) => {
        if (!dateString) return '';
        return new Date(dateString).toLocaleDateString('no-NO', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
      };


    const renderContent = () => {
        switch (activeSection) {
            case 'Home':
                return (
                    <div className="overview-section">
                        <div className="stats-and-chart">
                            <div className="stats-container">


                                <div className="stat-box-ranks">
                                    <p><b>Your Rank: {userStats.rank}</b>
                                        <span className="tooltip">
                                        <i className="circle-icon">ℹ️</i>  {/* Optional info icon or just leave it empty */}
                                        <span className="tooltip-text">Placement among other dealers</span>
                                        </span>
                                    </p>
                                    <div className="stat-boxtext tooltip">Files to rankup: {userStats.filesNeededToRankup}
                                        <span className="tooltip-text"> <b>Files to rankup</b><br></br><br></br>The number of files between you and the dealer above you.</span>
                                        </div>
                                        <div className="stat-boxtext tooltip">Files from rankdown: {userStats.filesAwayFromRankdown}
                                        <span className="tooltip-text"><b>Files from rankdown</b> <br></br><br></br>The number of files between you and the dealer below you.</span>
                                        </div>
                                        </div>
                                        <div className="stat-box-ranks">
                                            <p><b>Your Level: {companyLevel.level} </b>       {olevelStatus.forced ? (
                                        <>
                                            (Forced) <br />
                                            Expires: {formatDate(olevelStatus.olevelexp)}
                                        </>
                                        ) : (
                                        ''
                                        )}
                                        <span className="tooltip">
                                        <i className="circle-icon">ℹ</i>  {/* Optional info icon */}
                                        <span className="tooltip-text">Your level, based on last 90 days fileuploads</span>
                                        </span>
                                    </p>
                                    <div className="stat-boxtext tooltip">Files to levelup: {userStats.filesNeededToLevelup}
                                    <span className="tooltip-text">The number of files based on last 90 days you need to upload to level up.</span>
                                    </div>
                                    <div className="stat-boxtext tooltip">Files from leveldown: {userStats.filesAwayFromLeveldown}
                                    <span className="tooltip-text">The number of files based on last 90 days before you drop down a level.</span>
                                    </div>
                                    <div className="stat-boxtext tooltip">Files last 90 days: {userStats.filelast90days}
                                    <span className="tooltip-text">The total number of files uploaded in the last 90 days.</span>
                                    </div><br></br>



                                    <span className="stat-boxtext3">Base credit: {companyLevel.baseCredits}</span>
                                        <span className="tooltip">
                                        <i className="circle-icon">ℹ️</i>  {/* Optional info icon */}
                                        <span className="tooltip-text">Your basecredit, based on your level</span>
                                        </span>

                                </div>

                                <div className="stat-box-ranks">
                                    <div className="stat-boxtext2">Total Files Today: {userStats.todayFiles}</div>
                                    <div className="stat-boxtext2">Total Files Weekly: {userStats.weeklyFiles}</div>
                                    <div className="stat-boxtext2">Total Files Monthly: {userStats.monthlyFiles}</div>
                                    <div className="stat-boxtext2">Total Files Yearly: {userStats.yearlyFiles}</div>
                                    <div className="stat-boxtext2">Total Files Ever: {userStats.totalFiles}</div>
                                </div>




                            </div>
                            <div className="profilechart-container">
                            <div className="newchart-container">
                                <div className="newchart-upper">
                                <div className="newchart-div1upper">
                                    <div className="newchart-div1-row">
                                    {messages.length > 0 ? (
    <div className="messages-list">
        {messages.map((message) => {
            // Parse confirmedby som en array av tall
            const confirmedByIds = JSON.parse(message.confirmedby || '[]').map(id => parseInt(id, 10));

            // Fjerner <br> for visning, men beholder andre HTML-tagger
            const snippet = message.message
                .replace(/<br\s*\/?>/g, ' ') // Fjern <br> for å unngå linjeskift
                .slice(0, 80); // Kutt teksten til maks 80 tegn

            return (
                <div
                    key={message.id}
                    className={`message-row ${!confirmedByIds.includes(parseInt(usernameid, 10)) ? 'unread' : 'read'}`}
                    onClick={() => handleOpenMessage(message)}
                >
                    <div className={`message-icon ${confirmedByIds.includes(parseInt(usernameid, 10)) ? 'icon-read' : 'icon-unread'}`}>
                        {confirmedByIds.includes(parseInt(usernameid, 10)) ? '🗒️' : '📩'}
                    </div>

                    {/* Meldingsdetaljer */}
                    <div className="message-details">
                        <h4 className="message-title">{message.title}</h4>
                        {/* Render HTML som fet tekst */}
                        <p
                            className="message-snippet"
                            dangerouslySetInnerHTML={{
                                __html: `${snippet}...`, // Beholder HTML som <b> for fet tekst
                            }}
                        ></p>
                    </div>
                </div>
            );
        })}
        <div className="allmessages-btn-wrapper">
            <button className="allmessages-btn" onClick={openAllMessages}>See all messages</button>
        </div>
    </div>
) : (
    <p className="no-messages">Ingen nye meldinger fra Optifiles</p>
)}


{selectedMessage && (
    <div className="allmessage-background">
        <div className="allmessage-content">
            <div className="modal-header">
                <button className="close-btn" onClick={() => setSelectedMessage(null)}>CLOSE</button>

            </div>
            <h3>{selectedMessage.title}</h3>
            <p
                dangerouslySetInnerHTML={{ __html: selectedMessage.message }}
            ></p>

            {!JSON.parse(selectedMessage.confirmedby || '[]')
                .map(id => parseInt(id, 10))
                .includes(parseInt(usernameid, 10)) && (
                    <button
                        className="confirmreadmessages-btn"
                        onClick={() => {
                            confirmMessage(selectedMessage.id);
                            // Gi umiddelbar feedback ved å oppdatere `selectedMessage`
                            setSelectedMessage((prev) => ({
                                ...prev,
                                confirmedby: JSON.stringify([
                                    ...JSON.parse(prev.confirmedby || '[]'),
                                    parseInt(usernameid, 10),
                                ]),
                            }));
                        }}
                    >
                        Confirm read
                    </button>
            )}
        </div>
    </div>
)}


                                                                        {showAllMessagesModal && (
                                                                            <div className="allmessage-background">
                                                                                <div className="allmessage-content">
                                                                                    <div className="modal-header">
                                                                                        <button className="close-btn" onClick={closeAllMessagesModal}>CLOSE</button>
                                                                                    </div>
                                                                                    <h3>All Messages</h3>
                                                                                    <div className="messages-list">
                                                                                    {allMessages.length > 0 ? (
                                                                                        allMessages.map((message) => {
                                                                                            const confirmedByIds = JSON.parse(message.confirmedby || '[]').map(id => parseInt(id, 10));

                                                                                            return (
                                                                                                <div
                                                                                                    key={message.id}
                                                                                                    className={`message-row ${!confirmedByIds.includes(parseInt(usernameid, 10)) ? 'unread' : 'read'}`}
                                                                                                    onClick={() => handleOpenMessage(message)}
                                                                                                >
                                                                                                    <div
                                                                                                        className={`message-icon ${confirmedByIds.includes(parseInt(usernameid, 10)) ? 'icon-read' : 'icon-unread'}`}
                                                                                                    >
                                                                                                        {confirmedByIds.includes(parseInt(usernameid, 10)) ? '🗒️' : '📩'}
                                                                                                    </div>
                                                                                                    <div className="message-details">
                                                                                                        <h4 className="message-title">{message.title}</h4>
                                                                                                        <p className="message-snippet">{message.message.slice(0, 80)}...</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        })
                                                                                    ) : (
                                                                                        <p>Loading messages...</p>
                                                                                    )}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className="newchart-div1-row"><LatestDatabaseEntries /></div>
                                                                    <div className="newchart-div1-row"><LatestForumTopics /></div>
                                                                </div>

                                                                </div>
                                                                <div className="newchart-div2upper">
                                                                    <div className="newchart-div2-content">                                                                            {todaysBookings.length === 0 ? (
                                                                                    <p>Ingen bookinger i dag</p>
                                                                                ) : (
                                                                                    <ul className="dark-theme-list">
                                                                                        {todaysBookings.map((booking) => (
                                                                                            <li key={booking.id}>
                                                                                                <span className="booking-vehicle">{booking.vehicle}</span>
                                                                                                {/* <span className="booking-owner">
                                                                                                    {booking.taskowner_name || 'Ukjent'}
                                                                                                </span> */}
                                                                                                <span className="booking-time">
                                                                                                    {new Date(booking.datetime).toLocaleTimeString('no-NO', {
                                                                                                        hour: '2-digit',
                                                                                                        minute: '2-digit',
                                                                                                    })}
                                                                                                </span>

                                                                                            </li>
                                                                                        ))}
                                                                                    </ul>
                                                                                )}</div>
                                </div>
                            </div>
                            </div>

                        </div>
                    </div>
                );
            case 'Statistics':
                return (
                    <div className="profilechart-container">
                    <div style={chartContainerStyle}>
                        {uploadsData.length > 0 ? (
                            <Line data={processChartData(uploadsData)} options={getOptions(`Uploads`)} />
                        ) : (
                            <p>Loading chart data...</p>
                        )}
                    </div>
            </div>
                );
            case 'Log':
                return renderLogs();
            case 'optipoints':
                return renderoptipoints();
            case 'Shop':
                return renderShop();
            case 'Settings':
                return (
                    <p>Dette er settingssiden.. her ser hvis man er owner, oversikt over alle profiler som har tilgang på sitt firma, og kan kaste ut folk.</p>
                );
            default:
                return <p>Velg en seksjon.</p>;
        }
    };

    return (
        <div className="task-profilemodal-background" >
            <div className="task-profilemodal-content2" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <button className="close-btn" onClick={onClose}>CLOSE</button>
                </div>
                <div className="task-profile-header">
                    <div className="task-profile-left">
                        <div className="task-profile-picture">
                            <img src={avatar} alt="Profile" />
                        </div>
                        <div className="task-profile-info">
                            <p>Name: {username}</p>
                            <p>Dealer: {profileData.name}</p>
                            <p>Company: {profileData.legal}</p>
                            <p>VAT: {profileData.vat}</p>
                            <p>Address: {profileData.address}</p>
                            <p>Employees: {profileData.employees.join(', ')}</p>
                            <button className="task-edit-profile-button" onClick={openEditModal}>Edit Profile</button>
                        </div>
                    </div>
                    <div className="hot-items">
                        <div className="hot-item">
                            <p>Hot Manufacturer</p>
                            <img src={`/images/manufacturers/${hotManufacturer.toLowerCase()}.webp`} alt="Hot Manufacturer" />
                        </div>
                        <div className="hot-item">
                            <p>Hot Option</p>
                            <img src={`/images/options/${hotOption.toLowerCase()}.png`} alt="Hot Option" />
                        </div>
                        <div className="hot-item">
                            <p>Hot Tool</p>
                            <img src={`/images/tools/${hotTool.toLowerCase()}.webp`} alt="Hot Tool" />
                        </div>
                    </div>


                </div>

                <div className="task-profile-nav">
  <span
    className={activeSection === 'Home' ? 'active' : ''}
    onClick={() => setActiveSection('Home')}
  >
    Home
  </span>

  <span
    className={activeSection === 'Statistics' ? 'active' : ''}
    onClick={() => setActiveSection('Statistics')}
  >
    Statistics
  </span>
  <span
    className={activeSection === 'optipoints' ? 'active' : ''}
    onClick={() => setActiveSection('optipoints')}
  >
    Optipoints
  </span>
  <span
    className={activeSection === 'Log' ? 'active' : ''}
    onClick={() => setActiveSection('Log')}
  >
    Credits Log
  </span>


  <span
    className={activeSection === 'Shop' ? 'active' : ''}
    onClick={() => setActiveSection('Shop')}
  >
    Shop
  </span>
</div>


                <div className="task-profile-content">
                    {renderContent()}
                </div>


                {showEditModal && (
                    <div className="editprofilemodal-background" onClick={() => setShowEditModal(false)}>
                        <div className="editprofilemodal-content" onClick={e => e.stopPropagation()}>
                            <h3>Edit Profile</h3>
                            <input
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                value={editProfileData.phone}
                                onChange={handleEditInputChange}
                            />
                            <input
                                type="text"
                                name="email"
                                placeholder="Email"
                                value={editProfileData.email}
                                onChange={handleEditInputChange}
                            />
                            <input
                                type="file"
                                name="profilePicture"
                                onChange={handleProfilePictureChange}
                            />
                            <button className="save-button" onClick={handleEditProfile}>Save</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}