import React, { useState, useEffect } from 'react';

export default function ManualsCategoryManager({
    selectedCategory,
    setSelectedCategory,
    selectedSubcategory,
    setSelectedSubcategory,
    fetchCategories,
    categories,
    setCategories, // Add this
}) {
    const [categoryTitle, setCategoryTitle] = useState('');
    const [subcategoryTitle, setSubcategoryTitle] = useState('');
    const [subSubcategoryTitle, setSubSubcategoryTitle] = useState('');
    const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);
    const [subSubcategories, setSubSubcategories] = useState([]);
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [editCategoryTitle, setEditCategoryTitle] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingSubcategoryId, setEditingSubcategoryId] = useState(null);
    const [editSubcategoryTitle, setEditSubcategoryTitle] = useState('');
    const [newSubSubcategoryTitle, setNewSubSubcategoryTitle] = useState('');
    const [editingSubSubcategoryId, setEditingSubSubcategoryId] = useState(null);
    const [editSubSubcategoryTitle, setEditSubSubcategoryTitle] = useState('');
    const [newSubSubcategoryTitles, setNewSubSubcategoryTitles] = useState({});
    const [view, setView] = useState('main'); // "main", "editCategory", or "addSubcategory"
    
    const [categoryDescription, setCategoryDescription] = useState('');
    const [subcategoryDescription, setSubcategoryDescription] = useState('');
    const [subSubcategoryDescription, setSubSubcategoryDescription] = useState('');
    const [editCategoryDescription, setEditCategoryDescription] = useState('');
    const [editSubcategoryDescription, setEditSubcategoryDescription] = useState('');
    const [editSubSubcategoryDescription, setEditSubSubcategoryDescription] = useState('');
    const [descriptions, setDescriptions] = useState({});




    useEffect(() => {
        fetchCategories();
    }, []);

    // Fetch subcategories when a category is selected
    const fetchSubcategories = async (categoryId) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}/subcategories`
          );
          const data = await response.json();
          setSubcategories(data);
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      };

       // Fetch subcategories when a category is selected
       const handleCategoryChange = async (categoryId) => {
        // console.log('Manage Categories - Selected Category ID:', categoryId);
        const selectedCategory = categories.find((cat) => cat.id === Number(categoryId));
        setSelectedCategory(selectedCategory);
        setSelectedSubcategory(null);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}/subcategories`);
            const data = await response.json();
            // console.log('Manage Categories - Fetched Subcategories:', data);
            setSubcategories(data);
            // console.log('Fetched Subcategories:', data); 
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };
    
    
    useEffect(() => {
        // console.log('Updated Subcategories:', subcategories); 
    }, [subcategories]);

    const handleCategoryDescriptionChange = (categoryId, value) => {
        setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            [categoryId]: value, // Update only the specific category's description
        }));
    };

    const handleSubcategoryDescriptionChange = (subcategoryId, value) => {
        setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            [subcategoryId]: value, // Update only the specific subcategory's description
        }));
    };

    const handleSubSubcategoryDescriptionChange = (subSubcategoryId, value) => {
        setDescriptions((prevDescriptions) => ({
            ...prevDescriptions,
            [subSubcategoryId]: value, // Update only the specific sub-subcategory's description
        }));
    };
    

    // Fetch sub-subcategories when a subcategory is selected
    const handleSubcategoryChange = async (subcategoryId) => {
    
        const selectedSubcategory = subcategories.find((sub) => sub.id === subcategoryId);
        setSelectedSubcategory(selectedSubcategory);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}/sub-subcategories`);
            if (response.ok) {
                const data = await response.json();
                // Attach sub-subcategories to the specific subcategory
                setSubcategories((prevSubcategories) =>
                    prevSubcategories.map((sub) =>
                        sub.id === subcategoryId ? { ...sub, subSubcategories: data } : sub
                    )
                );
            } else {
                console.error('Failed to fetch sub-subcategories.');
            }
        } catch (error) {
            console.error('Error fetching sub-subcategories:', error);
        }
    };
    
    

    // Fetch manuals when a sub-subcategory is selected
   
    
    // Handle create category
    // const handleCreateCategory = async () => {
    //     if (!categoryTitle.trim()) {
    //         alert('Category title cannot be empty.');
    //         return;
    //     }
    
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories`, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ title: categoryTitle }),
    //         });
    
    //         if (response.ok) {
    //             const newCategory = await response.json(); // Get the created category
    //             setCategories((prevCategories) => [...prevCategories, newCategory]); // Update state immediately
    //             setCategoryTitle(''); // Clear input
    //             fetchCategories();
    //             alert('Category created successfully!');
    //         } else {
    //             console.error('Failed to create category');
    //             alert('Error creating category.');
    //         }
    //     } catch (error) {
    //         console.error('Error creating category:', error);
    //     }
    // };

    const handleCreateCategory = async () => {
        if (!categoryTitle.trim() || !categoryDescription.trim()) {
            alert('Both title and description are required.');
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ title: categoryTitle, description: categoryDescription }),
            });
    
            if (response.ok) {
                const newCategory = await response.json();
                setCategories((prev) => [...prev, newCategory]);
                setCategoryTitle('');
                setCategoryDescription('');
                alert('Category created successfully!');
            }
        } catch (error) {
            console.error('Error creating category:', error);
        }
    };
    
    
    
    // const handleCreateSubcategory = async () => {
    //     if (!subcategoryTitle.trim()) {
    //         alert('Subcategory title cannot be empty.');
    //         return;
    //     }
    
    //     if (!editingCategory) {
    //         alert('Please select a category to add the subcategory.');
    //         return;
    //     }
    
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories`, {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ categoryId: editingCategory.id, title: subcategoryTitle }),
    //         });
    
    //         if (response.ok) {
    //             const newSubcategory = await response.json(); // Get the created subcategory
    //             setSubcategories((prevSubcategories) => [...prevSubcategories, newSubcategory]); // Update state immediately
    //             setSubcategoryTitle(''); // Clear input
    //             handleCategoryChange(editingCategory.id);
    //             alert('Subcategory created successfully!');
    //         } else {
    //             console.error('Failed to create subcategory');
    //             alert('Error creating subcategory.');
    //         }
    //     } catch (error) {
    //         console.error('Error creating subcategory:', error);
    //     }
    // };
    
    const handleCreateSubcategory = async () => {
        if (!subcategoryTitle.trim() || !subcategoryDescription.trim()) {
            alert('Both title and description are required.');
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    categoryId: editingCategory.id,
                    title: subcategoryTitle,
                    description: subcategoryDescription,
                }),
            });
    
            if (response.ok) {
                const newSubcategory = await response.json();
                setSubcategories((prev) => [...prev, newSubcategory]);
                setSubcategoryTitle('');
                setSubcategoryDescription('');
                alert('Subcategory created successfully!');
            }
        } catch (error) {
            console.error('Error creating subcategory:', error);
        }
    };
    

// Create a new sub-subcategory
// const handleCreateSubSubcategory = async (subcategoryId) => {
//     const title = newSubSubcategoryTitles[subcategoryId] || ''; // Get the title for this subcategory

//     if (!title.trim()) {
//         alert('Sub-subcategory title cannot be empty.');
//         return;
//     }

//     try {
//         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ subcategoryId, title }),
//         });

//         if (response.ok) {
//             const data = await response.json();
//             console.log('Sub-subcategory created:', data);

//             // Clear the input field
//             setNewSubSubcategoryTitles((prevTitles) => ({
//                 ...prevTitles,
//                 [subcategoryId]: '',
//             }));

//             // Fetch and update the sub-subcategories for this subcategory
//             await handleSubcategoryChange(subcategoryId);
//         } else {
//             console.error('Failed to create sub-subcategory.');
//             alert('Error creating sub-subcategory.');
//         }
//     } catch (error) {
//         console.error('Error creating sub-subcategory:', error);
//     }
// };

const handleCreateSubSubcategory = async (subcategoryId) => {
    if (!newSubSubcategoryTitles[subcategoryId]?.trim()) {
        alert('Both title and description are required.');
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                subcategoryId,
                title: newSubSubcategoryTitles[subcategoryId],
                description: subSubcategoryDescription,
            }),
        });

        if (response.ok) {
            await handleSubcategoryChange(subcategoryId); // Refresh sub-subcategories
            setSubSubcategoryDescription('');
        }
    } catch (error) {
        console.error('Error creating sub-subcategory:', error);
    }
};



// Delete subcategory
const handleDeleteSubcategory = async (subcategoryId) => {
    if (!window.confirm('Are you sure you want to delete this subcategory?')) return;
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Failed to delete subcategory:', errorText);
        } else {
            // console.log('Subcategory deleted successfully');
            handleCategoryChange(selectedCategory.id); // Refresh subcategories
        }
    } catch (error) {
        console.error('Error deleting subcategory:', error);
    }
};


 // Delete sub-subcategory
 const handleDeleteSubSubcategory = async (subSubcategoryId) => {
    if (!window.confirm('Are you sure you want to delete this sub-subcategory?')) return;

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories/${subSubcategoryId}`, {
            method: 'DELETE',
        });

        if (response.ok) {
            // console.log('Sub-subcategory deleted successfully.');
            // Re-fetch subcategories and sub-subcategories for the selected subcategory
            if (selectedSubcategory) {
                await handleSubcategoryChange(selectedSubcategory.id);
            }
        } else {
            console.error('Failed to delete sub-subcategory.');
        }
    } catch (error) {
        console.error('Error deleting sub-subcategory:', error);
    }
};


const handleSubSubcategoryTitleChange = (subcategoryId, value) => {
    setNewSubSubcategoryTitles((prevTitles) => ({
        ...prevTitles,
        [subcategoryId]: value, // Set title for the specific subcategory
    }));
};

    // Function to update a category
    // const handleUpdateCategory = async () => {
    //     if (!editingCategory || editCategoryTitle.trim() === '') return;

    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${editingCategory.id}`, {
    //             method: 'PUT',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ title: editCategoryTitle }),
    //         });

    //         if (response.ok) {
    //             setEditingCategory(null);
    //             setEditCategoryTitle('');;
    //             fetchCategories();
    //             setView('main');
    //         }
    //     } catch (error) {
    //         console.error('Error updating category:', error);
    //     }
    // };

    const handleUpdateCategory = async () => {
        if (!editCategoryTitle.trim() || !editCategoryDescription.trim()) {
            alert('Both title and description are required.');
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${editingCategory.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ title: editCategoryTitle, description: editCategoryDescription }),
            });
    
            if (response.ok) {
                setEditingCategory(null);
                setEditCategoryTitle('');
                setEditCategoryDescription('');
                fetchCategories(); // Refresh the list
            }
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };
    

    // Handle edit category
   const handleEditCategory = async (category) => {
    setEditingCategory(category);
    setEditCategoryTitle(category.title);
    setView('editCategory');

    // Fetch subcategories for the selected category
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${category.id}/subcategories`);
        const data = await response.json();
        setSubcategories(data); // Update state with fetched subcategories
    } catch (error) {
        console.error('Error fetching subcategories:', error);
    }
};

const handleBackToMain = () => {
    setView('main'); // Go back to the main view
};

const handleEditSubcategoryView = (subcategory) => {
    setEditingSubcategoryId(subcategory.id);
    setEditSubcategoryTitle(subcategory.title);
    setView('editSubcategory'); // Set view to 'editSubcategory'
};


    // Function to delete a category
    const handleDeleteCategory = async (categoryId) => {
        if (!window.confirm('Are you sure you want to delete this category?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}`, {
                method: 'DELETE'
            });

            if (response.ok) {
                fetchCategories(); // Refresh categories
            } else {
                console.error('Failed to delete category');
            }
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    // Edit subcategory (enter edit mode)
    const handleEditSubcategory = (subcategory) => {
        if (subcategory) {
          setEditingSubcategoryId(subcategory.id);
          setEditSubcategoryTitle(subcategory.title || "");
          setEditSubcategoryDescription(subcategory.description || "");
        }
      };
    

// Edit sub-subcategory (enter edit mode)
const handleEditSubSubcategory = (subSubcategory) => {
    setEditingSubSubcategoryId(subSubcategory.id); // Set the sub-subcategory being edited
    setEditSubSubcategoryTitle(subSubcategory.title || ''); // Autofill title
    setEditSubSubcategoryDescription(subSubcategory.description || ''); // Autofill description
};



// Update subcategory
// const handleUpdateSubcategory = async () => {
//     if (!editSubcategoryTitle.trim()) return;

//     try {
//         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${editingSubcategoryId}`, {
//             method: 'PUT',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ title: editSubcategoryTitle }),
//         });

//         if (response.ok) {
//             // Update the subcategory list by fetching them again
//             const updatedSubcategories = subcategories.map((subcategory) =>
//                 subcategory.id === editingSubcategoryId
//                     ? { ...subcategory, title: editSubcategoryTitle }
//                     : subcategory
//             );
//             setSubcategories(updatedSubcategories);
//             setEditingSubcategoryId(null); // Exit edit mode
//             setEditSubcategoryTitle(''); // Clear input
//         } else {
//             console.error('Failed to update subcategory');
//         }
//     } catch (error) {
//         console.error('Error updating subcategory:', error);
//     }
// };

const handleUpdateSubcategory = async () => {
    if (!editSubcategoryTitle.trim() || !editSubcategoryDescription.trim()) {
      alert("Both title and description are required.");
      return;
    }
  
    const payload = {
      title: editSubcategoryTitle,
      description: editSubcategoryDescription,
    };
  
    // console.log("Updating Subcategory:", payload);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SITE_URL}/oc/subcategories/${editingSubcategoryId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );
  
      if (response.ok) {
        // console.log("Subcategory updated successfully.");
        setEditingSubcategoryId(null);
        setEditSubcategoryTitle("");
        setEditSubcategoryDescription("");
  
        // Fetch updated subcategories
        if (selectedCategory) {
          await fetchSubcategories(selectedCategory.id); // Ensure the updated list is fetched
        }
      } else {
        const errorDetails = await response.json();
        console.error("Failed to update subcategory:", errorDetails);
      }
    } catch (error) {
      console.error("Error updating subcategory:", error);
    }
  };
  


  const handleCancelEditSubcategory = () => {
    setEditingSubcategoryId(null);
    setEditSubcategoryTitle("");
    setEditSubcategoryDescription("");
  };

const handleCancelEditSubSubcategory = () => {
    setEditingSubSubcategoryId(null);
    setEditSubSubcategoryTitle('');
};

// const handleUpdateSubSubcategory = async (subSubcategory) => {
//     if (!editSubSubcategoryTitle.trim()) {
//         alert('Sub-subcategory title cannot be empty.');
//         return;
//     }

//     try {
//         const response = await fetch(
//             `${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories/${subSubcategory.id}`,
//             {
//                 method: 'PUT',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({ title: editSubSubcategoryTitle }),
//             }
//         );

//         if (response.ok) {
//             setEditingSubSubcategoryId(null);
//             setEditSubSubcategoryTitle('');
//             handleSubcategoryChange(subSubcategory.parent_subcategory_id); // Refresh list
//         } else {
//             alert('Failed to update sub-subcategory.');
//         }
//     } catch (error) {
//         console.error('Error updating sub-subcategory:', error);
//     }
// };

const handleUpdateSubSubcategory = async (subSubcategory) => {
    if (!editSubSubcategoryTitle.trim() || !editSubSubcategoryDescription.trim()) {
        alert('Both title and description are required.');
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories/${subSubcategory.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                title: editSubSubcategoryTitle,
                description: editSubSubcategoryDescription,
            }),
        });

        if (response.ok) {
            setEditingSubSubcategoryId(null);
            setEditSubSubcategoryTitle('');
            setEditSubSubcategoryDescription('');
            handleSubcategoryChange(subSubcategory.parent_subcategory_id); // Refresh the sub-subcategory list
        } else {
            alert('Failed to update sub-subcategory.');
        }
    } catch (error) {
        console.error('Error updating sub-subcategory:', error);
    }
};


const toggleSubcategory = (subcategoryId) => {
    const subcategoryElement = document.getElementById(`subcategory-${subcategoryId}`);
    subcategoryElement.classList.toggle('open');
};



return (
    <div>
      {view === "main" && (
        <div>
          <h4>Main Categories</h4>
          <ul className="manuals-modal-categories-list">
            {categories.map((category) => (
              <div key={category.id} className="manuals-modal-categories-list-item">
                <div className="manuals-modal-category-item">
                  <span>{category.title}</span>
                  <button
                    className="manuals-modal-button"
                    onClick={() => handleEditCategory(category)}
                  >
                    Edit
                  </button>
                  <button
                    className="manuals-modal-button"
                    onClick={() => handleDeleteCategory(category.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </ul>
          <div>
            <h4>Create New Category</h4>
            <input
              type="text"
              placeholder="New category title"
              value={categoryTitle}
              onChange={(e) => setCategoryTitle(e.target.value)}
              className="manuals-modal-input"
            />
            <input
              type="text"
              placeholder="Category description"
              value={categoryDescription}
              onChange={(e) => setCategoryDescription(e.target.value)}
              className="manuals-modal-input"
            />
            <button
              className="manuals-modal-button create"
              onClick={handleCreateCategory}
            >
              Create Category
            </button>
          </div>
        </div>
      )}
  
      {view === "editCategory" && editingCategory && (
        <div>
          <h4>Edit Category: {editingCategory?.title}</h4>
          <input
            type="text"
            placeholder="Edit category title"
            value={editCategoryTitle}
            onChange={(e) => setEditCategoryTitle(e.target.value)}
            className="manuals-modal-input"
          />
          <input
            type="text"
            placeholder="Category description"
            value={editCategoryDescription}
            onChange={(e) => setEditCategoryDescription(e.target.value)}
            className="manuals-modal-input"
          />
          <button
            className="manuals-modal-button save"
            onClick={handleUpdateCategory}
          >
            Save Changes
          </button>
          <button
            className="manuals-modal-button back"
            onClick={() => setView("main")}
          >
            Back to Categories
          </button>
  
          <div>
  <h4 className='manuals-center'>Subcategories</h4>
  {subcategories.length > 0 ? (
    <ul className="manuals-modal-categories-list">
      {subcategories.map((subcategory) => (
        <div key={subcategory.id} className="manuals-modal-subcategory-item">
          <div>
            {/* Subcategory Header */}
            {editingSubcategoryId === subcategory.id ? (
              <div>
                <input
                  type="text"
                  placeholder="Edit Subcategory Title"
                  value={editSubcategoryTitle}
                  onChange={(e) => setEditSubcategoryTitle(e.target.value)}
                  className="manuals-modal-input"
                />
                <input
                  type="text"
                  placeholder="Edit Subcategory Description"
                  value={editSubcategoryDescription}
                  onChange={(e) => setEditSubcategoryDescription(e.target.value)}
                  className="manuals-modal-input"
                />
                <div className="manuals-modal-buttons">
                  <button
                    className="manuals-modal-button save"
                    onClick={() => handleUpdateSubcategory(subcategory)}
                  >
                    Save
                  </button>
                  <button
                    className="manuals-modal-button cancel"
                    onClick={() => {
                      setEditingSubcategoryId(null);
                      setEditSubcategoryTitle("");
                      setEditSubcategoryDescription("");
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className='manuals-center'>
                <span
                    className="subcategory-title"
                    onClick={() => {
                        // Toggle visibility of the subcategory content
                        const isCurrentlySelected = selectedSubcategory?.id === subcategory.id;

                        if (!isCurrentlySelected) {
                        handleSubcategoryChange(subcategory.id); // Fetch sub-subcategories if not already selected
                        }

                        setSelectedSubcategory(isCurrentlySelected ? null : subcategory); // Toggle selection
                    }}
                    >
                    {subcategory.title}
                    </span>
                                    
                    <div className="manuals-modal-buttons">
                        <button
                        className="manuals-modal-button edit"
                        onClick={() => handleEditSubcategory(subcategory)}
                        >
                        Edit
                        </button>
                        <button
                        className="manuals-modal-button delete"
                        onClick={() => handleDeleteSubcategory(subcategory.id)}
                        >
                        Delete
                        </button>

                         {/* {selectedSubcategory?.id === subcategory.id && (
      <button
        className="close-button"
        onClick={() => {
          setSelectedSubcategory(null);
          console.log('Subcategory closed');
        }}
      >
        Close
      </button>
                        )} */}
                    </div>
              </div>
            )}
          </div>

          {selectedSubcategory?.id === subcategory.id && subcategory.subSubcategories?.length > 0 && (
  <div className="manuals-modal-subsubcategory-grid">
    {subcategory.subSubcategories.map((subSubcategory) => (
      <div key={subSubcategory.id} className="manuals-modal-subsubcategory-item">
        {editingSubSubcategoryId === subSubcategory.id ? (
          <div>
            <input
              type="text"
              placeholder="Edit Sub-Subcategory Title"
              value={editSubSubcategoryTitle}
              onChange={(e) => setEditSubSubcategoryTitle(e.target.value)}
              className="manuals-modal-input"
            />
            <input
              type="text"
              placeholder="Edit Sub-Subcategory Description"
              value={editSubSubcategoryDescription}
              onChange={(e) => setEditSubSubcategoryDescription(e.target.value)}
              className="manuals-modal-input"
            />
            <div className="manuals-modal-buttons">
              <button
                className="manuals-modal-button save"
                onClick={() => handleUpdateSubSubcategory(subSubcategory)}
              >
                Save
              </button>
              <button
                className="manuals-modal-button cancel"
                onClick={() => setEditingSubSubcategoryId(null)}
              >
                Cancel
              </button>
            </div>

            
          </div>
        ) : (
          <div>
            
            <span
              className="subsubcategory-title"
              onClick={() => handleEditSubSubcategory(subSubcategory)}
            >
              {subSubcategory.title}
            </span>
            <div className="manuals-modal-buttons">
              <button
                className="manuals-modal-button delete"
                onClick={() => handleDeleteSubSubcategory(subSubcategory.id)}
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
      
    ))}
    <div className="manuals-modal-add-subsubcategory">
            <input
              type="text"
              placeholder="New sub-subcategory title"
              value={newSubSubcategoryTitles[subcategory.id] || ""}
              onChange={(e) =>
                handleSubSubcategoryTitleChange(subcategory.id, e.target.value)
              }
              className="manuals-modal-input"
            />
            <button
              className="manuals-modal-button create"
              onClick={() => handleCreateSubSubcategory(subcategory.id)}
            >
              Add Sub-Subcategory
            </button>
          </div>
  </div>
  
)}

        </div>
      ))}
    </ul>
  ) : (
    <p>No subcategories available.</p>
  )}

  {/* Add New Subcategory */}
  <div>
    <h4>Create New Subcategory</h4>
    <input
      type="text"
      placeholder="New subcategory title"
      value={subcategoryTitle}
      onChange={(e) => setSubcategoryTitle(e.target.value)}
      className="manuals-modal-input"
    />
    <input
      type="text"
      placeholder="Subcategory description"
      value={subcategoryDescription}
      onChange={(e) => setSubcategoryDescription(e.target.value)}
      className="manuals-modal-input"
    />
    <button
      className="manuals-modal-button create"
      onClick={handleCreateSubcategory}
    >
      Create Subcategory
    </button>
  </div>
</div>
        </div>
      )}
    </div>
  );
}  