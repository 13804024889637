import React, { useEffect, useState, useRef, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { useAuth } from '../auth/AuthProvider';
import defaultModelImage from '../assets/modelimage.png';
import defaultDynoImage from '../assets/dynoimage.png';
import './Database.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import DynoChart from './DynoChart';
import DatabaseClassic from './DatabaseClassic'; // Importer komponenten


function Database({ onClose }) {
  const modalRef = useRef(null);
  const { authToken } = useAuth();
  const [vehicleModels, setVehicleModels] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editData, setEditData] = useState({
    power_original: '',
    torque_original: '',
    power_opti: '',
    torque_opti: '',
    power: '',
    torque: '',
    obd_location: '',
    ecu_location: '',
    acm_location: '',
    notes: ''
  });
  
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [viewImage, setViewImage] = useState(null);
  const [copiedOpti, setCopiedOpti] = useState(false);
  const [copiedPower, setCopiedPower] = useState(false);
  const [selectedFuelType, setSelectedFuelType] = useState(null);
  const [country, setCountry] = useState(''); // Add country state
  const { userProfile, setUserProfile } = useContext(UserContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTuner, setIsTuner] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [obdImage, setObdImage] = useState(null);
  const [ecuPicture, setEcuPicture] = useState(null);
  const [acmPicture, setAcmPicture] = useState(null);
  const [showLogModal, setShowLogModal] = useState(false);
  const [logEntries, setLogEntries] = useState([]);

  const handleShowLog = (model) => {
    setShowLogModal(true);
    fetchModelLog(model.id);
  };

  const fetchModelLog = async (modelId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/model-log/${modelId}`);
      if (response.ok) {
        const data = await response.json();
        setLogEntries(data);
      } else {
        console.error('Failed to fetch model log');
      }
    } catch (error) {
      console.error('Error fetching model log:', error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);


  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
  
          // Logg data for debugging
          // console.log('User profile data fetched:', data);
  
          setUserProfile(data);
          setIsAdmin(data.admin === 1);
          setIsTuner(data.tuner === 1);
          setIsSupport(data.support === 1);
        } else {
          throw new Error('Failed to fetch user profile');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
  
    if (authToken) {
      fetchUserProfile();
    }
  }, [authToken, setUserProfile]);
  
  
  const fetchVehicleModels = () => {
    fetch(process.env.REACT_APP_SITE_URL + '/vehicle-models')
      .then(response => response.json())
      .then(data => {
        setVehicleModels(data);
      })
      .catch(error => console.error('Error fetching vehicle models:', error));
  };

  const toggleRow = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const openImage = (imageUrl) => {
    setViewImage(imageUrl);
  };

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      if (type === 'opti') {
        setCopiedOpti(true);
        setTimeout(() => setCopiedOpti(false), 2500);
      } else if (type === 'power') {
        setCopiedPower(true);
        setTimeout(() => setCopiedPower(false), 2500);
      }
    }, (err) => {
      console.error('Failed to copy text to clipboard', err);
    });
  };

  const handleEdit = (model) => {
    setSelectedModelId(model.id);
    setSelectedFuelType(model.fueltype);
    setEditData({
      model_name: model.modelName, // Legger til modellnavnet
      power_original: model.power_original || '',
      torque_original: model.torque_original || '',
      power_opti: model.power_opti,
      torque_opti: model.torque_opti,
      power: model.power,
      torque: model.torque,
      obd_location: model.obd_location,
      ecu_location: model.ecu_location,
      acm_location: model.acm_location,
      notes: model.notes
    });
    setShowEditForm(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData(prev => ({ ...prev, [name]: value }));
  };

  const saveEdit = () => {
    const previousModel = vehicleModels.find((m) => m.id === selectedModelId);
  
    // Sjekk om vi skal overstyre opti-feltene:
    const isFuelType3 = (selectedFuelType === 3);
  
    // Forbered payload til backend
    const payload = {
      id: selectedModelId,
      model_name: editData.model_name,
      power_original: editData.power_original,
      torque_original: editData.torque_original,
      // Overstyr power_opti og torque_opti hvis fueltype=3:
      power_opti: isFuelType3 ? editData.power : editData.power_opti,
      torque_opti: isFuelType3 ? editData.torque : editData.torque_opti,
      // Resten fylles som normalt
      power: editData.power,
      torque: editData.torque,
      obd_location: editData.obd_location,
      ecu_location: editData.ecu_location,
      acm_location: editData.acm_location,
      notes: editData.notes,
      // Pass på at backend vet hvilken fueltype radene har
      fueltype: selectedFuelType 
    };
  
    fetch(`${process.env.REACT_APP_SITE_URL}/updateModel`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(() => {
        setShowEditForm(false);
        fetchVehicleModels(); // Oppdater lokalt
  
        // Logg endringer etc. (som før)
        const changes = [];
        if (previousModel.power_opti !== payload.power_opti) {
          changes.push(`power_opti: ${previousModel.power_opti} -> ${payload.power_opti}`);
        }
        if (previousModel.torque_opti !== payload.torque_opti) {
          changes.push(`torque_opti: ${previousModel.torque_opti} -> ${payload.torque_opti}`);
        }
        if (previousModel.power !== payload.power) {
          changes.push(`power: ${previousModel.power} -> ${payload.power}`);
        }
        if (previousModel.torque !== payload.torque) {
          changes.push(`torque: ${previousModel.torque} -> ${payload.torque}`);
        }
        if (previousModel.obd_location !== payload.obd_location) {
          changes.push(`obd_location: ${previousModel.obd_location} -> ${payload.obd_location}`);
        }
        if (previousModel.ecu_location !== payload.ecu_location) {
          changes.push(`ecu_location: ${previousModel.ecu_location} -> ${payload.ecu_location}`);
        }
        if (previousModel.acm_location !== payload.acm_location) {
          changes.push(`acm_location: ${previousModel.acm_location} -> ${payload.acm_location}`);
        }
  
        const definition = changes.length > 0
          ? `Updated model information: ${changes.join(', ')}`
          : 'No changes were made';
  
        postdbupdatelog(previousModel, definition, selectedModelId);
      })
      .catch((error) => {
        console.error('Error updating model:', error);
      });
  };
  
  

  const cancelEdit = () => {
    setShowEditForm(false);
  };

  const filteredModels = searchText ? vehicleModels.filter(model => {
    const searchTerms = searchText.toLowerCase().split(' ').filter(term => term.trim() !== '');
    return searchTerms.every(term =>
      model.modelName?.toLowerCase().includes(term) ||
      model.manufacturerName?.toLowerCase().includes(term) ||
      model.rangeName?.toLowerCase().includes(term) ||
      model.typeName?.toLowerCase().includes(term)
    );
  }) : [];

  useEffect(() => {
    if (searchText) {
      fetchVehicleModels();
    } else {
      setVehicleModels([]);
    }
  }, [searchText]);



  const postdbupdatelog = async (model, definition, modelId) => {
    // console.log(
    //   `${userProfile.name} ${userProfile.id} from ${userProfile.companyName} ${userProfile.did} updated ${model.modelName} ${modelId} with a ${definition} `
    // );
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/dbentrylog-update`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user: userProfile.name,
          userid: userProfile.id,
          company: userProfile.companyName,
          companyid: userProfile.did,
          model: model.modelName,
          modelid: modelId,
          definition: definition,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to log update');
      }
  
      // console.log('Update log posted successfully');
    } catch (error) {
      console.error('Error posting update log:', error);
    }
  };
  


  return (
    <div className="databasemodal-background">
      <div className="databasemodal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <button className="close-btn" onClick={onClose}>CLOSE</button>
        </div>
        <h1>Vehicles Database</h1>
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="search-input"
        />
        <div className="search-results">
          {filteredModels.length > 0 ? (
            filteredModels.map((model, index) => (
              <div key={index}>
                <div className={`model-entry ${index % 2 === 0 ? 'even' : 'odd'}`} onClick={() => toggleRow(index)}>
                  <span className="model-description">{`${model.typeName} - ${model.manufacturerName} ${model.rangeName} ${model.modelName}`}</span>
                  <span className="model-stats">
                    {model.fueltype !== 3 && (
                      <span className="model-opti">Opti: <b>{model.power_opti} Hp / {model.torque_opti} Nm</b></span>
                    )}
                    <span className="model-power">Power: <b>{model.power} Hp / {model.torque} Nm</b></span>
                  </span>
                </div>
                {activeIndex === index && (
                  <div>
                    <div className="model-name-display">
                      <h2>{model.modelName}</h2>
                    </div>
                    <div className="model-expanded-details">
                    <span className="databasemodal-orivalue">
                      <div className="db3image-container">
                            <img
                              src={model.modelimage || defaultModelImage}
                              alt="Model Image"
                              className="detail-image"
                              onClick={() => openImage(model.modelimage || defaultModelImage)}
                            />

                          {(isAdmin || isTuner || isSupport) && (
                            <div>
                              <button
                                className="db3column-button"
                                onClick={() => {
                                  document.getElementById(`model-upload-${model.id}`).click();
                                }}
                              >
                                Upload
                              </button>
                              <input
                                type="file"
                                id={`model-upload-${model.id}`}
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                  if (e.target.files.length > 0) {
                                    const file = e.target.files[0];
                                    const formData = new FormData();
                                    formData.append('modelImage', file);
                                    formData.append('modelId', model.id);
                                    
                                    fetch(`${process.env.REACT_APP_SITE_URL}/uploadModelImage`, {
                                      method: 'POST',
                                      body: formData,
                                    })
                                      .then((response) => response.json())
                                      .then((data) => {
                                        const updatedModels = vehicleModels.map((m) => {
                                          if (m.id === model.id) {
                                            return { ...m, modelimage: data.imageUrl };
                                          }
                                          return m;
                                        });
                                        setVehicleModels(updatedModels);
                                        alert('Model image uploaded successfully!');
                                        const definition = "new modelpicture";
                                        postdbupdatelog(model, definition, model.id);
                                        
                                      })
                                      .catch((error) => {
                                        console.error('Error uploading Model image:', error);
                                      });
                                  }
                                }}
                              /><br></br>
                                                         
                            </div>
                            
                          )}
                          
                       </div>
                      </span>
                      <div className="text-details">
                        <div class="databasemodal-details">
                          <div class="databasemodal-fullrow">
                            <div class="databasemodal-label">
                              Original: 
                              <span class="databasemodal-orivalue">
                                {model.power_original} Hp / {model.torque_original} Nm
                              </span>
                            </div>
                          </div>

                          {model.fueltype !== 3 && (
                          <div class="databasemodal-row">
                            <div class="databasemodal-left">
                                <span 
                                  className={`copy-iconopti ${copiedOpti ? 'copied' : ''}`} 
                                  onClick={() => copyToClipboard(`${model.power_opti} Hp / ${model.torque_opti} Nm`, 'opti')}
                                >
                                  <FontAwesomeIcon icon={copiedOpti ? faCheck : faCopy} />
                                </span>
                            </div>
                            <div class="databasemodal-right">
                                  <div class="databasemodal-optivalue">
                                    Opti: 
                                    <span class="databasemodal-orivalue">
                                      {model.power_opti} Hp / {model.torque_opti} Nm
                                      <br />
                                      <span className="databasemodal-difference">
                                        {`+${model.power_opti - model.power_original} Hp / +${model.torque_opti - model.torque_original} Nm`}
                                      </span>
                                    </span>
                                  </div>      
                            </div>
                          </div>
                        )}

                          <div class="databasemodal-row">
                            <div class="databasemodal-left">
                              <span 
                                className={`copy-iconpower ${copiedPower ? 'copied' : ''}`} 
                                onClick={() => copyToClipboard(`${model.power} Hp / ${model.torque} Nm`, 'power')}
                              >
                                <FontAwesomeIcon icon={copiedPower ? faCheck : faCopy} />
                              </span>
                            </div>
                            <div class="databasemodal-right">
                              <div class="databasemodal-powervalue">
                                Power: 
                                <span class="databasemodal-orivalue">
                                  {model.power} Hp / {model.torque} Nm
                                  <br />
                                  <span className="databasemodal-difference">
                                    {`+${model.power - model.power_original} Hp / +${model.torque - model.torque_original} Nm`}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                    </div>
                      <div class="databasemodal-label">
                      Notes:
                        <span class="databasemodal-orivalue">
                        {model.notes}
                        </span>
                      </div>
                      </div>
                      <span className="databasemodal-orivalue">
                      <div className="db3image-container">
                      {model.dynoimage ? (
                      <img
                        src={model.dynoimage}
                        alt="Dyno Image"
                        className="detail-image"
                        onClick={() => openImage(model.dynoimage)}
                      />
                    ) : (
                      <div className="dyno-chart-container">

                            <img
                              src={model.dynoimage || defaultDynoImage}
                              alt="Dyno Image"
                              className="detail-image"
                              onClick={() => openImage(model.dynoimage || defaultDynoImage)}
                                                    />

                              {/* <DynoChart
                                powerOriginal={model.power_original}
                                torqueOriginal={model.torque_original}
                                powerOpti={model.power_opti}
                                torqueOpti={model.torque_opti}
                              /> */}
                            </div>
                          )}
                          {(isAdmin || isTuner || isSupport) && (
                            <div>
                              <button
                                className="db3column-button"
                                onClick={() => {
                                  document.getElementById(`dyno-upload-${model.id}`).click();
                                }}
                              >
                                Upload
                              </button>
                              <input
                                type="file"
                                id={`dyno-upload-${model.id}`}
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                  if (e.target.files.length > 0) {
                                    const file = e.target.files[0];
                                    const formData = new FormData();
                                    formData.append('dynoImage', file);
                                    formData.append('modelId', model.id);

                                    fetch(`${process.env.REACT_APP_SITE_URL}/uploadDynoImage`, {
                                      method: 'POST',
                                      body: formData,
                                    })
                                      .then((response) => response.json())
                                      .then((data) => {
                                        const updatedModels = vehicleModels.map((m) => {
                                          if (m.id === model.id) {
                                            return { ...m, dynoimage: data.imageUrl };
                                          }
                                          return m;
                                        });
                                        setVehicleModels(updatedModels);
                                        alert('Dyno image uploaded successfully!');
                                        const definition = "new dynopicture";
                                        postdbupdatelog(model, definition, model.id);
                                      })
                                      .catch((error) => {
                                        console.error('Error uploading Dyno image:', error);
                                      });
                                  }
                                }}
                              /><br></br>
                              <button onClick={() => handleEdit(model)}>Edit Info</button>
                            
                              {(isAdmin || isTuner) && (
                                <>
                               <button onClick={() => handleShowLog(model)}>View Log</button>
                                </>
                              )}
                            </div>
                          )}
                       </div>
                      </span>
                    </div>
                    <div className="db3split">
                    <div className="dbcolumn">
                      <div className="databasemodal-label">
                        OBD Location:
                        <span className="databasemodal-orivalue">
                          {model.obd_location}
                          <br />
                          <div className="db3image-container">

                              <img
                                src={model.obdimage || defaultModelImage}
                                alt="OBD Picture"
                                className="littledetail-image"
                                onClick={() => openImage(model.obdimage || defaultModelImage)}
                              />

                              {(isAdmin || isTuner || isSupport) && (
                                <div>
                                  <button
                                    className="db3column-button"
                                    onClick={() => {
                                      document.getElementById(`obd-upload-${model.id}`).click();
                                    }}
                                  >
                                    Upload
                                  </button>
                                  <input
                                    type="file"
                                    id={`obd-upload-${model.id}`}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                      if (e.target.files.length > 0) {
                                        const file = e.target.files[0];
                                        const formData = new FormData();
                                        formData.append('obdImage', file);
                                        formData.append('modelId', model.id);

                                        fetch(`${process.env.REACT_APP_SITE_URL}/uploadOBDImage`, {
                                          method: 'POST',
                                          body: formData,
                                        })
                                          .then((response) => response.json())
                                          .then((data) => {
                                            const updatedModels = vehicleModels.map((m) => {
                                              if (m.id === model.id) {
                                                return { ...m, obdimage: data.imageUrl };
                                              }
                                              return m;
                                            });
                                            setVehicleModels(updatedModels);
                                            alert('OBD image uploaded successfully!');
                                            const definition = "new obdpicture";
                                            postdbupdatelog(model, definition, model.id);
                                          })
                                          .catch((error) => {
                                            console.error('Error uploading OBD image:', error);
                                          });
                                      }
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="dbcolumn">
                      <div className="databasemodal-label">
                        ECU Location:
                        <span className="databasemodal-orivalue">
                          {model.ecu_location}
                          <br />
                          <div className="db3image-container">

                              <img
                                src={model.ecuimage || defaultModelImage}
                                alt="ECU Picture"
                                className="littledetail-image"
                                onClick={() => openImage(model.ecuimage || defaultModelImage)}
                              />

                              {(isAdmin || isTuner || isSupport) && (
                                <div>
                                  <button
                                    className="db3column-button"
                                    onClick={() => {
                                      document.getElementById(`ecu-upload-${model.id}`).click();
                                    }}
                                  >
                                    Upload
                                  </button>
                                  <input
                                    type="file"
                                    id={`ecu-upload-${model.id}`}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                      if (e.target.files.length > 0) {
                                        const file = e.target.files[0];
                                        const formData = new FormData();
                                        formData.append('ecuImage', file);
                                        formData.append('modelId', model.id);

                                        fetch(`${process.env.REACT_APP_SITE_URL}/uploadECUImage`, {
                                          method: 'POST',
                                          body: formData,
                                        })
                                          .then((response) => response.json())
                                          .then((data) => {
                                            const updatedModels = vehicleModels.map((m) => {
                                              if (m.id === model.id) {
                                                return { ...m, ecuimage: data.imageUrl };
                                              }
                                              return m;
                                            });
                                            setVehicleModels(updatedModels);
                                            alert('ECU image uploaded successfully!');
                                            const definition = "new ecupicture";
                                            postdbupdatelog(model, definition, model.id);
                                          })
                                          .catch((error) => {
                                            console.error('Error uploading ECU image:', error);
                                          });
                                      }
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        </span>
                      </div>
                    </div>                    <div className="dbcolumn">
                      <div className="databasemodal-label">
                        ACM Location:
                        <span className="databasemodal-orivalue">
                          {model.acm_location}
                          <br />
                          <div className="db3image-container">

                              <img
                                src={model.acmimage || defaultModelImage}
                                alt="ACM Picture"
                                className="littledetail-image"
                                onClick={() => openImage(model.acmimage || defaultModelImage)}
                              />

                              {(isAdmin || isTuner || isSupport) && (
                                <div>
                                  <button
                                    className="db3column-button"
                                    onClick={() => {
                                      document.getElementById(`acm-upload-${model.id}`).click();
                                    }}
                                  >
                                    Upload
                                  </button>
                                  <input
                                    type="file"
                                    id={`acm-upload-${model.id}`}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                      if (e.target.files.length > 0) {
                                        const file = e.target.files[0];
                                        const formData = new FormData();
                                        formData.append('acmImage', file);
                                        formData.append('modelId', model.id);

                                        fetch(`${process.env.REACT_APP_SITE_URL}/uploadACMImage`, {
                                          method: 'POST',
                                          body: formData,
                                        })
                                          .then((response) => response.json())
                                          .then((data) => {
                                            const updatedModels = vehicleModels.map((m) => {
                                              if (m.id === model.id) {
                                                return { ...m, acmimage: data.imageUrl };
                                              }
                                              return m;
                                            });
                                            setVehicleModels(updatedModels);
                                            alert('ACM image uploaded successfully!');
                                            const definition = "new acmpicture";
                                            postdbupdatelog(model, definition, model.id);
                                          })
                                          .catch((error) => {
                                            console.error('Error uploading ACM image:', error);
                                          });
                                      }
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        </span>
                      </div>
                    </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="dbclassic">
            <DatabaseClassic country={country} />
          </div>
          )}
        </div>
        {viewImage && (
          <div className="image-modal" onClick={() => setViewImage(null)}>
            <img src={viewImage} alt="Full Size" />
          </div>
        )}

{showLogModal && (
  <div className="dblog-modal-overlay" onClick={() => setShowLogModal(false)}>
    <div className="dblog-modal-content" onClick={(e) => e.stopPropagation()}>
      <h3>Model Log</h3>
      {logEntries.length > 0 ? (
        <ul>
          {logEntries.map((entry, index) => (
            <li key={index}>
              <strong>{entry.user}</strong> ({entry.company}) updated on {new Date(entry.created_at).toLocaleString()}: {entry.definition}
             
            </li>
          ))}
        </ul>
      ) : (
        <p>No log entries available for this model.</p>
      )}
      <button onClick={() => setShowLogModal(false)}>Close</button>
    </div>
  </div>
)}



        {showEditForm && (
          <div className="edit-modal" ref={modalRef}>
            <h3>Edit Model Info</h3>
            {isAdmin && (
            <label>
              Model Name:
              <input
                type="text"
                name="model_name"
                value={editData.model_name || ''}
                onChange={handleEditChange}
              />
              
            </label>
            
            
          )}<br />

          {isAdmin && (
                      <label>
                Original HP:
                <input
                  type="text"
                  name="power_original"
                  value={editData.power_original}
                  onChange={handleEditChange}
                />
              </label>)}
              <br />
              {isAdmin && (
              <label>
                Original NM:
                <input
                  type="text"
                  name="torque_original"
                  value={editData.torque_original}
                  onChange={handleEditChange}
                />
              </label>
              )}
              <br />
            {selectedFuelType !== 3 && (
                        <>


                <label>
                  Opti HP:
                  <input type="text" name="power_opti" value={editData.power_opti} onChange={handleEditChange} />
                </label><br />
                <label>
                  Opti NM:
                  <input type="text" name="torque_opti" value={editData.torque_opti} onChange={handleEditChange} />
                </label><br />
              </>
            )}
            <label>
              Power HP:
              <input type="text" name="power" value={editData.power} onChange={handleEditChange} />
            </label><br />
            <label>
              Power NM:
              <input type="text" name="torque" value={editData.torque} onChange={handleEditChange} />
            </label><br />
            <label>
              OBD Location:
              <input type="text" name="obd_location" value={editData.obd_location} onChange={handleEditChange} />
            </label><br />
            <label>
              ECU Location:
              <input type="text" name="ecu_location" value={editData.ecu_location} onChange={handleEditChange} />
            </label><br />
            <label>
              ACM Location:
              <input type="text" name="acm_location" value={editData.acm_location} onChange={handleEditChange} />
            </label><br />
            <label>
              Notes:
              <textarea name="notes" value={editData.notes} onChange={handleEditChange} />
            </label><br />
            Alle endringer blir loggført<br></br>
            <button onClick={saveEdit}>Save</button>
            <button onClick={cancelEdit}>Cancel</button>
          </div>
        )}


        
      </div>
    </div>


  );
}

export default Database;
