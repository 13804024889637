import { useEffect } from 'react';

const TaskConfirmationPopup = ({ isOpen, onClose, onConfirm, onCancel }) => {
  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (event) => {
      if (event.code === 'Escape') { // Lytt etter 'Escape' knappen
        event.preventDefault();
        onCancel(); // Utfør decline handlingen
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onCancel]);

  if (!isOpen) return null;

  return (
    <div className="modal-background">
      <div className="edit-modal-content">
        <h2 style={{ color: 'white' }}>Confirm Delete</h2>
        <p style={{ color: 'white' }}>
          Are you sure you want to delete this task? This action cannot be undone.
        </p>
        <div className="modal-actions">
          <button onClick={onConfirm} className="confirm-button">
            Yes, Delete
          </button>
          <button onClick={onCancel} className="decline-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskConfirmationPopup;
