import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const StatChart = () => {
    const [allChartData, setAllChartData] = useState(null);
    const [chartDataPerMember, setChartDataPerMember] = useState({});
    const [members, setMembers] = useState([]);
    const [memberNames, setMemberNames] = useState({});

    useEffect(() => {
        fetch(process.env.REACT_APP_SITE_URL + '/uploads/charged-data')
            .then(response => response.json())
            .then(data => {
                const processedData = processAllChartData(data);
                setAllChartData(processedData);
            })
            .catch(error => console.error('Error fetching chart data:', error));

        fetch(process.env.REACT_APP_SITE_URL + '/uploads/charged-data-per-member')
            .then(response => response.json())
            .then(data => {
                const { perMemberData, memberIds } = processChartDataPerMember(data);
                setChartDataPerMember(perMemberData);
                setMembers(memberIds);
            })
            .catch(error => console.error('Error fetching per-member chart data:', error));

        fetch(process.env.REACT_APP_SITE_URL + '/members')
            .then(response => response.json())
            .then(data => {
                const memberNameMap = data.reduce((acc, member) => {
                    acc[member.id] = member.name;
                    return acc;
                }, {});
                setMemberNames(memberNameMap);
            })
            .catch(error => console.error('Error fetching member names:', error));
    }, []);

    const processAllChartData = (data) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const groupedData = data.reduce((acc, { year, month, count }) => {
            if (!acc[year]) {
                acc[year] = Array(12).fill(null);
            }
            acc[year][month - 1] = count;
            return acc;
        }, {});

        const colors = {
            2024: 'rgba(255, 99, 132, 1)',
            2023: 'rgba(54, 162, 235, 1)',
            2022: 'rgba(75, 192, 192, 1)',
            2021: 'rgba(153, 102, 255, 1)',
            2020: 'rgba(255, 159, 64, 1)'
        };

        const datasets = Object.keys(groupedData).map(year => ({
            label: year,
            data: groupedData[year],
            borderColor: colors[year] || getRandomColor(),
            backgroundColor: (colors[year] || getRandomColor()).replace('1)', '0.5)'),
            spanGaps: true,
            hidden: year !== "2025" // Skjuler alle andre år enn 2025 som standard
        }));

        datasets.sort((a, b) => b.label - a.label);

        const referenceLine = {
            label: 'Reference Line',
            data: [422, 438, 390, 530, 519, 514, 577, 560, 555, 484, 454, 385], // Eksempelverdier
            borderColor: 'rgba(200, 200, 200, 1)',
            backgroundColor: 'rgba(200, 200, 200, 0.5)',
            borderWidth: 2,
            borderDash: [5, 5], // Gjør linjen stiplet
            fill: false,
            pointRadius: 0, // Fjerner punktene på linjen
        };
    
        datasets.push(referenceLine);


        return {
            labels: months,
            datasets
        };
    };

    const processChartDataPerMember = (data) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const perMemberDataGrouped = {};
        const memberIds = new Set();

        data.forEach(({ mid, year, month, count }) => {
            if (!perMemberDataGrouped[mid]) {
                perMemberDataGrouped[mid] = {};
            }
            if (!perMemberDataGrouped[mid][year]) {
                perMemberDataGrouped[mid][year] = Array(12).fill(null);
            }
            perMemberDataGrouped[mid][year][month - 1] = count;

            memberIds.add(mid);
        });

        const colors = {
            2024: 'rgba(255, 99, 132, 1)',
            2023: 'rgba(54, 162, 235, 1)',
            2022: 'rgba(75, 192, 192, 1)',
            2021: 'rgba(153, 102, 255, 1)',
            2020: 'rgba(255, 159, 64, 1)'
        };

        const processDatasets = (groupedData) => {
            return Object.keys(groupedData).map(year => ({
                label: year,
                data: groupedData[year],
                borderColor: colors[year] || getRandomColor(),
                backgroundColor: (colors[year] || getRandomColor()).replace('1)', '0.5)'),
                spanGaps: true
            })).sort((a, b) => b.label - a.label);
        };

        const perMemberData = {};
        Object.keys(perMemberDataGrouped).forEach(mid => {
            perMemberData[mid] = {
                labels: months,
                datasets: processDatasets(perMemberDataGrouped[mid])
            };
        });

        return {
            perMemberData,
            memberIds: Array.from(memberIds)
        };
    };

    const getRandomColor = (opacity = 1) => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };

    const getOptions = (title) => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        return tooltipItems[0].label;
                    },
                    label: (tooltipItem) => {
                        const monthIndex = tooltipItem.dataIndex;
                        const chart = tooltipItem.chart;
                        const datasets = chart.data.datasets;

                        const uniqueTooltipData = new Map();

                        datasets.forEach(dataset => {
                            const year = dataset.label;
                            const value = dataset.data[monthIndex];
                            if (!uniqueTooltipData.has(year)) {
                                uniqueTooltipData.set(year, {
                                    year: year,
                                    value: value !== null ? value : 'null',
                                    color: dataset.borderColor
                                });
                            }
                        });

                        return Array.from(uniqueTooltipData.values())
                            .sort((a, b) => b.year - a.year)
                            .map(data => `<span style="color:${data.color}">${data.year}</span>: ${data.value}`);
                    },
                    labelColor: (tooltipItem) => {
                        return {
                            borderColor: tooltipItem.dataset.borderColor,
                            backgroundColor: tooltipItem.dataset.borderColor
                        };
                    }
                },
                usePointStyle: false,
                enabled: true,
                mode: 'index',
                intersect: false,
                itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
                callbacks: {
                    labelTextColor: function(tooltipItem) {
                        return tooltipItem.dataset.borderColor;
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    stepSize: 100
                }
            }
        }
    });

    const chartContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        height: '400px',  // Increased height
        margin: '0 auto',
        marginTop: '40px',  // Adding space between charts
        backgroundColor: '#282828',  // Change the background color to match the chart
        padding: '10px',  // Adding padding to the chart
        borderRadius: '8px'  // Adding border radius for better visual appearance
    };

    return (
        <div>
            <div style={chartContainerStyle}>
                {allChartData ? <Line data={allChartData} options={getOptions('All Members')} /> : <p>Loading chart data...</p>}
            </div>
            {members.map(mid => (
                <div key={mid} style={chartContainerStyle}>
                    {chartDataPerMember[mid] ? <Line data={chartDataPerMember[mid]} options={getOptions(memberNames[mid] || `Member ID: ${mid}`)} /> : <p>Loading chart data...</p>}
                </div>
            ))}
        </div>
    );
};

export default StatChart;
