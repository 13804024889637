import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Registrer nødvendige komponenter for Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CustomersChart = () => {
    const [hourlyData, setHourlyData] = useState(null);

    useEffect(() => {
        // Hent data for filer per time
        const fetchHourlyData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_SITE_URL + '/tasks/hourly', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Legger til token for autentisering
                    },
                });
                const data = await response.json();

                if (response.ok) {
                    // Formater data for Chart.js
                    const chartData = {
                        labels: data.map((item) => `${item.hour}`), // X-akse: Klokkeslett
                        datasets: [
                            {
                                data: data.map((item) => item.count), // Y-akse: Antall filer
                                backgroundColor: 'rgba(75, 192, 192, 0.5)', // Farge på søylene
                                borderColor: 'rgba(75, 192, 192, 1)',
                                borderWidth: 0,
                                barThickness: 10, // Smalere stolper
                            },
                        ],
                    };
                    setHourlyData(chartData);
                } else {
                    console.error('Failed to fetch hourly data:', data.message);
                }
            } catch (error) {
                console.error('Error fetching hourly data:', error);
            }
        };

        fetchHourlyData();
    }, []);

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false, // Skjul legend for å fjerne "undefined"
            },
            title: {
                display: false, // Ingen tittel for grafen
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Fjern rutenett for X-aksen
                },
                title: {
                    display: false, // Skjul X-akse tittel
                },

            },
            y: {
                grid: {
                    display: false, // Fjern rutenett for Y-aksen
                },
                title: {
                    display: false, // Skjul Y-akse tittel
                },
                beginAtZero: true,
                ticks: {
                    stepSize: 1, // Kun hele tall
                    callback: (value) => Number.isInteger(value) ? value : null, // Vis kun hele tall
                },
            },
        },
    };

    const chartContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '93%',
        height: '80px',
        margin: '0 auto',
        marginTop: '0px',
        backgroundColor: '#282828',
        padding: '10px',
        borderRadius: '2px',
    };

    return (
        <div>
            <div style={chartContainerStyle}>
                {hourlyData ? (
                    <Bar data={hourlyData} options={chartOptions} />
                ) : (
                    <p>Loading chart data...</p>
                )}
            </div>
        </div>
    );
};

export default CustomersChart;
