import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AutoresponderStats = () => {
    const [tunerStats, setTunerStats] = useState([]);
    const [tunerUploads, setTunerUploads] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const statsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/tuner-stats`);
                const statsData = await statsResponse.json();
                const uploadsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/tuner-135-uploads`);
                const uploadsData = await uploadsResponse.json();
    
                // Filtrer ut data før November 2024
                const filteredStats = statsData.filter(({ year, month }) => year > 2024 || (year === 2024 && month >= 11));
                const filteredUploads = uploadsData.filter(({ created }) => new Date(created) >= new Date('2024-11-01'));
    
                setTunerStats(filteredStats);
                setTunerUploads(filteredUploads);
                processChartData(filteredStats);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, []);
    
    const calculatePercentages = () => {
        const total = tunerUploads.length;
        const egrCount = tunerUploads.filter(upload => upload.egr === 1).length;
        const dpfCount = tunerUploads.filter(upload => upload.dpf === 1).length;
        const adblueCount = tunerUploads.filter(upload => upload.adblue === 1).length;
        const nslCount = tunerUploads.filter(upload => upload.nsl === 1).length;
        const o2Count = tunerUploads.filter(upload => upload.o2 === 1).length;

        return {
            egr: ((egrCount / total) * 100).toFixed(2),
            dpf: ((dpfCount / total) * 100).toFixed(2),
            adblue: ((adblueCount / total) * 100).toFixed(2),
            nsl: ((nslCount / total) * 100).toFixed(2),
            o2: ((o2Count / total) * 100).toFixed(2),
        };
    };

    const percentages = calculatePercentages();


    // Process data for chart
    const processChartData = (statsData) => {
        // Filtrer ut data før November 2024
        const filteredData = statsData.filter(({ year, month }) => {
            return year > 2024 || (year === 2024 && month >= 12);
        });
    
        const labels = filteredData.map(({ year, month }) => `${year}-${month.toString().padStart(2, '0')}`);
        const totalCounts = filteredData.map(({ total_count }) => total_count);
        const tunerCounts = filteredData.map(({ tuner_135_count }) => tuner_135_count);
    
        const percentageData = filteredData.map(({ total_count, tuner_135_count }) => (
            ((tuner_135_count / total_count) * 100).toFixed(2)
        ));
    
        setChartData({
            labels,
            datasets: [
                {
                    label: 'Total Files',
                    data: totalCounts,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Files by Tuner 135',
                    data: tunerCounts,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Percentage by Tuner 135 (%)',
                    data: percentageData,
                    backgroundColor: 'rgba(255, 206, 86, 0.6)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    type: 'line', // Line chart for percentage
                    borderWidth: 2,
                },
            ],
        });
    };
    

    return (
        <div>
            <div style={{ width: '80%', margin: '0 auto', marginBottom: '20px' }}>
                {isLoading ? (
                    <p>Loading chart data...</p>
                ) : (
                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                },
                                title: {
                                    display: true,
                                    text: 'Stats for Tuner 135',
                                },
                            },
                            scales: {
                                y: {
                                    beginAtZero: true,
                                },
                            },
                        }}
                    />
                )}
            </div>

            <div>
            <div style={{ marginTop: '20px' }}>
                        <p>EGR: {percentages.egr}%</p>
                        <p>DPF: {percentages.dpf}%</p>
                        <p>AdBlue: {percentages.adblue}%</p>
                        <p>NSL: {percentages.nsl}%</p>
                        <p>O2: {percentages.o2}%</p>
                    </div>
            {isLoading ? (
                <p>Loading vehicle data...</p>
            ) : tunerUploads.length > 0 ? (
                <>
                    <table style={{ width: '100%', borderCollapse: 'collapse', margin: '20px 0' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#333', color: '#fff', textAlign: 'left' }}>
                                <th style={{ padding: '8px' }}>ID</th>
                                <th style={{ padding: '8px' }}>Type</th>
                                <th style={{ padding: '8px' }}>Tuning Type</th>
                                <th style={{ padding: '8px' }}>Created</th>
                                <th style={{ padding: '8px' }}>Charged</th>
                                <th style={{ padding: '8px' }}>EGR</th>
                                <th style={{ padding: '8px' }}>DPF</th>
                                <th style={{ padding: '8px' }}>AdBlue</th>
                                <th style={{ padding: '8px' }}>NSL</th>
                                <th style={{ padding: '8px' }}>O2</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tunerUploads.map((upload, index) => (
                                <tr key={index} style={{ borderBottom: '1px solid #ddd', textAlign: 'left' }}>
                                    <td style={{ padding: '8px' }}>{upload.id}</td>
                                    <td style={{ padding: '8px' }}>{upload.vehicle_name || 'Unknown'}</td>
                                    <td style={{ padding: '8px' }}>{upload.tuning_type_value || 'N/A'}</td>

                                    <td style={{ padding: '8px' }}>{new Date(upload.created).toLocaleDateString()}</td>
                                    <td style={{ padding: '8px' }}>{upload.charged}</td>
                                    <td style={{ padding: '8px', textAlign: 'center' }}>
                                        {upload.egr === 1 ? '✅' : '❌'}
                                    </td>
                                    <td style={{ padding: '8px', textAlign: 'center' }}>
                                        {upload.dpf === 1 ? '✅' : '❌'}
                                    </td>
                                    <td style={{ padding: '8px', textAlign: 'center' }}>
                                        {upload.adblue === 1 ? '✅' : '❌'}
                                    </td>
                                    <td style={{ padding: '8px', textAlign: 'center' }}>
                                        {upload.nsl === 1 ? '✅' : '❌'}
                                    </td>
                                    <td style={{ padding: '8px', textAlign: 'center' }}>
                                        {upload.o2 === 1 ? '✅' : '❌'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </>
            ) : (
                <p>No data available for tuner 135.</p>
            )}
        </div>


        </div>
    );
};

export default AutoresponderStats;
