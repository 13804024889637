import React, { useEffect, useRef, useState } from 'react';

export default function Dropbox() {
    const embedContainerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const embedRef = useRef(null); // Track embed instance

    useEffect(() => {
        const loadDropboxScript = () => {
            return new Promise((resolve, reject) => {
                if (document.getElementById('dropboxjs')) {
                    resolve();
                } else {
                    const script = document.createElement('script');
                    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
                    script.id = 'dropboxjs';
                    script.setAttribute('data-app-key', 'oarwzxmdki584d4'); // Replace with your app key
                    script.onload = resolve;
                    script.onerror = () => reject(new Error('Failed to load Dropbox script'));
                    document.body.appendChild(script);
                }
            });
        };

        let debounceTimeout;

const initializeEmbed = () => {
    clearTimeout(debounceTimeout); // Clear any previous initialization attempts
    debounceTimeout = setTimeout(() => {
        const container = embedContainerRef.current;
        if (container && window.Dropbox && typeof window.Dropbox.embed === 'function') {
            if (embedRef.current) {
                console.warn('Embed already initialized');
                return;
            }

            const options = {
                link: 'https://www.dropbox.com/sh/h43ouuhd26z8yge/AAAowUKCsNf5DQRLAgYZYNPQa?dl=0',
                folder: {
                    view: 'list',
                    headerSize: 'normal',
                },
                width: '100%',
                height: '100%',
            };

            embedRef.current = window.Dropbox.embed(options, container);
            console.log('Dropbox embed initialized', embedRef.current);
            setIsLoading(false);
        } else {
            console.error('Dropbox.embed is not available');
            setIsLoading(false);
        }
    }, 200); // 200ms delay to handle fast switching
};

        loadDropboxScript()
            .then(() => {
                console.log('Dropbox script loaded');
                initializeEmbed();
            })
            .catch((error) => {
                console.error(error.message);
                setIsLoading(false);
            });

        return () => {
            // Cleanup on unmount
            if (embedRef.current) {
                try {
                    window.Dropbox.unmount(embedRef.current);
                } catch (err) {
                    console.warn('Error during Dropbox unmount:', err);
                }
                embedRef.current = null; // Clear the embed reference
            }

            if (embedContainerRef.current) {
                embedContainerRef.current.innerHTML = ''; // Clear the container
            }
        };
    }, []);

    return (
        <div>
            {isLoading && <p>Loading Dropbox...</p>}
            <div ref={embedContainerRef} id="dropbox-container"></div>
        </div>
    );
}
