import React, { useState, useEffect, useRef } from 'react';
import JSZip, { file } from 'jszip';
import filecomupload from '../assets/filecomupload.png';
import filecomcredit from '../assets/filecomcredit.png';
import SvvDetails from './SvvDetails';  // Importer den nye komponenten
import FileDetailsInfo from './FileDetailsInfo';  // Importer den nye komponenten
import FileDetailsMatch from './FileDetailsMatch';  // Importer den nye komponenten
import FileDetailsID from './FileDetailsID';  // Importer den nye komponenten
import MasterUploadConfirm from './MasterUploadConfirm'; // Importer den omdøpte modal-komponenten

function FileDetails({ fileId, onClose, isAdmin, isTuner, userProfile }) {
  const [fileDetails, setFileDetails] = useState(null);
  const [fileOptionsStatus, setFileOptionsStatus] = useState(null); // Endret navn
  const [dialogs, setDialogs] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isInternal, setIsInternal] = useState(false);
  const [showForm, setShowForm] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isUploadModPopupVisible, setIsUploadModPopupVisible] = useState(false);
  const [isLogPopupVisible, setIsLogPopupVisible] = useState(false);
  const [IsViewOptionAndCreditsVisible, setIsViewOptionAndCreditsVisible] = useState(false);
  const [IsSpecialVisible, setIsSpecialVisible] = useState(false);
  const [modFile, setModFile] = useState(null);
  const [masterFile, setMasterFile] = useState(null);
  const [gearboxFile, setGearboxFile] = useState(null);
  const [gearboxMasterFile, setGearboxMasterFile] = useState(null);
  const [textInput1, setTextInput1] = useState('');
  const [textInput2, setTextInput2] = useState('');
  const [selectedFilecomId, setSelectedFilecomId] = useState(null); // New state for selected filecom id
  const [vehicleData, setVehicleData] = useState(null);
  const [dealerCountry, setDealerCountry] = useState('');
  const [dealerCredit, setDealerCredit] = useState('');
  const [companyLevel, setCompanyLevel] = useState({ level: 0, baseCredits: 0 });
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modfileMatchesByDialog, setModfileMatchesByDialog] = useState({});
  const [showMatches, setShowMatches] = useState(false);
  const [manualCredits, setManualCredits] = useState('');
  const [logs, setLogs] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({}); // State to manage selected options
  const [estimatedCredits, setEstimatedCredits] = useState(0);
  const initialOptions = useRef({}); // Bruk useRef for å lagre initiale alternativer
  const [showCreditBackModal, setShowCreditBackModal] = useState(false);
  // const [currentFileComId, setCurrentFileComId] = useState(null);
  const [showCreditChangeModal, setShowCreditChangeModal] = useState(false);
  const [currentCredits, setCurrentCredits] = useState(0);
  const [fileOptions, setFileOptions] = useState(null);
  const [doneLogs, setDoneLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false); // Ny state for kopieringsnotifikasjon
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [mainFile, setMainFile] = useState(null);
  const [callbackFunction, setCallbackFunction] = useState(null);
  const [recurringCredits, setRecurringCredits] = useState([]);
  
  const handlePasteEvent = (e) => {
    const clipboardItems = e.clipboardData.items;
  
    for (const item of clipboardItems) {
      if (item.kind === 'file') {
        const file = item.getAsFile();
  
        if (file && file.type.startsWith('image/')) {
          // Legg til filen i selectedFiles for visning
          setSelectedFiles((prevFiles) => [...prevFiles, file]);
  
          // Oppdater fileInputRef for opplasting
          if (fileInputRef.current) {
            const dataTransfer = new DataTransfer();
  
            // Legg til eksisterende filer
            for (const existingFile of fileInputRef.current.files) {
              dataTransfer.items.add(existingFile);
            }
  
            // Legg til den nye filen
            dataTransfer.items.add(file);
            fileInputRef.current.files = dataTransfer.files;
          }
  
          // alert(`Image "${file.name}" added to upload list.`);
        }
      }
    }
  };
  
  
  useEffect(() => {
    const textarea = textareaRef.current;
  
    if (textarea) {
      textarea.addEventListener('paste', handlePasteEvent);
    }
  
    return () => {
      if (textarea) {
        textarea.removeEventListener('paste', handlePasteEvent);
      }
    };
  }, []);
  
  

  const handleCopyId = () => {
    const textToCopy = `${fileDetails.id} - ${fileDetails.vehicleDescription}`;

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  const fetchFileOptionsStatus = async (fileId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/${fileId}/options`);
      if (!response.ok) throw new Error('Failed to fetch file options status');
      const data = await response.json();
      setFileOptionsStatus(data);
    } catch (error) {
      console.error('Error fetching file options status:', error);
    }
  };

  useEffect(() => {
    fetchFileOptionsStatus(fileId);
  }, [fileId]);

  const getTuningTypeLabel = (tuningType) => {
    const tuningValue = String(tuningType);
    switch (tuningValue) {
      case '1': return { label: 'ECO', className: 'eco' };
      case '3': return { label: 'OPTI', className: 'opti' };
      case '4': return { label: 'POWER', className: 'power' };
      case '5': return { label: 'AS STOCK', className: 'as-stock' };
      default: return { label: '', className: '' };
    }
  };


  const isWithin60Days = (createdDate) => {
    const created = new Date(createdDate);
    const today = new Date();
    const timeDifference = today - created;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference <= 60;
  };

  // const handleOptionsAndCreditsSubmit = async (e) => {
  //   e.preventDefault();

  //   const creditsToAdd = parseInt(manualCredits, 10);

  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-file-options-and-credits`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${userProfile.token}`,
  //       },
  //       body: JSON.stringify({
  //         fileId: fileDetails.id,
  //         options: selectedOptions, // Send oppdaterte alternativer
  //         extraCredits: creditsToAdd, // Send ekstra kreditter
  //         mid: fileDetails.mid, // Legg til mid (Dealer's member ID)
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to update options and credits');
  //     }

  //     const data = await response.json();
  //     alert('Options and credits updated successfully!');

  //     setFileDetails(prevDetails => ({
  //       ...prevDetails,
  //       charged: prevDetails.charged + creditsToAdd,
  //     }));
  //     setIsViewOptionAndCreditsVisible(false);
  //   } catch (error) {
  //     console.error('Error updating options and credits:', error);
  //     alert('Failed to update options and credits. Please try again.');
  //   }
  // };

  const handleOptionsAndCreditsSubmit = async (e) => {
    e.preventDefault();
  
    const creditsToAdd = parseInt(manualCredits, 10);
  
    try {
      const dealercheckValue = fileDetails.dealercheck !== undefined ? fileDetails.dealercheck : 0; // Standardverdi
      const tunercheckValue = fileDetails.tunercheck !== undefined ? fileDetails.tunercheck : 0; // Standardverdi
  
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-file-options-and-credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userProfile.token}`,
        },
        body: JSON.stringify({
          fileId: fileDetails.id,
          options: selectedOptions, // Send oppdaterte alternativer
          extraCredits: creditsToAdd, // Send ekstra kreditter
          mid: fileDetails.mid, // Legg til mid (Dealer's member ID)
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update options and credits');
      }
  
      const data = await response.json();
      // alert('Options and credits updated successfully!');
  
      // Oppdater fileDetails lokalt
      setFileDetails(prevDetails => ({
        ...prevDetails,
        charged: prevDetails.charged + creditsToAdd,
      }));
  
      // Post melding i dialogen
      const postMessageResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/postMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userProfile.token}`,
        },
        body: JSON.stringify({
          fileId: fileId,
          user: userProfile.id,
          message: creditsToAdd > 0
            ? `Withdrew ${creditsToAdd} additional credits from the ID.`
            : `Gave back ${Math.abs(creditsToAdd)} credits to the ID.`,
          internal: 0, // Marker meldingen som intern
          messageStatus: 2, // Sett status som "Done"
          dealercheck: dealercheckValue, // Standardverdi brukes hvis undefined
          tunercheck: tunercheckValue,  // Standardverdi brukes hvis undefined
        }),
      });
  
      if (!postMessageResponse.ok) {
        throw new Error('Failed to post message in dialog');
      }
  
      await updateDialogs();
      // alert('Message posted in dialog successfully!');
      setIsViewOptionAndCreditsVisible(false);
  
    } catch (error) {
      console.error('Error updating options and credits:', error);
      alert('Failed to update options and credits. Please try again.');
    }
  };
  
  

  const handleUploadModClick = (dialog) => {

      if (!dialog.fileOptions) {
    console.error("File options are undefined for the selected dialog.");
    alert("File options are not available. Please refresh or try again.");
    return;
  }
    const options = {
      tuning_type: dialog.fileOptions.tuning_type || '', // Sette tuning_type som ren verdi
      egr: dialog.fileOptions.egr === 1,
      dpf: dialog.fileOptions.dpf === 1,
      adblue: dialog.fileOptions.adblue === 1,
      nsl: dialog.fileOptions.nsl === 1,
      o2: dialog.fileOptions.o2 === 1,
      epa: dialog.fileOptions.epa === 1,
      hybridturbo: dialog.fileOptions.hybridturbo === 1,
      gearbox: dialog.fileOptions.gearbox === 1,
      coldstart: dialog.fileOptions.coldstart === 1,
      startstop: dialog.fileOptions.startstop === 1,
      popsbangs: dialog.fileOptions.popsbangs === 1,
      burbles: dialog.fileOptions.burbles === 1,
      swirlflaps: dialog.fileOptions.swirlflaps === 1,
      tva: dialog.fileOptions.tva === 1,
      analysis: dialog.fileOptions.analysis === 1,
      priority: dialog.fileOptions.priority_value || false,
    };
    setSelectedOptions(options);
    initialOptions.current = options;
    setIsUploadModPopupVisible(true);
    setSelectedFilecomId(dialog.id);
  };

  const handleOptionChange = (optionName, value) => {
    const wasPreviouslySelected = selectedOptions[optionName];

    if (!value && wasPreviouslySelected) {
      window.alert(`Advarsel: Ved å fjerne ${optionName}, vil kredittbeløpet bli redusert. Dersom dette ikke er riktig, må du legge inn credits override`);
    }

    setSelectedOptions((prevOptions) => {
      const newOptions = {
        ...prevOptions,
        [optionName]: value,
      };
      let credits = calculateEstimatedCredits(newOptions);

      if (!value && wasPreviouslySelected) {
        credits -= calculateOptionCredits(optionName);
      }

      setEstimatedCredits(credits);
      return newOptions;
    });
  };


const calculateOptionCredits = (optionName) => {
  const optionCosts = {
      egr: isCustomType ? 15 : 3,
      dpf: isCustomType ? 15 : 3,
      adblue: isCustomType ? 20 : 5,
      nsl: isCustomType ? 10 : 2,
      o2: 2,
      epa: 5,
      hybridturbo: 7,
      gearbox: 5,
      coldstart: 2,
      startstop: 2,
      popsbangs: 5,
      burbles: 5,
      swirlflaps: 2,
      tva: 2,
      analysis: 0,
  };
  return optionCosts[optionName] || 0;
};

const ViewOptionAndCredits = async () => {
  setIsViewOptionAndCreditsVisible(true); // Open the logs popup
};

const SpecialAction = async () => {
  setIsSpecialVisible(true);
};


const ViewLogs = async (fileId) => {
  try {
    setIsLogPopupVisible(true);
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/fetch-logs/${fileId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userProfile.token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch logs');
    }
    const data = await response.json();
    setLogs(data.logs);  // Assuming the server returns an array of logs in 'logs'
  } catch (error) {
    console.error('Error fetching logs:', error);
    setLogs([]); // If an error occurs, set logs to an empty array
  }
};

  const CloseViewLogs = () => {
    setIsLogPopupVisible(false);
  };

  const CloseViewOptionAndCredits = () => {
    setIsViewOptionAndCreditsVisible(false);
  };

  const CloseSpecialAction = () => {
    setIsSpecialVisible(false);
  };


  const closeUploadModPopup = () => {
    setIsUploadModPopupVisible(false);
  };
  const handleToggleMatches = () => {
    setShowMatches(!showMatches);
  };

  const handleBoxClick = (id) => {
    setSelectedFileId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedFileId(null);
  };

  const fetchCompanyLevel = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/calculate-credits/${fileDetails.mid}`);
      const data = await response.json();
      setCompanyLevel(data);
    } catch (error) {
      console.error('Error fetching company level:', error);
    }
  };

  useEffect(() => {
    if (fileDetails && fileDetails.mid) {
      fetchCompanyLevel();
    }
  }, [fileDetails]);

  const deductCredits = async (creditsToDeduct) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/deduct-credits`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userProfile.token}`,
            },
            body: JSON.stringify({
                id: fileDetails.mid,
                credits: creditsToDeduct,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to deduct credits');
        }
        const data = await response.json();
        setDealerCredit(data.newCreditBalance);
    } catch (error) {
        console.error('Error deducting credits:', error);
        alert('Failed to deduct credits. Please try again.');
    }
};

const logTheDownload = async (action) => {
  try {
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 19).replace('T', ' '); // Format: 2024-08-23 17:57:46
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/log-the-download`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userProfile.token}`,
      },
      body: JSON.stringify({
        fileId: fileDetails.id,
        user: userProfile.name,
        action: action,
        date: formattedDate,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to log download action');
    }
  } catch (error) {
    console.error('Error logging download action:', error);
    alert('Failed to log download action. Please try again.');
  }
};


const fetchVehicleData = async (regNum) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getReg/${regNum}`);
    if (response.status === 404) {
      setVehicleData(null);
      return;
    }
    if (!response.ok) {
      throw new Error('Failed to fetch vehicle data');
    }
    const data = await response.json();
    setVehicleData(data);
  } catch (error) {
    setVehicleData(null);
  }
};

  useEffect(() => {
    if (fileDetails && fileDetails.regnum) {
      fetchVehicleData(fileDetails.regnum);
    }
  }, [fileDetails]);

  const fetchDealerDetails = async (dealerName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/dealer-details/${dealerName}`);
      if (!response.ok) {
        throw new Error('Failed to fetch dealer details');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching dealer details:', error);
      return null;
    }
  };
  useEffect(() => {
    if (fileDetails && fileDetails.dealerName) {
      fetchDealerDetails(fileDetails.dealerName).then(data => {
        if (data) {
          setDealerCountry(data.country);
          setDealerCredit(data.credit);
        }
      });
    }
  }, [fileDetails]);


  const updateFileDetails = async (fileId, updates) => {
    // console.log("Updating file details for:", fileId);
    // console.log("Updates to apply:", updates);

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/updateFileChecks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userProfile.token}`, // Fjern hvis ikke nødvendig
            },
            body: JSON.stringify({
                fileId,
                ...updates,
            }),
        });

        if (!response.ok) {
            throw new Error("Failed to update file details on the server");
        }

        const result = await response.json();
        // console.log("Server update successful:", result);
        return result;
    } catch (error) {
        console.error("Error updating file details on the server:", error);
        throw error; // La kallet kaste feilen videre for eventuell feilhåndtering
    }
};





  const handleUploadModSubmit = async (event, fileOptions) => {
    event.preventDefault();
    // console.log("Tooltype:", fileDetails.tooltype);

    const isAutotuner = fileDetails.tooltype === 1;
    if (!isAutotuner && (!modFile || !masterFile)) {
        alert('Du må velge både Modfile og Masterfile før du kan laste opp.');
        return;
    }

    const creditsToDeduct = calculateEstimatedCredits(fileOptions);
    const modMessage = `${textInput1.trim()}`;
    const modMessageWithCredits = `${textInput1.trim()} (Credits withdrawn: ${creditsToDeduct})`;
    const formData = new FormData();

    if (modFile && modFile.length > 1) {
      if (modFile.length > 1) {
          const zip = new JSZip();
          Array.from(modFile).forEach((file, index) => {
              zip.file(file.name, file); 
          });

          const zipBlob = await zip.generateAsync({ type: "blob" });
          const zipFile = new File([zipBlob], "modFiles.zip", { type: "application/zip" });
          formData.append("modFile", zipFile); 
      }
  } else if (modFile && modFile.length === 1) {
      formData.append('modFile', modFile[0]);
  }





    if (isAutotuner && !masterFile) {
      formData.append('useModAsMaster', true);
    } else if (masterFile) {
        formData.append('masterFile', masterFile);
    }

    if (gearboxFile) formData.append('gearboxFile', gearboxFile);
    if (gearboxMasterFile) formData.append('gearboxMasterFile', gearboxMasterFile);
    formData.append('fcid', selectedFilecomId);
    formData.append('textInput1', modMessageWithCredits);
    formData.append('options', JSON.stringify(selectedOptions));
    formData.append('regNum', fileDetails.regnum);
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploadModFiles`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to upload files');
        }

        const data = await response.json();
        setDialogs(prevDialogs => prevDialogs.map(dialog => {
            if (dialog.id === selectedFilecomId) {
                return {
                    ...dialog,
                    modurl: data.modFileUrl || dialog.modurl,
                    modmasterurl: data.masterFileUrl || dialog.modmasterurl,
                    modgearurl: data.gearboxFileUrl || dialog.modgearurl,
                    modgearmasterurl: data.gearboxMasterFileUrl || dialog.modgearmasterurl,
                    modmessage: modMessageWithCredits, // Bruk oppdatert melding
                    options: selectedOptions, // Oppdater dialogen med de nye valgene
                    isInitialUpload: data.isInitialUpload
                };
            }
            return dialog;
        }));

        if (textInput2.trim()) {
          await postInternalComment(textInput2);
        }
        // await postInternalComment(textInput2);
        await deductCredits(creditsToDeduct);
        await setCreditWithdrawn(fileDetails.id, creditsToDeduct);
        await updateFileStatus(fileDetails.id, 2); // Oppdater status til 'Done'
        await logCreditWithdrawal(fileDetails.mid, creditsToDeduct, fileDetails.dealerName, userProfile.name);
        await processRecurringCredits(fileDetails.mid);
        setIsUploadModPopupVisible(false);
        setModFile(null);
        setMasterFile(null);
        setGearboxFile(null);
        setGearboxMasterFile(null);
        setTextInput1('');
        setTextInput2('');
        setFileDetails(prevDetails => ({
            ...prevDetails,
            status: 2, // Oppdater status til 'Done'
        }));

        await updateFileDetails(fileDetails.id, { tunercheck: 0 });



        sendNotification(
          fileDetails.mid,
          'Optifiles - ' + userProfile.name,
          'ID ' + fileId + ': DONE',
          { messageId: '123', sender: 'John Doe' }
        );
        sendNotification(
          fileDetails.mid,
          'Optifiles - ' + userProfile.name,
          'ID ' + fileId + ': ' + modMessage,
          { messageId: '123', sender: 'John Doe' }
        );

        if (data.isInitialUpload) {
            if (data.gearboxFileUrl) {
                // Håndter eventuelle spesielle forhold her
            }
        }

    } catch (error) {
        console.error('Error uploading files:', error);
        alert('Failed to upload files. Please try again.');
    }
};




    const setCreditWithdrawn = async (fileId, creditsWithdrawn) => {
      try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/set-credit-withdrawn`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${userProfile.token}`,
              },
              body: JSON.stringify({
                  fileId: fileId,
                  creditsWithdrawn: creditsWithdrawn,
              }),
          });
          if (!response.ok) {
              throw new Error('Failed to log credit withdrawal');
          }
      } catch (error) {
          console.error('Error logging credit withdrawal:', error);
      }
    };

    const postInternalComment = async (comment) => {
        try {
            const payload = {
                fileId: fileId,
                user: userProfile.id,
                message: comment,
                internal: 1,
                date: new Date().toISOString(),
            };
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/postInternalComment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userProfile.token}` },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                throw new Error('Failed to post internal comment');
            }

            const data = await response.json();

            setDialogs(prevDialogs => [...prevDialogs, { ...payload, id: data.commentId }]);

        } catch (error) {
            console.error('Error posting internal comment:', error);
            alert('Failed to post internal comment. Please try again.');
        }
    };

    

    const processRecurringCredits = async (memberId) => {
      try {
        // 1. Hent uploads-telling for siste 30 dager
        const uploadsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads-count/${memberId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userProfile.token}`,
          },
        });
    
        if (!uploadsResponse.ok) {
          throw new Error('Failed to fetch uploads count');
        }
    
        const { uploadCount } = await uploadsResponse.json();
        // console.log(`Uploads count for member ID ${memberId}:`, uploadCount);
    
        // 2. Bestem drawamount basert på uploads-telling
        const calculateDrawAmount = (baseDrawAmount) => {
          if (uploadCount < 5) return baseDrawAmount + 10;
          if (uploadCount < 10) return baseDrawAmount + 6;
          if (uploadCount < 20) return baseDrawAmount + 4;
          if (uploadCount < 30) return baseDrawAmount + 2;
          return baseDrawAmount;
        };
    
        // 3. Sjekk aktive kreditter for medlemmet
        const activeCreditsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/recurring-credits/active/${memberId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userProfile.token}`,
          },
        });
    
        if (!activeCreditsResponse.ok) {
          throw new Error('Failed to fetch active recurring credits');
        }
    
        const activeCredits = await activeCreditsResponse.json();
    
        if (activeCredits.length === 0) {
          // console.log(`No active recurring credits found for member ID: ${memberId}`);
          return;
        }
    
        // console.log(`Active recurring credits for member ID ${memberId}:`, activeCredits);
    
        // 4. Håndter hver aktiv kredittoppføring
        for (const credit of activeCredits) {
          const { id, owedcredits, drawamount, description } = credit;
    
          // Juster drawamount basert på regler for uploads-telling
          const adjustedDrawAmount = ['Royalties', 'Tool Subscription'].includes(description)
            ? calculateDrawAmount(drawamount)
            : drawamount;
    
          // Trekk kun det som er igjen
          const actualDrawAmount = Math.min(adjustedDrawAmount, owedcredits);
          const newOwedCredits = owedcredits - actualDrawAmount;
    
          // Oppdater owedcredits i databasen
          const updateResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/update-recurring-credits`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userProfile.token}`,
            },
            body: JSON.stringify({
              id,
              owedcredits: newOwedCredits,
            }),
          });
    
          if (!updateResponse.ok) {
            throw new Error(`Failed to update owed credits for recurring entry ID: ${id}`);
          }
    


          // Trekk credits fra medlemmet
          const deductCreditsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/deduct-credits`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userProfile.token}`,
            },
            body: JSON.stringify({
              id: memberId,
              credits: actualDrawAmount,
            }),
          });

          if (!deductCreditsResponse.ok) {
            throw new Error(`Failed to deduct credits for member ID: ${memberId}`);
          }

          const { newCreditBalance } = await deductCreditsResponse.json();
          // console.log(`Credits deducted for member ID: ${memberId}. New balance: ${newCreditBalance}`);

          const dealerName = fileDetails?.dealerName || "Unknown Dealer";

          // Post en melding
          const messageResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/postMessage`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userProfile.token}`,
            },
            body: JSON.stringify({
              fileId, // Sørg for at fileId er definert
              user: 135,
              message: `Deducted ${actualDrawAmount} credits from ${dealerName}. Reason: (${description}). Remaining owed credits: ${newOwedCredits}`,
              internal: 0,
              rredo: 0,
              rcredit: 0,
              dealercheck: 1,
              tunercheck: 1,
              messageStatus: 2,
            }),
          });
    
          if (!messageResponse.ok) {
            throw new Error(`Failed to post message for recurring entry ID: ${id}`);
          }
    
          // console.log(`Posted message for recurring entry ID: ${id}`);
    
          // Logg handlingen
          const logResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/log-recurring-credit`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userProfile.token}`,
            },
            body: JSON.stringify({
              recurring_id: id,
              text: `Deducted ${actualDrawAmount} credits from ${dealerName}. Reason: (${description}). Remaining owed credits: ${newOwedCredits}`,
            }),
          });
    
          if (!logResponse.ok) {
            throw new Error(`Failed to log recurring credit action for entry ID: ${id}`);
          }
    
          // console.log(`Logged recurring credit action for entry ID: ${id}`);
        }
      } catch (error) {
        console.error('Error processing recurring credits:', error);
        alert('Failed to process recurring credits. Please try again.');
      }
    };
    
  
  
  

  

    const updateFileStatus = async (fileId, status) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-status/${fileId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            status,
            tuner: userProfile.name,
            dealercheck: 1 // Setter dealercheck til 1
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error response from server:', errorData);
          throw new Error(`Failed to update file status. Server responded with status: ${response.status}`);
        }

      } catch (error) {
        console.error('Error updating file status and dealercheck:', error);
        alert('Failed to update file status. Please try again.');
      }
    };

    const logCreditWithdrawal = async (mid, creditsWithdrawn, dealerName, username) => {
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');
      const op_text = `${fileDetails.id}: ${username} withdrew -${creditsWithdrawn} credits from ${dealerName} (${dealerCredit - creditsWithdrawn} total)`;
      const payload = {
          d_mid: mid, // Dealer's member ID
          op_text,
          created: formattedDate,
      };
      try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/logCreditWithdrawal`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
          });
          if (!response.ok) {
              throw new Error('Failed to log credit withdrawal');
          }
      } catch (error) {
          console.error('Error logging credit withdrawal:', error);
          alert('Failed to log credit withdrawal. Please try again.');
      }
  };

  const transmissionOptions = {
    2: 'Auto',
    3: 'Manual',
    4: 'DSG',
  };

  const fuelOptions = {
    2: 'Diesel',
    3: 'Petrol',
    5: 'Hybrid',
  };

  const tuningToolOptions = {
    1: 'Autotuner',
    2: 'MMFlex',
    3: 'NewGenius',
    9: 'Unlisted - Other tool',
    10: 'Trasdata',
    11: 'Kess V3',
    12: 'K-Tag',
  };

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  const handleClose = () => {
    onClose();
    window.location.reload();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
        window.location.reload();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [dialogs]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '1px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newMessage]);

  const handleTextareaInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '1px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const getMessageType = (dialog) => {
    let classes = [];
    if (dialog.internal === 1) classes.push('internal-message');
    if (dialog.user === fileDetails.dealerName) classes.push('dealer-message');
    else classes.push('admin-message');
    if (dialog.rredo === 1) classes.push('rredo-message');
    if (dialog.rcredit === 1) classes.push('rcredit-message');
    if (dialog.url) classes.push('url-message');
    if (dialog.uploadurl) classes.push('uploadurl-message');
    if (dialog.uploaded === 1) classes.push('uploaded-message');
    return classes.join(' ');
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    fileInputRef.current.files = event.target.files;
    setDropdownVisible(false);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    const dataTransfer = new DataTransfer();
    updatedFiles.forEach(file => dataTransfer.items.add(file));
    fileInputRef.current.files = dataTransfer.files;
  };

  function isImage(url) {
    return /\.(jpg|jpeg|png|gif)$/.test(url);
  }

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleInternalChange = (event) => {
    setIsInternal(event.target.checked);
  };

  const handleRequestComment = () => {
    setShowForm('comment');
    setNewMessage('');
  };


  const sendNotification = async (userId, title, body, data = {}) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userProfile.token}`
        },
        body: JSON.stringify({ userId, title, body, data })
      });
      if (!response.ok) {
        throw new Error('Failed to send notification');
      }
      const result = await response.json();
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const sendNotificationToAdmin = async (title, body, data = {}) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userProfile.token}`
        },
        body: JSON.stringify({ title, body, data })
      });
      if (!response.ok) {
        throw new Error('Failed to send notification to admin');
      }
      const result = await response.json();
    } catch (error) {
      console.error('Error sending notification to admin:', error);
    }
  };

  const handleUploadSubmit = async (event) => {
    event.preventDefault();

    if (!newMessage.trim() && selectedFiles.length === 0) {
      alert("Du må enten skrive inn en melding eller velge en fil før innsending.");
      return;
    }

    const files = fileInputRef.current ? fileInputRef.current.files : [];
    const selectedMainFile = files && files.length > 0 ? files[0] : null;

    if (selectedMainFile) {
      setMainFile(selectedMainFile);

      if (isAdmin || isTuner) {
        // Åpne `MasterUploadConfirm`-modalen
        setIsConfirmModalOpen(true);
        setCallbackFunction(() => async (uploadMaster) => {
          setIsConfirmModalOpen(false);
          if (uploadMaster) {
            const masterFileInput = document.createElement('input');
            masterFileInput.type = 'file';
            masterFileInput.onchange = async (e) => {
              const masterFile = e.target.files && e.target.files[0];
              if (masterFile) {
                await uploadFiles(selectedMainFile, masterFile);
              } else {
                await uploadFiles(selectedMainFile);
              }
            };
            masterFileInput.click();
          } else {
            await uploadFiles(selectedMainFile);
          }
        });
      } else {
        await uploadFiles(selectedMainFile);
      }
    } else {
      await postTextMessage();
    }

    if (isAdmin) {
      if(isInternal) {
        sendNotificationToAdmin(
          userProfile.name,
          `(Internal) ID ${fileId}: ${newMessage} ${mainFile ? mainFile.name : ''}`,
          { messageId: '123', sender: 'John Doe' }
        );
      } else {
        sendNotification(
          fileDetails.mid,
          'Optifiles - ' + userProfile.name,
          `ID ${fileId}: ${newMessage} ${mainFile ? mainFile.name : ''}`,
          { messageId: '123', sender: 'John Doe' }
        );
      }
    } else {
      sendNotificationToAdmin(
        userProfile.companyName + ' (' + userProfile.name + ')',
        `ID ${fileId}: ${newMessage} ${mainFile ? mainFile.name : ''}`,
        { messageId: '123', sender: 'John Doe' }
      );
    }

    setNewMessage('');
    setIsInternal(false);
    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setShowForm(null);
    
  };

  const handleConfirmUpload = async (uploadMaster) => {
    if (callbackFunction) {
      await callbackFunction(uploadMaster);
    }
  };

  const uploadFiles = async (mainFile, masterFile = null) => {
    try {
        if (!mainFile) {
            throw new Error('No main file selected');
        }

        const isImage = /\.(jpg|jpeg|png|gif)$/i.test(mainFile.name);
        const defaultMessage = isImage ? 'Image upload' : 'File upload';

        const mainFormData = new FormData();
        mainFormData.append('file', mainFile);
        mainFormData.append('fileid', fileId);
        mainFormData.append('user', userProfile.id);
        mainFormData.append('message', newMessage || defaultMessage);
        mainFormData.append('internal', '0');

        const mainUploadResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploadDialogueFile`, {
            method: 'POST',
            body: mainFormData,
        });

        if (!mainUploadResponse.ok) {
            const errorText = await mainUploadResponse.text();
            throw new Error(`Main file upload failed: ${errorText || 'Unknown error'}`);
        }
        if (masterFile) {
            const masterFormData = new FormData();
            masterFormData.append('file', masterFile);
            masterFormData.append('fileid', fileId);
            masterFormData.append('user', userProfile.id);
            masterFormData.append('message', 'Master file upload');
            masterFormData.append('internal', '1');

            const masterUploadResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploadDialogueFile`, {
                method: 'POST',
                body: masterFormData,
            });

            if (!masterUploadResponse.ok) {
                const errorText = await masterUploadResponse.text();
                throw new Error(`Master file upload failed: ${errorText || 'Unknown error'}`);
            }
        }
        const payload = {
          fileId: fileId,
          messageStatus: isAdmin || isTuner ? 2 : 1,
          dealercheck: isAdmin ? 1 : 1,
          tunercheck: isAdmin ? 1 : 1
      };
        const updateStatusResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/update-check-status`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });

        if (!updateStatusResponse.ok) {
            throw new Error('Failed to update message status');
        }

        await updateDialogs();
    } catch (error) {
        console.error('Error uploading file(s):', error);
        alert(`File upload failed: ${error.message}`);
    }
};


  const postTextMessage = async () => {
    const payload = {
      fileId: fileId,
      user: userProfile.id,
      message: newMessage,
      internal: isInternal ? 1 : 0,
      rredo: showForm === 'redo' ? 1 : 0,
      rcredit: showForm === 'creditback' ? 1 : 0,
      dealercheck: isAdmin ? 1 : 1, // Endret slik at dealercheck alltid er 1 for ikke-admin
      tunercheck: isAdmin ? fileDetails.tunercheck : 1,
      messageStatus: isAdmin ? 2 : 1,
    };

    try {
      const messageResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/postMessage`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!messageResponse.ok) {
        throw new Error('Failed to post message');
      }

      const messageData = await messageResponse.json();
      await updateDialogs();
    } catch (error) {
      console.error('Error posting message:', error);
    }
  };

  // const updateDialogs = async () => {
  //   try {
  //     const dialogsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/filecom/${fileId}`);
  //     if (!dialogsResponse.ok) {
  //       throw new Error('Failed to fetch updated dialogs');
  //     }
  //     const updatedDialogs = await dialogsResponse.json();
  //     const processedDialogs = await Promise.all(updatedDialogs.map(async (dialog) => {
  //       const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-did-company/${dialog.user}`);
  //       const data = await response.json();
  //       let companyName = data.companyName;
  //       let companyStyle = { color: 'green' };
  //       if (data.admin === 1 || data.tuner === 1) {
  //         companyName = 'Optifiles';
  //         companyStyle = { color: 'red' };
  //       }
  //       if (data.support === 1) {
  //         companyName += '';
  //         companyStyle = { color: 'green' };
  //       }
  //       return { ...dialog, companyName, companyStyle, isSupport: data.support === 1 };
  //     }));
  //     setDialogs(processedDialogs);
  //   } catch (error) {
  //     console.error('Error fetching updated dialogs:', error);
  //   }
  // };

  const updateDialogs = async () => {
    try {
      const dialogsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/filecom/${fileId}`);
      if (!dialogsResponse.ok) {
        throw new Error('Failed to fetch updated dialogs');
      }
      const updatedDialogs = await dialogsResponse.json();
  
      // console.log('Fetched dialogs from backend:', updatedDialogs); // Log fetched dialogs
  
      const processedDialogs = await Promise.all(
        updatedDialogs.map(async (dialog) => {
          console.log('Dialog before processing:', dialog); // Log entire dialog object
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-did-company/${dialog.user}`);
          if (!response.ok) {
            throw new Error(`Failed to fetch user details for user ID: ${dialog.user}`);
          }
          const data = await response.json();
      
          // console.log('Fetched user data:', data); // Log fetched user data
      
          // Tilpasse visning basert på roller
          let companyName = data.companyName;
          let companyStyle = { color: 'green' };
          if (data.admin === 1 || data.tuner === 1) {
            companyName = 'Optifiles';
            companyStyle = { color: 'red' };
          }
          if (data.support === 1) {
            companyName += '';
            companyStyle = { color: 'green' };
          }
      
          const processedDialog = {
            ...dialog,
            username: dialog.username || 'Unknown User', // Ensure username has a value
            companyName,
            companyStyle,
            isSupport: data.support === 1,
          };
      
          // console.log('Processed dialog username:', processedDialog.username); // Log username specifically
          return processedDialog;
        })
      );
      
  
      // console.log('Final processed dialogs:', processedDialogs); // Log the final array of dialogs
      setDialogs(processedDialogs);
    } catch (error) {
      console.error('Error fetching dialogs:', error);
    }
  };
  

  
  const renderLinks = (urlsString) => {
    if (!urlsString) return null;
  
    const urls = urlsString.split(',');
  
    const handleLinkClick = (url, isImage) => {
      const fileName = url.split('/').pop(); // Trekker ut filnavnet fra URL
      const action = isImage ? `Image clicked: ${fileName}` : `File downloaded: ${fileName}`;
      logTheDownload(action);
    };
  
    const imageElements = urls.filter(isImage).map((url, index) => (
      <img
        key={index}
        src={url}
        alt={`Uploaded File ${index + 1}`}
        className="thumbnail"
        onClick={() => {
          handleLinkClick(url, true);
          window.open(url, '_blank');
        }}
      />
    ));
  
    const downloadLinkElements = urls.filter(url => !isImage(url)).map((url, index) => (
      <a
        key={index}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="download-link"
        onClick={() => handleLinkClick(url, false)}
      >
        {url.split('/').pop()}
      </a>
    ));
  
    return (
      <>
        <div className="thumbnails-container">{imageElements}</div>
        <div className="download-links-container">{downloadLinkElements}</div>
      </>
    );
  };
  
  

  const handleWipChange = async () => {
    const newWipStatus = !fileDetails.wip;
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + `/updateWipStatus`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userProfile.token}` },
        body: JSON.stringify({ fileId: fileDetails.id, wip: newWipStatus })
      });
      if (response.ok) {

        setFileDetails(prevDetails => ({ ...prevDetails, wip: newWipStatus }));
      } else {
        throw new Error('Failed to update WIP status');
      }
    } catch (error) {
      console.error('Error updating WIP status:', error);
    }
  };

  const fetchFileOptions = async (dialog) => {
    if (!dialog.oriurl && !dialog.gearurl) {
      return null;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/file-options/${dialog.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch file options');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching file options:', error);
      return null;
    }
  };

  const constructAvatarUrl = (relativePath) => {
    let baseUrl = process.env.REACT_APP_SITE_URL;
    if (baseUrl.includes('/api')) {
        baseUrl = baseUrl.split('/api')[0];
    }
    return `${baseUrl}${relativePath}`;
};

const MessageAvatar = ({ dialog }) => {
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
      if (dialog.avatar) {
          const constructedAvatarUrl = constructAvatarUrl(dialog.avatar);
          setAvatarUrl(constructedAvatarUrl);
      }
  }, [dialog.avatar]);

  return (
      <div className="message-avatar">
          {avatarUrl ? (
              <img src={avatarUrl} alt="Avatar" className="avatar-icon" />
          ) : (
              <>
                  {getMessageType(dialog).includes('dealer-message') && <span className="avatar-icon">💼</span>}
                  {getMessageType(dialog).includes('admin-message') && <span className="avatar-icon">👤</span>}
              </>
          )}
      </div>
  );
};

const fetchDoneLogs = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/donelogs/${fileId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch donelogs');
    }
    const data = await response.json();
    setDoneLogs(data);
    setIsLoading(false);
  } catch (error) {
    console.error('Error fetching donelogs:', error);
    setIsLoading(false);
  }
};

useEffect(() => {
  fetchDoneLogs();
}, [fileId]);

const fetchLogs = async (fileId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/fetch-logs/${fileId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userProfile.token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch logs');
    }

    const data = await response.json();
    setLogs(data.logs);  // Assuming the server returns an array of logs in 'logs'
  } catch (error) {
    console.error('Error fetching logs:', error);
  }
};

const handleDownload = (url, fileDetails) => {
  const originalFilename = url.substring(url.lastIndexOf('/') + 1);
  const sanitizeForFilename = (value) => {
    return String(value).replace(/[^a-z0-9]/gi, '_');
  };
  const sanitizedId = sanitizeForFilename(fileDetails.id);
  const sanitizedRegnum = sanitizeForFilename(fileDetails.regnum).toUpperCase();
  const newFilename = `${sanitizedId}_${sanitizedRegnum}_${originalFilename}`;

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', newFilename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// For encrypted files (ending in .slave)
const handleEncryptedDownload = (url, fileDetails) => {
  // Get the original filename
  const originalFilename = url.substring(url.lastIndexOf('/') + 1);

  // Make sure we're downloading the encrypted version (without .bin)
  const encryptedFilename = originalFilename.endsWith('.slave.bin')
    ? originalFilename.slice(0, -4)  // Remove .bin from .slave.bin
    : originalFilename;

  const sanitizeForFilename = (value) => {
    return String(value).replace(/[^a-z0-9]/gi, '_');
  };

  const sanitizedId = sanitizeForFilename(fileDetails.id);
  const sanitizedRegnum = sanitizeForFilename(fileDetails.regnum).toUpperCase();
  const newFilename = `${sanitizedId}_${sanitizedRegnum}_${encryptedFilename}`;

  // Create the URL for the encrypted version by ensuring it points to .slave file
  const encryptedUrl = url.endsWith('.slave.bin')
    ? url.slice(0, -4)  // Remove .bin from the URL if it's there
    : url;

  const link = document.createElement('a');
  link.href = encryptedUrl;  // Use the URL for the encrypted file
  link.setAttribute('download', newFilename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const DownloadOriUpdateStatus = async (id, url) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-status/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: 1, tuner: userProfile.id }),
    });

    if (!response.ok) {
      console.error('Failed to update status');
      return;
    }

    setFileDetails(prevDetails => ({
      ...prevDetails,
      status: 1, // Oppdater status til 'IP'
    }));

    handleDownload(url, fileDetails);

  } catch (error) {
    console.error('Error updating status:', error);
  }
};

const DownloadOriHidden = async (id, url) => {
  try {
    setDialogs(prevDialogs => [...prevDialogs]);
    handleDownload(url, fileDetails);
  } catch (error) {
    console.error('Error updating status or posting message:', error);
  }
};

const getTuningTypeClass = (tuningTypeValue) => {
  switch (tuningTypeValue.toLowerCase()) {
    case 'eco':
      return 'tuning-type-eco';
    case 'opti':
      return 'tuning-type-opti';
    case 'power':
      return 'tuning-type-power';
    case 'as stock':
      return 'tuning-type-as-stock';
    case 'extreme':
      return 'tuning-type-extreme';
    default:
      return '';
  }
};

// useEffect(() => {
//   const fetchData = async () => {
//     try {
//       const detailsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/file/${fileId}`);
//       const detailsData = await detailsResponse.json();
//       setFileDetails(detailsData);

//       const dialogsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/filecom/${fileId}`);
//       const dialogsData = await dialogsResponse.json();

//       const updatedDialogs = await Promise.all(dialogsData.map(async (dialog) => {
//         const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-did-company/${dialog.user}`);
//         const data = await response.json();

//         let companyName = data.companyName;
//         let companyStyle = { color: 'green' };

//         if (data.admin === 1 || data.tuner === 1) {
//           companyName = 'Optifiles';
//           companyStyle = { color: 'red' };
//         }
//         if (data.support === 1) {
//           companyName += '';
//           companyStyle = { color: 'green' };
//         }
//         const fileOptions = await fetchFileOptions(dialog);
//         return { ...dialog, companyName, companyStyle, isSupport: data.support === 1, fileOptions };
//       }));

//       setDialogs(updatedDialogs);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   fetchData();
// }, [fileId]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const detailsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/file/${fileId}`);
      const detailsData = await detailsResponse.json();
      setFileDetails(detailsData);

      // console.log('File details fetched:', detailsData); // Log file details

      const dialogsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/filecom/${fileId}`);
      const dialogsData = await dialogsResponse.json();

      // console.log('Dialogs fetched:', dialogsData); // Log dialogs from backend

      const updatedDialogs = await Promise.all(
        dialogsData.map(async (dialog) => {
          // console.log('Processing dialog:', dialog); // Log dialog before processing

          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-did-company/${dialog.user}`);
          const data = await response.json();

          // console.log('Fetched user data:', data); // Log fetched user data

          let companyName = data.companyName;
          let companyStyle = { color: 'green' };

          if (data.admin === 1 || data.tuner === 1) {
            companyName = 'Optifiles';
            companyStyle = { color: 'red' };
          }
          if (data.support === 1) {
            companyName += '';
            companyStyle = { color: 'green' };
          }

          const fileOptions = await fetchFileOptions(dialog);
          const username = dialog.username || 'Unknown User';
          const processedDialog = {
            ...dialog,
            username,
            companyName,
            companyStyle,
            isSupport: data.support === 1,
            fileOptions,
          };

          // console.log('Processed dialog:', processedDialog); // Log final processed dialog
          return processedDialog;
        })
      );

      setDialogs(updatedDialogs);
      // console.log('Updated dialogs set:', updatedDialogs); // Log final dialogs array
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [fileId]);


const handleCreditChange = async (newCredits) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/updateCredits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userProfile.token}`
      },
      body: JSON.stringify({
        fileId: fileId,
        newCredits: newCredits
      })
    });

    if (!response.ok) {
      throw new Error('Failed to update credits');
    }

    const data = await response.json();
    setCurrentCredits(newCredits);
    setFileDetails(prevDetails => ({
      ...prevDetails,
      charged: newCredits
    }));
    setShowCreditChangeModal(false);
    alert('Credits updated successfully');
  } catch (error) {
    console.error('Error updating credits:', error);
    alert('Failed to update credits. Please try again.');
  }
};

// const fetchCreditAndOptions = async () => {
//   try {
//     const response = await fetch(`${process.env.REACT_APP_SITE_URL}/file-credits-and-options/${fileId}`);
//     if (!response.ok) {
//       throw new Error('Failed to fetch credit and options data');
//     }
//     const data = await response.json();
//     setCurrentCredits(data.currentCredits);
//     setFileOptions(data.fileOptions);
//   } catch (error) {
//     console.error('Error fetching credit and options:', error);
//   }
// };

const formatDateTime = (dateTimeString) => {
  if (!dateTimeString || isNaN(new Date(dateTimeString).getTime())) {
    console.warn('Invalid date received:', dateTimeString);
    return 'Invalid Date';
  }
  const options = {
    timeZone: 'UTC',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };
  return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateTimeString));
};

  if (!fileDetails) return <div>No file details available.</div>;
  const buttonClass = fileDetails.message === 1 ? 'read-button' : 'read-button-done';
  const buttonText = fileDetails.message === 1 ? 'Merk som lest' : 'Lest';
  const dealerStatusClass = fileDetails.dealercheck === 1 ? 'status-open' : 'status-closed';
  const dealerStatusText = fileDetails.dealercheck === 1 ? 'OPEN' : 'CLOSED';
  const handleDeleteMessage = async (messageId) => {
    if (window.confirm('Are you sure you want to delete this message?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/deleteDialogueMessage/${messageId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${userProfile.token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to delete message');
        }
        setDialogs(prevDialogs => prevDialogs.filter(dialog => dialog.id !== messageId));
      } catch (error) {
        console.error('Error deleting message:', error);
        alert('Failed to delete message. Please try again.');
      }
    }
  };

  const isCustomType = [6, 8, 9].includes(fileDetails.v_type);


// ||||||||||||||||||||||| DENNE KODEN SKAL FJERNES 01.01.2025.  |||||||||||||||||||||||||||||||||||
const calculateEstimatedCredits = (fileOptions) => {
      if (manualCredits !== '') {
      return parseInt(manualCredits, 10);  // Konverter strengen til et tall og returner umiddelbart
    }
  let credits = 7;
  const type = fileDetails.v_type;
  const isCustomType = [6, 8, 9].includes(type);
  // Konverter tuning_type til et tall for å sikre riktig sammenligning
  const tuningTypeValue = parseInt(fileOptions.tuning_type, 10);
  // For custom type files
  if (isCustomType) {
    credits = 20; // Minimum 20 credits for custom type
    // Hvis noen av egr, dpf, eller adblue er valgt, blir det 40 credits
    if (fileOptions.egr === 1 || fileOptions.dpf === 1 || fileOptions.adblue === 1) {
      credits = 40;
    }
  } else {
    // For non-custom type files
    credits = 7; // Minimum 7 credits
    // Hvis tuning_type er forskjellig fra 5
    if (tuningTypeValue && tuningTypeValue !== 5) {
      if (fileOptions.adblue === 1) {
        credits = 21; // 21 credits hvis adblue er valgt sammen med annen tuning_type
      } else {
        credits = 16; // 16 credits hvis tuning_type ikke er 5 og adblue ikke er valgt
      }
    } else if (tuningTypeValue === 5 && fileOptions.adblue === 1) {
      credits = 16; // 16 credits hvis tuning_type er 5 og adblue er valgt
    }
    // Hvis kun egr eller dpf er valgt, trekk kun 7 credits totalt
    if (fileOptions.egr === 1 && !fileOptions.dpf && !fileOptions.adblue && tuningTypeValue === 5) {
      credits = 7;
    }
  }
  return credits;
};



  // ||||||||||||||||||||||| DENNE KODEN SKAL FJERNES 01.01.2025.  |||||||||||||||||||||||||||||||||||



// ||||||||||||||||||||||| DENNE KODEN SKAL LANSERES 01.01.2025. SÅ IKKE FJERN |||||||||||||||||||||||||||||||||||
  // const calculateEstimatedCredits = (fileOptions) => {
  //   if (manualCredits !== '') {
  //     return parseInt(manualCredits, 10);  // Konverter strengen til et tall og returner umiddelbart
  //   }
  //   let credits = companyLevel.baseCredits || 0;
  //   const type = fileDetails.v_type;
  //   const isCustomType = [6, 8, 9].includes(type);
  //   const tuningTypeCredit = isCustomType ? 12 : 4;
  // const relevantOptions = ['egr', 'dpf', 'adblue', 'nsl'];
// ||||||||||||||||||||||| DENNE KODEN SKAL LANSERES 01.01.2025. SÅ IKKE FJERN |||||||||||||||||||||||||||||||||||
  //   if (fileOptions.tuning_type_value && fileOptions.tuning_type_value !== '5') {
  //     credits += tuningTypeCredit;
  //   }
  //   const optionCosts = {
  //     egr: isCustomType ? 15 : 3,
  //     dpf: isCustomType ? 15 : 3,
  //     adblue: isCustomType ? 20 : 5,
  //     nsl: isCustomType ? 10 : 2,
  //     o2: 2,
  //     epa: 5,
  //     hybridturbo: 7,
  //     gearbox: 5,
  //     coldstart: 2,
  //     startstop: 2,
  //     popsbangs: 5,
  //     burbles: 5,
  //     swirlflaps: 2,
  //     tva: 2,
  //     analysis: 0,
  //   };
  // ||||||||||||||||||||||| DENNE KODEN SKAL LANSERES 01.01.2025. SÅ IKKE FJERN |||||||||||||||||||||||||||||||||||
  //   Object.keys(optionCosts).forEach((option) => {
  //     if (fileOptions[option] == 1) {
  //         credits += optionCosts[option];
  //     }
  //   });
  //   Object.keys(selectedOptions).forEach(option => {
  //     const wasPreviouslySelected = initialOptions.current[option];  // Tidligere tilstand (opprinnelige valg)
  //     const isSelectedNow = selectedOptions[option];  // Nåværende tilstand (nye valg)
  //     if (isSelectedNow && !wasPreviouslySelected) {
  //       credits += optionCosts[option] || 0;
  //     }
  //     if (!isSelectedNow && wasPreviouslySelected) {
  //       credits -= optionCosts[option] || 0;
  //     }
  //   });
// ||||||||||||||||||||||| DENNE KODEN SKAL LANSERES 01.01.2025. SÅ IKKE FJERN |||||||||||||||||||||||||||||||||||
  //   if (fileOptions.euro_class === 2) {
  //     const anyOptionSelected = ['egr', 'dpf', 'adblue', 'nsl'].some(option => fileOptions[option] !== 0);
  // if (fileOptions.euro_class === 2 && isCustomType && anyOptionSelected) {
  //   return 70;
  // }
  // if (fileOptions.euro_class === 2 && !anyOptionSelected) {
  //   return credits;
  // }
  //   }
  //   const finalCredits = credits > 50 ? 50 : credits;
  //   return finalCredits;
  // };
// ||||||||||||||||||||||| DENNE KODEN SKAL LANSERES 01.01.2025. SÅ IKKE FJERN |||||||||||||||||||||||||||||||||||

const updatePaymentStatus = async (fileId, paidStatus) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-paid-status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userProfile.token}`,
      },
      body: JSON.stringify({ fileId, paidStatus }),
    });

    if (!response.ok) {
      throw new Error('Failed to update payment status');
    }
    setFileDetails(prevDetails => ({ ...prevDetails, paid: paidStatus }));
  } catch (error) {
    console.error('Failed to update payment status', error);
  }
};


const getFileNameFromURL = (url) => {
  if (!url) {
    return '';
  }
  return url.substring(url.lastIndexOf('/') + 1);
};

const handleRequestCreditBack = () => {
  setShowCreditBackModal(true);
};

const handleCreditBackSubmit = async (logFile, comment) => {
  const formData = new FormData();
  formData.append('logFile', logFile);
  formData.append('comment', comment);
  formData.append('fileId', fileDetails.id);
  formData.append('user', userProfile.id);

  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/requestCreditBack`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${userProfile.token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to submit credit back request');
    }
    const result = await response.json();
    setDialogs(prevDialogs => [...prevDialogs, {
      id: result.filecomId,
      user: userProfile.id,
      message: `Credit back request: ${comment}`,
      date: new Date().toISOString(),
      rcredit: 1,
      companyName: userProfile.companyName,
      companyStyle: { color: 'green' },
      status: 'pending'

    }]);
    setShowCreditBackModal(false);
    alert('Credit back request submitted successfully');
  } catch (error) {
    console.error('Error submitting credit back request:', error);
    alert('Failed to submit credit back request. Please try again.');
  }
};

const handleCreditBackAction = (dialogId, action) => {
  const adminComment = prompt(`Please enter a comment for ${action}ing this credit back request:`);
  if (adminComment === null) return; // User cancelled the prompt
  if (adminComment.trim() === '') {
    alert('A comment is required.');
    return;
  }

  updateCreditBackRequest(dialogId, action, adminComment);
};

const updateCreditBackRequest = async (dialogId, action, adminComment) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/updateCreditBackRequest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userProfile.token}`
      },
      body: JSON.stringify({
        dialogId,
        action,
        adminComment,
        adminId: userProfile.id
      })
    });

    if (!response.ok) {
      throw new Error('Failed to update credit back request');
    }

    const result = await response.json();
    setDialogs(prevDialogs => prevDialogs.map(dialog =>
      dialog.id === dialogId
        ? { ...dialog, status: action }
        : dialog
    ));

    const newDialog = {
      id: Date.now(), // temporary id, you might want to fetch the actual id from the server
      user: userProfile.id,
      message: action === 'accepted'
        ? `Accepted: ${adminComment}\nCredits returned: ${result.creditedAmount}`
        : `Denied: ${adminComment}`,
      date: new Date().toISOString(),
      rcredit: 0,
      status: action,
      companyName: 'Optifiles',
      companyStyle: { color: 'red' }
    };
    setDialogs(prevDialogs => [...prevDialogs, newDialog]);
    if (action === 'accepted') {
      setFileDetails(prevDetails => ({
        ...prevDetails,
        charged: 0 // Set charged to 0 as all credits have been returned
      }));
    }
    alert(`Credit back request ${action} successfully`);
  } catch (error) {
    console.error('Error updating credit back request:', error);
    alert('Failed to update credit back request. Please try again.');
  }
};


return (

  <div className="filemodal-background">
    <div className="filemodal-content" onClick={e => e.stopPropagation()}>
    <div className="filedetailmodal-header">
  <div className="filedetailmodalheader-left">
    <div className="description-row">
      <div className="vehicle-description">
        {fileDetails.vehicleDescription} -
      </div>
      <div className="vehicle-descriptionid">
        <b>ID: {fileDetails.id}  </b>
        {(isAdmin || isTuner) && (
          <>
            <button className="copy-button3" onClick={handleCopyId}>Copy</button>
            {copySuccess && <div className="copy-notification">ID copied!</div>}
          </>
        )}


      </div>
    </div>

    <div className="buttondet-row">
             <div className="file-optionsdet">
                {fileOptionsStatus && (
                  <>
                  {fileOptionsStatus.tuning_type && (
                    <span className={`tuningType ${getTuningTypeLabel(fileOptionsStatus.tuning_type).className}`}>
                      {getTuningTypeLabel(fileOptionsStatus.tuning_type).label}
                    </span>
                  )}
                  {fileOptionsStatus.egr !== 0 && <span className="file-option-small egr">E</span>}
                  {fileOptionsStatus.dpf !== 0 && <span className="file-option-small dpf">D</span>}
                  {fileOptionsStatus.adblue !== 0 && <span className="file-option-small adblue">A</span>}
                  {fileOptionsStatus.nsl !== 0 && <span className="file-option-small nsl">NSL</span>}
                  {fileOptionsStatus.o2 !== 0 && <span className="file-option-small o2">O2</span>}
                  {fileOptionsStatus.epa !== 0 && <span className="file-option-small epa">EPA</span>}
                  {fileOptionsStatus.hybridturbo !== 0 && <span className="file-option-small hybrid-turbo">HT</span>}
                  {fileOptionsStatus.gearbox !== 0 && <span className="file-option-small gearbox">GT</span>}
                  {fileOptionsStatus.coldstart !== 0 && <span className="file-option-small cold-start">CS</span>}
                  {fileOptionsStatus.startstop !== 0 && <span className="file-option-small start-stop">SS</span>}
                  {fileOptionsStatus.popsbangs !== 0 && <span className="file-option-small pops-bangs">PB</span>}
                  {fileOptionsStatus.burbles !== 0 && <span className="file-option-small burbles">BU</span>}
                  {fileOptionsStatus.swirlflaps !== 0 && <span className="file-option-small swirl-flaps">SW</span>}
                  {fileOptionsStatus.tva !== 0 && <span className="file-option-small tva">TVA</span>}
                  {fileOptionsStatus.analysis !== 0 && <span className="file-option-small tva">NS</span>}

                </>
                )}
              </div>
</div>
  </div>

        <div className="filedetailmodalheader-right">
          {(userProfile.id === 3 || userProfile.id === 37) && (
            <>
              <button className="special-btn" onClick={() => SpecialAction(fileDetails.id)}>SPECIAL</button>
            </>
          )}
          {isAdmin && (
            <>
              <button className="credits-btn" onClick={() => ViewOptionAndCredits(fileDetails.id)}>CREDS & OPS</button>
            </>
          )}
          {(isAdmin || isTuner) && (
            <>
              <button className="log-btn" onClick={() => ViewLogs(fileDetails.id)}>LOGS</button>
            </>
          )}
          <button className="close-btn" onClick={handleClose}>CLOSE</button>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="column message-column">
          <div className="messages">
          {Array.isArray(dialogs) && dialogs.map((dialog, index) => (
          (dialog.internal === 0 || isAdmin || isTuner) && (
            <div key={index} className={`message-wrapper ${getMessageType(dialog)}`}>
              <div className="dialog-message">
                <MessageAvatar dialog={dialog} />
                <div className="message-content">
                  <div className="message-header">
                    <span className="dialog-user"><b>{dialog.username}</b></span>
                    <span className="dialog-date">{formatDateTime(dialog.date)}</span>
                  </div>
                  <div className="company-name" style={dialog.companyStyle}>
                    {dialog.companyName}
                    {dialog.isSupport && <span style={{ color: 'blue' }}> (Support)</span>}
                  </div>
                  <div className="message-body">
                  <p className="dialog-message-content">{dialog.message}          {isAdmin && (
                    <button className="delete-message-button" onClick={() => handleDeleteMessage(dialog.id)}>
                      <img src="/images/red-trash-can-icon.svg" alt="Delete" className="delete-icon" />
                    </button>
        )}</p>
        {dialog.message.startsWith("File upload:") && (
  <>
    <div className="dialogdownload-buttons">
      {(isAdmin || isTuner) && (
        <>

<button
      onClick={() => {
        DownloadOriUpdateStatus(fileDetails.id, dialog.oriurl);
        logTheDownload('Downloaded Orifile');
      }}
      className="oridownload-button">
Orifile
    </button>

    <button
      onClick={() => {
        DownloadOriHidden(fileDetails.id, dialog.oriurl);
        logTheDownload('Downloaded Orifile (Hidden)');
      }}
      className="orihiddownload-button">
      Orifile (Hidden)
    </button>
          {fileDetails.tooltype === 1 && (
            <button
  onClick={() => {
    handleEncryptedDownload(dialog.oriurl, fileDetails);
    logTheDownload('Downloaded Orifile (Encrypted)');
  }}
  className="oriencdownload-button">
  Ori (Encrypted)
</button>
          )}<div className="modurl">ECU ORI: {getFileNameFromURL(dialog.oriurl)}</div>
              {dialog.gearurl && (
                <>
                      <button
      onClick={() => {
        handleDownload(dialog.gearurl, fileDetails);
        logTheDownload('Downloaded Orifile');
      }}
      className="oridownload-button">
      Original
    </button>
                  {fileDetails.tooltype === 1 && (
      <button
      onClick={() => {
        handleEncryptedDownload(dialog.gearurl, fileDetails);
        logTheDownload('Downloaded Orifile (Encrypted)');
      }}
      className="oriencdownload-button">
      Ori (Encrypted)
    </button>
                  )}<div className="modurl">ACM / TCU ORI: {getFileNameFromURL(dialog.gearurl)}</div>
                </>
              )}
                    </>
                  )}
              </div>
                {isUploadModPopupVisible && (
                  <div className="upload-mod-popup-background">
                    <div className="upload-mod-popup-content" onClick={e => e.stopPropagation()}>     
                    <div className="modal-header">            
                      <div className="vehicle-descriptionidmod">
        <b>ID: {fileDetails.id} - {fileDetails?.regnum}  </b>



      </div>
              <button className="close-btn" onClick={closeUploadModPopup}>CLOSE</button>
            </div>
            <form onSubmit={(e) => handleUploadModSubmit(e, dialog.fileOptions, selectedOptions)}>
                          <div className="file-input-row">
                          <div className="file-input-column">
                            <label className="file-input-label">Modfile:</label>
                            <input 
      className="unique-file-input" 
      type="file" 
      name="modFile" 
      onChange={e => setModFile(e.target.files)} 
      multiple 
    />
                          </div>
                          <div className="file-input-column">
                            <label className="file-input-label">Master:</label>
                            <input className="unique-file-input" type="file" name="masterFile" onChange={e => setMasterFile(e.target.files[0])} />
                          </div>
                          <div className="file-input-column">
                            <label className="file-input-label">Gearbox:</label>
                            <input className="unique-file-input" type="file" name="gearboxFile" onChange={e => setGearboxFile(e.target.files[0])} />
                          </div>
                          <div className="file-input-column">
                            <label className="file-input-label">Gearbox Master:</label>
                            <input className="unique-file-input" type="file" name="gearboxMasterFile" onChange={e => setGearboxMasterFile(e.target.files[0])} />
                          </div>
                        </div>
                        <div className="selected-options-summary">
                          {dialog.fileOptions && (
                            <>
                            <div className="file-options">
                              {dialog.fileOptions.tuning_type_value && (
                                <p className={`file-option tuning-type ${getTuningTypeClass(dialog.fileOptions.tuning_type_value)}`}>
                                  {dialog.fileOptions.tuning_type_value}
                                </p>
                              )}
                              {dialog.fileOptions.egr !== 0 && <p className="file-option egr">EGR</p>}
                              {dialog.fileOptions.dpf !== 0 && <p className="file-option dpf">DPF</p>}
                              {dialog.fileOptions.adblue !== 0 && <p className="file-option adblue">UREA</p>}
                              {dialog.fileOptions.nsl !== 0 && <p className="file-option nsl">NSL</p>}
                              {dialog.fileOptions.o2 !== 0 && <p className="file-option o2">O2</p>}
                              {dialog.fileOptions.epa !== 0 && <p className="file-option epa">EPA</p>}
                              {dialog.fileOptions.hybridturbo !== 0 && <p className="file-option hybrid-turbo">Hybrid Turbo</p>}
                              {dialog.fileOptions.gearbox !== 0 && <p className="file-option gearbox">Gearbox</p>}
                              {dialog.fileOptions.coldstart !== 0 && <p className="file-option cold-start">Cold Start</p>}
                              {dialog.fileOptions.startstop !== 0 && <p className="file-option start-stop">Start/Stop</p>}
                              {dialog.fileOptions.popsbangs !== 0 && <p className="file-option pops-bangs">Pops & Bangs</p>}
                              {dialog.fileOptions.burbles !== 0 && <p className="file-option burbles">Burbles</p>}
                              {dialog.fileOptions.swirlflaps !== 0 && <p className="file-option swirl-flaps">Swirl Flaps</p>}
                              {dialog.fileOptions.tva !== 0 && <p className="file-option tva">TVA</p>}
                              {dialog.fileOptions.analysis !== 0 && <p className="file-option tva">NS</p>}
                              {dialog.fileOptions.priority_value && <p className="file-option priority">Prioritet: {dialog.fileOptions.priority_value}</p>}
                              </div>
                              {dialog.fileOptions.euro_class && (
                                <p className="euroclass">{dialog.fileOptions.euro_class === 1 ? 'Euro 4/5' : 'Euro 6'}</p>
                              )}
                              <p><strong>Credits to be withdrawn:</strong> {calculateEstimatedCredits(dialog.fileOptions, companyLevel.baseCredits)}</p>
                              <div>
                              <input
                                type="number"
                                value={manualCredits}
                                onChange={(e) => setManualCredits(e.target.value)}
                                placeholder="Credits Override"
                                className="credits-override-input"
                              />
                            </div>
                                  <div className="advanced-options">
                                          <div className="tuning-type-buttons">
                                              <button
                                                type="button"
                                                className={`eco-button ${String(selectedOptions.tuning_type) === '1' ? 'active-option-button' : ''}`}
                                                onClick={() => handleOptionChange('tuning_type', '1')}
                                              >
                                                ECO
                                              </button>
                                              <button
                                                type="button"
                                                className={`opti-button ${String(selectedOptions.tuning_type) === '3' ? 'active-option-button' : ''}`}
                                                onClick={() => handleOptionChange('tuning_type', '3')}
                                              >
                                                OPTI
                                              </button>
                                              <button
                                                type="button"
                                                className={`power-button ${String(selectedOptions.tuning_type) === '4' ? 'active-option-button' : ''}`}
                                                onClick={() => handleOptionChange('tuning_type', '4')}
                                              >
                                                POWER
                                              </button>
                                              <button
                                                type="button"
                                                className={`as-stock-button ${String(selectedOptions.tuning_type) === '5' ? 'active-option-button' : ''}`}
                                                onClick={() => handleOptionChange('tuning_type', '5')}
                                              >
                                                AS STOCK
                                              </button>
                                            </div>
                                    <div className="checkbox-columns-container">
                                        <div className="checkbox-column">
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={selectedOptions.egr || false}
                                              onChange={(e) => handleOptionChange('egr', e.target.checked)}
                                            />
                                            EGR ({isCustomType ? 15 : 3} credits)
                                          </label>
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={selectedOptions.dpf || false}
                                              onChange={(e) => handleOptionChange('dpf', e.target.checked)}
                                            />
                                            DPF ({isCustomType ? 15 : 3} credits)
                                          </label>
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={selectedOptions.adblue || false}
                                              onChange={(e) => handleOptionChange('adblue', e.target.checked)}
                                            />
                                            UREA ({isCustomType ? 20 : 5} credits)
                                          </label>
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={selectedOptions.nsl || false}
                                              onChange={(e) => handleOptionChange('nsl', e.target.checked)}
                                            />
                                            NSL ({isCustomType ? 10 : 2} credits)
                                          </label>
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={selectedOptions.o2 || false}
                                              onChange={(e) => handleOptionChange('o2', e.target.checked)}
                                            />
                                            O2 (2 credits)
                                          </label>
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={selectedOptions.epa || false}
                                              onChange={(e) => handleOptionChange('epa', e.target.checked)}
                                            />
                                            EPA (5 credits)
                                          </label>
                                          <label>
                                            <input
                                              type="checkbox"
                                              checked={selectedOptions.hybridturbo || false}
                                              onChange={(e) => handleOptionChange('hybridturbo', e.target.checked)}
                                            />
                                            Hybrid Turbo (7 credits)
                                          </label>
                                        </div>

                                    <div className="checkbox-column">

                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={selectedOptions.gearbox || false}
                                          onChange={(e) => handleOptionChange('gearbox', e.target.checked)}
                                        />
                                        Gearbox (5 credits)
                                      </label>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={selectedOptions.coldstart || false}
                                          onChange={(e) => handleOptionChange('coldstart', e.target.checked)}
                                        />
                                        Cold Start (2 credits)
                                      </label>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={selectedOptions.startstop || false}
                                          onChange={(e) => handleOptionChange('startstop', e.target.checked)}
                                        />
                                        Start/Stop (2 credits)
                                      </label>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={selectedOptions.popsbangs || false}
                                          onChange={(e) => handleOptionChange('popsbangs', e.target.checked)}
                                        />
                                        Pops & Bangs (5 credits)
                                      </label>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={selectedOptions.burbles || false}
                                          onChange={(e) => handleOptionChange('burbles', e.target.checked)}
                                        />
                                        Burbles (5 credits)
                                      </label>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={selectedOptions.swirlflaps || false}
                                          onChange={(e) => handleOptionChange('swirlflaps', e.target.checked)}
                                        />
                                        Swirl Flaps (2 credits)
                                      </label>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={selectedOptions.tva || false}
                                          onChange={(e) => handleOptionChange('tva', e.target.checked)}
                                        />
                                        TVA (2 credits)
                                      </label>
                                      <label>
                                        <input
                                          type="checkbox"
                                          checked={selectedOptions.analysis || false}
                                          onChange={(e) => handleOptionChange('analysis', e.target.checked)}
                                        />
                                        NS (0 credits)
                                      </label>
                                    </div>
                                  </div>
                                  {dialog.fileOptions.priority_value && (
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={selectedOptions.priority || false}
                                        onChange={(e) => handleOptionChange('priority', e.target.checked)}
                                      />
                                      Prioritet: {dialog.fileOptions.priority_value}
                                    </label>
                                  )}
</div>

                              <div className="text-input-row">
                          <label className="text-input-label-uploadcomment">Dealerkommentar:</label>
                          <textarea className="unique-text-input" value={textInput1} onChange={e => setTextInput1(e.target.value)} />
                        </div>
                        <div className="text-input-row">
                          <label className="text-input-label-uploadcomment">Internkommentar:</label>
                          <textarea className="unique-text-input" value={textInput2} onChange={e => setTextInput2(e.target.value)} />
                        </div>
                        <input type="hidden" value={dialog.id} />
                        <button className="admin-button" type="submit">Upload</button>
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                )}
          </>
        )}

{showCreditChangeModal && (
          <CreditChangeModal
            currentCredits={currentCredits}
            fileOptions={fileOptions}
            onClose={() => setShowCreditChangeModal(false)}
            onSubmit={handleCreditChange}
          />
        )}

          {dialog.fileOptions && (
            <div className="file-options">
              {dialog.fileOptions.tuning_type_value && (
                <p className={`file-option tuning-type ${getTuningTypeClass(dialog.fileOptions.tuning_type_value)}`}>
                  {dialog.fileOptions.tuning_type_value}
                </p>
                    )}
                    {dialog.fileOptions.egr !== 0 && <p className="file-option egr">EGR</p>}
                    {dialog.fileOptions.dpf !== 0 && <p className="file-option dpf">DPF</p>}
                    {dialog.fileOptions.adblue !== 0 && <p className="file-option adblue">UREA</p>}
                    {dialog.fileOptions.nsl !== 0 && <p className="file-option nsl">NSL</p>}
                    {dialog.fileOptions.o2 !== 0 && <p className="file-option o2">O2</p>}
                    {dialog.fileOptions.epa !== 0 && <p className="file-option epa">EPA</p>}
                    {dialog.fileOptions.hybridturbo !== 0 && <p className="file-option hybrid-turbo">Hybrid Turbo</p>}
                    {dialog.fileOptions.gearbox !== 0 && <p className="file-option gearbox">Gearbox</p>}
                    {dialog.fileOptions.coldstart !== 0 && <p className="file-option cold-start">Cold Start</p>}
                    {dialog.fileOptions.startstop !== 0 && <p className="file-option start-stop">Start/Stop</p>}
                    {dialog.fileOptions.popsbangs !== 0 && <p className="file-option pops-bangs">Pops & Bangs</p>}
                    {dialog.fileOptions.burbles !== 0 && <p className="file-option burbles">Burbles</p>}
                    {dialog.fileOptions.swirlflaps !== 0 && <p className="file-option swirl-flaps">Swirl Flaps</p>}
                    {dialog.fileOptions.tva !== 0 && <p className="file-option tva">TVA</p>}
                    {dialog.fileOptions.analysis !== 0 && <p className="file-option tva">Not Stock</p>}
                    {dialog.fileOptions.priority_value && <p className="file-option priority">{dialog.fileOptions.priority_value}</p>}
                  </div>
                )}
          {(isAdmin || isTuner) && modfileMatchesByDialog[dialog.id] && modfileMatchesByDialog[dialog.id].length > 0 && (
    <>
      <button className="toggle-matches-button" onClick={handleToggleMatches}>
        {showMatches ? 'Hide Matching Modfiles' : 'Show Matching Modfiles'}
      </button>
      {showMatches && (
        <div className="matches-container">
          <div className="matches-list">
            {modfileMatchesByDialog[dialog.id].map(id => (
              <div key={id} className="match-box" onClick={() => handleBoxClick(id)}>
                ID: {id}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )}
              {dialog.uploadurl && renderLinks(dialog.uploadurl)}
              {dialog.url && renderLinks(dialog.url)}
              {dialog.rcredit === 1 && (
                <div className="credit-back-request">
                  {(isAdmin || isTuner) && dialog.status === 'pending' && (
                    <div className="admin-actions">
                      <button onClick={() => handleCreditBackAction(dialog.id, 'accepted')}>Accept</button>
                      <button onClick={() => handleCreditBackAction(dialog.id, 'deny')}>Deny</button>
                    </div>
                  )}
                  {dialog.adminComment && (
                    <p className="admin-comment">Admin comment: {dialog.adminComment}</p>
                  )}
                </div>
              )}
              {dialog.message.startsWith("File upload:") && (
              <>
                {dialog.modmessage && (
                  <div className="dialogdownload-buttons">
{dialog.modurl && (
  <button
    onClick={() => {
      handleDownload(dialog.modurl, fileDetails);
      logTheDownload('ECU MOD');
    }}
    className="finishdownload-button">
    ECU MOD
  </button>
)}
{dialog.modgearurl && (
  <button
    onClick={() => {
      handleDownload(dialog.modgearurl, fileDetails);
      logTheDownload('ACM / TCU MOD');
    }}
    className="finishdownload-button">
    ACM / TCU MOD
  </button>
)}
                    {(isAdmin || isTuner) && (
                      <>
{dialog.modmasterurl && (
  <button
    onClick={() => {
      handleDownload(dialog.modmasterurl, fileDetails);
      logTheDownload('ECU MASTER');
    }}
    className="finishdownloadmaster-button">
    ECU MASTER
  </button>
)}
{dialog.modgearmasterurl && (
  <button
    onClick={() => {
      handleDownload(dialog.modgearmasterurl, fileDetails);
      logTheDownload('ACM / TCU MASTER');
    }}
    className="finishdownloadmaster-button">
    ACM / TCU MASTER
  </button>
)}
                      </>
                    )}
                  </div>
                )}
                <>
                  <div className="modurl">ECU MOD: {getFileNameFromURL(dialog.modurl)}</div>
                  <div className="modurl">ACM / TCU MOD: {getFileNameFromURL(dialog.gearurl)}</div>
                  {/* {(isAdmin || isTuner) && (
                    <>
                      <div className="modurl">MasterURLMod(Denne skal fjernes når fil blir renamet riktig): {getFileNameFromURL(dialog.modmasterurl)}</div>
                      <div className="modurl">MasterGEARURLMod(Denne skal fjernes når fil blir renamet riktig): {getFileNameFromURL(dialog.modgearmasterurl)}</div>
                    </>
                  )} */}
                </>
                {(isAdmin || isTuner) && !dialog.modurl && (
                  <div className="dialogdownload-buttons">
                        <button onClick={() => handleUploadModClick(dialog)} className="updownload-button">
                  Upload Modfile
                </button>
                  </div>
                )}
                <div className="modmessage">{dialog.modmessage}</div>

              </>
            )}
          <div className="row-icons">
            {dialog.rredo === 1 && <span className="material-symbols-outlined">change_circle</span>}
            {dialog.uploaded === 1 && <div className="row-uploadedfile">UPLOADED FILE</div>}
          </div>
          {dialog.rredo === 1 && isAdmin && (
            <div className="row-answer">
              <div className="answer-button">Last opp fil, HER SVARER MAN</div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)
))}
            <div ref={messagesEndRef} />
          </div>
            <div className="selected-files">
              {selectedFiles.map((file, index) => (
                <div key={index} className="selected-file">
                  {file.type.startsWith('image/') && (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className="thumbnail-preview"
                      onLoad={(e) => URL.revokeObjectURL(e.target.src)} // Frigjør minnet når bildet er lastet inn
                    />
                  )}
                  <span>{file.name}</span>
                  <button
                    type="button"
                    className="remove-file-button"
                    onClick={() => handleRemoveFile(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
<form
  onSubmit={(e) => {
    e.preventDefault();
    handleUploadSubmit(e); // Håndterer innsending av meldingen
  }}
  className="message-form"
>
  <div className="dropdown-container">
    <button type="button" onClick={toggleDropdown} className="upload-button">+</button>
    {dropdownVisible && (
      <div className="dropdown-menu">
        <button type="button" onClick={() => fileInputRef.current.click()}>
          <img src={filecomupload} alt="Alert" className="filecompng" />Upload a File
        </button>
        {isWithin60Days(fileDetails.created) ? (
          <button type="button" onClick={handleRequestCreditBack}>
            <img src={filecomcredit} alt="Alert" className="filecompng" />Request creditback
          </button>
        ) : (
          <div className="nocredits"> Creditback not eligible</div>
        )}
      </div>
    )}
  </div>
  <input
    type="file"
    ref={fileInputRef}
    onChange={(e) => {
      handleFileChange(e);
      if (textareaRef.current) {
        textareaRef.current.focus(); // Sett fokus til tekstfeltet
      }
    }}
    multiple
    style={{ display: 'none' }}
  />
  <textarea
    ref={textareaRef}
    value={newMessage}
    onChange={(e) => {
      handleNewMessageChange(e);
      handleTextareaInput();
    }}
    onPaste={handlePasteEvent} // Legg til onPaste-håndterer
    placeholder="Message..."
    style={{ resize: 'none', overflow: 'hidden' }}
    onKeyDown={(e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleUploadSubmit(e); // Kall funksjonen for å sende meldingen
      }
    }}
  />

  {/* <button type="submit" className="send-message-button">Add Message</button> */}
  {isAdmin && (
    <label>
      <input
        type="checkbox"
        checked={isInternal}
        onChange={handleInternalChange}
      /> Internal
    </label>
  )}
</form>

          <MasterUploadConfirm
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => handleConfirmUpload(true)} // Bruker ønsker å laste opp masterfil
        onCancel={() => handleConfirmUpload(false)} // Bruker ønsker ikke å laste opp masterfil
      />
        </div>
        <div className="column file-details-column">
          <div className="info-box">
          <FileDetailsID
            fileDetails={fileDetails}
            setFileDetails={setFileDetails}  // Sørg for å sende denne inn som en prop
            isAdmin={isAdmin}
            isTuner={isTuner}
            userProfile={userProfile}
            buttonClass={buttonClass}
            buttonText={buttonText}
            dealerStatusClass={dealerStatusClass}
            dealerStatusText={dealerStatusText}
            transmissionOptions={transmissionOptions}
            fuelOptions={fuelOptions}
            handleWipChange={handleWipChange}  // Pass the function as a prop
            closeModal={handleClose} // Pass the closeModal function
          />

            <SvvDetails dealerCountry={dealerCountry} vehicleData={vehicleData} />
   </div>

              <FileDetailsMatch
              fileDetails={fileDetails}
              isAdmin={isAdmin}
              isTuner={isTuner}
              handleBoxClick={handleBoxClick}
              showModal={showModal}
              selectedFileId={selectedFileId}
              closeModal={closeModal}
              userProfile={userProfile}
              dialogs={dialogs} // Ny prop for dialogs
            />
          <FileDetailsInfo
            fileDetails={fileDetails}
            dealerCredit={dealerCredit}
            tuningToolOptions={tuningToolOptions}
            isAdmin={isAdmin}
            isTuner={isTuner}
            updatePaymentStatus={updatePaymentStatus}
          />
          </div>
        </div>
      </div>
      <CreditBackModal
          isOpen={showCreditBackModal}
          onClose={() => setShowCreditBackModal(false)}
          fileDetails={fileDetails}
          onSubmit={handleCreditBackSubmit}
        />
              {IsSpecialVisible && (
              <div className="special-modal-background">
                <div className="special-modal-content">
                  <div className="modal-header">
                    <button className="close-btn" onClick={CloseSpecialAction}>CLOSE</button>
                  </div>
                  <div className="modal-content-scroll">
                    <div className="options-credits-content">Donelog
                      {isLoading ? (
                        <p>Loading donelogs...</p>
                      ) : (
                        doneLogs.length > 0 ? (
                          <table className="logs-table">
                            <thead>
                              <tr>
                                <th>User</th>
                                <th>Time</th></tr>
                            </thead>
                            <tbody>
                              {doneLogs.map(log => (
                                <tr key={log.id}>
                                  <td>{log.user}</td>
                                  <td>{new Date(log.time).toLocaleString('no-NO')}</td></tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p>No donelogs available for this file.</p>
                        )
                      )}
                    </div>
                    <div className="options-credits-content">Otherlog
                    </div>
                  </div>
                </div>
              </div>
            )}
              {IsViewOptionAndCreditsVisible && (
                <div className="credits-modal-background">
                  <div className="credits-modal-content">
                    <div className="modal-header">
                      <button className="close-btn" onClick={CloseViewOptionAndCredits}>CLOSE</button>
                    </div>
                    <div className="options-credits-content">
                      <form onSubmit={handleOptionsAndCreditsSubmit}>
                        <div>
                          <h3>Current Credits Withdrawn: {fileDetails.charged}</h3>
                        </div>
                        <div>
                          <label>Extra Credits to Withdraw:</label>
                          <input
                            type="number"
                            value={manualCredits}
                            onChange={(e) => setManualCredits(e.target.value)}
                            placeholder="Enter additional credits"
                          />
                        </div>
                        <div>
                          <button type="submit" className="admin-button">Save Changes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              {isLogPopupVisible && (
                <div className="logs-modal-background">
                  <div className="logs-modal-content">
                    <div className="modal-header">
                      <button className="close-btn" onClick={CloseViewLogs}>CLOSE</button>
                    </div>
                    <div className="logs-content">
                      {logs.length > 0 ? (
                        <ul>
                          {logs.map((log, index) => (
                            <li key={index}>{log.tuner} {log.action} {log.date}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>No logs found.</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
    </div>

);
}

const CreditBackModal = ({ isOpen, onClose, fileDetails, onSubmit }) => {
  const [logFile, setLogFile] = useState(null);
  const [comment, setComment] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!logFile || !comment.trim()) {
      alert('Please upload a log file and add a comment.');
      return;
    }
    onSubmit(logFile, comment);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-background">
      <div className="creditback-modal">
        <h2>Request Credit Back</h2>
        <div className="creditback-text"> You can only apply for refund of credits if the vehicle is returned to its original condition within 30 days.
        A copy of the log from the tools must be sent attached, as well as the reason why the credit is wanted refunded.</div>
        <p>Credits withdrawn for this upload: {fileDetails.charged}</p>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Upload Log File:</label>
            <input
              type="file"
              onChange={(e) => setLogFile(e.target.files[0])}
              required
            />
          </div>
          <div>
            <label>Comment:</label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="confirm-button">Submit Request</button>
          <button type="button" onClick={onClose} className="decline-button">Cancel</button>
        </form>
      </div>
    </div>
  );
};

function CreditChangeModal({ currentCredits, fileOptions, onClose, onSubmit }) {
  const [newCredits, setNewCredits] = useState(currentCredits);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(newCredits);
  };

  return (
    <div className="modal-background">
      <div className="credit-change-modal">
        <h2>Change Credits</h2>
        <p>Current Credits: {currentCredits}</p>
        <form onSubmit={handleSubmit}>
          <label>
            New Credits:
            <input
              type="number"
              value={newCredits}
              onChange={(e) => setNewCredits(Number(e.target.value))}
              min="0"
            />
          </label>
          <div className="file-options">
            <h3>File Options:</h3>
            {fileOptions && Object.entries(fileOptions).map(([key, value]) => (
              <div key={key}>
                <strong>{key}:</strong> {value.toString()}
              </div>
            ))}
          </div>
          <button type="submit">Submit</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default FileDetails;