import React, { useState, useEffect } from 'react';
import './ViewLogsModal.css'; // Opprett gjerne en egen CSS

function ViewLogsModal({ onClose }) {
  const [logNew, setLogNew] = useState([]);
  const [logCreated, setLogCreated] = useState([]);

  useEffect(() => {
    // Hent v_model_log_new
    fetch(process.env.REACT_APP_SITE_URL + '/logs/new')
      .then(res => res.json())
      .then(data => setLogNew(data))
      .catch(error => console.error('Error fetching v_model_log_new:', error));

    // Hent v_model_log_created
    fetch(process.env.REACT_APP_SITE_URL + '/logs/created')
      .then(res => res.json())
      .then(data => setLogCreated(data))
      .catch(error => console.error('Error fetching v_model_log_created:', error));
  }, []);

  return (
    <div className="viewlogs-modal-background" onClick={onClose}>
      <div className="viewlogs-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">

          <button className="close-btn" onClick={onClose}>
            X
          </button>
          
        </div>
        <h2>Vehicle Model Logs</h2>
        <div className="logs-container">
          {/* Venstre kolonne: v_model_log_new */}
          <div className="log-column">
            <h3>Added Other Vehicle</h3>
            {logNew.map((entry) => (
              <div key={entry.id} className="log-entry">
  <span>
    <strong>User:</strong> {entry.user} (ID: {entry.userid}) | 
    <strong> Content:</strong> {entry.content} | 
    <strong> Created:</strong> {new Date(entry.created_at).toLocaleString()}
  </span>
</div>

            ))}
          </div>

          {/* Høyre kolonne: v_model_log_created */}
          <div className="log-column">
  <h3 style={{ color: '#ccc', marginBottom: '10px' }}>Created new model</h3>
  {logCreated.map((entry) => (
    <div key={entry.id} className="log-entry">
      <span>
        <strong>User:</strong> {entry.user} (ID: {entry.userid}) |{" "}
        <strong>Content:</strong> {entry.content} |{" "}
        <strong>Created:</strong> {new Date(entry.created_at).toLocaleString()}
      </span>
    </div>
  ))}
</div>

        </div>
      </div>
    </div>
  );
}

export default ViewLogsModal;
