import React, { useEffect, useState } from 'react';
import './Finances.css';

const FinancesOnline = () => {
  const [yearlyStats, setYearlyStats] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchYearlyStats = async () => {
      try {
        // Husk: endre path hvis du har satt opp endepunktet annerledes
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/yearly-stats`);
        if (!response.ok) {
          throw new Error(`Failed to fetch yearly stats: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setYearlyStats(data);
      } catch (error) {
        console.error('Error fetching yearly stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchYearlyStats();
  }, []);

  if (loading) {
    return <p>Laster data ...</p>;
  }

  if (!yearlyStats || yearlyStats.length === 0) {
    return <p>Ingen statistikk tilgjengelig.</p>;
  }

  return (
    <div className="finances-container">
      <h2>Antall filer per år med charged over 5</h2>
      <ul>
        {yearlyStats.map((stat) => (
          <li key={stat.year}>
            {stat.year}: {stat.total_files}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FinancesOnline;
