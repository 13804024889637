import React, { useState, useEffect } from 'react';

export default function ManageUsers({ allMembers, username, userProfile }) {
    const [users, setUsers] = useState([]);
    const [editUserId, setEditUserId] = useState(null);
    const [editField, setEditField] = useState('');
    const [editValue, setEditValue] = useState('');
    const [olevelexp, setOlevelexp] = useState(''); 
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [highlightedRow, setHighlightedRow] = useState(null);
    const [highlightedCell, setHighlightedCell] = useState(null);
    const [didDropdownVisible, setDidDropdownVisible] = useState(null);

    const hasAccess = [3, 37, 10].includes(userProfile?.id);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/users');
            const data = await response.json();
            const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
            setUsers(sortedData);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleEditClick = (userId, field, value) => {
        setEditUserId(userId);
        setEditField(field);
        setEditValue(value != null ? value.toString() : '');
        setShowEditPopup(true);

        // Only reset the oLevel expiration if we are editing the olevel field
        if (field === 'olevel') {
            setOlevelexp('');
        }
    };

    const handleSaveEdit = async (newValue) => {
        const user = users.find(u => u.id === editUserId);
        if (!user) return;

        let formattedValue;
        if (editField === 'credit') {
            const oldCredit = user.credit;
            const newCredit = parseInt(newValue);
            formattedValue = newCredit;

            if (!isNaN(newCredit) && newCredit !== oldCredit) {
                // Log credit update
                await logCreditUpdate(user.id, oldCredit, newCredit, getDealerName(user.did), user.name);
            }
        } else if (editField === 'olevel') {
            // For olevel, we just keep the string/newValue
            formattedValue = newValue;
        } else if (['admin', 'tuner', 'opti', 'wl', 'approved', 'support', 'franchisecredits', 'subcredits'].includes(editField)) {
            // Convert to 1 (YES) or 0 (NO)
            formattedValue = newValue === '1' || newValue === 'YES' ? 1 : 0;
        } else {
            // For all other fields (text fields)
            formattedValue = newValue;
        }

        setEditValue(formattedValue);
        setShowConfirmationPopup(true);
        setShowEditPopup(false);
    };

    const confirmSaveEdit = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/updateUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: editUserId,
                    field: editField,
                    value: editValue,
                    // Only send olevelexp if we are editing olevel
                    olevelexp: editField === 'olevel' ? olevelexp : null
                }),
            });

            if (response.ok) {
                const updatedUsers = users.map(user =>
                    user.id === editUserId
                        ? { ...user, [editField]: editValue, ...(editField === 'olevel' ? { olevelexp } : {}) }
                        : user
                );
                setUsers(updatedUsers);
                setEditUserId(null);
                setEditField('');
                setEditValue('');
                setOlevelexp('');
                setShowConfirmationPopup(false);
            } else {
                console.error('Failed to update user');
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const logCreditUpdate = async (mid, oldCredit, newCredit, dealerName, userdid) => {
        const now = new Date();
        const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');

        const op_text = `${username} forceupdated credits for ${userdid} from ${oldCredit} to ${newCredit} credits`;
        const payload = {
            d_mid: mid,
            op_text,
            created: formattedDate,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/logCreditWithdrawal`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Failed to log credit update');
            }
        } catch (error) {
            console.error('Error logging credit update:', error);
        }
    };

    const handleDidChange = async (userId, newDid) => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/updateUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: userId, field: 'did', value: newDid }),
            });

            if (response.ok) {
                setUsers(users.map(user => user.id === userId ? { ...user, did: newDid } : user));
                setDidDropdownVisible(null);
            } else {
                console.error('Failed to update DID');
            }
        } catch (error) {
            console.error('Error updating DID:', error);
        }
    };

    const getDealerName = (did) => {
        const dealer = allMembers.find(member => member.id === did);
        return dealer ? dealer.name : did;
    };

    const renderUserRow = (user) => {
        const isManageUsers = !(user.opti === 1 || user.wl === 1);
        const isOptiWlDealers = user.opti === 1 || user.wl === 1;
        const formattedOlevelexp = user.olevelexp
            ? new Date(user.olevelexp).toLocaleDateString('en-GB')
            : 'N/A';

        return (
            <tr
                key={user.id}
                onMouseEnter={() => setHighlightedRow(user.id)}
                onMouseLeave={() => setHighlightedRow(null)}
                className={highlightedRow === user.id ? 'highlight' : ''}
            >
                <td>{user.id}</td>
                {isManageUsers && (
                    <>
                        <td>
                            <div
                                onClick={() =>
                                    setDidDropdownVisible(user.id === didDropdownVisible ? false : user.id)
                                }
                            >
                                {getDealerName(user.did)}
                            </div>
                            {didDropdownVisible === user.id && (
                                <select
                                    onChange={(e) => handleDidChange(user.id, e.target.value)}
                                    value={user.did}
                                >
                                    {allMembers.map(member => (
                                        <option key={member.id} value={member.id}>
                                            {member.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </td>
                        <td onClick={() => handleEditClick(user.id, 'name', user.name)}>{user.name}</td>
                        <td onClick={() => handleEditClick(user.id, 'email', user.email)}>{user.email}</td>
                        <td onClick={() => handleEditClick(user.id, 'phone', user.phone)}>{user.phone}</td>
                        <td onClick={() => handleEditClick(user.id, 'country', user.country)}>{user.country}</td>
                        {['admin', 'tuner', 'opti', 'wl', 'approved', 'support'].map(field => (
                            <td
                                key={field}
                                onClick={() => handleEditClick(user.id, field, user[field])}
                                onMouseEnter={() => setHighlightedCell(`${user.id}-${field}`)}
                                onMouseLeave={() => setHighlightedCell(null)}
                                className={`${highlightedCell === `${user.id}-${field}` ? 'highlight-cell' : ''} ${
                                    user[field] ? 'yes-cell' : 'no-cell'
                                }`}
                            >
                                {user[field] ? 'YES' : 'NO'}
                            </td>
                        ))}
                    </>
                )}
                {isOptiWlDealers && (
                    <>
                        <td onClick={() => handleEditClick(user.id, 'name', user.name)}>{user.name}</td>
                        <td onClick={() => handleEditClick(user.id, 'legal', user.legal)}>{user.legal}</td>
                        <td onClick={() => handleEditClick(user.id, 'vat', user.vat)}>{user.vat}</td>
                        <td onClick={() => handleEditClick(user.id, 'address', user.address)}>{user.address}</td>
                        <td onClick={() => handleEditClick(user.id, 'city', user.city)}>{user.city}</td>
                        <td onClick={() => handleEditClick(user.id, 'postal', user.postal)}>{user.postal}</td>
                        {['opti', 'wl', 'franchisecredits', 'subcredits'].map(field => (
                            <td
                                key={field}
                                onClick={() => handleEditClick(user.id, field, user[field])}
                                onMouseEnter={() => setHighlightedCell(`${user.id}-${field}`)}
                                onMouseLeave={() => setHighlightedCell(null)}
                                className={`${highlightedCell === `${user.id}-${field}` ? 'highlight-cell' : ''} ${
                                    user[field] ? 'yes-cell' : 'no-cell'
                                }`}
                            >
                                {user[field] ? 'YES' : 'NO'}
                            </td>
                        ))}
                        <td
                            onClick={() => handleEditClick(user.id, 'credit', user.credit)}
                            onMouseEnter={() => setHighlightedCell(`${user.id}-credit`)}
                            onMouseLeave={() => setHighlightedCell(null)}
                            className={highlightedCell === `${user.id}-credit` ? 'highlight-cell' : ''}
                            style={{ cursor: 'pointer' }}
                        >
                            {user.credit}
                        </td>
                        <td onClick={() => handleEditClick(user.id, 'olevel', user.olevel)}>{user.olevel}</td>
                        <td>{formattedOlevelexp}</td>
                    </>
                )}
            </tr>
        );
    };

    const optiWlUsers = users.filter(user => user.opti === 1 || user.wl === 1);
    const otherUsers = users.filter(user => user.opti !== 1 && user.wl !== 1);

    return (
        <div>
            <div>
                <h3>Manage Users</h3>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Dealer</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Country</th>
                            <th>Admin</th>
                            <th>Tuner</th>
                            <th>Opti</th>
                            <th>WL</th>
                            <th>APRVD</th>
                            <th>Support</th>
                        </tr>
                    </thead>
                    <tbody>
                        {otherUsers.map(renderUserRow)}
                    </tbody>
                </table>
            </div>

            <div style={{ height: '20px' }}></div>

            <div>
                <h3>Manage Optituning / Whitelist Dealers</h3>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Legal</th>
                            <th>VAT</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>Postal</th>
                            <th>Opti</th>
                            <th>WL</th>
                            <th>Franchise Credits</th>
                            <th>Sub Credits</th>
                            <th>Credits</th>
                            <th>Level</th>
                            <th>Exp. Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {optiWlUsers.map(renderUserRow)}
                    </tbody>
                </table>
            </div>

            {showEditPopup && (
                <div className="modal-background">
                    <div className="edit-modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <button className="close-btn" onClick={() => setShowEditPopup(false)}>
                                CLOSE
                            </button>
                        </div>
                        <h3>Edit {editField.charAt(0).toUpperCase() + editField.slice(1)}</h3>

                        {editField === 'credit' && (
                            <>
                                <p>Current Credit: {users.find(u => u.id === editUserId)?.credit}</p>
                                <input
                                    type="number"
                                    value={editValue}
                                    onChange={(e) => setEditValue(e.target.value)}
                                />
                                <button className="admin-button" onClick={() => handleSaveEdit(editValue)}>
                                    SAVE
                                </button>
                            </>
                        )}

                        {editField !== 'credit' &&
                            ![
                                'admin',
                                'tuner',
                                'opti',
                                'wl',
                                'approved',
                                'support',
                                'franchisecredits',
                                'subcredits',
                                'olevel',
                            ].includes(editField) && (
                                <>
                                    <input
                                        type="text"
                                        value={editValue}
                                        onChange={(e) => setEditValue(e.target.value)}
                                    />
                                    {hasAccess && (
                                        <button
                                            className="admin-button"
                                            onClick={() => handleSaveEdit(editValue)}
                                        >
                                            SAVE
                                        </button>
                                    )}
                                </>
                            )}

                        {['admin','tuner','opti','wl','approved','support','franchisecredits','subcredits'].includes(editField) && (
                            <>
                                <p>Set {editField.charAt(0).toUpperCase() + editField.slice(1)} to:</p>
                                <div>
                                    <button
                                        className="admin-button"
                                        onClick={() => handleSaveEdit('1')}
                                        style={{ backgroundColor: 'green', color: 'white', margin: '0 5px' }}
                                    >
                                        YES
                                    </button>
                                    <button
                                        className="tos-button"
                                        onClick={() => handleSaveEdit('0')}
                                        style={{ backgroundColor: 'red', color: 'white', margin: '0 5px' }}
                                    >
                                        NO
                                    </button>
                                </div>
                            </>
                        )}

                        {editField === 'olevel' && (
                            <>
                                <p style={{ color: 'blue' }}>
                                    Changing the olevel may impact the user's base credits. Please ensure the
                                    level corresponds to the correct base credit assignment.
                                </p>
                                {/* Olevel input */}
                                <label>Olevel:</label>
                                <input
                                    type="text"
                                    value={editValue}
                                    onChange={(e) => setEditValue(e.target.value)}
                                />
                                <br />
                                {/* Expire date input */}
                                <label>Expire Date:</label>
                                <input
                                    type="date"
                                    value={olevelexp}
                                    onChange={(e) => {
                                        setOlevelexp(e.target.value);
                                    }}
                                />
                                <br />
                                <button className="admin-button" onClick={() => handleSaveEdit(editValue)}>
                                    SAVE
                                </button>
                            </>
                        )}
                    </div>
                </div>
            )}

            {showConfirmationPopup && (
                <div className="modal-background">
                    <div className="edit-modal-content" onClick={(e) => e.stopPropagation()}>
                        <h3>Confirm Changes</h3>
                        <p>
                            Are you sure you want to change {editField} to {editValue}?
                        </p>
                        <button className="admin-button" onClick={confirmSaveEdit}>
                            CONFIRM
                        </button>
                        <button className="tos-button" onClick={() => setShowConfirmationPopup(false)}>
                            CANCEL
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
