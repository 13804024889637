import React from 'react';

const StatsSection = React.memo(({ userStats }) => {
  const statBoxes = [
    { label: 'Today', value: userStats.todayFiles },
    { label: 'Week', value: userStats.weeklyFiles },
    { label: 'Month', value: userStats.monthlyFiles },
    { label: 'Year', value: userStats.yearlyFiles },
    // { label: 'Ever', value: userStats.totalFiles }
  ];

  return (
    <div className="stats-section">
      <div className="box-container">
        {statBoxes.map(({ label, value }) => (
          <div key={label} className="box">
            {label}: {value}
          </div>
        ))}
      </div>
    </div>
  );
});

export default StatsSection;