import React, { useState, useEffect } from 'react';

export default function Credits() {
    const [recurringCredits, setRecurringCredits] = useState([]);
    const [completedCredits, setCompletedCredits] = useState([]);
    const [members, setMembers] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [selectedMember, setSelectedMember] = useState('');
    const [owedCredits, setOwedCredits] = useState('');
    const [drawAmount, setDrawAmount] = useState(1); // Default value is 1
    const [description, setDescription] = useState('');


    // useEffect(() => {
    //     // Kall API-ruten når siden lastes
    //     const triggerRecurringCredits = async () => {
    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_SITE_URL}/apply-recurring-royalties-and-subscription`, {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                 },
    //             });

    //             if (response.ok) {
    //                 const data = await response.json();
    //                 console.log('Recurring credits applied:', data);
    //             } else {
    //                 console.error('Failed to apply recurring credits');
    //             }
    //         } catch (error) {
    //             console.error('Error triggering recurring credits:', error);
    //         }
    //     };

    //     triggerRecurringCredits();
    // }, []); // Tom array sørger for at dette bare kjører én gang ved reload


    useEffect(() => {
        const fetchRecurringCredits = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/recurring-credits`);
                const data = await response.json();
                setRecurringCredits(data);
            } catch (error) {
                console.error('Error fetching recurring credits:', error);
            }
        };

        const fetchCompletedCredits = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/completed-recurring-credits`);
                const data = await response.json();
                setCompletedCredits(data);
            } catch (error) {
                console.error('Error fetching completed credits:', error);
            }
        };

        const fetchMembers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/members/opti`);
                const data = await response.json();
                setMembers(data);
            } catch (error) {
                console.error('Error fetching members:', error);
            }
        };

        fetchRecurringCredits();
        fetchCompletedCredits();
        fetchMembers();
    }, []);

    const handleAddCredit = async () => {
        if (!selectedMember || !owedCredits) {
            alert('Please select a member and enter owed credits.');
            return;
        }

        const newCredit = {
            memberid: selectedMember,
            owedcredits: parseInt(owedCredits, 10),
            drawamount: parseInt(drawAmount, 10), // Inkluder drawamount
            description,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/recurring-credits`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newCredit),
            });

            if (response.ok) {
                const addedCredit = await response.json();
                setRecurringCredits(prev => [...prev, addedCredit]);
                setSelectedMember('');
                setOwedCredits('');
                setDrawAmount(1); // Tilbakestill til standardverdi
                setDescription('');
                setShowAddForm(false);
            } else {
                console.error('Failed to add recurring credit.');
            }
        } catch (error) {
            console.error('Error adding recurring credit:', error);
        }
    };

    return (
        <div>
            <h3>Recurring Credits</h3>
            <button onClick={() => setShowAddForm(!showAddForm)}>
                {showAddForm ? 'Cancel' : 'Add Recurring Credit'}
            </button>
            {showAddForm && (
                <div>
                    <h4>Add Recurring Credit</h4>
                    <div>
                        <label>
                            Select Member:
                            <select
                                value={selectedMember}
                                onChange={(e) => setSelectedMember(e.target.value)}
                            >
                                <option value="">-- Select a Member --</option>
                                {members.map(member => (
                                    <option key={member.id} value={member.id}>
                                        {member.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div>
                        <label>
                            Owed Credits:
                            <input
                                type="number"
                                value={owedCredits}
                                onChange={(e) => setOwedCredits(e.target.value)}
                            />
                        </label>
                    </div>
                    <div>
                        <label>
                            Draw Amount:
                            <select
                                value={drawAmount}
                                onChange={(e) => setDrawAmount(e.target.value)}
                            >
                                {[1, 2, 3, 4, 5].map(amount => (
                                    <option key={amount} value={amount}>
                                        {amount}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                    <div>
                        <label>
                            Description:
                            <input
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </label>
                    </div>
                    <button onClick={handleAddCredit}>Submit</button>
                </div>
            )}
            <h4>Active Recurring Credits</h4>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Member Name</th>
                        <th>Owed Credits</th>
                        <th>Draw Amount</th>
                        <th>Owed Credits Start</th>
                        <th>Description</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                    </tr>
                </thead>
                <tbody>
                    {recurringCredits.map(credit => (
                        <tr key={credit.recurring_id}>
                            <td>{credit.recurring_id}</td>
                            <td>{credit.member_name}</td>
                            <td>{credit.owedcredits}</td>
                            <td>{credit.drawamount}</td>
                            <td>{credit.owedcreditsstart}</td>
                            <td>{credit.description}</td>
                            <td>{new Date(credit.created_at).toLocaleString()}</td>
                            <td>{new Date(credit.updated_at).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h4>Completed Recurring Credits</h4>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Member Name</th>
                        <th>Owed Credits</th>
                        <th>Owed Credits Start</th>
                        <th>Description</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                    </tr>
                </thead>
                <tbody>
                    {completedCredits.map(credit => (
                        <tr key={credit.recurring_id}>
                            <td>{credit.recurring_id}</td>
                            <td>{credit.member_name}</td>
                            <td>{credit.owedcredits}</td>
                            <td>{credit.owedcreditsstart}</td>
                            <td>{credit.description}</td>
                            <td>{new Date(credit.created_at).toLocaleString()}</td>
                            <td>{new Date(credit.updated_at).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
