// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vehicle-section-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.add-vehicle-section,
.change-vehicle-section {
  flex: 1 1;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
}

.add-vehicle-section h4,
.change-vehicle-section h4 {
  text-align: center;
  margin-bottom: 15px;
}

form label {
  display: block;
  margin-bottom: 10px;
}



.editveh-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Higher z-index to ensure it appears above other content */
}

.editveh-content {
  background-color: #1f1f1f;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  z-index: 2001; /* One higher than the background */
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}


.filedetail-image {
  max-width: 65px;
  max-height: 65px;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/FileDetails.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;;AAEA;;EAEE,SAAO;EACP,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB;;AAEA;;EAEE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;;;AAIA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,4DAA4D;AAC7E;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa,EAAE,mCAAmC;EAClD,YAAY;EACZ,wCAAwC;AAC1C;;;AAGA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".vehicle-section-container {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.add-vehicle-section,\n.change-vehicle-section {\n  flex: 1;\n  border: 1px solid #ccc;\n  padding: 20px;\n  border-radius: 8px;\n}\n\n.add-vehicle-section h4,\n.change-vehicle-section h4 {\n  text-align: center;\n  margin-bottom: 15px;\n}\n\nform label {\n  display: block;\n  margin-bottom: 10px;\n}\n\n\n\n.editveh-modal-background {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.7);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 2000; /* Higher z-index to ensure it appears above other content */\n}\n\n.editveh-content {\n  background-color: #1f1f1f;\n  padding: 20px;\n  border-radius: 8px;\n  width: 80%;\n  max-width: 800px;\n  max-height: 90vh;\n  overflow-y: auto;\n  position: relative;\n  z-index: 2001; /* One higher than the background */\n  color: white;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);\n}\n\n\n.filedetail-image {\n  max-width: 65px;\n  max-height: 65px;\n  height: auto;\n  object-fit: cover;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
