import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useAuth } from '../auth/AuthProvider';
import alert from '../assets/alert.gif';
import { UserContext } from '../context/UserContext';

import ViewRow from './ViewRow';
import WarningSection from './WarningSection';
import StatsSection from './StatsSection';


export default function FilePortal() {
  const [vehicleData, setVehicleData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const { authToken } = useAuth();
  const [selectedDealer, setSelectedDealer] = useState('');
  const [userStats, setUserStats] = useState({
    todayFiles: 0,
    weeklyFiles: 0,
    monthlyFiles: 0,
    yearlyFiles: 0
  });
  const [dealers, setDealers] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [taskCountToday, setTaskCountToday] = useState(0);
  const [latestUploads, setLatestUploads] = useState([]);
  const [showDropdown, setShowDropdown] = useState({});
  const [initialWarnings, setInitialWarnings] = useState([]);
  const { userProfile, setUserProfile } = useContext(UserContext);
  const isAdmin = userProfile.admin === 1;
  const isTuner = userProfile.tuner === 1;
  const isSupport = userProfile.support === 1;
  const [wipOnly, setWipOnly] = useState(false);
  const [showChat, setShowChat] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showOohModal, setShowOohModal] = useState(false);
  const [showTosModal, setShowTosModal] = useState(false);

  const [overrideDates, setOverrideDates] = useState([]);
  const [isAutoRefreshOn, setIsAutoRefreshOn] = useState(false);


  const fetchPendingCount = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ooh/pending-count`);
        const data = await response.json();
        setPendingCount(data.pendingCount || 0);
    } catch (error) {
        console.error('Error fetching pending requests count:', error);
    }
};

useEffect(() => {
  if (isAdmin) {
      fetchPendingCount();
  }
}, [isAdmin]);

  const fetchVehicleData = useCallback(async () => {
    let url = `${process.env.REACT_APP_SITE_URL}/uploads?page=${page}&limit=70&searchText=${encodeURIComponent(searchText)}&wipOnly=${wipOnly}`;
    if (!(isAdmin || isTuner) && userProfile.pid) {
      url += `&pid=${userProfile.pid}`;
    }
    if (isSupport) {
      url += `&support=true`;
    }
    if (selectedDealer) {
      url += `&dealerId=${selectedDealer}`;
    }

    try {
      const response = await fetch(url, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const newData = await response.json();
      if (response.ok) {
        setVehicleData(prev => page === 1 ? newData : [...prev, ...newData]);
      } else {
        console.error("Failed to fetch vehicle data:", newData.message);
      }
    } catch (error) {
      console.error("Error fetching vehicle data:", error);
    }
  }, [page, searchText, wipOnly, authToken, isAdmin, isTuner, isSupport, userProfile.pid, selectedDealer]);

  useEffect(() => {
    fetchVehicleData();
  }, [fetchVehicleData]);



  useEffect(() => {
    const fetchTaskCountToday = async () => {
      console.log('Starting fetchTaskCountToday');
      try {
        console.log('Fetching task count from:', process.env.REACT_APP_SITE_URL + '/tasks/count-today');
        const response = await fetch(process.env.REACT_APP_SITE_URL + '/tasks/count-today', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });

        console.log('Received response:', response);
        const data = await response.json();

        console.log('Parsed response data:', data);
        if (response.ok) {
          console.log('Response is OK. Setting task count to:', data.count);
          setTaskCountToday(data.count);
        } else {
          console.error('Failed to fetch task count. Server responded with:', data.message);
        }
      } catch (error) {
        console.error('Error fetching task count:', error);
      }
    };

    console.log('useEffect called. authToken:', authToken);
    fetchTaskCountToday();
  }, [authToken]);



  const fetchInitialData = useCallback(async () => {

    const fetchDealers = fetch(`${process.env.REACT_APP_SITE_URL}/dealers`).then(res => res.json());

    const fetchOverrideDates = fetch(`${process.env.REACT_APP_SITE_URL}/override-dates`, {
      headers: { Authorization: `Bearer ${authToken}` },
    }).then(res => res.json());
    const fetchLatestUploads = fetch(`${process.env.REACT_APP_SITE_URL}/uploads/latest`, {
      headers: { Authorization: `Bearer ${authToken}` },
    }).then(res => res.json());

    const [dealersData, datesData, uploadsData] = await Promise.all([
      fetchDealers, fetchOverrideDates, fetchLatestUploads
    ]);


    setDealers(dealersData);
    setOverrideDates(datesData.map(date => new Date(date).toISOString().split('T')[0]));
    setLatestUploads(uploadsData);
  }, [authToken]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const fetchUserProfile = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.ok) {
        const data = await response.json();
        setUserProfile(data);
      } else {
        throw new Error('Failed to fetch user profile');
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  }, [authToken, setUserProfile]);

  useEffect(() => {
    if (authToken) {
      fetchUserProfile();
    }
  }, [authToken, fetchUserProfile]);

  const fetchStats = useCallback(async () => {
    const url = isAdmin || isTuner
      ? `${process.env.REACT_APP_SITE_URL}/uploads/stats`
      : `${process.env.REACT_APP_SITE_URL}/uploads/stats/${userProfile.did}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        setUserStats(data);
      } else {
        console.error('Failed to fetch stats:', data.message);
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  }, [isAdmin, isTuner, userProfile.did]);

  useEffect(() => {
    if (userProfile.id) {
      fetchStats();
    }
  }, [userProfile.id, fetchStats]);

//  // Automatisk oppdatering hvert 20. sekund
//  useEffect(() => {
//   fetchVehicleData(); // Initial kall

//   const interval = setInterval(() => {
//     fetchVehicleData();
//   }, 10000);

//   return () => clearInterval(interval); // Rydder opp intervallet ved avmontering
// }, [fetchVehicleData]);

useEffect(() => {
  const interval = setInterval(() => {
    window.location.reload();
  }, 30000); // 30 sec

  return () => clearInterval(interval);
}, []);

  const handleUpdateStatus = useCallback(async (id, newStatus, isAdmin, tuner) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id, status: newStatus, isAdmin }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      if (newStatus === 2) {
        logDoneAction(id, userProfile.name);  // Pass userProfile.name
      }

      fetchVehicleData();
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  }, [authToken, fetchVehicleData]);

  const logDoneAction = async (id, username) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/log-done-action`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`, // Use authToken here for consistency
        },
        body: JSON.stringify({
          fileid: id,      // Corresponds to the vehicle id
          user: username   // Send userProfile.name as the user
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to log the done action');
      }

      // console.log('Action logged successfully!');
    } catch (error) {
      console.error('Error logging the done action:', error);
    }
  };

  const handleDelete = useCallback(async (fileId) => {
    if (!isAdmin) {
      console.error("Unauthorized: Only admins can delete files.");
      return;
    }

    // Confirmation dialog
    const confirmDelete = window.confirm(`Er du sikker på at du vil slette filen med ID: ${fileId}?`);
    if (!confirmDelete) {
      console.log("Sletting av fil avbrutt.");
      return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/deleteFile`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({ id: fileId }),
        });

        if (response.ok) {
            console.log('File deleted successfully!');
            logDeleteAction(fileId, userProfile.name); // Log deletion action with userProfile.name
            fetchVehicleData(); // Refresh the vehicle data after deletion
        } else {
            console.error('Failed to delete file');
        }
    } catch (error) {
        console.error('Error deleting file:', error);
    }
}, [authToken, fetchVehicleData, isAdmin, userProfile.name]);





  const logDeleteAction = async (fileId, username) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/log-delete-action`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
                fileId: fileId,      // Corresponds to the vehicle id
                user: username       // Send userProfile.name as the user
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to log the delete action');
        }

        console.log('Delete action logged successfully!');
    } catch (error) {
        console.error('Error logging the delete action:', error);
    }
};



  const toggleDropdown = useCallback((index) => {
    setShowDropdown(prevState => {
      if (prevState[index]) {
        return {};
      }
      return { [index]: true };
    });
  }, []);

  const handleAction = useCallback(async (index, action) => {
    let newStatus;
    switch (action) {
      case 'Sent':
        newStatus = 0;
        break;
      case 'In progress':
        newStatus = 1;
        break;
      case 'Ready':
        newStatus = 2;
        break;
      case 'Hold':
        newStatus = 3;
        break;
      case 'Support':
        newStatus = 4;
        break;
      case 'Delete':
        await handleDelete(vehicleData[index].id); // Call handleDelete for 'Delete' action
        return;
      case 'Credback':
        // Implement creditback logic here
        return;
      default:
        return;
    }

    await handleUpdateStatus(vehicleData[index].id, newStatus, isAdmin);
    setShowDropdown({});
  }, [vehicleData, handleUpdateStatus, isAdmin, handleDelete]);

  const sortedVehicleData = useMemo(() => {
    return [...vehicleData].sort((a, b) => {
      const alertA = (isAdmin && a.tunercheck === 1) || (!isAdmin && a.dealercheck === 1);
      const alertB = (isAdmin && b.tunercheck === 1) || (!isAdmin && b.dealercheck === 1);
      if (a.status !== 2 && b.status === 2) return -1;
      if (a.status === 2 && b.status !== 2) return 1;
      if (alertA && !alertB) return -1;
      if (!alertA && alertB) return 1;
      return b.id - a.id;
    });
  }, [vehicleData, isAdmin]);

// Vi bruker sortedVehicleData for å filtrere etter prioritetslogikk
const priorityList = sortedVehicleData.filter(vehicle => vehicle.status !== 2 ||
  (isAdmin && vehicle.tunercheck === 1) || (!isAdmin && vehicle.dealercheck === 1));

const otherList = sortedVehicleData.filter(vehicle => !priorityList.includes(vehicle));


  const handleRowClick = useCallback((fileId) => {
    setSelectedFileId(fileId);
    setShowModal(true);
  }, []);

  useEffect(() => {
    const fetchInitialWarnings = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/warnings`);
        const data = await response.json();
        setInitialWarnings(data);
      } catch (error) {
        console.error('Error fetching initial warnings:', error);
      }
    };
    fetchInitialWarnings();
  }, []);

  return (
    <div className="container">
      {/* {(isAdmin || isTuner) && ( */}
      <span style={{
        color: 'white',
        backgroundColor: 'black', // Lys rød bakgrunn
        padding: '5px 5px', // Litt avstand inni elementet
        fontWeight: 'bold', // Fet tekst
        display: 'inline-block', // Bedre justering
        marginLeft: '10px', // Litt avstand til venstre
        fontSize: 'large',
        }}>

          Expected files today: {taskCountToday}
          {pendingCount > 0 && (
          <span
            style={{
              color: 'white',
              backgroundColor: 'red', // Lys rød bakgrunn
              padding: '5px 5px', // Litt avstand inni elementet
              borderRadius: '3px', // Runde hjørner
              border: '1px solid red', // Outline i rød farge
              fontWeight: 'bold', // Fet tekst
              display: 'inline-block', // Bedre justering
              marginLeft: '10px' // Litt avstand til venstre
            }}
          >
            - Pending ooh requests: {pendingCount}
          </span>
        )}
          <br />
        </span>
      {/* )} */}
      <div className="samlecontainer">
          <div className="news-column">
          <div className="box-container" style={{fontWeight: 'bold', fontSize: '1.5em'}}>
            <div className="box">Today<br />{userStats.todayFiles || 0}</div>
            <div className="box">Weekly<br />{userStats.weeklyFiles || 0}</div>
            <div className="box">Monthly<br />{userStats.monthlyFiles || 0}</div>
            <div className="box">Yearly<br />{userStats.yearlyFiles || 0}</div>
          </div>
        </div>
      </div>
      <div className="files-section">
         {/* Prioliste */}
        {priorityList.length > 0 && (
  <>

    <table className="priority-list">
      <thead>
        <tr className="priority-header">
          <th>ID</th>
          <th>Vehicle</th>
          <th>Registration</th>
          <th>Dealer</th>
          <th></th> {/* For alert om melding */}
          <th></th> {/* For ev. ikoner som varsler */}
          <th>Type</th>
          <th>Tuner</th>

          <th>Date</th>
          <th>Charged</th>
          <th>Tool</th>

        </tr>
      </thead>
      <tbody>
        {priorityList.map((vehicle, index) => (
          <ViewRow
            key={vehicle.id}
            vehicle={vehicle}
            isAdmin={isAdmin}
            isTuner={isTuner}
            handleRowClick={handleRowClick}
            handleUpdateStatus={handleUpdateStatus}
            showDropdown={showDropdown[index]}
            toggleDropdown={() => toggleDropdown(index)}
            handleDelete={() => handleDelete(vehicle.id)}  // Sender handleDelete som prop
          />
        ))}
      </tbody>
    </table>
    {/* Åpenrom mellom priolisten og øvrige filer */}
    <div style={{ height: "35px" }} />
  </>
)}

        {/* Ikke-prioriterte filer */}
        <table className="other-list">
  <thead>
    <tr className="other-header">
      <th>ID</th>
      <th>Vehicle</th>
      <th>Registration</th>
      <th>Dealer</th>
      <th></th> {/* For ev. ikoner som varsler */}
      <th></th> {/* For ev. ikoner som varsler */}
      <th>Type</th>
      <th>Tuner</th>
      <th>Date</th>
      <th>Charged</th>
      <th>Tool</th>

    </tr>
  </thead>


  <tbody>
  {otherList.map((vehicle, index) => (
      <ViewRow
        key={vehicle.id}
        vehicle={vehicle}
        isAdmin={isAdmin}
        isTuner={isTuner}
        handleRowClick={handleRowClick}
        handleUpdateStatus={handleUpdateStatus}
        showDropdown={showDropdown[index + priorityList.length]}
        toggleDropdown={() => toggleDropdown(index + priorityList.length)}
        handleDelete={() => handleDelete(vehicle.id)}  // Sender handleDelete som prop
      />
    ))}

  </tbody>
</table>
      </div>
    </div>
  );
};