import React from 'react';
import ReactDOM from 'react-dom/client'; // Endret fra 'react-dom'
import './index.css'; // Global CSS-fil
import App from './App'; // Hovedkomponenten

const root = ReactDOM.createRoot(document.getElementById('root')); // Bruk createRoot i stedet
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
