import React, { useState, useEffect } from 'react';

export default function Oohsite({ onClose, did, country, userProfile }) {
    const [requests, setRequests] = useState([]);
    const [days, setDays] = useState([]);
    const [activeTab, setActiveTab] = useState('ooh');

    useEffect(() => {
        generateDays();
        fetchOohRequests();
    }, [did, country]);

    const generateDays = () => {
        const today = new Date();
        const daysArray = [];
        for (let i = 0; i < 14; i++) {
            const nextDay = new Date(today);
            nextDay.setDate(today.getDate() + i);
            daysArray.push(nextDay);
        }
        setDays(daysArray);
    };

    const fetchOohRequests = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ooh`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setRequests(data);
        } catch (error) {
            console.error('Error fetching ooh requests:', error);
        }
    };

    const formatDate = (date) => {
        const d = new Date(date);
        return d.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    };


    const sendNotificationToAdmin = async (title, body, data = {}) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ title, body, data })
            });
            if (!response.ok) {
                throw new Error('Failed to send notification to admin');
            }
            const result = await response.json();
            console.log('Notification sent to admin:', result);
        } catch (error) {
            console.error('Error sending notification to admin:', error);
        }
    };
    


      const handleRequest = async (date) => {
        const formattedDate = formatDate(date);
        const payload = { did: did, date: formattedDate };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ooh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const responseData = await response.json();
    
            if (response.ok) {
                // Oppdater listen over forespørsler
                fetchOohRequests();
    
                // Send notifikasjon til admin
                const notificationTitle = `${userProfile.companyName} (${userProfile.name})`;
                const notificationBody = `OOH request ${formattedDate}`;
                const notificationData = { messageId: `${did}-${formattedDate}`, sender: userProfile.name };
    
                await sendNotificationToAdmin(notificationTitle, notificationBody, notificationData);
    
                console.log('OOH request and notification successfully created.');
            } else {
                console.error('Error creating OOH request:', responseData);
            }
        } catch (error) {
            console.error('Error creating OOH request:', error);
        }
    };
    
    

    const handleCancelRequest = async (date) => {
        const formattedDate = formatDate(date); // Expected to return 'YYYY-MM-DD'

        // Function to format the request date to 'YYYY-MM-DD' in local timezone
        const formatRequestDate = (dateString) => {
            const dateObj = new Date(dateString);
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const day = String(dateObj.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        // Find the request that matches both the DID and the formatted date
        const requestToCancel = requests.find(req => {
            const requestDate = formatRequestDate(req.date); // Convert to 'YYYY-MM-DD' without timezone issues
            return req.did === did && requestDate === formattedDate;
        });

        if (!requestToCancel) {
            console.error('Request not found');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ooh/${requestToCancel.id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                fetchOohRequests(); // Refresh the list of requests
            } else {
                const responseData = await response.json();
                console.error('Error cancelling request:', responseData);
            }
        } catch (error) {
            console.error('Error cancelling request:', error);
        }
    };






    const renderCountryText = () => {
        switch (country) {
            case 'NO':
                return 'Dersom du har behov for filservice etter kl. 18:00, må du legge inn en forespørsel. Ved aksept vil du kunne avtale direkte med tuneren når og hvilke kjøretøy som er ventet. Det er ingen begrensning på antall filer eller tidspunkt, alt etter avtale. Merk at enkelte kjøretøy og løsninger ikke kan løses på kveldstid, avhengig av type løsning, kjøretøy, hvilke programmer som trengs, og hva som eventuelt hentes av eksterne løsninger, samt tilgang på definisjonsfiler for tuneren. OOH-filer har en tilleggskostnad på 3 credits.';
            case 'SE':
                return 'Om du behöver filservice efter kl. 18:00, måste du lämna in en förfrågan. Vid godkännande kan du avtala direkt med tunern när och vilka fordon som förväntas. Det finns ingen begränsning på antal filer eller tidpunkt, allt enligt överenskommelse. Observera att vissa fordon och lösningar inte kan lösas på kvällstid beroende på typ av lösning, fordon, vilka program som behövs och vad som eventuellt hämtas från externa lösningar, samt tillgång till definitionsfiler för tunern. OOH-filer kostar 3 credits extra.';
            default:
                return 'If you need file service after 6:00 PM, you must submit a request. Upon acceptance, you will be able to arrange directly with the tuner when and which vehicles are expected. There is no limit on the number of files or the time, all according to the agreement. Note that certain vehicles and solutions cannot be resolved in the evening, depending on the type of solution, vehicle, which programs are needed, and what might be retrieved from external solutions, as well as access to definition files for the tuner. OOH files cost an additional 3 credits.';
        }
    };

    const renderContent = () => {
        if (activeTab === 'ooh') {
            return (
                <div className="admintasks-content">
                    <h2>Out of Hour Tuner Requests</h2>
                    <div className="ooh-contentholder">{renderCountryText()}</div>
                    <table className="admintasks-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Request</th></tr>
                            
                        </thead>
                        <tbody>
                            {days.map(day => (
                                <tr key={day.toISOString()}>
                                    <td>{day.toDateString()}</td>
                                    <td>
                                        {requests.some(req => new Date(req.date).toDateString() === day.toDateString() && req.did === did) ? (
                                            requests.find(req => new Date(req.date).toDateString() === day.toDateString() && req.did === did && req.status === 1)
                                                ? `OOH Tuner: ${requests.find(req => new Date(req.date).toDateString() === day.toDateString() && req.did === did && req.status === 1).tuner_name}`
                                                : <>
                                                    Request pending...
                                                    <button onClick={() => handleCancelRequest(day)}>Cancel Request</button>
                                                  </>
                                        ) : (
                                            <button onClick={() => handleRequest(day)}>
                                                Request OOH Tuner
                                            </button>
                                        )}
                                    </td></tr>
                                
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
        return null;
    };


    return (
        <div className="adminmodal-background" onClick={onClose}>
            <div className="adminmodal-content" onClick={e => e.stopPropagation()}>
                {renderContent()}
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}
