// Fil: AdminTempImages.jsx
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import './AdminTempImages.css';  // <-- Importer CSS

function AdminTempImages({ onClose }) {
  const [models, setModels] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const { userProfile } = useContext(UserContext);

  useEffect(() => {
    fetchTempImages();
  }, []);

  const fetchTempImages = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/temp-images');
      if (!response.ok) throw new Error('Failed to fetch temp images');
      const data = await response.json();
      setModels(data);
    } catch (error) {
      console.error('Error fetching temp images:', error);
    }
  };

  const handleApprove = async (modelId, imageType, adminId) => {
    if (!window.confirm(`Approve ${imageType} for modelId ${modelId}?`)) return;
    try {
      const resp = await fetch(process.env.REACT_APP_SITE_URL + '/temp-images/approve', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ modelId, imageType, adminId })
      });
      if (!resp.ok) throw new Error('Failed to approve image');
      const result = await resp.json();
      console.log('Approval successful:', result);
      fetchTempImages();
    } catch (error) {
      console.error('Error approving image:', error);
    }
  };

  const handleReject = async (modelId, imageType) => {
    if (!window.confirm(`Reject ${imageType} for modelId ${modelId}?`)) return;
    try {
      const resp = await fetch(process.env.REACT_APP_SITE_URL + '/temp-images/reject', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ modelId, imageType })
      });
      if (!resp.ok) throw new Error('Failed to reject image');
      await resp.json();
      fetchTempImages();
    } catch (error) {
      console.error('Error rejecting image:', error);
    }
  };

  const openImage = (url) => setSelectedImage(url);
  const closeImage = () => setSelectedImage(null);

  return (
    <div className="viewlogs-modal-background" onClick={onClose}>
      <div className="viewlogs-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <button className="close-btn" onClick={onClose}>X</button>
        </div>

        <div className="admin-temp-images-container">
          <h2>Admin: Temp Images</h2>
          {models.length === 0 ? (
            <p>No temp images found</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Model Name</th>
                  <th>Temp Images</th>
                </tr>
              </thead>
              <tbody>
                {models.map((m) => (
                  <tr key={m.id}>
                    <td>{m.id}</td>
                    <td>{m.fullModelName}</td>
                    <td className="temp-image-td">
                      {/* tempmodelimage */}
                      {m.tempmodelimage && (
                        <div className="temp-image-block">
                          <strong>tempmodelimage</strong>
                          <img
                            src={m.tempmodelimage}
                            alt="tempmodelimage"
                            className="thumbnail"
                            onClick={() => openImage(m.tempmodelimage)}
                          />
                          <div>
                            <button
                              className="button-approve"
                              onClick={() => handleApprove(m.id, 'modelimage', userProfile.id)}>
                              Approve
                            </button>
                            <button
                              className="button-reject"
                              onClick={() => handleReject(m.id, 'modelimage')}>
                              Reject
                            </button>
                          </div>
                          {/* Hvis du har en "tempModelImageUploadedBy" i JSON */}
                          {m.tempModelImageUploadedBy && (
                            <span className="uploaded-by-label">
                              Uploaded by: {m.tempModelImageUploadedBy}
                            </span>
                          )}
                        </div>
                      )}

                      {/* tempobdimage */}
                      {m.tempobdimage && (
                        <div className="temp-image-block">
                          <strong>tempobdimage</strong>
                          <img
                            src={m.tempobdimage}
                            alt="tempobdimage"
                            className="thumbnail"
                            onClick={() => openImage(m.tempobdimage)}
                          />
                          <div>
                            <button
                              className="button-approve"
                              onClick={() => handleApprove(m.id, 'obdimage', userProfile.id)}>
                              Approve
                            </button>
                            <button
                              className="button-reject"
                              onClick={() => handleReject(m.id, 'obdimage')}>
                              Reject
                            </button>
                          </div>
                          {m.tempOBDImageUploadedBy && (
                            <span className="uploaded-by-label">
                              Uploaded by: {m.tempOBDImageUploadedBy}
                            </span>
                          )}
                        </div>
                      )}

                      {/* tempecuimage */}
                      {m.tempecuimage && (
                        <div className="temp-image-block">
                          <strong>tempecuimage</strong>
                          <img
                            src={m.tempecuimage}
                            alt="tempecuimage"
                            className="thumbnail"
                            onClick={() => openImage(m.tempecuimage)}
                          />
                          <div>
                            <button
                              className="button-approve"
                              onClick={() => handleApprove(m.id, 'ecuimage', userProfile.id)}>
                              Approve
                            </button>
                            <button
                              className="button-reject"
                              onClick={() => handleReject(m.id, 'ecuimage')}>
                              Reject
                            </button>
                          </div>
                          {m.tempECUImageUploadedBy && (
                            <span className="uploaded-by-label">
                              Uploaded by: {m.tempECUImageUploadedBy}
                            </span>
                          )}
                        </div>
                      )}

                      {/* tempacmimage */}
                      {m.tempacmimage && (
                        <div className="temp-image-block">
                          <strong>tempacmimage</strong>
                          <img
                            src={m.tempacmimage}
                            alt="tempacmimage"
                            className="thumbnail"
                            onClick={() => openImage(m.tempacmimage)}
                          />
                          <div>
                            <button
                              className="button-approve"
                              onClick={() => handleApprove(m.id, 'acmimage', userProfile.id)}>
                              Approve
                            </button>
                            <button
                              className="button-reject"
                              onClick={() => handleReject(m.id, 'acmimage')}>
                              Reject
                            </button>
                          </div>
                          {m.tempACMImageUploadedBy && (
                            <span className="uploaded-by-label">
                              Uploaded by: {m.tempACMImageUploadedBy}
                            </span>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {/* Lightbox */}
          {selectedImage && (
            <div className="lightbox-overlay" onClick={closeImage}>
              <img src={selectedImage} alt="Full Preview" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminTempImages;
