import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Analytics = () => {
    const [fileCounts, setFileCounts] = useState({
        dpf: 0,
        egr: 0,
        adblue: 0,
        nsl: 0,
        o2: 0,
    });
    const [vTypeCounts, setVTypeCounts] = useState({});
    const [toolTypeCounts, setToolTypeCounts] = useState({});
    const [tuningTypeCounts, setTuningTypeCounts] = useState({});
    const [uploadTimeCounts, setUploadTimeCounts] = useState({});
    const [responseTimeCounts, setResponseTimeCounts] = useState({});
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState('total');
    const [data, setData] = useState([]);
    const [manufacturerCounts, setManufacturerCounts] = useState({});

    useEffect(() => {
        fetch(process.env.REACT_APP_SITE_URL + '/uploads/analytics-data')
            .then(response => response.json())
            .then(data => {
                setData(data);
                setFileCounts(processFileData(data, 'total'));
                setVTypeCounts(processVTypeData(data, 'total'));
                setToolTypeCounts(processToolTypeData(data, 'total'));
                setTuningTypeCounts(processTuningTypeData(data, 'total'));
                setUploadTimeCounts(processUploadTimeData(data, 'total'));
                setResponseTimeCounts(processResponseTimeData(data, 'total'));
                setManufacturerCounts(processManufacturerData(data, 'total'));
            })
            .catch(error => console.error('Error fetching file data:', error));

        fetch(process.env.REACT_APP_SITE_URL + '/uploads/unique-years')
            .then(response => response.json())
            .then(data => {
                setYears(data);
            })
            .catch(error => console.error('Error fetching unique years:', error));
    }, []);

    useEffect(() => {
        setFileCounts(processFileData(data, selectedYear));
        setVTypeCounts(processVTypeData(data, selectedYear));
        setToolTypeCounts(processToolTypeData(data, selectedYear));
        setTuningTypeCounts(processTuningTypeData(data, selectedYear));
        setUploadTimeCounts(processUploadTimeData(data, selectedYear));
        setResponseTimeCounts(processResponseTimeData(data, selectedYear));
        setManufacturerCounts(processManufacturerData(data, selectedYear));
    }, [selectedYear, data]);

    const processFileData = (data, year) => {
        const fileCounts = {
            dpf: 0,
            egr: 0,
            adblue: 0,
            nsl: 0,
            o2: 0,
        };

        data.forEach(item => {
            if (year === 'total' || item.year === parseInt(year)) {
                if (item.dpf === 1) fileCounts.dpf++;
                if (item.egr === 1) fileCounts.egr++;
                if (item.adblue === 1) fileCounts.adblue++;
                if (item.nsl === 1) fileCounts.nsl++;
                if (item.o2 === 1) fileCounts.o2++;
            }
        });

        return fileCounts;
    };

    const processManufacturerData = (data, year) => {
        const manufacturerCounts = {};
      
        data.forEach(item => {
          if (year === 'total' || item.year === parseInt(year)) {
            const name = item.manufacturerName || 'Unknown';
            if (!manufacturerCounts[name]) {
              manufacturerCounts[name] = 0;
            }
            manufacturerCounts[name]++;
          }
        });
      
        return manufacturerCounts;
      };
      const sortedAndFiltered = Object.entries(manufacturerCounts)
      // 1) Filtrer ut dem som har under 30
      .filter(([manufacturerName, count]) => count >= 30)
      // 2) Sorter synkende etter count
      .sort((a, b) => b[1] - a[1]);
      const manufacturerLabels = sortedAndFiltered.map(([manufacturerName]) => manufacturerName);
      const manufacturerValues = sortedAndFiltered.map(([ , count]) => count);
      const manufacturerChartData = {
        labels: manufacturerLabels,
        datasets: [
          {
            label: 'Manufacturers',
            data: manufacturerValues,
            backgroundColor: '#42a5f5',
            // eventuelt barSpacing:
            barThickness: 15,
            maxBarThickness: 25,
            categoryPercentage: 0.5
          }
        ]
      };

    const processVTypeData = (data, year) => {
        const vTypeCounts = {
            cars: 0,
            construction: 0,
            marine: 0,
            mc_atv: 0,
            tractor: 0,
            truck: 0,
        };

        data.forEach(item => {
            if (year === 'total' || item.year === parseInt(year)) {
                switch (item.v_type) {
                    case 1:
                        vTypeCounts.cars++;
                        break;
                    case 9:
                        vTypeCounts.construction++;
                        break;
                    case 10:
                        vTypeCounts.marine++;
                        break;
                    case 14:
                        vTypeCounts.mc_atv++;
                        break;
                    case 8:
                        vTypeCounts.tractor++;
                        break;
                    case 6:
                        vTypeCounts.truck++;
                        break;
                    default:
                        break;
                }
            }
        });

        return vTypeCounts;
    };

    const processToolTypeData = (data, year) => {
        const toolTypeCounts = {
            autotuner: 0,
            mmflex: 0,
            newgenius: 0,
            trasdata: 0,
            ktag: 0,
            kessv3: 0,
            unlisted: 0,
        };

        data.forEach(item => {
            if (year === 'total' || item.year === parseInt(year)) {
                switch (item.tooltype) {
                    case 1:
                        toolTypeCounts.autotuner++;
                        break;
                    case 2:
                        toolTypeCounts.mmflex++;
                        break;
                    case 3:
                        toolTypeCounts.newgenius++;
                        break;
                    case 10:
                        toolTypeCounts.trasdata++;
                        break;
                    case 12:
                        toolTypeCounts.ktag++;
                        break;
                    case 11:
                        toolTypeCounts.kessv3++;
                        break;
                    case 9:
                        toolTypeCounts.unlisted++;
                        break;
                    default:
                        break;
                }
            }
        });

        return toolTypeCounts;
    };

    const processTuningTypeData = (data, year) => {
        const tuningTypeCounts = {
            eco: 0,
            opti: 0,
            power: 0,
            as_stock: 0,
        };

        data.forEach(item => {
            if (year === 'total' || item.year === parseInt(year)) {
                switch (item.tuning_type) {
                    case 1:
                        tuningTypeCounts.eco++;
                        break;
                    case 3:
                        tuningTypeCounts.opti++;
                        break;
                    case 4:
                        tuningTypeCounts.power++;
                        break;
                    case 5:
                        tuningTypeCounts.as_stock++;
                        break;
                    default:
                        break;
                }
            }
        });

        return tuningTypeCounts;
    };

    const processUploadTimeData = (data, year) => {
        const uploadTimeCounts = {
            before_9: 0,
            between_9_12: 0,
            between_12_15: 0,
            between_15_18: 0,
            between_18_21: 0,
        };

        data.forEach(item => {
            if (year === 'total' || item.year === parseInt(year)) {
                const hour = new Date(item.created).getHours();
                if (hour < 9) {
                    uploadTimeCounts.before_9++;
                } else if (hour >= 9 && hour < 12) {
                    uploadTimeCounts.between_9_12++;
                } else if (hour >= 12 && hour < 15) {
                    uploadTimeCounts.between_12_15++;
                } else if (hour >= 15 && hour < 18) {
                    uploadTimeCounts.between_15_18++;
                } else if (hour >= 18 && hour < 21) {
                    uploadTimeCounts.between_18_21++;
                }
            }
        });

        return uploadTimeCounts;
    };

    const processResponseTimeData = (data, year) => {
        const responseTimeCounts = {
            less_than_5: 0,
            between_5_15: 0,
            between_15_30: 0,
            between_30_60: 0,
            over_60: 0,
        };

        data.forEach(item => {
            if (year === 'total' || item.year === parseInt(year)) {
                const createdTime = new Date(item.created);
                const updatedTime = new Date(item.updated);
                const differenceInMinutes = (updatedTime - createdTime) / (1000 * 60);

                if (differenceInMinutes < 5) {
                    responseTimeCounts.less_than_5++;
                } else if (differenceInMinutes >= 5 && differenceInMinutes < 15) {
                    responseTimeCounts.between_5_15++;
                } else if (differenceInMinutes >= 15 && differenceInMinutes < 30) {
                    responseTimeCounts.between_15_30++;
                } else if (differenceInMinutes >= 30 && differenceInMinutes < 60) {
                    responseTimeCounts.between_30_60++;
                } else if (differenceInMinutes >= 60) {
                    responseTimeCounts.over_60++;
                }
            }
        });

        return responseTimeCounts;
    };

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const containerStyle = {
        padding: '20px',
        backgroundColor: '#121212',  // Dark background
        color: '#ffffff',  // White text
        borderRadius: '8px',
        maxWidth: '1200px',
        margin: '0 auto',
        marginTop: '40px',
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        position: 'sticky',
        top: '0',
        backgroundColor: '#121212',
        zIndex: 1000,
        padding: '10px 0',
    };

    const buttonStyle = {
        padding: '10px 20px',
        backgroundColor: selectedYear === 'total' ? '#4caf50' : '#333333',  // Green for selected button, dark for others
        color: '#ffffff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginRight: '10px',
    };

    const chartRowStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    };

    const fullWidthChartContainerStyle = {
        flex: '0 1 100%',   // Fyller 100% av bredden
        height: '500px',
        marginBottom: '40px',
        fontsize: '10px',
      };

    const chartContainerStyle = {
        flex: '0 1 calc(50% - 20px)',
        height: '400px',
        marginBottom: '40px',
    };

    const fileChartData = {
        labels: ['DPF', 'EGR', 'Adblue', 'NSL', 'O2'],
        datasets: [{
            label: 'Solutions',
            data: [
                fileCounts.dpf,
                fileCounts.egr,
                fileCounts.adblue,
                fileCounts.nsl,
                fileCounts.o2
            ],
            backgroundColor: [
                '#4caf50',
                '#ffeb3b',
                '#ff9800',
                '#f44336',
                '#9c27b0'
            ]
        }]
    };

    const vTypeChartData = {
        labels: ['Cars', 'Construction', 'Marine', 'MC / ATV', 'Tractor', 'Truck'],
        datasets: [{
            label: 'Vehicletypes',
            data: [
                vTypeCounts.cars,
                vTypeCounts.construction,
                vTypeCounts.marine,
                vTypeCounts.mc_atv,
                vTypeCounts.tractor,
                vTypeCounts.truck
            ],
            backgroundColor: [
                '#4caf50',
                '#ffeb3b',
                '#ff9800',
                '#f44336',
                '#9c27b0',
                '#2196f3'
            ]
        }]
    };

    const toolTypeChartData = {
        labels: ['Autotuner', 'MMFlex', 'NewGenius', 'Trasdata', 'K-Tag', 'Kess V3', 'Unlisted'],
        datasets: [{
            label: 'Tooltypes',
            data: [
                toolTypeCounts.autotuner,
                toolTypeCounts.mmflex,
                toolTypeCounts.newgenius,
                toolTypeCounts.trasdata,
                toolTypeCounts.ktag,
                toolTypeCounts.kessv3,
                toolTypeCounts.unlisted
            ],
            backgroundColor: [
                '#4caf50',
                '#ffeb3b',
                '#ff9800',
                '#f44336',
                '#9c27b0',
                '#2196f3',
                '#607d8b'
            ]
        }]
    };

    const tuningTypeChartData = {
        labels: ['ECO', 'OPTI', 'POWER', 'STOCK'],
        datasets: [{
            label: 'Tuningtypes',
            data: [
                tuningTypeCounts.eco,
                tuningTypeCounts.opti,
                tuningTypeCounts.power,
                tuningTypeCounts.as_stock
            ],
            backgroundColor: [
                '#4caf50',
                '#ffeb3b',
                '#ff9800',
                '#f44336'
            ]
        }]
    };

    const uploadTimeChartData = {
        labels: ['Before 09:00', '09:00-12:00', '12:00-15:00', '15:00-18:00', '18:00-21:00'],
        datasets: [{
            label: 'Uploadhours',
            data: [
                uploadTimeCounts.before_9,
                uploadTimeCounts.between_9_12,
                uploadTimeCounts.between_12_15,
                uploadTimeCounts.between_15_18,
                uploadTimeCounts.between_18_21
            ],
            backgroundColor: [
                '#4caf50',
                '#ffeb3b',
                '#ff9800',
                '#f44336',
                '#9c27b0'
            ]
        }]
    };

    const responseTimeChartData = {
        labels: ['Less than 5 min', '5-15 min', '15-30 min', '30-60 min', 'Over 60 min'],
        datasets: [{
            label: 'Responsetime',
            data: [
                responseTimeCounts.less_than_5,
                responseTimeCounts.between_5_15,
                responseTimeCounts.between_15_30,
                responseTimeCounts.between_30_60,
                responseTimeCounts.over_60
            ],
            backgroundColor: [
                '#4caf50',
                '#ffeb3b',
                '#ff9800',
                '#f44336',
                '#9c27b0'
            ]
        }]
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#ffffff'  // White text for the legend
                }
            },
            title: {
                display: true,
                text: '',
                color: '#ffffff'  // White text for the title
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#ffffff',
                    font: {
                        size: 12
                      },

                }
            },
            y: {
                ticks: {
                    color: '#ffffff'
                }
            }
        }
    };

    return (
        <div style={containerStyle}>
            <div style={buttonContainerStyle}>
                <button
                    style={{ ...buttonStyle, backgroundColor: selectedYear === 'total' ? '#4caf50' : '#333333' }}
                    onClick={() => handleYearChange('total')}
                >
                    Total
                </button>
                {years.map(year => (
                    <button
                        key={year}
                        style={{ ...buttonStyle, backgroundColor: selectedYear === year ? '#4caf50' : '#333333' }}
                        onClick={() => handleYearChange(year)}
                    >
                        {year}
                    </button>
                ))}
            </div>
            <div style={chartRowStyle}>
                <div style={chartContainerStyle}>
                    <Bar data={fileChartData} options={{ ...chartOptions, title: { ...chartOptions.title, text: 'File Type Counts' } }} />
                </div>
                <div style={chartContainerStyle}>
                    <Bar data={vTypeChartData} options={{ ...chartOptions, title: { ...chartOptions.title, text: 'Vehicle Types' } }} />
                </div>
            </div>
            <div style={chartRowStyle}>
                <div style={chartContainerStyle}>
                    <Bar data={toolTypeChartData} options={{ ...chartOptions, title: { ...chartOptions.title, text: 'Tool Types' } }} />
                </div>
                <div style={chartContainerStyle}>
                    <Bar data={tuningTypeChartData} options={{ ...chartOptions, title: { ...chartOptions.title, text: 'Tuning Types' } }} />
                </div>
            </div>
            <div style={chartRowStyle}>
                <div style={chartContainerStyle}>
                    <Bar data={uploadTimeChartData} options={{ ...chartOptions, title: { ...chartOptions.title, text: 'Upload Times' } }} />
                </div>
                <div style={chartContainerStyle}>
                    <Bar data={responseTimeChartData} options={{ ...chartOptions, title: { ...chartOptions.title, text: 'Response Times' } }} />
                </div>
            </div>
            <div style={chartRowStyle}>
                <div style={fullWidthChartContainerStyle}>
                    <Bar
                    data={manufacturerChartData}
                    options={{
                        ...chartOptions,
                        title: {
                        ...chartOptions.title,
                        text: 'Manufacturers'
                        }
                    }}
                    />
                </div>
                </div>

        </div>
    );
};

export default Analytics;
