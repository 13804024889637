import React, { useState, useEffect, useRef, useContext } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { UserContext } from '../context/UserContext';

const Chat = ({ onClose, userId, isAdmin, isTuner, did }) => {
  const { userProfile } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState('home');
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { authToken } = useAuth();
  const ws = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (activeTab === 'messages') {
      fetchRooms();
    }
  }, [activeTab]);

  useEffect(() => {
    if (selectedRoom) {
      fetchMessages(selectedRoom.id);

      if (ws.current) {
        ws.current.close();
      }

      ws.current = new WebSocket(`ws://localhost:5000`);
      ws.current.onmessage = (event) => {
        try {
          const parsedMessage = JSON.parse(event.data);
          if (parsedMessage.type === 'message') {
            setMessages((prevMessages) => [...prevMessages, parsedMessage.data]);
            scrollToBottom();
          }
        } catch (error) {
          console.error('Error parsing message:', error);
        }
      };
    }

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [selectedRoom]);

  const fetchRooms = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/chat/rooms?userId=${userId}&isAdmin=${isAdmin}&isTuner=${isTuner}&did=${did}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await response.json();
      setRooms(data);
      if (data.length > 0) {
        setSelectedRoom(data[0]);
      }
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  };

  const fetchMessages = async (roomId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/chat/rooms/${roomId}/messages`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await response.json();
      setMessages(data.reverse());
      scrollToBottom();
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const sendMessage = async () => {
    try {
      const user_name = userProfile.name;
      const messageData = { message: newMessage, userId, user_name, roomId: selectedRoom.id };
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/chat/rooms/${selectedRoom.id}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(messageData),
      });
      if (response.ok) {
        const wsMessage = JSON.stringify({ type: 'message', data: messageData });
        ws.current.send(wsMessage);
        setMessages((prevMessages) => [...prevMessages, messageData]);
        setNewMessage('');
        scrollToBottom();
      } else {
        console.error('Error sending message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Render metoder for de forskjellige fanene
  const renderHome = () => (
    <div className="chat-content">
      <div className="chat-header">Hi there 👋 How can we help?</div>
      <div className="chat-room">
        <h4>Recent message</h4>
        <p>The team will be back in 30 minutes!</p>
      </div>
      <div className="chat-room">
        <button>Send us a message</button>
      </div>
    </div>
  );

  const renderMessages = () => (
    <div className="chat-content">
      <div className="chat-header">Messages</div>
      <div className="chat-rooms">
        {rooms.map(room => (
          <div
            key={room.id}
            className={`chat-room ${selectedRoom && room.id === selectedRoom.id ? 'active' : ''}`}
            onClick={() => setSelectedRoom(room)}
          >
            {room.name}
          </div>
        ))}
      </div>
      {selectedRoom && (
        <>
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div key={index} className="chat-message">
                <span>{msg.user_name}</span>: {msg.message}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input">
            <textarea
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
                e.target.style.height = 'auto';
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
              placeholder="Type your message..."
            />
            <button onClick={sendMessage}>Send</button>
          </div>
        </>
      )}
    </div>
  );

  const renderHelp = () => (
    <div className="chat-content">
      <div className="chat-header">Help</div>
      <div className="chat-room">Support</div>
    </div>
  );

  return (
    <div className="uploadmodal-background">
      <div className="chat-container">
        <div className="chat-content">
          {activeTab === 'home' && renderHome()}
          {activeTab === 'messages' && renderMessages()}
          {activeTab === 'help' && renderHelp()}
        </div>

        <nav className="chat-bottom-nav">
          <button
            className={`chat-nav-button ${activeTab === 'home' ? 'active' : ''}`}
            onClick={() => setActiveTab('home')}
          >
            Home
          </button>
          <button
            className={`chat-nav-button ${activeTab === 'messages' ? 'active' : ''}`}
            onClick={() => setActiveTab('messages')}
          >
            Messages
          </button>
          <button
            className={`chat-nav-button ${activeTab === 'help' ? 'active' : ''}`}
            onClick={() => setActiveTab('help')}
          >
            Help
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Chat;
