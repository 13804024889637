import React, { useState, useEffect } from 'react';
import TaskConfirmationPopup from './TaskConfirmationPopup';
import WeekPlanner from './WeekPlanner'; 
import TaskDetails from './TaskDetails';

export default function Profilesite({ onClose, username, usernameid, did, country, userProfile }) {
    const [activeSection, setActiveSection] = useState('Tasks');
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [filter, setFilter] = useState('All');
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [members, setMembers] = useState([]);
    const [newTask, setNewTask] = useState({
        vehicle: '',
        info: '',
        customer: '',
        datetime: '',
        endDate: '',
        taskowner: '',
    });
    const [selectedTask, setSelectedTask] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); // Legg til søkefeltstate
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [pendingTaskId, setPendingTaskId] = useState(null);
    
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [viewMode, setViewMode] = useState('default'); // 'default' for renderContent, 'weekly' for renderContentWeekly
    const [newTaskVehicle, setNewTaskVehicle] = useState(''); // Ny state for input-feltet

    useEffect(() => {
        fetchTasks();
        fetchMembers();
    }, [did]);
    

    useEffect(() => {
        filterTasks(filter);
    }, [tasks, filter]);




    
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    const updateTask = (updatedTask) => {
        setTasks((prevTasks) =>
            prevTasks.map((task) =>
                task.id === updatedTask.id ? updatedTask : task
            )
        );
    };

    

    const sendNotificationToAdmin = async (title, body, data = {}) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ title, body, data }),
            });
    
            if (!response.ok) {
                throw new Error('Failed to send notification to admin');
            }
            const result = await response.json();
            console.log('Notification sent to admin:', result);
        } catch (error) {
            console.error('Error sending notification to admin:', error);
        }
    };

    
    useEffect(() => {
        if (selectedTask) {
            fetchComments(selectedTask.id);
        }
    }, [selectedTask]);

    useEffect(() => {
        console.log("SelectedTask updated:", selectedTask);
      }, [selectedTask]);
      
    
    const fetchComments = async (taskId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/taskcom/${taskId}`);
          const data = await response.json();
          setComments(data); // Oppdaterer state med kommentarene
        } catch (error) {
          console.error('Error fetching comments:', error);
        }
      };

      const addComment = async () => {
        if (!newComment.trim()) {
            console.error("Comment is empty");
            return;
        }
    
        if (!selectedTask || !selectedTask.id) {
            console.error("No task selected");
            return;
        }
    
        const payload = {
            taskid: selectedTask.id,
            user: userProfile.name || "Unknown User", // Bruker userProfile.name
            message: newComment,
        };
    
        console.log("Payload to be sent:", payload); // Log payload
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/taskcom`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });
    
            if (response.ok) {
                const addedComment = await response.json();
                setComments((prevComments) => [...prevComments, { ...payload, id: addedComment.commentId, date: new Date() }]);
                setNewComment('');
            } else {
                const errorResponse = await response.json();
                console.error("Server error:", errorResponse);
            }
        } catch (error) {
            console.error("Error adding comment:", error);
        }
    };

    const fetchTasks = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks?did=${did}`);
          const data = await response.json();
      
          const formattedTasks = data.map((task) => ({
            ...task,
            start: task.datetime,
            end: task.enddatetime,
          }));
      
          setTasks(formattedTasks);
      
          // Unngå å nullstille `selectedTask` utilsiktet
          setSelectedTask((prevSelectedTask) =>
            prevSelectedTask
              ? formattedTasks.find((task) => task.id === prevSelectedTask.id) || prevSelectedTask
              : null
          );
        } catch (error) {
          console.error("Error fetching tasks:", error);
        }
      };
      
      
    
    const fetchMembers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasksmembers?did=${did}`);
            const data = await response.json();
            setMembers(data);
        } catch (error) {
            console.error('Error fetching members:', error);
        }
    };

    
    const filterTasks = (filter) => {
        const now = new Date();
        let filtered = tasks;

        switch (filter) {
            case 'Today':
                filtered = tasks.filter(task => new Date(task.datetime).toDateString() === now.toDateString());
                break;
            case 'Week':
                const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 1)); // Start of the week (Monday)
                const endOfWeek = new Date(startOfWeek);
                endOfWeek.setDate(startOfWeek.getDate() + 6);
                filtered = tasks.filter(task => new Date(task.datetime) >= startOfWeek && new Date(task.datetime) <= endOfWeek);
                break;
            case 'Month':
                const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
                const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                filtered = tasks.filter(task => new Date(task.datetime) >= startOfMonth && new Date(task.datetime) <= endOfMonth);
                break;
            case 'Year':
                const startOfYear = new Date(now.getFullYear(), 0, 1);
                const endOfYear = new Date(now.getFullYear(), 11, 31);
                filtered = tasks.filter(task => new Date(task.datetime) >= startOfYear && new Date(task.datetime) <= endOfYear);
                break;
            case 'Monday':
            case 'Tuesday':
            case 'Wednesday':
            case 'Thursday':
            case 'Fri9day':
            case 'Saturday':
            case 'Sunday':
                const dayIndex = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(filter);
                filtered = tasks.filter(task => new Date(task.datetime).getDay() === dayIndex);
                break;
            case 'All': // Håndtering for "Alle"
                filtered = tasks; // Viser alle oppgaver
                break;
            default:
                // Hvis filter er et medlem (id)
                filtered = tasks.filter(task => task.taskowner === parseInt(filter, 10));
                break;
        }

        // Sorter oppgaver basert på tidspunkt (eldste først)
        filtered.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

        setFilteredTasks(filtered);
    };
    


      const handleCreateTaskQuick = async () => {
        if (!newTaskVehicle.trim()) return; // Ingen tomme oppgaver
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ vehicle: newTaskVehicle, did }), // Kun `vehicle` sendes
            });
            if (response.ok) {
                fetchTasks(); // Oppdater oppgavelisten
                setNewTaskVehicle(''); // Tøm input-feltet
            } else {
                console.error('Error creating task');
            }
        } catch (error) {
            console.error('Error creating task:', error);
        }
    };


    const handleTaskClick = (task) => {
        setSelectedTask((prevTask) => {
          if (prevTask?.id === task.id) {
            // Hvis oppgaven allerede er valgt, ikke re-initialiser
            return prevTask;
          }
          return {
            ...task,
            datetime: getInputDateTimeString(task.datetime), // Konverter til riktig format
            endDate: task.enddatetime ? getInputDateTimeString(task.enddatetime) : "",
          };
        });
      };
      
     
    
      
    
    const handleDeleteTask = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${selectedTask.id}`, {
            method: 'DELETE',
          });
    
          if (response.ok) {
            console.log("Task successfully deleted.");
            fetchTasks();
            setSelectedTask(null);
          } else {
            const errorData = await response.json();
            console.error("Error deleting task:", errorData);
          }
        } catch (error) {
          console.error("Error deleting task:", error);
        }
      };

    const handleMarkAsDone = async (taskId) => {
        const confirm = window.confirm("Are you sure you want to mark this task as complete? This action cannot be undone.");
        if (!confirm) {
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${taskId}/done`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ done: 1 }),
            });
            if (response.ok) {
                fetchTasks();
            } else {
                console.error('Error marking task as done');
            }
        } catch (error) {
            console.error('Error marking task as done:', error);
        }
    };

    const formatDateTime = (dateTimeString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
        return new Date(dateTimeString).toLocaleString('no-NO', { timeZone: 'UTC' }, options).replace(',', ' -');
    };
    
    const getInputDateTimeString = (dateTimeString) => {
        const date = new Date(dateTimeString); // Lag datoobjekt fra streng
        const year = date.getUTCFullYear(); // Bruk UTC-verdi
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Bruk UTC-verdi
        const day = String(date.getUTCDate()).padStart(2, '0'); // Bruk UTC-verdi
        const hours = String(date.getUTCHours()).padStart(2, '0'); // Bruk UTC-verdi
        const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Bruk UTC-verdi
    
        // Returner i format som støttes av <input type="datetime-local">
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    
    const isExpired = (dateTimeString) => {
        return new Date(dateTimeString) < new Date();
    };

    const getFilteredDoneTasks = () => {
        return filteredTasks
            .filter(task => task.done) // Kun ferdige oppgaver
            .filter(task => {
                const vehicle = task.vehicle ? task.vehicle.toLowerCase() : '';
                const info = task.info ? task.info.toLowerCase() : '';
                const customer = task.customer ? task.customer.toLowerCase() : '';
                return (
                    vehicle.includes(searchQuery) ||
                    info.includes(searchQuery) ||
                    customer.includes(searchQuery)
                );
            });
    };
    
    const renderTasks = () => {
        const doneTasks = getFilteredDoneTasks();
        const notDoneTasks = filteredTasks.filter(task => !task.done);
        const getRowClass = (task) => {
            if (isExpired(task.datetime)) {
                return 'row-expired'; // Rød bakgrunn for "expired" filer
            }
            switch (task.ooh) {
                case 1:
                    return 'row-ooh-requested'; // Requested
                case 2:
                    return 'row-ooh-accepted'; // Accepted
                case 3:
                    return 'row-ooh-denied'; // Denied
                default:
                    return 'row-default'; // Default (No OOH)
            }
        };

        const handleToggleTaskStatus = async (task) => {
            try {
                // Konverter datetime til MySQL-format
                const formatDateForMySQL = (isoDate) => {
                    if (!isoDate) return null; // Håndter null-verdier
                    const date = new Date(isoDate);
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const seconds = String(date.getSeconds()).padStart(2, '0');
                    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                };
        
                const updatedTask = {
                    ...task,
                    datetime: formatDateForMySQL(task.datetime), // Konverter til MySQL-format
                    enddatetime: formatDateForMySQL(task.enddatetime), // Konverter til MySQL-format
                    done: task.done ? 0 : 1, // Toggle "done"-status
                };
        
                console.log('Sending updatedTask:', updatedTask);
        
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${task.id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedTask),
                });
        
                if (response.ok) {
                    console.log('Task toggled successfully');
                    fetchTasks(); // Oppdater listen over oppgaver
                } else {
                    const errorData = await response.json();
                    console.error('Error toggling task status:', errorData);
                }
            } catch (error) {
                console.error('Error toggling task status:', error);
            }
        };
        
        const renderDot = (task) => {
            return (
                <div
                    className={`task-dot ${task.done ? 'dot-green' : 'dot-blue'}`}
                    onClick={(e) => {
                        e.stopPropagation(); // Hindrer klikk fra å propagere til raden
                        console.log('Toggling task:', task); // Logg tasken som toggles
                        handleToggleTaskStatus(task);
                    }}
                >
                    {task.done ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{ width: '12px', height: '12px' }}
                        >
                            <path d="M20 6L9 17l-5-5" />
                        </svg>
                    ) : null}
                </div>
            );
        };
        

        const isOutOfHours = (datetimeString) => {
            const datetime = new Date(datetimeString);
            const utcHours = datetime.toLocaleString('no-NO', { timeZone: 'UTC', hour: '2-digit', hour12: false });
            const utcMinutes = datetime.toLocaleString('no-NO', { timeZone: 'UTC', minute: '2-digit', hour12: false });
            const day = datetime.getUTCDay(); 
            if (day >= 1 && day <= 5) {
                if (utcHours >= 9 && (utcHours < 21 || (utcHours === 21 && utcMinutes === '00'))) {
                    return false; 
                }
            }
            if (day === 6) {
                if (utcHours >= 10 && (utcHours < 14 || (utcHours === 14 && utcMinutes === '00'))) {
                    return false; 
                }
            }
            return true; 
        };
        
        const handleRequestOOH = (taskId) => {
            setPendingTaskId(taskId); // Lagre taskId for oppgaven som krever bekreftelse
            setShowConfirmationPopup(true); // Vis confirmation popup
        };
        
        const confirmRequestOOH = async () => {
            if (!pendingTaskId) return;

            const task = tasks.find((t) => t.id === pendingTaskId);
            if (!task) return;
        
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${pendingTaskId}/ooh`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ ooh: 1 }), // Sett OOH-status til 1
                });
        
                if (response.ok) {
                    setTasks((prevTasks) =>
                        prevTasks.map((task) =>
                            task.id === pendingTaskId ? { ...task, ooh: 1 } : task // Oppdater status i state
                        )
                    );
                    setShowConfirmationPopup(false); // Lukk popup
                    setPendingTaskId(null); // Nullstill pending task

                    const formatDetailedDateTime = (dateTimeString) => {
                        const date = new Date(dateTimeString);
                        const options = {
                            weekday: 'long', // Fullt navn på ukedagen
                            day: '2-digit', // Dag med to sifre
                            month: 'long', // Fullt navn på måneden
                            hour: '2-digit', // Timer med to sifre
                            minute: '2-digit', // Minutter med to sifre
                        };
                        return date.toLocaleString('no-NO', options);
                    };
                    
                    const notificationTitle = `${userProfile.companyName} (${userProfile.name})`;
                    const notificationBody = `Requested OOH - ${formatDetailedDateTime(task.datetime)}`;
                    const notificationData = {
                        messageId: `task-${pendingTaskId}`,
                        sender: username,
                    };
                    
        
                    await sendNotificationToAdmin(notificationTitle, notificationBody, notificationData);

                } else {
                    const errorData = await response.json();
                    console.error('Error confirming OOH request:', errorData.error || 'Unknown error');
                    alert(`Error: ${errorData.error || 'Failed to confirm OOH request.'}`);
                }
            } catch (error) {
                console.error('Error confirming OOH request:', error);
                alert('An unexpected error occurred while confirming the OOH request.');
            }
        };
        
        const cancelRequestOOH = () => {
            setShowConfirmationPopup(false); // Lukk popup
            setPendingTaskId(null); // Nullstill pending task
        };
          

        const handleSearch = (e) => {
            setSearchQuery(e.target.value.toLowerCase());
        };



          const renderOOHButtonOrText = (task) => {
            const handleAbortOOH = async (taskId) => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${taskId}/ooh`, {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ ooh: 0 }), // Sett OOH-status til 0 (avbrutt)
                    });
        
                    if (response.ok) {
                        setTasks((prevTasks) =>
                            prevTasks.map((t) =>
                                t.id === taskId ? { ...t, ooh: 0 } : t // Oppdater status i state
                            )
                        );
                        alert('OOH request aborted.');
                    } else {
                        const errorData = await response.json();
                        console.error('Error aborting OOH request:', errorData.error || 'Unknown error');
                    }
                } catch (error) {
                    console.error('Error aborting OOH request:', error);
                }
            };
            if (isExpired(task.datetime) || !isOutOfHours(task.datetime)) {
                return null; // Ikke vis noe hvis filen er "expired" eller innenfor åpningstid
              }

                return (
                    <div>
                    {task.ooh === 1 && (
                           
                    <> <div className="oohabort-container">
        <p className="oohreq-text">OOH Requested</p>
                    <button
                        className="abort-ooh-button"
                        onClick={(e) => {
                            e.stopPropagation(); // Hindrer klikket fra å propagere til raden
                            handleAbortOOH(task.id);
                        }}
                    >
                        Abort OOH Request
                    </button>
            </div>
                        </>
                    )}
                    {task.ooh === 2 && <p>OOH Accepted by {task.oohtuner || 'Unknown Admin'}</p>}
                    {task.ooh === 3 && (
                        <div>
                        <p>OOH Request Denied (Try a new time)</p>
                        <button
                            className="request-ooh-button"
                            onClick={(e) => {
                                e.stopPropagation(); // Hindrer klikket fra å propagere til raden
                                handleRequestOOH(task.id);
                            }}
                        >
                            Request OOH
                        </button>
                        </div>
                    )}
                    {task.ooh === 0 && (
        <button
        className="request-ooh-button"
        onClick={(e) => {
            e.stopPropagation(); // Hindrer klikket fra å propagere til raden
            handleRequestOOH(task.id);
        }}
    >
        Request OOH
    </button>
                    )}
                    </div>
                );
          };
          
          
          const renderTaskRow = (task) => {
            return (
                <tr
                    key={task.id}
                    className={getRowClass(task)}
                    onClick={() => handleTaskClick(task)}
                >
                    <td colSpan="6">
                        <div className="task-container">
                            {renderDot(task)}
                            <div className="task-left">
                                <span className="task-vehicle">{task.vehicle}</span>
                                {task.taskowner_name && (
                                    <span className="task-owner">{task.taskowner_name}</span>
                                )}
                            </div>
                            <div className="task-right">
                                <span className="task-datetime">
                                    {isExpired(task.datetime)
                                        ? '(expired, set new date or remove)'
                                        : formatDateTime(task.datetime)}
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        };

        
        return (
            <div className="task-table-container">
            {showConfirmationPopup && (
                <div className="modal-background">
                    <div className="uploadconfirmation">
                        <p>Are you sure you want to request OOH Tuner for this task? Requesting OOH Tuner will cost up to 5 extra non-refundable credits and may have limited support or availability of solutions</p>
                        <div className="popup-actions">
                            <button className="confirm-button" onClick={confirmRequestOOH}>Yes, Confirm</button>
                            <button className="decline-button" onClick={cancelRequestOOH}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            <div className="create-task-input-container">
                <input
                    type="text"
                    placeholder="Create New Customer...."
                    value={newTaskVehicle}
                    onChange={(e) => setNewTaskVehicle(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleCreateTaskQuick();
                        }
                    }}
                    className="create-task-input"
                />
            </div>
            <table className="task-table">
  <tbody>
    {notDoneTasks.map((task) => (
      <tr
        key={task.id}
        className={getRowClass(task)}
        onClick={() => handleTaskClick(task)}
      >
        <td colSpan="6">
          <div className="task-container">
            <div className="task-left">
              {renderDot(task)}
              <span className="task-vehicle">{task.vehicle}</span>
              {task.taskowner_name && (
                <span className="task-owner">{task.taskowner_name}</span>
              )}
            </div>
            <div className="task-right">
              <span className="task-datetime">
                {isExpired(task.datetime)
                  ? "(expired, set new date or remove)"
                  : formatDateTime(task.datetime)}
              </span>
            </div>
          </div>
          {renderOOHButtonOrText(task)}
        </td>
      </tr>
    ))}
  </tbody>
</table>

            <button
            className={`done-tasks-button ${filter === 'All' ? 'active' : ''}`}
            onClick={() => setFilter('All')}
        >
            Done
        </button>
        <table className="task-table">
  <tbody>
    {doneTasks.map((task) => (
      <tr
        key={task.id}
        className={getRowClass(task)}
        onClick={() => handleTaskClick(task)}
      >
        <td colSpan="6">
          <div className="task-container">
            <div className="task-left">
              {renderDot(task)}
              <span className="task-vehicle">{task.vehicle}</span>
              {task.taskowner_name && (
                <span className="task-owner">{task.taskowner_name}</span>
              )}
            </div>
            <div className="task-right">
              <span className="task-datetime">
                {isExpired(task.datetime)
                  ? "(expired, set new date or remove)"
                  : formatDateTime(task.datetime)}
              </span>
            </div>
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>


      <TaskConfirmationPopup
            isOpen={isConfirmOpen}
            onClose={() => setIsConfirmOpen(false)}
            onConfirm={() => {
            handleDeleteTask();
            setIsConfirmOpen(false);
            }}
            onCancel={() => setIsConfirmOpen(false)}
        />

                </div>
            );
        };


    const renderActiveContent = () => {
        if (viewMode === 'default') {
          return renderTasks();
        } else if (viewMode === 'weekly') {
          return (
            <WeekPlanner
              tasks={tasks}
              onTaskClick={(task) => setSelectedTask(task)} // Åpne oppgave ved klikk
            />
          );
        }
      };
    

      return (
        <div className="task-profilemodal-background">
      <div
        className={`task-profilemodal-container ${
          selectedTask ? 'with-task-details' : 'without-task-details'
        }`}
      >
            <div className="task-profilemodal-content">
              <div className="modal-header">
                <button className="close-btn" onClick={onClose}>
                  CLOSE
                </button>
              </div>
              <div className="view-toggle-buttons">
                <button
                  className={`toggle-button ${viewMode === 'default' ? 'active' : ''}`}
                  onClick={() => setViewMode('default')}
                >
                  Customer List
                </button>
                <button
                  className={`toggle-button ${viewMode === 'weekly' ? 'active' : ''}`}
                  onClick={() => setViewMode('weekly')}
                >
                  Weekplanner
                </button>
              </div>
              <div className="member-filters">
                <button
                  className={`member-filter-button ${filter === 'All' ? 'active' : ''}`}
                  onClick={() => setFilter('All')}
                >
                  All
                </button>
                {members.map((member) => (
                  <button
                    key={member.id}
                    className={`member-filter-button ${filter === String(member.id) ? 'active' : ''}`}
                    onClick={() => setFilter(String(member.id))}
                  >
                    {member.name}
                  </button>
                ))}
              </div>
              <div className="task-profile-content">{renderActiveContent()}</div>
            </div>
    
        {selectedTask && (
          <div className="task-details-column">
            <TaskDetails
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              comments={comments}
              setComments={setComments} // Send setComments som prop
              newComment={newComment}
              setNewComment={setNewComment}
              addComment={() => console.log('Add comment')}
              members={members}
              handleModalInputChange={(e) => console.log(e.target.value)}
              handleSaveTask={() => console.log('Save task')}
              handleDeleteTask={() => console.log('Delete task')}
              updateTask={updateTask} // Legger til updateTask
              userProfile={userProfile} // Send userProfile som prop
            />
          </div>
        )}
          </div>
        </div>
      );
}
