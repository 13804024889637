import React, { useState, useEffect } from 'react';

const LatestAlerts = ({ userStats, websocket }) => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchNotifications = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getnotificationslog`);
      const data = await response.json();

      const latestNotifications = data
        .filter((notification) => notification.to === 'admins')
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(0, 3);

      setNotifications(latestNotifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();

    if (websocket) {
      const handleMessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          if (data.type === 'notification') {
            setNotifications(prevNotifications => {
              const newNotification = data.data;

              // Only add if it's an admin notification
              if (newNotification.to !== 'admins') {
                return prevNotifications;
              }

              // Add new notification and maintain sorting and limit
              const updatedNotifications = [newNotification, ...prevNotifications]
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .slice(0, 3);

              return updatedNotifications;
            });
          }
        } catch (error) {
          console.error('Error handling WebSocket message:', error);
        }
      };

      websocket.addEventListener('message', handleMessage);

      return () => {
        websocket.removeEventListener('message', handleMessage);
      };
    }
  }, [websocket]);

  return (
    <div className="alertcenter-alerts">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <table className="custom-alert-table">
          <tbody>
            {notifications.map((notification) => (
              <tr key={notification.id} className="table-row">
                <td className="table-cell">
                  {notification.body.length > 80
                    ? `${notification.body.slice(0, 80)}...`
                    : notification.body}
                  <span className="alerts-title">
                    {notification.title}
                  </span>
                </td>
                <td className="table-cell">
                {
                new Date(notification.created_at).toLocaleString('no-NO', {
                    timeZone: 'UTC', // Bruk UTC for å unngå lokal tidssonejustering når vi bruker verdi direkte fra databasen og formatterer visning av dato
                    hour: '2-digit',
                    minute: '2-digit',
                })
                }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LatestAlerts;