import React, { useState, useRef, useEffect } from "react";

import tasknote from "../assets/tasknote.png";
import taskperson from "../assets/taskperson.png";
import taskcalender from "../assets/taskcalender.png";
import taskhidden from "../assets/taskhidden.png";
import taskchat from "../assets/taskchat.png";

import "./Tasks.css"; // <--- create and adjust name as needed

export default function TaskDetails({
  selectedTask,
  setSelectedTask,
  members,
  comments,
  newComment,
  setNewComment,
  setComments,
  updateTask, // keep your logic
  userProfile,
  fetchTasks,
}) {
  const [isEditing, setIsEditing] = useState({});
  const modalRef = useRef(null);
  const [editedTask, setEditedTask] = useState(selectedTask || {});
  const [isNotificationChecked, setIsNotificationChecked] = useState(false);

  // ---------------------
  //  Keep all your logic:
  // ---------------------
  useEffect(() => {
    setEditedTask(selectedTask || {});
  }, [selectedTask]);

  const handleNotificationCheckboxChange = (e) => {
    setIsNotificationChecked(e.target.checked);
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setEditedTask((prev) => ({
      ...prev,
      [name]: name === 'taskowner' ? parseInt(value, 10) : value,
    }));
  };
  

  const formatDateTimeForMySQL = (isoDateString) => {
    if (!isoDateString) return null;
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const saveTask = async () => {
    try {
      const formattedTask = {
        ...editedTask,
        datetime: formatDateTimeForMySQL(editedTask.datetime),
        enddatetime: formatDateTimeForMySQL(editedTask.enddatetime),
      };

      const response = await fetch(
        `${process.env.REACT_APP_SITE_URL}/tasks/${editedTask.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formattedTask),
        }
      );

      if (response.ok) {
        setEditedTask(formattedTask);
        setSelectedTask(formattedTask);
        updateTask(formattedTask);
      } else {
        const errorData = await response.json();
        console.error("Error saving task:", errorData);
      }
    } catch (error) {
      console.error("Error saving task:", error);
    }
  };

  const saveTime = async (taskToSave) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SITE_URL}/tasks/${taskToSave.id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(taskToSave),
        }
      );

      if (response.ok) {
        setSelectedTask(taskToSave);
        updateTask(taskToSave);
        if (fetchTasks) {
          await fetchTasks();
        }
      } else {
        const errorData = await response.json();
        console.error("Error saving task:", errorData);
      }
    } catch (error) {
      console.error("Error saving task:", error);
    }
  };

  const handleFieldEdit = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: true }));
  };

  const handleFieldBlur = (field) => {
    setIsEditing((prev) => ({ ...prev, [field]: false }));
    saveTask();
  };

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      Object.keys(isEditing).forEach((field) => {
        if (isEditing[field]) {
          handleFieldBlur(field);
        }
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isEditing]);

  const sendNotificationToAdmin = async (title, body, data = {}) => {
    const sanitizedData = Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = String(value);
      return acc;
    }, {});
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ title, body, data: sanitizedData }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send notification to admin");
      }
      const result = await response.json();
      // console.log('Notification sent to admin:', result);
    } catch (error) {
      console.error("Error sending notification to admin:", error);
    }
  };

  const adjustDateTime = async (minutesToAdd) => {
    try {
      const currentDateTime = new Date(editedTask.datetime);
      currentDateTime.setMinutes(currentDateTime.getMinutes() + minutesToAdd);

      const year = currentDateTime.getFullYear();
      const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
      const day = String(currentDateTime.getDate()).padStart(2, "0");
      const hours = String(currentDateTime.getHours()).padStart(2, "0");
      const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");
      const seconds = String(currentDateTime.getSeconds()).padStart(2, "0");

      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      const updatedTask = { ...editedTask, datetime: formattedDateTime };
      setEditedTask(updatedTask);
      await saveTime(updatedTask);

      if (setSelectedTask) {
        setSelectedTask(updatedTask);
      }
    } catch (error) {
      console.error("Error adjusting datetime:", error);
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) {
      console.error("Comment is empty");
      return;
    }
    if (!selectedTask || !selectedTask.id) {
      console.error("No task selected");
      return;
    }

    const payload = {
      taskid: selectedTask.id,
      user: userProfile?.name || "Unknown User",
      message: newComment,
    };

    try {
      // Post to a custom route that checks duplicates or so:
      const response = await fetch(
        `${process.env.REACT_APP_SITE_URL}/taskcom-with-check`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const addedComment = await response.json();
        setComments((prevComments) => [
          ...prevComments,
          { ...payload, id: addedComment.commentId, date: new Date() },
        ]);
        setNewComment("");

        // Send notification only if checkbox is checked
        if (isNotificationChecked) {
          const notificationTitle = `${userProfile.companyName} (${
            userProfile?.name || "Unknown User"
          })`;
          const notificationBody = `(Customer) ${
            selectedTask.vehicle
          }: ${newComment}`;
          const notificationData = {
            taskId: String(selectedTask.id),
            commentId: String(addedComment.commentId),
          };

          await sendNotificationToAdmin(
            notificationTitle,
            notificationBody,
            notificationData
          );
        }
      } else {
        const errorResponse = await response.json();
        console.error("Server error:", errorResponse);
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  // If no task selected, show nothing
  if (!selectedTask) return null;

  // ---------------------
  //  BRAND-NEW UI LAYOUT
  // ---------------------
  return (
    <div className="new-task-details-container" ref={modalRef}>
      {/* Top bar with close button */}
      <div className="new-task-details-topbar">
        <button
          className="new-task-details-close-btn"
          onClick={() => setSelectedTask(null)}
        >
          X
        </button>
      </div>

      {/* Top section: main info about the task */}
      <div className="new-task-details-top">
        {/* The "title" (vehicle name) */}
        <div className="new-task-details-title">
          <div className={`status-dot ${selectedTask.done ? "done" : "not-done"}`}></div>
          {isEditing.vehicle ? (
            <input
              className="title-edit-input"
              type="text"
              name="vehicle"
              value={editedTask.vehicle || ""}
              onChange={handleModalInputChange}
              onBlur={() => handleFieldBlur("vehicle")}
              autoFocus
            />
          ) : (
            <h2 onClick={() => handleFieldEdit("vehicle")}>
              {editedTask.vehicle || "Click to edit title"}
            </h2>
          )}
        </div>

        {/* Date/time row */}
        <div className="new-task-details-time-row">
          <img src={taskcalender} alt="calendar" />
          {isEditing.datetime ? (
            <input
              type="datetime-local"
              name="datetime"
              value={editedTask.datetime || ""}
              onChange={handleModalInputChange}
              onBlur={() => handleFieldBlur("datetime")}
              autoFocus
              className="time-edit-input"
            />
          ) : (
            <span
              className="time-display"
              onClick={() => handleFieldEdit("datetime")}
            >
              {editedTask.datetime
                ? new Date(editedTask.datetime).toLocaleString("no-NO", {
                    dateStyle: "short",
                    timeStyle: "short",
                  })
                : "Set date"}
            </span>
          )}
        </div>

        {/* Quick date/time adjust buttons */}
        <div className="new-task-details-adjust-buttons">
          <button onClick={() => adjustDateTime(-1440)}>-1d</button>
          <button onClick={() => adjustDateTime(-60)}>-1t</button>
          <button onClick={() => adjustDateTime(-10)}>-10m</button>
          <button onClick={() => adjustDateTime(10)}>+10m</button>
          <button onClick={() => adjustDateTime(60)}>+1t</button>
          <button onClick={() => adjustDateTime(1440)}>+1d</button>
        </div>

        {/* Shared info/notes (info) */}
        <div className="new-task-details-info-section">
          <img src={tasknote} alt="note" />
          {isEditing.info ? (
            <textarea
              className="info-edit-textarea"
              name="info"
              rows="5"
              value={editedTask.info || ""}
              onChange={handleModalInputChange}
              onBlur={() => handleFieldBlur("info")}
              autoFocus
            />
          ) : (
            <p onClick={() => handleFieldEdit("info")}>
              {editedTask.info || "Click to add shared (Fileservice) notes"}
            </p>
          )}
        </div>

        {/* Internal/hidden notes (customer) */}
        <div className="new-task-details-hidden-section">
          <img src={taskhidden} alt="hidden" />
          {isEditing.customer ? (
            <textarea
              className="hidden-edit-textarea"
              name="customer"
              rows="3"
              value={editedTask.customer || ""}
              onChange={handleModalInputChange}
              onBlur={() => handleFieldBlur("customer")}
              autoFocus
            />
          ) : (
            <p onClick={() => handleFieldEdit("customer")}>
              {editedTask.customer || "Click to add internal (hidden) notes"}
            </p>
          )}
        </div>

        {/* Task owner assignment */}
        <div className="new-task-details-owner-row">
          <img src={taskperson} alt="person" />
          {isEditing.taskowner ? (
            <select
              name="taskowner"
              value={editedTask.taskowner || ""}
              onChange={handleModalInputChange}
              onBlur={() => handleFieldBlur("taskowner")}
              autoFocus
            >
              <option value="">Unassigned</option>
              {members.map((member) => (
                <option key={member.id} value={member.id}>
                  {member.name}
                </option>
              ))}
            </select>
          ) : (
            <span onClick={() => handleFieldEdit("taskowner")}>
              {members.find((m) => m.id === editedTask.taskowner)?.name ||
                "Assign to someone"}
            </span>
          )}
        </div>
      </div>

      {/* Middle section: the comment list (scrollable) */}
      <div className="new-task-details-main">
        {/* The scrollable area with all existing comments */}
        <div className="new-task-details-comments-list">
          {comments.length > 0 ? (
            comments.map((comment) => (
              <div key={comment.id} className="new-task-comment">
                <div className="new-task-comment-header">
                  <strong>{comment.user}</strong>
                  <span>
                    {new Date(comment.date).toLocaleString("no-NO", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </span>
                </div>
                <div className="new-task-comment-body">
                  {comment.message}
                </div>
              </div>
            ))
          ) : (
            <p className="no-comments">No comments yet. Start the conversation!</p>
          )}
        </div>
      </div>

      {/* Bottom section: new comment field + "Alert to tuner" checkbox */}
      <div className="new-task-details-bottom">
        <div className="new-task-details-comment-input">
          <textarea
            placeholder="Add a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            rows="3"
          ></textarea>
          <button
            onClick={handleAddComment}
            className="new-task-details-add-comment-button"
            disabled={!newComment.trim()}
          >
            Send
          </button>
        </div>

        <div className="new-task-details-notification-checkbox">
          <label>
            <input
              type="checkbox"
              checked={isNotificationChecked}
              onChange={handleNotificationCheckboxChange}
            />
            Alert to tuner
          </label>
          <small>
            (Check this only if you want to notify about time changes or keep the
            fileservice alerted with important info.)
          </small>
        </div>
      </div>
    </div>
  );
}
