// 
import React, { createContext, useContext, useState } from 'react';

const ReadingMessageContext = createContext();

export function ReadingMessageProvider({ children }) {
  const [isReadingMessage, setIsReadingMessage] = useState(false);

  return (
    <ReadingMessageContext.Provider value={{ isReadingMessage, setIsReadingMessage }}>
      {children}
    </ReadingMessageContext.Provider>
  );
}

// Custom hook for easy usage
export function useReadingMessage() {
  return useContext(ReadingMessageContext);
}
