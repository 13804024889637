import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../../auth/AuthProvider';
import { UserContext } from '../../context/UserContext';
import Newscenter from './Newscenter';
import Support from './Support';
import Dropbox from './Dropbox';
import Optischool from './Optischool';
import Manuals from './Manuals';
import './../../App.css';

export default function Opticenter() {
    const { authToken } = useAuth();
    const { userProfile, setUserProfile } = useContext(UserContext);
    const [selectedCategory, setSelectedCategory] = useState('dropbox');
    const [isAdmin, setIsAdmin] = useState(false);

    // Menu access configuration
    const menuAccess = {
        newscenter: isAdmin, // Admins only
        support: true, // Admins only
        dropbox: true, // Alle
        optischool: isAdmin, // Admins only
        manuals: isAdmin, // Admins only
    };

    // Category names for breadcrumb display
    const categoryNames = {
        newscenter: 'Newscenter',
        support: 'Supportforum',
        dropbox: 'Dropbox',
        optischool: 'Optischool',
        manuals: 'Manuals',
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserProfile(data);
                    setIsAdmin(data.admin === 1);
                } else {
                    throw new Error('Failed to fetch user profile');
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
        if (authToken) {
            fetchUserProfile();
        }
    }, [authToken, setUserProfile]);

    useEffect(() => {
        if (!document.getElementById('dropboxjs')) {
            const script = document.createElement('script');
            script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
            script.id = 'dropboxjs';
            script.setAttribute('data-app-key', 'oarwzxmdki584d4'); // Replace with your app key
            document.body.appendChild(script);
        }
    }, []);

    const renderCategory = () => {
        switch (selectedCategory) {
            case 'newscenter':
                return <Newscenter />;
            case 'support':
                return <Support isAdmin={isAdmin} />;
            case 'dropbox':
                return <Dropbox />;
            case 'optischool':
                return <Optischool />;
            case 'manuals':
                return <Manuals />;
            default:
                return <Newscenter />;
        }
    };

    const renderMenu = () => {
        return Object.keys(menuAccess).map((category) => {
            if (menuAccess[category]) {
                return (
                    <li
                        key={category}
                        className="opticenter-menu-title"
                        onClick={() => setSelectedCategory(category)}
                    >
                        {categoryNames[category]}
                    </li>
                );
            }
            return null;
        });
    };

    return (
        <div className="opticenter-container">
            <div className="opticenter-sidebar">
                <div className="opticenter-profile-section">
                    Hello,<h3 className="opticenter-top-name">{userProfile.name}</h3>
                    {/* <p>{isAdmin ? 'Admin' : 'User'}</p> */}
                    {/* <p>{userProfile.companyName}</p> */}
                </div>
                <div className="opticenter-category-section">
                    <ul>{renderMenu()}</ul>
                </div>
            </div>
            <div className="opticenter-content-section">
                {/* Breadcrumb section */}
                <div className="opticenter-breadcrumb">
                    <p>
                        <span
                            className="breadcrumb-link"
                            onClick={() => setSelectedCategory('newscenter')}
                        >
                            Opticenter
                        </span>
                        {' > '}
                        <span className="breadcrumb-link" onClick={() => setSelectedCategory(selectedCategory)}>
                            {categoryNames[selectedCategory]}
                        </span>
                    </p>
                </div>
                {menuAccess[selectedCategory] ? renderCategory() : <p>Access Denied: Section Restricted</p>}
            </div>
        </div>
    );
}
