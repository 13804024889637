import React, { useState, useEffect, useContext, useRef } from 'react';
import { useAuth } from '../../auth/AuthProvider';
import { UserContext } from '../../context/UserContext';

export default function Support({ isAdmin, isTuner, isSupport }) {
  const { authToken } = useAuth();
  const { userProfile, setUserProfile } = useContext(UserContext);
  const [showReplyField, setShowReplyField] = useState(false); // Ny tilstand for å styre synligheten av reply-feltet
  const [categories, setCategories] = useState({
    1: {
      id: 1,
      name: 'Introduce Yourself',
      icon: '👋',
      description: 'Few words about you',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    },
    2: {
      id: 2,
      name: 'General Support / Help',
      icon: '🆘',
      description: 'The General Help & Discussions',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    },
    3: {
      id: 3,
      name: 'Hardware Discussions',
      icon: '💻',
      description: 'General Posts About Tools, Laptops, Diagnostic`s etc..',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    },
    4: {
      id: 4,
      name: 'Guides, Procedures & Tutorials',
      icon: '🔷',
      description: 'Have you something you would like to share?',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    },
    5: {
      id: 5,
      name: 'Dynotalk and Modifications',
      icon: '🏎️⚙️',
      description: 'General Posts About Dynos, Figures, Modifications etc..',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    },
    6: {
      id: 6,
      name: 'Supportmembers Only',
      icon: '🫶',
      description: 'Closed forums for Supportmembers discussion..',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    }

  });

  const [topics, setTopics] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentTopic, setCurrentTopic] = useState(null);
  const [currentTopicTitle, setCurrentTopicTitle] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [newTitle, setNewTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [userPostCounts, setUserPostCounts] = useState({});
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [quote, setQuote] = useState(null);
  const textareaRef = useRef(null);
  const replyFieldRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState(''); // Ny tilstand for søkefeltet
  const [notificationOption, setNotificationOption] = useState(null); // Ingen standardverdi valgt



  const [selectedIcon, setSelectedIcon] = useState(null);
  
  const [icons, setIcons] = useState([]);
  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/support-icons`);
        if (response.ok) {
          const data = await response.json();
          setIcons(data);
        } else {
          console.error('Failed to fetch icons');
        }
      } catch (error) {
        console.error('Error fetching icons:', error);
      }
    };
  
    fetchIcons();
  }, []);

  
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserProfile(data);
        } else {
          throw new Error('Failed to fetch user profile');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    if (authToken) {
      fetchUserProfile();
    }
  }, [authToken, setUserProfile]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/categories`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log('API Response:', data); // Sjekk om data inneholder lastUser og lastMessageDate
        const updatedCategories = { ...categories };
        data.forEach(category => {
          if (updatedCategories[category.categoryId]) {
            updatedCategories[category.categoryId].topicsCount = category.topicsCount;
            updatedCategories[category.categoryId].messagesCount = category.repliesCount;
            updatedCategories[category.categoryId].lastUser = category.lastUser; // Oppdaterer lastUser
            updatedCategories[category.categoryId].lastMessageDate = category.lastMessageDate; // Oppdaterer lastMessageDate
          }
        });
        setCategories(updatedCategories);
      } else {
        throw new Error('Failed to fetch categories');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchCategories();
  }, [authToken]);



  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('no-NO', {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    }).format(date);
  };
  

  

  
  const fetchTopics = async (categoryId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/category-topics/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        // Map topics og parse reads som en liste
        const topicsWithReads = data.map((topic) => ({
          ...topic,
          reads: topic.reads ? topic.reads.split(',').map(Number) : [], // Konverter reads til en liste med tall
        }));
        setTopics(topicsWithReads); // Sett topics med parsed reads
        setCurrentCategory(categoryId);
        setMessages([]);
        setCurrentTopic(null);
        setCurrentTopicTitle('');
      } else {
        throw new Error('Failed to fetch topics');
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
    } finally {
      setLoading(false);
    }
  };
  
  

  const fetchMessages = async (topicId, topicTitle) => {
    if (!topicId) {
        console.error('Error: topicId is undefined or invalid');
        return;
    }

    setLoading(true);

    try {

      await fetch(`${process.env.REACT_APP_SITE_URL}/topic/${topicId}/mark-read`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ userId: userProfile.id }), // Send brukerens ID
      });

      
        await fetch(`${process.env.REACT_APP_SITE_URL}/topics/${topicId}/views`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        });

        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/topic-messages/${topicId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Fetched messages:', data);

            // Fjern duplikater basert på messageId
            const uniqueMessages = data.filter(
                (msg, index, self) => index === self.findIndex((m) => m.messageId === msg.messageId)
            );

            setMessages(uniqueMessages);
            setCurrentTopic(topicId);
            setCurrentTopicTitle(topicTitle);
        } else {
            throw new Error('Failed to fetch messages');
        }
    } catch (error) {
        console.error('Error fetching messages or updating view count:', error);
    } finally {
        setLoading(false);
    }
};


  
  
  

  const fetchUserPostCounts = async (userIds) => {
    try {
      const counts = {};
      await Promise.all(userIds.map(async (userId) => {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-post-count/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          counts[userId] = data.postCount;
        } else {
          counts[userId] = 0;
        }
      }));
      setUserPostCounts(counts);
    } catch (error) {
      console.error('Error fetching user post counts:', error);
    }
  };

  const handleBackToCategories = () => {
    setCurrentCategory(null); // Tilbakestill den valgte kategorien
    setTopics([]); // Tøm topics for å vise hovedkategoriene
    setMessages([]); // Tøm meldinger for å unngå feilvisning
    setCurrentTopic(null); // Tilbakestill den valgte topicen
  };
  

  const handleBackToTopics = () => {
    setCurrentTopic(null);
    setMessages([]);
  };


  const sendNotification = async (userId, title, body, data = {}) => {
    console.log('Preparing to send notification:');
    console.log('UserID:', userId);
    console.log('Title:', title);
    console.log('Body:', body);
    console.log('Data:', data);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userProfile.token}`, // Legg til token for autentisering om nødvendig
        },
        body: JSON.stringify({ userId, title, body, data }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to send notification');
      }
  
      const result = await response.json();
      console.log('Notification sent successfully:', result);
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };
  

  
  
  const handlePostReply = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const timestamp = Date.now();
    const fullMessage = quote
      ? `[[QUOTE${timestamp}]]${quote}[[/QUOTE${timestamp}]]\n\n${newMessage}`
      : newMessage;
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create-reply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          message: fullMessage,
          categoryId: currentCategory,
          topicId: currentTopic,
          userId: userProfile.id,
        }),
      });
  
      if (response.ok) {
        const result = await response.json();
        const threadStarterResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/topic-starter/${currentTopic}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        
        if (threadStarterResponse.ok) {
          const { userId: threadStarterId, title: threadTitle, parent } = await threadStarterResponse.json();
          if (parent && parent !== userProfile.id) {
            const notificationTitle = `New Reply in Topic: ${threadTitle}`;
            const notificationBody = `${userProfile.name}: "${newMessage}"`;
            await sendNotification(parent, notificationTitle, notificationBody, {
              topicId: String(currentTopic),
              sender: String(userProfile.name),
            });
          }
        }
        
        setNewMessage('');
        setQuote(null);
        fetchMessages(currentTopic, currentTopicTitle);
      } else {
        throw new Error('Failed to create reply');
      }
    } catch (error) {
      console.error('Error creating reply or sending notification:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const parseMessageWithQuotes = (message) => {
    const quoteRegex = /\[\[QUOTE(\d+)\]\](.*?)\[\[\/QUOTE\1\]\]/gs;
  
    const parse = (msg) => {
      const parts = [];
      let match;
      while ((match = quoteRegex.exec(msg)) !== null) {
        const [fullMatch, quoteId, quoteContent] = match;
        const beforeQuote = msg.slice(0, match.index);
        if (beforeQuote.trim()) {
          parts.push({ type: "text", content: beforeQuote.trim() });
        }
        parts.push({
          type: "quote",
          id: quoteId,
          content: parse(quoteContent), 
        });

        msg = msg.slice(match.index + fullMatch.length);
      }
      if (msg.trim()) {
        parts.push({ type: "text", content: msg.trim() });
      }
      return parts;
    };
    return parse(message);
  };
  
  
  const renderParsedMessage = (parsedMessage) => {
    return parsedMessage.map((part, index) => {
      if (part.type === "text") {
        return <p key={index}>{part.content}</p>;
      }

      if (part.type === "quote") {
        return (
          <div
            key={index}
            className="quote-box"
            style={{
              borderLeft: "4px solid #ccc",
              paddingLeft: "10px",
              margin: "10px 0",
              background: "#f9f9f9",
            }}
          >
            <strong>Quote #{part.id}:</strong>
            {renderParsedMessage(part.content)}
          </div>
        );
      }
  
      return null;
    });
  };
  
  

  const handleReplyToMessage = (message) => {
    const quote = `> ${message.userName} wrote:\n> ${message.message.replace(/\n/g, '\n> ')}`;
    setQuote(quote);
    setShowReplyForm(true);

    if (replyFieldRef.current) {
      replyFieldRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
  const formatMessageWithQuotes = (message) => {
    const quoteRegex = /\[\[QUOTE\d*\]\](.*?)\[\[\/QUOTE\d*\]\]/gs; // Matcher alle quotes
    let parts = [];
    let lastIndex = 0;
  
    let match;
    while ((match = quoteRegex.exec(message)) !== null) {
      const beforeQuote = message.slice(lastIndex, match.index).trim(); // Tekst før quote
      const quoteContent = match[1].trim(); // Teksten inni quote
  
      if (beforeQuote) {
        parts.push(<p key={`text-${lastIndex}`}>{beforeQuote}</p>);
      }
      parts.push(
        <div key={`quote-${match.index}`} className="quote-box">
          {formatMessageWithQuotes(quoteContent)}
        </div>
      );
      lastIndex = quoteRegex.lastIndex; // Flytt til slutten av matchen
    }
    const afterLastQuote = message.slice(lastIndex).trim();
    if (afterLastQuote) {
      parts.push(<p key={`text-${lastIndex}`}>{afterLastQuote}</p>);
    }
    return parts;
  };
  
  
  const handleDeleteMessage = async (messageId) => {
    if (!messageId) {
      console.error('Message ID is undefined');
      alert('Failed to delete message: Invalid ID');
      return;
    }
  
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/delete-message/${messageId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
  
      if (response.ok) {
        alert('Message deleted successfully');
        fetchMessages(currentTopic, currentTopicTitle); // Oppdater meldinger
      } else {
        throw new Error('Failed to delete message');
      }
    } catch (error) {
      console.error('Error deleting message:', error);
      alert('Failed to delete the message. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  

const sendNotificationToAdmin = async (title, body, data = {}) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ title, body, data }),
        });

        if (!response.ok) {
            throw new Error('Failed to send notification to admin');
        }
        const result = await response.json();

    } catch (error) {
        console.error('Error sending notification to admin:', error);
    }
};

const sendNotificationToAll = async (title, body, data = {}) => {
  try {
    const adminResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ title, body, data }),
    });

    if (!adminResponse.ok) {
      throw new Error('Failed to send notification to admins');
    }
    console.log('Notification sent to admins successfully');
    const massResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/send-mass-notification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ title, body, data }),
    });

    if (!massResponse.ok) {
      throw new Error('Failed to send mass notification');
    }
    console.log('Mass notification sent successfully');
  } catch (error) {
    console.error('Error in sendNotificationToAll:', error);
  }
};


const handlePostTopic = async (e) => {
  e.preventDefault();

  if (!newTitle.trim() || !newMessage.trim()) {
    alert('Please fill out both Title and Message before creating a topic.');
    return; // Avbryt funksjonen
  }
  
  if (!notificationOption) {
    alert('Please select a notification option before proceeding.');
    return;
  }
  setLoading(true);
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create-topic`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        title: newTitle,
        message: newMessage,
        categoryId: currentCategory,
        userId: userProfile.id,
        topicIcon: selectedIcon, // Send valgt ikon
      }),
    });

    if (response.ok) {
      const result = await response.json();
      const categoryName =
        categories[currentCategory]?.name.length > 9
          ? `${categories[currentCategory]?.name.slice(0, 9)}...`
          : categories[currentCategory]?.name || 'Unknown';
      const notificationTitle = `SUPPORTFORUM - ${categoryName} (${userProfile.name})`;
      
      const notificationBody = `${newTitle}: ${newMessage}`;
      const notificationData = {
        topicId: String(result.topicId), // Bruk det opprettede topicId
        sender: String(userProfile.name),
        categoryId: String(currentCategory),
      };

      if (notificationOption === 'staff') {
        await sendNotificationToAdmin(notificationTitle, notificationBody, notificationData);
      } else if (notificationOption === 'all') {
        await sendNotificationToAll(notificationTitle, notificationBody, notificationData);
      }
      setIsModalOpen(false);
      setNewTitle('');
      setNewMessage('');
      setSelectedIcon(null);
      setNotificationOption('none'); // Tilbakestill valgt notifikasjonsalternativ
      fetchTopics(currentCategory); // Refresh the topics list
    } else {
      throw new Error('Failed to create topic');
    }
  } catch (error) {
    console.error('Error creating topic:', error);
  } finally {
    setLoading(false);
  }
};

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('no-NO', { timeZone: 'UTC' }, options);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  const handleThankMessage = async (messageId, thankedUserId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/thank-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          userId: userProfile.id,
          messageId: messageId,
          thankedUserId: thankedUserId, // Legger til thankedUserId
        }),
      });
      if (response.ok) {
        fetchMessages(currentTopic, currentTopicTitle); // Oppdaterer meldinger
      } else {
        throw new Error('Failed to update thank');
      }
    } catch (error) {
      console.error('Error updating thank:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderCategories = () => (
    <div className="categories-grid">
      {Object.keys(categories).map((categoryId) => {
        const isRestrictedCategory = categoryId === '6'; // Begrens tilgang til kategori 6
        const hasAccess =
          isAdmin || userProfile?.tuner === 1 || userProfile?.support === 1; // Sjekk tilgang
  
        return (
          <div
            key={categoryId}
            className={`category-card ${isRestrictedCategory && !hasAccess ? 'inactive-category' : ''}`}
            onClick={() => {
              if (!isRestrictedCategory || hasAccess) {
                fetchTopics(categoryId); // Tillat tilgang hvis ikke-restricted eller har tilgang
              } else {
                alert('Access Denied: This category is restricted to support members.');
              }
            }}
          >
            <div className="category-header">
              <h3>
                {categories[categoryId].icon} {categories[categoryId].name}
              </h3>
            </div>
            <div className="category-body">
              <p>{categories[categoryId].description}</p>
              <div className="category-stats">
                <span>Topics: {categories[categoryId].topicsCount || 0}</span>
                <span>Replies: {categories[categoryId].messagesCount || 0}</span>
              </div>
            </div>
            <div className="category-footer">
              <span>Last post by {categories[categoryId].lastUser || 'Unknown'}</span>
              <span>
                {categories[categoryId].lastMessageDate
                  ? formatDateTime(categories[categoryId].lastMessageDate)
                  : 'No Date'}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
  
  
  const [sortOption, setSortOption] = useState('lastReply'); // Standard sortering etter siste reply

  const sortedTopics = [...topics].sort((a, b) => {
    if (sortOption === 'lastReply') {
      return new Date(b.last_message_date) - new Date(a.last_message_date); // Sorter etter siste reply
    } else if (sortOption === 'replies') {
      return b.reply_count - a.reply_count; // Sorter etter antall svar
    } else if (sortOption === 'views') {
      return b.view_count - a.view_count; // Sorter etter visninger
    }
    return 0; // Ingen sortering hvis ingen match
  });


  const renderTopics = () => (
  <div className="topics-list">
      <h4 className="topiclist-breadcrumb">
        <span className="clickable" onClick={handleBackToCategories}>
          Forum
        </span>{' '}
        > {categories[currentCategory]?.name}
      </h4>

      {/* Sorteringsmeny */}
      <div className="topiclist-sort">

        <select
          id="sortTopics"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
        >
          <option value="lastReply">Sort by: Latest Reply</option>
          <option value="replies">Sort by: Most Replies</option>
          <option value="views">Sort by: Most Views</option>
        </select>
      </div>

      {topics.length === 0 ? (
        <div className="topiclist-empty">No topics found. Start by creating a new topic!</div>
      ) : (
        sortedTopics.map((topic) => (
          <div
          key={topic.topic_id || Math.random()}
          className={`topic ${!topic.reads.includes(userProfile.id) ? 'new-replies' : ''}`} // Legg til klassen "new-replies"
          onClick={() => {
              if (topic.topic_id && topic.title) {
                fetchMessages(topic.topic_id, topic.title);
              } else {
                console.error('Missing topic_id or title for topic:', topic);
              }
            }}
          >
            <div className="topic-column topic-header">
              <div className="topic-header-content">
                <div className="topic-icon">
                  {topic.topic_icon && (
                    <img
                      src={`/assets/supporticons/${topic.topic_icon}`}
                      alt="Topic Icon"
                      className="topic-icon-image"
                    />
                  )}
                </div>
                {!topic.reads.includes(userProfile.id) && (
                  <span
                    style={{
                      color: 'red',
                      fontSize: '18px',
                      marginLeft: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    🔔 New
                  </span>
                )}
                <div className="topic-text">
                  <h3>{topic.title || 'Untitled Topic'}</h3>
                  <p className="topic-author">
                    Author: {topic.userName || 'Unknown Author'}
                  </p>
                </div>
              </div>
            </div>
            <div className="topic-column topic-stats">
              <span>Replies: {topic.reply_count !== undefined ? topic.reply_count : 'N/A'}</span>
              <span>Views: {topic.view_count || 0}</span>
            </div>
            <div className="topic-column topic-last">
              <span>
                Last post by: {topic.last_user || 'No Posts Yet'}
              </span>
              <span>
                {topic.last_message_date
                  ? formatDateTime(topic.last_message_date)
                  : 'No Date'}
              </span>
            </div>
          </div>
        ))
      )}
      <button
        className="confirm-button"
        onClick={() => setIsModalOpen(true)}
      >
        New Topic
      </button>
    </div>
  );

    const constructAvatarUrl = (relativePath) => {
      let baseUrl = process.env.REACT_APP_SITE_URL;
      if (baseUrl.includes('/api')) {
          baseUrl = baseUrl.split('/api')[0];
      }
      return `${baseUrl}${relativePath}`;
  };

  const MessageAvatar = ({ avatar }) => {
    const [avatarUrl, setAvatarUrl] = useState('');
  
    useEffect(() => {
      if (avatar) {
        const constructedAvatarUrl = constructAvatarUrl(avatar);
        setAvatarUrl(constructedAvatarUrl);
      }
    }, [avatar]);
  
    return (
      <div className="message-avatar">
        {avatarUrl ? (
          <img src={avatarUrl} alt="Avatar" className="avatar-icon" />
        ) : (
          <span className="avatar-icon">👤</span> // Default icon
        )}
      </div>
    );
  };
  const generateStars = (replies) => {
    let stars = 0;
    if (replies >= 2 && replies < 20) stars = 1;
    else if (replies >= 20 && replies < 50) stars = 2;
    else if (replies >= 50 && replies < 100) stars = 3;
    else if (replies >= 100 && replies < 200) stars = 4;
    else if (replies >= 200) stars = 5;
  
    return Array(stars)
      .fill('⭐')
      .map((star, index) => <span key={index}>{star}</span>);
  };
  

      const renderMessages = () => (
        <div className="support-messages-list">
          <h4>
            <span className="clickable" onClick={handleBackToCategories}>Forum</span> >
            <span className="clickable" onClick={handleBackToTopics}>{categories[currentCategory]?.name}</span> > {currentTopicTitle || 'Unknown Topic'}
          </h4>
          {messages.length === 0 ? <div>No messages found</div> : null}
          {messages.map((message) => (
            <div key={message.id} className="support-message">
              <div className="support-message-header">

              <div className="support-user-info">
                <div className="support-user-column-left">
                  <div className="support-avatar-container">
                    <MessageAvatar avatar={message.avatar} />
                  </div>
                  <div className="support-name-container">
                  <span className="support-username">
                    {message.userName}
                    <span className="support-stars">{generateStars(message.user_posts)}</span>
                  </span>
                    <span className="support-company">{message.companyName || 'Unknown Company'}</span>
                  </div>
                </div>
                <div className="support-user-column">
                  <span>Replies: {message.user_posts || 0}</span>
                  <span>Topics: {message.user_topics || 0}</span>
                </div>
                <div className="support-user-column">
                  <span>Thanks Given: {message.thanks_given || 0}</span>
                  <span>Thanks Received: {message.thanks_received || 0}</span>
                </div>
              </div>
            <div className="support-message-date">
            {formatDateTime(message.message_date)}
            </div>
          </div>
          <div className="support-message-content">
  {renderParsedMessage(parseMessageWithQuotes(message.message))}
</div>

<div className="support-message-actions">
  <div className="left-actions">
    <button
      className="quote-button"
      onClick={() => handleReplyToMessage(message)}
    >
      Quote Reply
    </button>
  </div>
  <div className="right-actions">
    <button
      className="thank-button"
      onClick={() => handleThankMessage(message.id, message.userid)}
    >
      Thank
    </button>
    {isAdmin && (
        <button
            className="delete-button"
            onClick={() => handleDeleteMessage(message.messageId || message.id)}
        >
            Delete
        </button>
    )}
  </div>
</div>

        </div>
      ))}
      {/* {!showReplyForm && (
        <button className="reply-toggle-button" onClick={() => setShowReplyForm(true)}>
          Reply
        </button>
      )} */}
{showReplyForm && (
  <form
    className="reply-form"
    onSubmit={handlePostReply}
    ref={replyFieldRef} // Knytt referansen her
  >
    {quote && (
      <div className="quote-box">
        <pre>{quote}</pre>
      </div>
    )}
    <textarea
      className="reply-textarea"
      placeholder="Write your quote reply..."
      value={newMessage}
      onChange={(e) => setNewMessage(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          handlePostReply(e);
        }
      }}
    />
    <button type="submit" className="reply-submit-button">
      Post Quote Reply
    </button>
  </form>
)}
        <div className="reply-field">
          <form
            className="reply-form"
            onSubmit={handlePostReply}
          >
            <textarea
              className="reply-textarea"
              placeholder="Write your reply..."
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
                adjustTextareaHeight(e.target);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handlePostReply(e);
                }
              }}
            />
            <button className="replydelete-button" type="submit">Post Reply</button>
          </form>
          <button className="cancel-reply-button" onClick={() => setShowReplyField(false)}>
            Cancel
          </button>
        </div>



      {/* <form className="reply-form" onSubmit={handlePostReply}>
        <textarea
          className="reply-textarea"
          placeholder="Skriv en ny melding..."
          value={newMessage}
          onChange={(e) => {
            setNewMessage(e.target.value);
            adjustTextareaHeight(e.target);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handlePostReply(e);
            }
          }}
        />
        <button className="reply-button" type="submit">Post Reply</button>
      </form> */}

          </div>
        );


  return (
    <div className="support-container">
      {loading && <div className="loader">Loading...</div>}

      <div className="support-search-bar-container">
      {/* Search Bar Section */}
      <div className="support-search-bar">
        <div className="support-search-header">Search the entire forum for knowledge</div>
        <div className="support-search-input-container">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="support-search-input"
          />
          <button
            className="support-search-icon-button"
            onClick={() => console.log('Search for:', searchQuery)}
          >
            <i className="fas fa-search"></i> {/* FontAwesome Search Icon */}
          </button>
        </div>
      </div>

      {/* Image Section */}
      <div className="support-image">
        <img
          src="/assets/supportback.png"
          alt="Support Background"
          className="support-background-image"
        />
      </div>
    </div>





      <div className="content">
        {currentTopic ? renderMessages() : (currentCategory ? renderTopics() : renderCategories())}
      </div>
      {isModalOpen && (
  <div className="modal">
    <div className="support-modal-create-background">
      <div className="support-modal-create">
        <button className="close-x" onClick={() => setIsModalOpen(false)}>
          &times;
        </button>
        <h2>CREATE NEW TOPIC</h2>
        <form onSubmit={handlePostTopic}>
          <input
            type="text"
            placeholder="Title"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <textarea
            placeholder="Message"
            value={newMessage}
            onChange={(e) => {
              setNewMessage(e.target.value);
              adjustTextareaHeight(e.target);
            }}
          />
          <div className="icon-selector">
            <p>Select an icon for the topic:</p>
            <div className="icon-no-icon">
              <label className={`radio-button ${selectedIcon === null ? 'selected' : ''}`}>
                <input
                  type="radio"
                  name="icon"
                  checked={selectedIcon === null}
                  onChange={() => setSelectedIcon(null)}
                />
                <span className="radio-custom"></span>
                No Icon
              </label>
            </div>
            <div className="icon-grid">
              {icons.map((icon) => (
                <div
                  key={icon}
                  className={`icon-wrapper ${selectedIcon === icon ? 'selected' : ''}`}
                  onClick={() => setSelectedIcon(icon)}
                >
                  <img
                    src={`/assets/supporticons/${icon}`}
                    alt={`Icon ${icon}`}
                    className="icon"
                  />
                  
                </div>
              ))}
            </div>
          </div>

          <div className="notification-options">
            <p>Select Notification Option:</p>
            <label>
              <input
                type="radio"
                name="notification"
                value="none"
                checked={notificationOption === 'none'}
                onChange={() => setNotificationOption('none')}
              />
              No Notifications (This is for non-urgent topics)
            </label>
            <label>
              <input
                type="radio"
                name="notification"
                value="staff"
                checked={notificationOption === 'staff'}
                onChange={() => setNotificationOption('staff')}
              />
              Notifications to Staff (May result in longer response time)
            </label>
            <label>
              <input
                type="radio"
                name="notification"
                value="all"
                checked={notificationOption === 'all'}
                onChange={() => setNotificationOption('all')}
              />
              Notifications to All (This will alert everyone, and give you fast response)
            </label>
          </div>

          <button className="confirm-button" type="submit">
            Create topic
          </button>
        </form>
      </div>
    </div>
  </div>
)}

    </div>
  );
}
