import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import UsersOnline from './UsersOnline';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const UserStats = () => {
  const [logEntries, setLogEntries] = useState([]);
  const [companyChartData, setCompanyChartData] = useState(null);

  useEffect(() => {
    fetchLogEntries();
    fetchCompanyChartData();
  }, []);

  const fetchLogEntries = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/model-log`);
      const data = await response.json();
      setLogEntries(data);
    } catch (error) {
      console.error('Error fetching log entries:', error);
    }
  };

  const fetchCompanyChartData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/model-log-chart`);
      const data = await response.json();
      setCompanyChartData(processChartData(data));
    } catch (error) {
      console.error('Error fetching company chart data:', error);
    }
  };

  const processChartData = (data) => {
    const months = [
      'January', 'February', 'March', 'April', 'May',
      'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const groupedData = data.reduce((acc, { year, month, count, companyid }) => {
      if (!acc[companyid]) {
        acc[companyid] = Array(12).fill(0);
      }
      acc[companyid][month - 1] += count;
      return acc;
    }, {});

    const datasets = Object.keys(groupedData).map((companyid) => ({
      label: `Company ${companyid}`,
      data: groupedData[companyid],
      borderColor: getRandomColor(),
      backgroundColor: getRandomColor(0.5),
      spanGaps: true,
    }));

    return {
      labels: months,
      datasets,
    };
  };

  const getRandomColor = (opacity = 1) => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  return (
    <div className="dbstatscoluser-stats-container">
      <div className="dbstatscolrow">
        <div className="dbstatscol col-1">
        <UsersOnline />
        </div>

        <div className="dbstatscol col-2">
        {companyChartData ? (
            <Line
              data={companyChartData}
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: 'Databaseupdates',
                  },
                },
              }}
            />
          ) : (
            <p>Loading chart...</p>
          )}
        </div>


      </div>
      <div className="dbstatscolrow full-width">
        <h3>Log Entries</h3>
        <div className="unique-log-container">
      {logEntries.map((entry, index) => (
      <div key={index} className="log-entry">

        <strong>{entry.user}</strong> ({entry.company}) updated 
        <span className="model-name"> {entry.model} </span>
        on <span className="timestamp">{new Date(entry.created_at).toLocaleString()}</span>
        - <span className="definition">{entry.definition}</span>

    </div>
  ))}
</div>

      </div>
    </div>
  );
  
};

export default UserStats;
