import React, { useState } from 'react';

const VersionSelector = ({ versions, fileId, filecomId, onVersionSelect, onClose, userProfile, fileOptionsStatus }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => {});

  // Helper function to get original options from fileOptionsStatus
  const getOriginalOptions = () => {
    const options = [];

    if (fileOptionsStatus) {
      if (fileOptionsStatus.tuning_type === 3) {
        options.push('OPTI');
      }
      if (fileOptionsStatus.tuning_type === 4) {
        options.push('POWER');
      }
      if (fileOptionsStatus.egr !== 0) options.push('EGR');
      if (fileOptionsStatus.dpf !== 0) options.push('DPF');
      if (fileOptionsStatus.adblue !== 0) options.push('UREA');
      if (fileOptionsStatus.o2 !== 0) options.push('O2');
      if (fileOptionsStatus.epa !== 0) options.push('EPA');
      if (fileOptionsStatus.hybridturbo !== 0) options.push('HYBRIDTURBO');
      if (fileOptionsStatus.gearbox !== 0) options.push('GEARBOX');
      if (fileOptionsStatus.nsl !== 0) options.push('NSL');
      if (fileOptionsStatus.coldstart !== 0) options.push('COLDSTART');
      if (fileOptionsStatus.startstop !== 0) options.push('STARTSTOP');
      if (fileOptionsStatus.popsbangs !== 0) options.push('POPSBANGS');
      if (fileOptionsStatus.burbles !== 0) options.push('BURBLES');
      if (fileOptionsStatus.swirlflaps !== 0) options.push('SWIRLFLAPS');
      if (fileOptionsStatus.tva !== 0) options.push('TVA');
    }

    return options.join(' ');
  };

  // Function to post internal message
  const postInternalMessage = async (originalOptions, selectedVersion) => {
    try {
      const message = `Version selection: Original request was "${originalOptions}", user selected "${selectedVersion}"`;

      const payload = {
        fileId,
        user: 135,
        message: message,
        internal: 1, // Set as internal message
        tunercheck: 0,
      };

      const messageResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/postMessage`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!messageResponse.ok) {
        throw new Error('Failed to post internal message');
      }

      console.log('Internal message posted successfully');
    } catch (err) {
      console.error('Error posting internal message:', err);
      // We don't want to fail the main operation if this fails
    }
  };

  const executeVersionSelect = async (version) => {
    try {
      setIsLoading(true);
      setError(null);

      // Get original options for the internal message
      const originalOptions = getOriginalOptions();

      // Post the internal message first
      await postInternalMessage(originalOptions, version);

      const versionParams = version.split(/[\s,]+/).map(param => param.trim());

      const parameters = {
        OPTI: false,
        POWER: false,
        EGR: false,
        DPF: false,
        UREA: false,
        O2: false,
        EPA: false,
        HYBRIDTURBO: false,
        GEARBOX: false,
        NSL: false,
        COLDSTART: false,
        STARTSTOP: false,
        POPSBANGS: false,
        BURBLES: false,
        SWIRLFLAPS: false,
        TVA: false
      };

      versionParams.forEach(param => {
        switch (param.toUpperCase()) {
          case 'OPTI': parameters.OPTI = true; break;
          case 'POWER': parameters.POWER = true; break;
          case 'EGR': parameters.EGR = true; break;
          case 'DPF': parameters.DPF = true; break;
          case 'UREA': parameters.UREA = true; break;
          case 'O2': parameters.O2 = true; break;
          case 'EPA': parameters.EPA = true; break;
          case 'HYBRIDTURBO': parameters.HYBRIDTURBO = true; break;
          case 'GEARBOX': parameters.GEARBOX = true; break;
          case 'NSL': parameters.NSL = true; break;
          case 'COLDSTART': parameters.COLDSTART = true; break;
          case 'STARTSTOP': parameters.STARTSTOP = true; break;
          case 'POPSBANGS': parameters.POPSBANGS = true; break;
          case 'BURBLES': parameters.BURBLES = true; break;
          case 'SWIRLFLAPS': parameters.SWIRLFLAPS = true; break;
          case 'TVA': parameters.TVA = true; break;
        }
      });

      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/process-version`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          parameters,
          fileId,
          fcid: filecomId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to process version');
      }

      onVersionSelect(); // Callback to parent to handle success
    } catch (err) {
      setError('Failed to process version. Please try again.');
      console.error('Version processing error:', err);
    } finally {
      setIsLoading(false);
      setShowConfirmation(false); // Hide modal after completion
    }
  };

  const executeRequestOriginal = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/fireNotification/${fileId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to send request');
      }

      const statusResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/update-status-no-tuner/${fileId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 0,
          tuner: 135,
          dealercheck: 1,
          tunercheck: 1,
        }),
      });

      if (!statusResponse.ok) {
        throw new Error('Failed to update file status');
      }

      onVersionSelect(); // Notify parent of success
    } catch (error) {
      setError('Failed to send request. Please try again.');
      console.error('Error sending request:', error);
    } finally {
      setIsLoading(false);
      setShowConfirmation(false);
    }
  };

  const confirmVersionSelect = (version) => {
    setConfirmationMessage(`Are you sure you want to proceed with version "${version}"?`);
    setOnConfirm(() => () => executeVersionSelect(version));
    setShowConfirmation(true);
  };

  const confirmRequestOriginal = () => {
    setConfirmationMessage('Are you sure you want to request the original options from the file service?');
    setOnConfirm(() => executeRequestOriginal);
    setShowConfirmation(true);
  };

  const handleConfirmClick = () => {
    if (onConfirm) onConfirm();
  };

  const handleDeclineClick = () => {
    setShowConfirmation(false);
  };

  const isStandardVersion = (version) => {
    const standardParams = [
      'OPTI',
      'POWER',
      'EGR',
      'DPF',
      'UREA',
      'O2',
      'EPA',
      'HYBRIDTURBO',
      'GEARBOX',
      'NSL',
      'COLDSTART',
      'STARTSTOP',
      'POPSBANGS',
      'BURBLES',
      'SWIRLFLAPS',
      'TVA'
    ];

    const versionParams = version.split(/[\s,]+/).map(param => param.trim().toUpperCase());

    return versionParams.every(param => standardParams.includes(param));
  };

  return (
    <>
    <div className="version-selector bg-white p-4 rounded-lg shadow-lg">
      <div className="mb-4 flex justify-between items-center">
        <p className="detailtextmedium"><b>Available Versions (Recive Instant file)</b></p>
      </div>

      {error && (
        <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
          {error}
        </div>
      )}

      <div className="grid grid-cols-2 gap-2">
        {versions
          .filter(isStandardVersion)
          .map((version, index) => (
            <button
              key={index}
              onClick={() => confirmVersionSelect(version)}
              disabled={isLoading}
              className="versionselector-button"
            >
              {version}
            </button>
          ))}

      <p className="detailtextcleartopmedium"><b>Request intial options: ( Actual options )</b></p>
        <button
          onClick={confirmRequestOriginal}
          disabled={isLoading}
          className="confirm-button"
        >
          Send to fileservice
        </button>
      </div>

      {isLoading && (
        <div className="mt-4 text-center text-gray-600">
          Processing version...
        </div>
      )}
    </div>
    {showConfirmation && (
        <div className="modal-background">
          <div className="uploadconfirmation">
            <div className="confirm-header">
              <p>{confirmationMessage}</p>
            </div>
            <div className="modal-body">
              <button className="confirm-button" onClick={handleConfirmClick}>
                CONFIRM
              </button>
              <button className="decline-button" onClick={handleDeclineClick}>
                CANCEL
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VersionSelector;