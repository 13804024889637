import React from 'react';

const SvvDetails = ({ dealerCountry, vehicleData }) => {
  if (dealerCountry === 'NO') {
    return vehicleData && vehicleData.kjoretoydataListe && vehicleData.kjoretoydataListe[0] ? (
      <div className="svv-box">
        <p className="detailtext"><b>Brand:</b> {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse?.[0] || 'Ikke oppgitt'}</p>
        <p className="detailtext"><b>Licenseplate:</b> {vehicleData.kjoretoydataListe[0].kjoretoyId?.kjennemerke || 'Ikke oppgitt'}</p>
        <p className="detailtext"><b>VIN:</b> {vehicleData.kjoretoydataListe[0].kjoretoyId?.understellsnummer || 'Ikke oppgitt'}</p>
        <p className="detailtext"><b>Registration date:</b> {vehicleData.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato || 'Ikke oppgitt'}</p>
        <p className="detailtext"><b>Emission:</b> {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.euroKlasse?.kodeBeskrivelse?.substring(0, 8) || 'Ikke oppgitt'}</p>
        <p className="detailtext"><b>Particulatefilter:</b> {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.[0]?.forbrukOgUtslipp?.[0]?.partikkelfilterFabrikkmontert ? 'Ja' : 'Nei'}</p>
        <p className="detailtext"><b>Fueltype:</b> {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.[0]?.drivstoffKodeMiljodata?.kodeBeskrivelse || 'Ikke oppgitt'}</p>
        <p className="detailtext"><b>Enginesize:</b> {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor?.[0]?.slagvolum ? `${vehicleData.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.motorOgDrivverk.motor[0].slagvolum} cm3` : 'Ikke oppgitt'}</p>
        <p className="detailtext"><b>Gearbox:</b> {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.girkassetype?.kodeBeskrivelse || 'Ikke oppgitt'}</p>
        <p className="detailtext"><b>Power:</b> 
          {(() => {
            const kW = vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor?.[0]?.drivstoff?.[0]?.maksNettoEffekt;
            if (kW) {
              const hp = (kW * 1.36).toFixed(0);
              return `${kW} kW / ${hp} HP`;
            } else {
              return 'Ikke oppgitt';
            }
          })()}
        </p>
      </div>
    ) : (
      <p>Loading vehicle data...</p>
    );
  } else if (dealerCountry === 'SE') {
    return (
      <div className="svv-box">
        <p>Kan inte henta dator for Sverige</p>
      </div>
    );
  } else {
    return (
      <div className="svv-box">
        <p>Data is not available for this country</p>
      </div>
    );
  }
};

export default SvvDetails;
